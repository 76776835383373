import { Utils } from "../Utils/index"
import { BigNumber } from "bignumber.js"
import { beltPools } from "../constants/index"
import { BANANA, BISWAP, BSW, CAKE, TENFI, TENFARM } from "../abi"
import constants from "@src/constants.json"

export class UserPool {
  pool
  poolId = null
  utility
  tokenPriceList = {}
  beltTokenList = []
  alpacaTokenList = []
  available
  pending
  balance
  deposit
  availableUSD
  pendingUSD
  balanceUSD
  depositUSD
  beltPools = [...beltPools]

  constructor(
    provider,
    poolId,
    tokenPriceList = {},
    beltTokenList = [],
    alpacatokenList = []
  ) {
    this.utility = new Utils(provider)
    this.tokenPriceList = tokenPriceList
    this.beltTokenList = beltTokenList
    this.poolId = poolId
    this.alpacaTokenList = alpacatokenList
  }

  getUserPoolData = async (tenFarmContract, userAddress) => {
    const poolInfo = await tenFarmContract.methods.poolInfo(this.poolId).call()
    const contractAddress = poolInfo["want"]
    const vaultAddress = poolInfo["strat"]

    this.pool = this.utility.getPoolByAddress(contractAddress)
    const tokenContract = this.utility.getContractByAddress(
      this.pool.lp,
      contractAddress
    )

    const stratergyContract = this.utility.getContractByAddress(
      this.pool.strategy,
      vaultAddress
    )

    const balanceStruct = await tenFarmContract.methods
      .userInfo(this.poolId, userAddress)
      .call()

    const userShares = parseFloat(balanceStruct["shares"])

    const wantlockedtotal = await stratergyContract.methods
      .wantLockedTotal()
      .call()

    const shareTotalAqua = await stratergyContract.methods.sharesTotal().call()

    this.balance = new BigNumber(userShares).div(1e18).toNumber()

    this.deposit =
      (userShares * (wantlockedtotal / shareTotalAqua)) / Math.pow(10, 18)

    await this.getPendingTenFiClaim(userAddress, this.poolId)

    this.available =
      (await tokenContract.methods.balanceOf(userAddress).call()) / 1e18

    await this.getAssetPrice()

    const poolDetails = {
      id: this.poolId,
      name: this.pool.name,
      available: this.available,
      pending: this.pending,
      balance: this.balance,
      deposit: isNaN(this.deposit) ? 0 : this.deposit,
      available_usd: this.available * this.assetPrice,
      pending_usd: this.pending * this.tokenPriceList[TENFI],
      balance_usd: this.balance * this.assetPrice,
      deposit_usd: this.deposit * this.assetPrice,
    }

    return poolDetails
  }

  getAssetPrice = async () => {
    if (this.beltPools.includes(this.pool.name)) {
      this.assetPrice = this.tokenPriceList[this.pool.name]
    } else if (constants.alpacaTokens.includes(this.pool.name)) {
      if (this.tokenPriceList[this.pool.name]) {
        this.assetPrice = this.tokenPriceList[this.pool.name]
      } else {
        this.assetPrice = 0
      }
      return this.assetPrice
    } else if (
      this.pool.name === BANANA ||
      this.pool.name === BSW ||
      this.pool.name === CAKE ||
      this.pool.name === TENFI
    ) {
      this.assetPrice = this.tokenPriceList[this.pool.name]
    } else
      try {
        switch (this.pool.name) {
          case BISWAP:
            this.assetPrice = this.tokenPriceList[BSW]
            break
          default:
            const [reserve0, reserve1] = await this.utility.getTokenReserves(
              this.pool.lp,
              this.pool.address
            )

            const totalSupply = await this.utility.getTotalSupply(
              this.pool.lp,
              this.pool.address
            )

            this.assetPrice =
              (reserve0 * this.tokenPriceList[this.pool.pair[0]] +
                reserve1 * this.tokenPriceList[this.pool.pair[1]]) /
              totalSupply
        }
      } catch (error) {
        this.assetPrice = 0
      }
  }

  getPendingTenFiClaim = async (userAddress, poolId) => {
    try {
      const tenFarmContract = this.utility.getContract(TENFARM, TENFARM)

      this.pending =
        (await tenFarmContract.methods
          .pendingTENFI(poolId, userAddress)
          .call()) / 1e18
    } catch (error) {
      this.pending = 0
    }
  }
}

import { ReactComponent as Certik } from "@images/certik-logo.svg"
import { ReactComponent as PeckSheild } from "@images/peckshield.svg"

import constants from "@src/constants.json"
import { useHistory } from "react-router"

function Badge(props) {
  const history = useHistory()

  if (history.location.pathname === "/") {
    return (
      <a
        id={"badgeID"}
        style={{ pointerEvents: "none" }}
        href={constants.peckshieldLink}
        target={"_blank"}
        rel='noopener noreferrer'>
        <div
          className={
            "z-50 md:fixed bottom-10 right-8 flex flex-row space-x-4 shadow-lg items-center cursor-pointer " +
            "border md:rounded-lg " +
            "bg-white " +
            "dark:hover:bg-night-500 dark:hover:border-night-700 " +
            "dark:border-night-500 dark:text-white text-night-10 dark:bg-night-50 px-6 py-4 transition duration-300" +
            "flex justify-center"
          }>
          <PeckSheild style={{ width: 100 }} />

          {/* <span className={`text-green-1000 text-xs	`}>SKYNET</span> */}
          <lottie-player
            src={`./waves.json`}
            background='transparent'
            speed='1'
            style={{ width: `30px`, height: `30px` }}
            loop
            autoplay></lottie-player>
        </div>
      </a>
    )
  } else
    return (
      <a
        href={constants.certikLink}
        target={"_blank"}
        rel='noopener noreferrer'>
        <div
          className={
            "z-50 md:fixed bottom-10 right-8 flex flex-row space-x-4 shadow-lg items-center cursor-pointer " +
            "border md:rounded-lg " +
            "bg-white " +
            "dark:hover:bg-night-500 dark:hover:border-night-700 " +
            "dark:border-night-500 dark:text-white text-night-10 dark:bg-night-50 px-6 py-4 transition duration-300" +
            "flex justify-center"
          }>
          <Certik style={{ width: 100 }} />

          <span className={`text-green-1000 text-xs	`}>SKYNET</span>
          <lottie-player
            src={`./waves.json`}
            background='transparent'
            speed='1'
            style={{ width: `30px`, height: `30px` }}
            loop
            autoplay></lottie-player>
        </div>
      </a>
    )
}

export default Badge

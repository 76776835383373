import { lendingTokenMarkets } from "@src/utils/tokenMarketNames"
import { COMPTROLLER, UNICONTROLLER, CONTRACT } from "../abi"
import { LendingToken } from "../provider/lendingtoken"

export class LendingUserToken extends LendingToken {
  userAddress = null
  token = null
  xToken = null
  tokenBalance = 0
  xTokenBalance = 0
  underlyingBalance = 0
  supplyBalance = 0
  borrowBalance = 0
  tokenContract = null
  xTokenContract = null

  enterMarket = false

  supplyAllowance = false

  borrowLimit = 0
  supplyDistributedApy = 0
  borrowDistributedApy = 0

  supplyBalanceUsd = 0
  borrowBalanceUsd = 0
  price = 0
  isDepricated = false
  borrowLimit = 0

  netApy = 0
  assetsIn = []
  constructor(userAddress, token, assetsIn) {
    super(token)
    this.userAddress = userAddress
    this.token = lendingTokenMarkets[token]
    this.xToken = token
    this.assetsIn = assetsIn
  }

  getlendingUserTokenData = async () => {
    try {
      this.tokenContract = this.getContract(this.token, this.token)
      this.xTokenContract = this.getContract(this.xToken, this.xToken)

      this.price = await this.getUnderlyingPrice(CONTRACT[this.xToken])

      this.tokenBalance = await this.getUserAssetBalance(
        this.token,
        this.userAddress
      )
      this.xTokenBalance = await this.getxTokenBalance(
        this.userAddress,
        CONTRACT[this.xToken]
      )

      this.supplyBalance = await this.getSupplyBalance(
        this.userAddress,
        CONTRACT[this.xToken]
      )

      this.borrowBalance = await this.getBorrowBalance(
        this.userAddress,
        CONTRACT[this.xToken]
      )

      this.supplyBalanceUsd = this.supplyBalance * this.price
      this.borrowBalanceUsd = this.borrowBalance * this.price

      await this.getEnteredMarketStats()
      await this.getSupplyAllowance()

      this.isDepricated = await this.checkIsDepricated(CONTRACT[this.xToken])

      await this.getBorrowLimit()

      await this.getNetApy()

      return {
        xToken: this.xToken,
        token: this.token,
        tokenBalance: this.tokenBalance,
        xTokenBalance: this.xTokenBalance,
        supplyBalance: this.supplyBalance,
        borrowBalance: this.borrowBalance,
        enterMarket: this.enterMarket,
        supplyAllowance: this.supplyAllowance,
        borrowBalanceUsd: this.borrowBalanceUsd,
        supplyBalanceUsd: this.supplyBalanceUsd,
        isDepricated: this.isDepricated,
        borrowLimit: this.borrowLimit,
        netApy: this.netApy,
        price: this.price,
      }
    } catch (error) {
      throw error
    }
  }

  getEnteredMarketStats = async () => {
    try {
      const contract = this.getContract(COMPTROLLER, UNICONTROLLER)
      this.enterMarket = await contract.methods
        .checkMembership(this.userAddress, CONTRACT[this.xToken])
        .call()
      /* if (membershipStats) {
        this.enterMarket = true
      } else {
        this.enterMarket = false
      } */
    } catch (error) {
      throw error
    }
  }

  getSupplyAllowance = async () => {
    try {
      const supplyAllowance = await this.tokenContract.methods
        .allowance(this.userAddress, CONTRACT[this.xToken])
        .call()

      if (supplyAllowance === "0") {
        this.supplyAllowance = false
      } else {
        this.supplyAllowance = true
      }
    } catch (error) {
      throw error
    }
  }

  getBorrowLimit = async () => {
    try {
      const collateralFactor = await this.getCollateralFactor(
        CONTRACT[this.xToken]
      )

      this.borrowLimit = collateralFactor * this.supplyBalance * this.price
    } catch (error) {
      throw error
    }
  }

  getNetApy = async () => {
    try {
      const supplyApy = await this.getSupplyApy(
        this.xToken,
        CONTRACT[this.xToken]
      )
      const borrowApy = await this.getBorrowApy(
        this.xToken,
        CONTRACT[this.xToken]
      )

      const xTenfiSupplyApy = await this.getXTenfiSupplyApy(
        this.xToken,
        CONTRACT[this.xToken]
      )

      const xTenfiBorrowApy = await this.getXTenfiBorrowApy(
        this.xToken,
        CONTRACT[this.xToken]
      )

      const netSupplyApy =
        (this.supplyBalance * this.price * supplyApy) / 100 +
        (this.supplyBalance * this.price * xTenfiSupplyApy) / 100 +
        (this.borrowBalance * this.price * xTenfiBorrowApy) / 100

      const netBorrowApy =
        (-1 * this.borrowBalance * this.price * borrowApy) / 100

      this.netApy = netSupplyApy - netBorrowApy
    } catch (error) {
      throw error
    }
  }
}

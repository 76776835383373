export function numberWithCommas(x, dec) {
  if (!x) x = 0
  if (!dec) dec = 2
  return parseFloat(x)
    .toFixed(dec)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const convertNumFormat = (value, toFixedVal) => {
  const toFixed = !!toFixedVal ? toFixedVal : 2
  if (!!value) {
    let finalVal = parseFloat(value)
    if (finalVal > 1000000000 || finalVal < -1000000000) {
      finalVal = `${(finalVal / 1000000000).toFixed(toFixed)}B`
    } else if (finalVal >= 1000000 || finalVal <= -1000000) {
      finalVal = `${(finalVal / 1000000).toFixed(toFixed)}M`
    } else if (finalVal > 10000 || finalVal < -10000) {
      finalVal = `${(finalVal / 1000).toFixed(toFixed)}K`
    } else {
      finalVal = finalVal.toFixed(toFixed)
    }
    return finalVal
  } else {
    const zeroVal = "0"
    return parseFloat(zeroVal).toFixed(toFixed)
  }
}

export function commaFy(num) {
  var str = num.toString().split(".")
  if (str[0].length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,")
  }
  return str.join(".")
}

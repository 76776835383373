import Web3 from "web3"
import WalletLink from "walletlink"
import { BigNumber } from "bignumber.js"
import constants from "@src/constants.json"
import detectEthereumProvider from "@metamask/detect-provider"
import WalletConnectProvider from "@walletconnect/web3-provider"
import {
  ABI,
  BNB,
  BUSD,
  CONTRACT,
  getPoolByAddress,
  ICO1,
  MASTERCHEF,
  PANCAKE,
  PCSFARM,
  POOLS,
  TENFARM,
  TENFI,
  TENFI_LP,
  TENFIBUSD,
  TENFIV1,
  TENTOKEN,
  USD,
  USDC,
  USDT,
  BNBBUSD,
  BNBTENFI,
  CAKE,
  beltBTC,
  belt4BELT,
  beltBNB,
  beltETH,
  BANANA,
  APESWAP,
  TENFIBNB,
  BISTATERGY,
  BSWUSDT_BI,
  BIFARM,
  BISWAP,
  APESTATERGY,
  YIELDEXPOOLS,
  YIELDEXCONTRACT,
  YIELDEX_POOL_STABLE,
  YIELDEX_POOL_PREMIUM,
  YIELDEX_POOL_HIGH_YIELD,
  BUSDCHR,
  TENLOTS,
  TENLOTS_TRANSFER,
  YIELDEX_POOL_STABLE_NEW,
  YIELDEX_POOL_PREMIUM_NEW,
  YIELDEX_POOL_HIGH_YIELD_NEW,
  YIELDEX_POOL_BISWAP,
  YIELDEX_POOL_ALPACA_1,
  YIELDEX_POOL_ALPACA_2,
  BABY_YIELDEX,
} from "@web3/abi"
import axios from "@src/../node_modules/axios/index"
import { token_Balance } from "./transactions"
// import { funFairlogin } from "@src/utils/funWalletUtils"
import window from "@funfair-tech/wallet-sdk/window"

const NonCompoundedPools = [
  "0xd15C444F1199Ae72795eba15E8C1db44E47abF62",
  "0xac6EE351e2E9108f03c7F5c49296505B8F336Be3",
  "0x09F39f9B7d6395155396Fed7347620dD54Da1dc6",
]

const beltPools = [beltBTC, belt4BELT, beltBNB, beltETH]

const walletMainConnetId = constants.web3.mainNet
let httpProvider = null
let web3 = null
// let pancakeswapData = null
let gasPrice = null
let gasFee = null

// 10 minutes cache validity
const CACHE_VALID = 1000 * 10
const CACHE_ENABLED = false

export const METAMASK_PROVIDER = "METAMASK_PROVIDER"
export const BSC_PROVIDER = "BSC_PROVIDER"
export const WALLETCONNECT_PROVIDER = "WALLETCONNECT_PROVIDER"
export const MATHWALLET_PROVIDER = "MATHWALLET_PROVIDER"
export const COIN98_PROVIDER = "COIN98_PROVIDER"
export const ONTO_PROVIDER = "ONTO_PROVIDER"
export const SAFEPAL_PROVIDER = "SAFEPAL_PROVIDER"
export const FUNFAIR_PROVIDER = "FUNFAIR_PROVIDER"
export const BLANKWALLET_PROVIDER = "BLANKWALLET_PROVIDER"
export const COINBASE_PROVIDER = "COINBASE_PROVIDER"

export const BURN_FEE = {
  [TENTOKEN]: 1.5,
  [PANCAKE]: 3.0,
}
export const GAS_FEE = 0.3
export const GAS_PRICE = "5"
export const GAS = "250000"
export const NETWORK_FEE = 0.5
export const DEPOSIT_FEE = 0.1
export const WITHDRAW_FEE = 0.0
export const CAKES_PER_BLOCK = 40
export const BANANA_PER_BLOCK = 10
export const BSW_PER_BLOCK = 28

// export const CHAIN_ID = [56, 4]
export const CHAIN_ID = [56, 97]

//BLOCKS_PER_DAY varies acccording to network all values are approx and they keep changing
//BLOCKS_PER_DAY = 21600 for Kovan Testnet
export const BLOCKS_PER_DAY = 28800 //for BSC Testnet
//BLOCKS_PER_DAY = 6400 for Ethereum Mainnet
export const REWARD_PER_BLOCK = 10000000000

export function web3Provider() {
  if (web3 !== null) {
    ;(async function () {
      if (!(await isRightNetwork())) {
        alert("You are running on wrong network. Use ChainId = 56 on BSC")
      }
    })()

    return web3
  }
  if (httpProvider !== null) {
    return httpProvider
  }

  httpProvider = new Web3(
    new Web3.providers.HttpProvider(constants.web3.httpProvider)
  )
  return httpProvider
}

let _cache = {
  ...JSON.parse(localStorage.getItem("cache")),
}

function _cacheGet(object, path) {
  // last item left
  if (path.length === 1) {
    if (object[path[0]]) return object[path[0]]
    else return undefined
  }

  // no matching key
  if (!object[path[0]]) return undefined

  const newPath = [...path]
  newPath.shift()

  return _cacheGet(object[path[0]], newPath)
}

function _cacheSet(object = {}, path, value) {
  if (path.length === 0) return { value, changed: Date.now() }

  const newPath = [...path]
  newPath.shift()

  return {
    ...object,
    [path[0]]: _cacheSet(object[path[0]], newPath, value),
  }
}

function getCache(method, params) {
  if (!CACHE_ENABLED) return undefined

  const path = [method, ...params]
  const leaf = _cacheGet(_cache, path)

  if (leaf === undefined) return undefined

  const diff = Date.now() - leaf.changed

  if (diff > CACHE_VALID) {
    return undefined
  }

  return leaf.value
}

function setCache(method, params, values) {
  if (!CACHE_ENABLED) return undefined

  const path = [method, ...params]
  _cache = _cacheSet(_cache, path, values)

  // store cache to localstorage
  localStorage.setItem("cache", JSON.stringify(_cache))
}

export function commaFy(num) {
  var str = num.toString().split(".")
  if (str[0].length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,")
  }
  return str.join(".")
}

export const getCakePrice = async () => {}

export const addTenfiToken = async () => {
  try {
    if (walletConnected() && window.ethereum) {
      return await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: CONTRACT[TENFI], // The address that the token is at.
            symbol: TENFI, // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18, // The number of decimals in the token
            image: "https://bscscan.com/token/images/tenfinance_32.png", // A string url of the token logo
          },
        },
      })
    }
  } catch (e) {
    if (process.env.NODE_ENV === "development") console.log(e)
  }
}

export const walletConnected = () => {
  return web3 && web3.currentProvider !== undefined
}

export const onWalletChanged = () => {
  return new Promise((resolve, reject) => {
    window.ethereum &&
      window.ethereum.on("accountsChanged", function (accounts) {
        resolve(accounts[0])
      })
  })
}

export const getTokenYield = async (tokenYield, n) => {
  return (Math.pow(1 + ((tokenYield / 100) * n) / 365 / n, n) - 1) * 100
}

export const getAccounts = (web3, providerName) => {
  return new Promise(async (resolve, reject) => {
    try {
      switch (providerName) {
        case WALLETCONNECT_PROVIDER:
          web3.currentProvider
            .enable()
            .then(() => {
              resolve(web3.eth.getAccounts())
            })
            .catch(reject)
          break
        /* case FUNFAIR_PROVIDER:
          try {
            const account = await funFairlogin()

            resolve(account)
          } catch (error) {
            reject(error)
          }
          break */
        default:
          web3.currentProvider
            .request({
              method: "eth_requestAccounts",
            })
            .then((account) => {
              resolve(account)
            })
            .catch((err) => {
              reject(err)
            })
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const logoutWallet = async () => {
  if (web3.currentProvider.disconnect) {
    try {
      await web3.currentProvider.disconnect()
    } catch (error) {
      throw error
    }
  }

  localStorage.removeItem("provider-name")
  localStorage.setItem("disconnected", "1")
}

export const walletWasLoggedOut = () => {
  return localStorage.getItem("disconnected") === "1"
}

export const loginWalletEnable = () => {
  return localStorage.setItem("disconnected", "0")
}

export const getWeb3 = () => web3

function onError(...args) {
  if (process.env.NODE_ENV === "development") console.log(args)
}

export const getWeb3Instance = (providerName) => {
  return new Promise(async (resolve, reject) => {
    web3 = new Web3(Web3.givenProvider)

    // if (providerName !== FUNFAIR_PROVIDER) {
    //   ;(async function f() {
    //     if (!(await isRightNetwork())) {
    //       reject("You are running on wrong network. Use ChainId = 56 on BSC")
    //     }
    //   })()
    // }
    const { ethereum } = window
    switch (providerName) {
      case "MOBILE_CONNECT":
        try {
          web3.setProvider(Web3.givenProvider)
          resolve(web3)
        } catch (error) {
          reject("Wallet Not Found")
        }
        break
      case METAMASK_PROVIDER:
        if (ethereum && ethereum.isMetaMask) {
          detectEthereumProvider()
            .then((provider) => {
              web3.eth.setProvider(provider)
              resolve(web3)
            })
            .catch(reject)
        } else {
          reject("Metamask Wallet Not Found")
        }
        break
      case BSC_PROVIDER:
        ;(async function () {
          const provider = await window.BinanceChain
          if (provider) {
            // const web3 = new Web3(await window.BinanceChain)
            web3.setProvider(provider)
            resolve(web3)
          } else {
            reject("BSC wallet is not installed")
          }
        })()
        break
      case WALLETCONNECT_PROVIDER:
        web3.setProvider(
          new WalletConnectProvider({
            rpc: {
              56: walletMainConnetId,
              // [networkTestChainId]: walletTestConnetId,
            },
            chainId: 56,
            bridge: "https://bridge.walletconnect.org",
            qrcode: true,
            pollingInterval: 12000,
          })
        )
        resolve(web3)
        break
      case MATHWALLET_PROVIDER:
        if (ethereum && ethereum.isMathWallet) {
          web3.setProvider(ethereum)
          resolve(web3)
        } else {
          reject("Math Wallet Not Found")
        }
        break
      case COIN98_PROVIDER:
        if (window.ethereum.isCoin98 || window.coin98) {
          web3.setProvider(window.ethereum)
          resolve(web3)
        } else {
          reject("Coin98 Wallet Not Found")
        }
        break
      case ONTO_PROVIDER:
        if (window.onto) {
          try {
            web3.setProvider(window.onto)
            resolve(web3)
          } catch (error) {
            reject(error.message)
          }
        } else {
          try {
            web3.setProvider(Web3.givenProvider)
            resolve(web3)
          } catch (error) {
            reject("ONTO Wallet Not Found")
          }
        }
        break
      case SAFEPAL_PROVIDER:
        if (Web3.givenProvider) {
          try {
            web3.setProvider(Web3.givenProvider)
            resolve(web3)
          } catch (error) {
            reject("Error Connecting to wallet")
          }
        } else {
          reject("Error Connecting to wallet")
        }
        break
      case BLANKWALLET_PROVIDER:
        if (ethereum.isBlank) {
          web3.setProvider(ethereum)
          resolve(web3)
        } else {
          reject("Blank Wallet Not Found")
        }
        break
      case COINBASE_PROVIDER:
        try {
          const walletLink = new WalletLink({
            appName: "TEN FINANCE",
            appLogoUrl: "https://bscscan.com/token/images/tenfinance_32.png",
            darkMode: false,
          })
          const eth = walletLink.makeWeb3Provider(
            constants.web3.httpProvider,
            56
          )

          web3.setProvider(eth)
          resolve(web3)
        } catch (error) {
          reject("Coinbase Wallet Not Found")
        }

        break
      /* case FUNFAIR_PROVIDER:
        ;(async () => {
          try {
            if (
              window.funwallet &&
              window.funwallet.sdk &&
              window.funwallet.sdk.ethereum
            ) {
              web3 = new Web3(window.funwallet.sdk.ethereum)
              // const result = await web3Instance()
              // web3.setProvider(result)
              resolve(web3)
            } else {
              reject("Error in connectiong to Fun Wallet")
            }
          } catch (error) {
            reject(error)
          }
        })()
        break */
      default:
        reject("not a valid provider")
        break
    }
  })
}

export const toWei = (totalAmount) => {
  const web3 = new Web3()
  return web3.utils.toWei(totalAmount.toString(), "ether")
}

export const toGwei = (amount) => {
  return web3.utils.toWei(amount, "gwei")
}

export const toEther = (totalAmount) => {
  let x = new BigNumber(totalAmount)
  return x.div(1e18).toNumber()
}

export const toLp = (amount) => {
  return web3.utils.toWei(amount, "ether")
}

export const fromLp = (amount) => {
  return web3.utils.fromWei(amount, "ether")
}

export const getTenfiPrice = async () => {
  const cached = getCache("getTenfiPrice", [])
  if (cached) return cached

  const pancakeLPinstance = await LP.getContractByAddress(
    PANCAKE,
    CONTRACT[TENFI_LP]
  )
  const pancakeLPinstance3 = await LP.getContractByAddress(
    PANCAKE,
    POOLS[BNBBUSD].address
  )

  let reserveOfBUSDBNB = await pancakeLPinstance3.methods.getReserves().call()
  let getBNBReserve = await reserveOfBUSDBNB["_reserve0"]
  let getUSDReserve = await reserveOfBUSDBNB["_reserve1"]
  const bnbPrice = getUSDReserve / getBNBReserve

  let getReserves = await pancakeLPinstance.methods.getReserves().call()
  let reserve0 = parseFloat(await getReserves["_reserve0"])
  let reserve1 = parseFloat(await getReserves["_reserve1"])
  let bnbPerTENFI = reserve0 / reserve1
  const result = bnbPerTENFI * bnbPrice

  setCache("getTenfiPrice", [], result)
  return result
}

export const tokenPrice = async (tokenName) => {
  //return 1
  const USD_TOKENS = [BUSD, USDT, USDC, USD]
  const BNB_TOKENS = [BNB]
  const STABLES = [...BNB_TOKENS, ...USD_TOKENS]

  const cached = getCache("tokenPrice", [tokenName])
  if (cached) return cached

  // return stable coin price
  if (USD_TOKENS.includes(tokenName)) {
    return 1
  }

  let price = 0

  // TENFI
  if (tokenName === TENFI || tokenName === TENFIV1) price = getTenfiPrice()
  else if (beltPools.includes(tokenName)) {
    price = getBeltLPPrice(CONTRACT[tokenName])
  }
  // get price of BNB
  else if (BNB_TOKENS.includes(tokenName)) {
    const [reserve0, reserve1] = await LP.getLpTokenReserves(
      PANCAKE,
      POOLS[BNBBUSD].address
    )
    price = reserve1 / reserve0
  } else {
    // get price of all other tokens
    try {
      let pool = null

      for (const poolData of Object.values(POOLS)) {
        const containsStable = STABLES.filter((x) => poolData.pair.includes(x))

        if (poolData.pair.includes(tokenName) && containsStable) {
          pool = poolData
        }
      }
      if (!pool) return 0

      const [reserve0, reserve1] = await LP.getLpTokenReserves(
        PANCAKE,
        pool.address
      )

      const pair1Token = pool.pair[0]
      const pair2Token = pool.pair[1]
      const prices = {
        [pair1Token]: STABLES.includes(pair1Token)
          ? await tokenPrice(pair1Token)
          : (reserve1 / reserve0) * (await tokenPrice(pair2Token)),
        [pair2Token]: STABLES.includes(pair2Token)
          ? await tokenPrice(pair2Token)
          : (reserve0 / reserve1) * (await tokenPrice(pair1Token)),
      }
      if (typeof prices[tokenName] !== "number") {
        if (process.env.NODE_ENV === "development") console.log(tokenName)
      }
      price = prices[tokenName]
    } catch (err) {
      if (process.env.NODE_ENV === "development") console.log(err, tokenName)
      return 0
    }
  }
  setCache("tokenPrice", [tokenName], price)
  return price
}

export const LP = {
  getCurrentLpDeposit: async (userAddress, poolId) => {
    try {
      /* const tenfarmInstance = await LP.getContractByAddress(
        TENFARM,
        CONTRACT[TENFARM]
      )



      let currentLPdeposit = await tenfarmInstance.methods
        .userInfo(poolId, userAddress)
        .call()
      let x = new BigNumber(currentLPdeposit["shares"])
      return new BigNumber(x.div(1e18).toNumber()).toString() */
      const mainPool = await LP.getContractByAddress(TENFARM, CONTRACT[TENFARM])
      const poolDetails = await mainPool.methods.poolInfo(poolId).call()
      const contractAddr = poolDetails["want"]
      const vaultAddress = poolDetails["strat"]

      const pool = getPoolByAddress(contractAddr)
      const strategyContract = await LP.getContractByAddress(
        pool.strategy,
        vaultAddress
      )

      const balanceStruct = await mainPool.methods
        .userInfo(poolId, userAddress)
        .call()
      const userShares = parseFloat(balanceStruct["shares"])
      const wantlockedtotal = await strategyContract.methods
        .wantLockedTotal()
        .call()
      const shareTotalAqua = await strategyContract.methods.sharesTotal().call()
      const deposit =
        (userShares * (wantlockedtotal / shareTotalAqua)) / Math.pow(10, 18)

      return deposit
    } catch (err) {
      if (process.env.NODE_ENV === "development") console.log(err)
      onError(err)
      return 0
    }
  },
  getGasPrice: async () => {
    const cached = getCache("getGasPrice", [])
    if (cached) return cached

    if (gasPrice !== null) return gasPrice

    gasPrice = await web3Provider().eth.getGasPrice()
    setCache("getGasPrice", [], gasPrice)
    return gasPrice
  },
  getGasFee: async () => {
    const cached = getCache("getGasFee", [])
    if (cached) return cached

    try {
      if (gasFee !== null) return gasFee

      gasFee = await web3Provider().utils.fromWei(
        await LP.getGasPrice(),
        "Gwei"
      )
      setCache("getGasFee", [], gasFee)
      return gasFee
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e)
      onError(e)
      return 0
    }
  },
  getTvl: async (lp, contractAddr, strategyLp, strategyAddr, assetPrice) => {
    const cached = getCache("getTvl", [
      lp,
      contractAddr,
      strategyLp,
      strategyAddr,
    ])
    if (cached) return cached

    try {
      const strategy = await LP.getContractByAddress(strategyLp, strategyAddr)
      const tvl = await strategy.methods.wantLockedTotal().call()
      const result = new BigNumber(tvl)
        .div(1e18)
        .multipliedBy(assetPrice)
        .toNumber()
      setCache("getTvl", [lp, contractAddr, strategyLp, strategyAddr], result)
      return result
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e)
      onError(e)
      return 0
    }
  },
  getPairs: async (lp, contractAddr) => {
    const cached = getCache("getPairs", [lp, contractAddr])
    if (cached) return cached

    try {
      const contract = await LP.getContractByAddress(lp, contractAddr)

      // special case for TENFI
      if (!contract.methods.token0) return ["", ""]

      const token0Address = await contract.methods.token0().call()
      const token1Address = await contract.methods.token1().call()

      const result = [token0Address, token1Address]
      setCache("getPairs", [lp, contractAddr], result)
      return result
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e)
      onError(e, lp, contractAddr)
      return ["", ""]
    }
  },
  getPairsDetails: async (lp, contractAddr) => {
    const cached = getCache("getPairsDetails", [lp, contractAddr])
    if (cached) return cached

    try {
      const pairs = await LP.getPairs(lp, contractAddr)

      let pair1
      if (contractAddr === "0x2E28b9B74D6d99D4697e913b82B41ef1CAC51c6C")
        pair1 = {
          name: "TUSD Token",
          price: "1",
          price_BNB: "0.003000795608811686409113756898547239",
          symbol: "TUSD",
          address: pairs[0],
        }
      else
        pair1 = {
          // ...(await getPancakeswapData())[pairs[0]],
          symbol: Object.values(POOLS).filter(function (ele) {
            return ele.address.toLowerCase() === contractAddr.toLowerCase()
          })[0].pair[0],

          address: pairs[0],
        }

      const result = [
        {
          ...pair1,
        },
        {
          // ...(await getPancakeswapData())[pairs[1]],
          symbol: Object.values(POOLS).filter(function (ele) {
            return ele.address.toLowerCase() === contractAddr.toLowerCase()
          })[0].pair[1],
          address: pairs[1],
        },
      ]
      setCache("getPairsDetails", [lp, contractAddr], result)
      return result
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e)
      onError(e, lp, contractAddr)
      return [{}, {}]
    }
  },
  getTokenYield: async (tokenYield, n) => {
    const apy = (Math.pow(1 + ((tokenYield / 100) * n) / 365 / n, n) - 1) * 100
    return apy
  },
  getAPY: async (lp, address, strategy, strategyAddr, pool) => {
    if (beltPools.includes(strategy)) {
      const token = (await axios.get("https://s.belt.fi/info/all.json")).data
      let temp = token["info"]["BSC"]["vaultPools"]

      let apr

      if (strategy !== "belt4BELT") {
        temp.forEach((e) => {
          if (e.name === strategy) {
            apr = e.totalAPR
          }
        })
      } else {
        temp.forEach((e) => {
          if (e.name === "4Belt") {
            apr = e.totalAPR
          }
        })
      }
      // return apr
      return {
        apy_daily: apr / 365,
        apy: await getTokenYield(apr, 365),
      }
    }
    if (strategy === BISTATERGY) {
      try {
        const rewardTokenPrice = await LP.getBISWAPTokenAssetPrice()
        const LPInstance = await LP.getContractByAddress(PANCAKE, address)
        let BalanceOfMasterChef = await LPInstance.methods
          .balanceOf(CONTRACT[BIFARM])
          .call()

        const assetPrice =
          address.toLowerCase() ===
          "0x965F527D9159dCe6288a2219DB51fc6Eef120dD1".toLowerCase()
            ? rewardTokenPrice
            : await LP.getAssetPrice(pool)
        const _strategy = await LP.getContractByAddress(strategy, strategyAddr)
        const BSWInstance = await LP.getContract(MASTERCHEF, BIFARM)
        let PID = await _strategy.methods.pid().call()
        let allocPoint = await BSWInstance.methods
          .poolInfo(PID)
          .call()
          .then((data) => {
            return data.allocPoint
          })
        let totalAllocBSW = await BSWInstance.methods.totalAllocPoint().call()
        let bswEarnedPerDay =
          (BSW_PER_BLOCK * BLOCKS_PER_DAY * allocPoint) / totalAllocBSW

        const aprBSW =
          ((bswEarnedPerDay * rewardTokenPrice) /
            (parseFloat(BalanceOfMasterChef / Math.pow(10, 18)) * assetPrice)) *
          365 *
          100

        return {
          apy_daily: aprBSW / 365,
          apy: await getTokenYield(aprBSW, 365),
        }
      } catch (err) {
        console.log(err)
      }
    }
    if (strategy === APESTATERGY) {
      const bananaRewardTokenPrice = await LP.getBananaTokenAssetPrice()
      const lpInst = await LP.getContractByAddress(BANANA, address)
      const balance = await lpInst.methods.balanceOf(CONTRACT[APESWAP]).call()
      const assetPrice =
        address.toLowerCase() ===
        "0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95".toLowerCase()
          ? bananaRewardTokenPrice
          : await LP.getAssetPrice(pool)
      const apeInstance = LP.getContract(APESWAP, APESWAP)
      const strategyABI = await LP.getContractByAddress(strategy, strategyAddr)
      let PID = await strategyABI.methods.pid().call()
      let allocPoint = await apeInstance.methods
        .poolInfo(PID)
        .call()
        .then((data) => data.allocPoint)

      let totalAllocPoint = await apeInstance.methods.totalAllocPoint().call()

      let bananaEarnedPerDay =
        (BANANA_PER_BLOCK * BLOCKS_PER_DAY * allocPoint) / totalAllocPoint

      const aprBanana =
        ((bananaEarnedPerDay * bananaRewardTokenPrice) /
          (parseFloat(balance / Math.pow(10, 18)) * assetPrice)) *
        365 *
        100

      return {
        apy_daily: aprBanana / 365,
        apy: await getTokenYield(aprBanana, 365),
      }
    }
    try {
      const rewardTokenPrice = await tokenPrice("CAKE")
      // get balance of masterchef
      const LPInstance = await LP.getContractByAddress(PANCAKE, address)
      let BalanceOfMasterChef = await LPInstance.methods
        .balanceOf(CONTRACT["PCSFARM"])
        .call()
      const assetPrice =
        address.toLowerCase() === CONTRACT[CAKE].toLowerCase()
          ? rewardTokenPrice
          : await LP.getAssetPrice(pool)
      const strategyABI = await LP.getContractByAddress(strategy, strategyAddr)
      const PCSInstance = await LP.getContract(MASTERCHEF, PCSFARM)
      let PID = await strategyABI.methods.pid().call()

      let allocPoint = await PCSInstance.methods
        .poolInfo(PID)
        .call()
        .then((data) => {
          return data.allocPoint
        })
      let totalAllocCake = await PCSInstance.methods.totalAllocPoint().call()
      let cakeEarnedPerDay =
        (CAKES_PER_BLOCK * BLOCKS_PER_DAY * allocPoint) / totalAllocCake
      const apr =
        ((cakeEarnedPerDay * rewardTokenPrice) /
          (parseFloat(BalanceOfMasterChef / Math.pow(10, 18)) * assetPrice)) *
        365 *
        100

      const result = await getTokenYield(apr, 365)
      const apy = (address = "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
        ? new BigNumber(result)
        : new BigNumber(apr))
      return apy.isNaN() || !apy.isFinite()
        ? {
            apy_daily: 0.0 / 365,
            apy: 0.0,
          }
        : {
            apy_daily: apr / 365,
            apy: apy.toNumber(),
          }
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e)
      onError(e)
      return 0
    }
  },
  getAssetPrice: async (poolName) => {
    const cached = getCache("getAssetPrice", [poolName])
    if (cached) return cached

    try {
      const pool = POOLS[poolName]
      const [reserve0, reserve1] = await LP.getLpTokenReserves(
        pool.lp,
        pool.address
      )
      let reserve0tokenPrice, reserve1tokenPrice
      const totalSupply = await LP.getLpTokenTotalSupply(pool.lp, pool.address)

      if (pool.pair[0] === "BANANA") {
        reserve0tokenPrice = await LP.getBananaTokenAssetPrice()
      } else if (pool.pair[0] === "BSW") {
        reserve0tokenPrice = await LP.getBISWAPTokenAssetPrice()
      } else {
        reserve0tokenPrice = await tokenPrice(pool.pair[0])
      }

      if (poolName === BUSDCHR) {
        reserve1tokenPrice = (await tokenPrice(pool.pair[1])) / 1e12
      } else {
        reserve1tokenPrice = await tokenPrice(pool.pair[1])
      }
      // let result
      // if (poolName === BUSDCHR) {
      //   result =
      //     (reserve0 * reserve0tokenPrice + reserve1 * reserve1tokenPrice) /
      //     totalSupply
      // } else {
      const result =
        (reserve0 * reserve0tokenPrice + reserve1 * reserve1tokenPrice) /
        totalSupply
      // }

      setCache("getAssetPrice", [poolName], result)
      return result
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e)
      onError(e)
      return 0
    }
  },
  calculateLpTokenPrice: async (lp, address) => {
    const cached = getCache("calculateLpTokenPrice", [lp, address])
    if (cached) return cached

    let rewardTokenPrice = await getTenfiPrice()
    const tokenPriceCumulative = new BigNumber(1 * rewardTokenPrice).sqrt()
    const totalReserve = await LP.getLpTokenReserves(lp, address)

    // This is square root of (r0 * r1) with reference to the image above
    const tokenReserveCumulative = new BigNumber(totalReserve[0])
      .times(totalReserve[1])
      .sqrt()

    // Total Supply of LP Tokens in the Market
    const totalSupply = await LP.getLpTokenTotalSupply(lp, address)

    // Calculate LP Token Price in accordance to the image above
    const lpTokenPrice = tokenReserveCumulative
      .times(tokenPriceCumulative)
      .times(2)
      .div(totalSupply)

    // If lpTokenPrice is a valid number return lpTokenPrice or return 0
    const result =
      lpTokenPrice.isNaN() || !lpTokenPrice.isFinite()
        ? 0
        : lpTokenPrice.toNumber()

    setCache("calculateLpTokenPrice", [lp, address], result)
    return result
  },
  getLpTokenTotalSupply: async (lp, address) => {
    const cached = getCache("getLpTokenTotalSupply", [lp, address])
    if (cached) return cached

    try {
      const contract = LP.getContractByAddress(lp, address)
      const result = await contract.methods.totalSupply().call()

      setCache("getLpTokenTotalSupply", [lp, address], result)
      return result
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e)
      onError(e)
      return 0
    }
  },
  getContractByAddress: (lp, address) => {
    const result = new (web3Provider().eth.Contract)(ABI[lp], address)
    return result
  },
  getLpTokenReserves: async (lp, address) => {
    const cached = getCache("getLpTokenReserves", [lp, address])
    if (cached) return cached

    try {
      const contract = await LP.getContractByAddress(lp, address)

      // method checking, special for TENTOKEN which doesn't implement it
      if (contract.methods.getReserves) {
        const totalReserves = await contract.methods.getReserves().call()
        const result = [totalReserves[0], totalReserves[1]]
        setCache("getLpTokenReserves", [lp, address], result)
        return result
      }
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e)
      onError(e)
    }
    return [0, 0]
  },
  getContract: (lp, contractName) => {
    if (ABI[lp] && CONTRACT[contractName]) {
      try {
        return LP.getContractByAddress(lp, CONTRACT[contractName])
      } catch (error) {
        throw error
      }
    } else {
      return "ABI or contract name does not exists"
    }
  },
  getAPR: async (tvl, rewardPerBlock, poolAllocPoint, totalAllocPoint) => {
    const allocRatio = poolAllocPoint / totalAllocPoint
    const tokenPrice = await getTenfiPrice()
    const blockReward = rewardPerBlock * BLOCKS_PER_DAY
    return tvl > 0
      ? ((blockReward * allocRatio * tokenPrice) / tvl) * 365 * 100
      : blockReward * allocRatio * tokenPrice
  },
  getTenfiAPY: async () => {
    /*return
            tvl > 0
                ? ((tenPerBlock *
                28800 *
                (poolAllocPoint / totalAllocPoint) *
                (await getTenPrice())) /
                tvl) *
                365 *
                100
                : tenPerBlock *
                28800 *
                (poolAllocPoint / totalAllocPoint) *
                (await getTenPrice());*/
  },
  getBananaTokenAssetPrice: async () => {
    try {
      // const contract = LP.getContract(PANCAKE, "APESWAPFI")

      // const totalSupply = await contract.methods.totalSupply().call()
      const reserves = await LP.getBananaTokenReserves()
      const price = reserves[1] / reserves[0]
      return price
    } catch (error) {
      throw error
    }
  },

  getBananaTokenReserves: async () => {
    try {
      const contract = LP.getContract(PANCAKE, "APESWAPFI")
      const reserves = await contract.methods.getReserves().call()
      return [reserves[0], reserves[1]]
    } catch (error) {
      throw error
    }
  },

  getBISWAPTokenAssetPrice: async () => {
    try {
      const reserves = await LP.getBISWAP_USDTTokenReserves()
      const price = reserves[0] / reserves[1]
      return price
    } catch (error) {
      throw error
    }
  },

  getBISWAP_USDTTokenReserves: async () => {
    try {
      const contract = LP.getContract(PANCAKE, BSWUSDT_BI)
      const reserves = await contract.methods.getReserves().call()
      return [reserves[0], reserves[1]]
    } catch (error) {
      throw error
    }
  },
}

export const getBeltLPPrice = async (lpAddress) => {
  const token = (await axios.get("https://s.belt.fi/info/all.json")).data
  let temp = token["tokenList"]["BSC"]
  let assetPrice = 0

  temp.forEach((e) => {
    if (lpAddress.toLowerCase() === e.address.toLowerCase()) {
      assetPrice = e.price
    }
  })

  return assetPrice
}

// const getPancakeswapData = async () => {
//   // cache autofarm data
//   if (pancakeswapData != null) {
//     return pancakeswapData
//   }
//   const response = await fetch(constants.pools.pancake)
//   pancakeswapData = (await response.json()).data
//   return pancakeswapData
// }

export const getPendingTENClaim = async (userAddress, poolId) => {
  try {
    const tenfarmInstance = await LP.getContract(TENFARM, TENFARM)
    return toEther(
      await tenfarmInstance.methods.pendingTENFI(poolId, userAddress).call()
    )
  } catch (err) {
    if (process.env.NODE_ENV === "development") console.log(err)
    onError(err)
  }
}

export const getPoolUserData = async (mainPool, poolId, userAddress) => {
  const poolDetails = await mainPool.methods.poolInfo(poolId).call()
  const contractAddr = poolDetails["want"]
  const vaultAddress = poolDetails["strat"]

  const pool = getPoolByAddress(contractAddr)

  const tokenContract = await LP.getContractByAddress(pool.lp, contractAddr)
  const strategyContract = await LP.getContractByAddress(
    pool.strategy,
    vaultAddress
  )

  const balanceStruct = await mainPool.methods
    .userInfo(poolId, userAddress)
    .call()
  const userShares = parseFloat(balanceStruct["shares"])

  const wantlockedtotal = await strategyContract.methods
    .wantLockedTotal()
    .call()
  const shareTotalAqua = await strategyContract.methods.sharesTotal().call()

  const balance = new BigNumber(userShares).div(1e18).toNumber()
  const deposit =
    (userShares * (wantlockedtotal / shareTotalAqua)) / Math.pow(10, 18)
  const pending = await getPendingTENClaim(userAddress, poolId)
  const available = toEther(
    await tokenContract.methods.balanceOf(userAddress).call()
  )

  let assetPrice = 0

  if (beltPools.includes(pool.name)) {
    assetPrice = await getBeltLPPrice(CONTRACT[pool.name])
  } else {
    switch (pool.name) {
      case TENFI:
        assetPrice = await getTenfiPrice()
        break
      case CAKE:
        assetPrice = await tokenPrice("CAKE")
        break
      case BANANA:
        assetPrice = await LP.getBananaTokenAssetPrice()
        break
      case BISWAP:
        assetPrice = await LP.getBISWAPTokenAssetPrice()
        break
      default:
        assetPrice = await LP.getAssetPrice(pool.name)
    }
  }

  // const assetPrice =
  //   pool.name === TENFI
  //     ? await getTenfiPrice()
  //     : pool.name === CAKE
  //     ? await tokenPrice("CAKE")
  //     : beltPools.includes(pool.name)
  //     ? await getBeltLPPrice(CONTRACT[pool.name])
  //     : pool.name === BANANA
  //     ? await LP.getBananaTokenAssetPrice()
  //     : pool.name === "BISWAP"
  //     ? await LP.getBISWAPTokenAssetPrice()
  //     : await LP.getAssetPrice(pool.name)

  return {
    id: poolId,
    name: pool.name,
    available,
    pending,
    balance,
    deposit: isNaN(deposit) ? 0 : deposit,
    available_usd: available * assetPrice,
    pending_usd: pending * (await getTenfiPrice()),
    balance_usd: balance * assetPrice,
    deposit_usd: deposit * assetPrice,
  }
}

export const getTokenBalance = async (userAddress, lpName, tokenName) => {
  const tokenContract = await LP.getContractByAddress(
    lpName,
    CONTRACT[tokenName]
  )
  const balance = toEther(
    await tokenContract.methods.balanceOf(userAddress).call()
  )
  return {
    name: tokenName,
    balance,
    balance_usd: balance * (await tokenPrice(tokenName)),
  }
}

const isRightNetwork = async () => {
  if (web3 && web3.eth) {
    const chainId = await web3.eth.getChainId()
    if (!CHAIN_ID.includes(chainId)) {
      return false
    }
  }
  return true
}

export const getUserData = async (userAddress) => {
  if (!(await isRightNetwork())) {
    return Promise.reject(
      "You are running on wrong network. Use ChainId = 56 on BSC"
    )
  }
  web3.eth.defaultAccount = userAddress
  const mainPool = await LP.getContract(TENFARM, TENFARM)
  // const poolLength = await mainPool.methods.poolLength().call()

  let pools = {}
  let deposited_usd = 0
  let rewards = 0
  let rewards_usd = 0
  // for (let i = 0; i < poolLength; i++) {
  //   const poolData = await getPoolUserData(mainPool, i, userAddress)
  //   deposited_usd += isNaN(poolData.deposit_usd) ? 0 : poolData.deposit_usd
  //   rewards += poolData.pending
  //   rewards_usd += poolData.pending_usd
  //   pools[poolData.name] = poolData
  // }
  let allPoolsDetails = []

  for (let i = 0; i < 69; i++) {
    if (constants.poolIdExcluded.includes(i)) {
      continue
    }
    allPoolsDetails.push(getPoolUserData(mainPool, i, userAddress))
  }

  let allPoolData = await Promise.all(allPoolsDetails)
  allPoolData.forEach((element) => {
    pools[element.name] = element
    deposited_usd += isNaN(element.deposit_usd) ? 0 : element.deposit_usd
    rewards += element.pending
    rewards_usd += element.pending_usd
  })

  const tokenBalance = {
    [BUSD]: await getTokenBalance(userAddress, PANCAKE, BUSD),
    [BNB]: await getTokenBalance(userAddress, PANCAKE, BNB),
    [TENFIV1]: await getTokenBalance(userAddress, ICO1, TENFIV1),
    [TENFI]: await getTokenBalance(userAddress, TENTOKEN, TENFI),
  }

  let yieldexPools
  try {
    yieldexPools = await getAllYieldexUserData(userAddress)
    // yieldexPools = await getAllYieldexUserData(
    //   "0xaDC83042Db3a395E8e580A785eB0310B9aF9a6a3"
    // )
  } catch (error) {
    yieldexPools = {}
  }

  return {
    pools,
    yieldexPools,
    tokenBalance,
    deposited_usd,
    rewards,
    rewards_usd,
  }
}

export const getLpSwapLink = (pair) => {
  return `${constants.lpSwapLink}${pair
    .map((token) => CONTRACT[token])
    .join("/")}`
}

const getPoolData = async (
  mainPool,
  poolIndex,
  extraParams = {
    rewardsPerBlock: 0,
  }
) => {
  const poolDetails = await mainPool.methods.poolInfo(poolIndex).call()
  const contractAddr = poolDetails["want"]
  const strategyAddr = poolDetails["strat"]

  const pool = getPoolByAddress(contractAddr)

  const poolAllocPoint = parseFloat(poolDetails["allocPoint"])
  const totalAllocPoint = parseFloat(
    await mainPool.methods.totalAllocPoint().call()
  )
  const tenfiPrice = await getTenfiPrice()
  const totalSupply = await LP.getLpTokenTotalSupply(pool.lp, contractAddr)

  let poolData = {
    // token: {
    //   // ...pool,

    // },
    farm_address: contractAddr,
    vault_address: strategyAddr,
    gas_price: await LP.getGasPrice(),
    gas_fee: await LP.getGasFee(),
    burn_fee: BURN_FEE,
    deposit_fee: DEPOSIT_FEE,
    withdraw_fee: WITHDRAW_FEE,
    apr: 0, // differs each token
    rewardPerBlock: parseFloat(extraParams.rewardsPerBlock),
    pair: await LP.getPairsDetails(pool.lp, contractAddr),
    totalSupply: totalSupply,
  }

  let price = 0
  let reserves = []

  // let rewardsPerBlock = extraParams.rewardsPerBlock

  if (beltPools.includes(pool.name)) {
    price = await getBeltLPPrice(contractAddr)
  } else {
    switch (pool.name) {
      case CAKE:
        price = await tokenPrice("CAKE")
        break
      case TENFI:
        price = tenfiPrice
        break
      case BNBTENFI:
        const reservesBNB = await LP.getLpTokenReserves(
          POOLS[BNBBUSD].lp,
          POOLS[BNBBUSD].address
        )
        reserves = await LP.getLpTokenReserves(pool.lp, contractAddr)
        const reservesRatio = reservesBNB[1] / reservesBNB[0]
        price =
          (reserves[1] * tenfiPrice + reserves[0] * reservesRatio) / totalSupply
        break
      case TENFIBUSD:
        reserves = await LP.getLpTokenReserves(pool.lp, contractAddr)
        price = (tenfiPrice * reserves[0] + 1 * reserves[1]) / totalSupply
        break
      case BANANA:
        price = await LP.getBananaTokenAssetPrice()
        reserves = await LP.getBananaTokenReserves()
        break
      case BISWAP:
        price = await LP.getBISWAPTokenAssetPrice()
        reserves = await LP.getBISWAP_USDTTokenReserves()
        break
      default:
        reserves = await LP.getLpTokenReserves(pool.lp, contractAddr)
        price = await LP.getAssetPrice(pool.name)
        // rewardsPerBlock = CAKES_PER_BLOCK
        break
    }
  }

  const tvl = await LP.getTvl(
    pool.lp,
    contractAddr,
    pool.strategy,
    strategyAddr,
    price
  )
  const apr = await LP.getAPR(
    tvl,
    extraParams.rewardsPerBlock,
    poolAllocPoint,
    totalAllocPoint
  )

  let { apy, apy_daily } = await LP.getAPY(
    pool.lp,
    pool.address,
    pool.strategy,
    strategyAddr,
    pool.name
  )

  // let apy = await LP.getAPY(
  //   pool.lp,
  //   pool.address,
  //   pool.strategy,
  //   strategyAddr,
  //   pool.name
  // )
  const compoundedAPY = await LP.getTokenYield(apr, 365)
  const newAPY = apr + compoundedAPY * 100
  let m = 365
  let a = newAPY / 100 + 1
  a = (Math.pow(a, 1 / m) - 1) * m
  const farmAPR = (a * 100) / m

  const totalAPY = NonCompoundedPools.includes(contractAddr)
    ? compoundedAPY
    : apy + apr

  let gasFee = GAS_FEE
  let networkFee = NETWORK_FEE
  let burnFee = BURN_FEE[pool.name.includes(TENFI) ? TENTOKEN : PANCAKE]

  if (
    contractAddr === CONTRACT[TENFI] ||
    contractAddr === CONTRACT[TENFIBUSD] ||
    contractAddr === CONTRACT[TENFIBNB]
  ) {
    gasFee = 0.0
    networkFee = 0.0
    burnFee = 0.0
  }

  return {
    name: pool.name,
    id: poolIndex,
    ...poolData,
    tvl,
    reserves,
    apy,
    totalAPY,
    price,
    apr,
    farmAPR,
    newAPY,
    lpLink: pool.lpLink ? pool.lpLink : getLpSwapLink(pool.pair),
    gas_fee: gasFee,
    network_fee: networkFee,
    burn_fee: burnFee,
    deposit_fee: DEPOSIT_FEE,
    apr_daily: apr / 365,
    apy_daily: apy_daily,
  }
}

export const getMainPoolLength = async () => {
  const cached = getCache("getMainPoolLength", [])
  if (cached) return cached

  const mainPool = await LP.getContract(TENFARM, TENFARM)
  const length = await mainPool.methods.poolLength().call()

  setCache("getMainPoolLength", [], length)
  return length
}

export const getAllPoolsData = async () => {
  const mainPool = await LP.getContract(TENFARM, TENFARM)
  // const poolLength = await mainPool.methods.poolLength().call()
  const rewardsPerBlock = toEther(await mainPool.methods.TENFIPerBlock().call())

  const allPoolsDetails = []
  for (let i = 0; i < 69; i++) {
    if (constants.poolIdExcluded.includes(i)) {
      continue
    }
    allPoolsDetails.push(
      getPoolData(mainPool, i, {
        rewardsPerBlock,
      })
    )
  }

  return await Promise.all(allPoolsDetails)
}

export const getPlatformData = async () => {
  return new Promise((resolve, reject) => {
    ;(async function f() {
      if (!(await isRightNetwork())) {
        reject("You are running on wrong network. Use ChainId = 56 on BSC")
      }

      const platformData = {
        tvl: 0,
        tenfi_usd: await getTenfiPrice(),
      }

      const poolsDetails = await getAllPoolsData()
      if (!poolsDetails) reject("unable to get pool length")

      poolsDetails.forEach((pool) => {
        platformData.tvl += pool["tvl"]
        // @TODO user stuff
      })

      resolve({
        platformData,
        poolsDetails: [...poolsDetails].reduce(
          (acc, cur) => ({ ...acc, [cur.id]: cur }),
          {}
        ),
      })
    })()
  })
}

export const getWeightedApy = (pools, balances) => {
  const poolsArray = Object.keys(pools).map((key) => ({ ...pools[key] }))
  const poolApys = poolsArray.map((pool) => pool.apy)
  const poolApr = poolsArray.map((pool) => pool.apr)
  const poolBalances = Object.values(balances).map(
    (poolBalance) => poolBalance.balance_usd
  )

  const sumBalance = poolBalances.reduce((sum, x) => sum + x)
  let average = 0

  for (let i = 0; i < poolApys.length; i++) {
    average += (poolApys[i] + poolApr[i]) * (poolBalances[i] / sumBalance)
  }

  return average
}

export const checkIfWalletAlreadyConnected = () => {
  return new Promise((resolve) => {
    let provider = null
    // Check if browser is running Metamask
    if (window.ethereum) {
      web3 = new Web3(window.ethereum)
      provider = window.ethereum
    } else if (window.web3) {
      web3 = new Web3(window.web3.currentProvider)
      provider = window.web3.currentProvider
    }

    // Check if User is already connected by retrieving the accounts
    if (web3 && web3.eth && web3.currentProvider) {
      web3.eth.getAccounts().then(async (addr) => {
        if (addr.length > 0) resolve(addr[0], provider)
      })
    }
  })
}

export const getAllYieldexPoolData = async () => {
  const mainPool = await LP.getContract(TENFARM, TENFARM)
  const totalAllocPoint = parseFloat(
    await mainPool.methods.totalAllocPoint().call()
  )
  const rewardsPerBlock = toEther(await mainPool.methods.TENFIPerBlock().call())
  const fees = {
    entranceFee: 0.1,
    gasFee: GAS_FEE,
    networkFee: NETWORK_FEE,
    burnFee: BURN_FEE[PANCAKE],
    depositFee: 1.0,
    withdrawFee: WITHDRAW_FEE,
  }

  try {
    const yieldexStablePoolsNew = await getYieldexInternalPoolsData(
      YIELDEX_POOL_STABLE_NEW,
      mainPool,
      totalAllocPoint,
      rewardsPerBlock
    )

    const yieldexPremiumPoolsNew = await getYieldexInternalPoolsData(
      YIELDEX_POOL_PREMIUM_NEW,
      mainPool,
      totalAllocPoint,
      rewardsPerBlock
    )

    const yieldexHighYieldPoolsNew = await getYieldexInternalPoolsData(
      YIELDEX_POOL_HIGH_YIELD_NEW,
      mainPool,
      totalAllocPoint,
      rewardsPerBlock
    )

    const yieldexStablePools = await getYieldexInternalPoolsData(
      YIELDEX_POOL_STABLE,
      mainPool,
      totalAllocPoint,
      rewardsPerBlock
    )

    const yieldexPremiumPools = await getYieldexInternalPoolsData(
      YIELDEX_POOL_PREMIUM,
      mainPool,
      totalAllocPoint,
      rewardsPerBlock
    )

    const yieldexHighYieldPools = await getYieldexInternalPoolsData(
      YIELDEX_POOL_HIGH_YIELD,
      mainPool,
      totalAllocPoint,
      rewardsPerBlock
    )
    return {
      [YIELDEX_POOL_HIGH_YIELD_NEW]: {
        pools: yieldexHighYieldPoolsNew,
        ...fees,
        ...getYieldexPoolData(
          yieldexHighYieldPoolsNew,
          YIELDEX_POOL_HIGH_YIELD_NEW
        ),
        poolId: 0,
      },
      [YIELDEX_POOL_PREMIUM_NEW]: {
        pools: yieldexPremiumPoolsNew,
        ...fees,
        ...getYieldexPoolData(yieldexPremiumPoolsNew, YIELDEX_POOL_PREMIUM_NEW),
        poolId: 0,
      },
      [YIELDEX_POOL_STABLE_NEW]: {
        pools: yieldexStablePoolsNew,
        ...fees,
        ...getYieldexPoolData(yieldexStablePoolsNew, YIELDEX_POOL_STABLE_NEW),
        poolId: 0,
      },
      [YIELDEX_POOL_STABLE]: {
        pools: yieldexStablePools,
        ...fees,
        ...getYieldexPoolData(yieldexStablePools, YIELDEX_POOL_STABLE),
        poolId: 0,
      },
      [YIELDEX_POOL_PREMIUM]: {
        pools: yieldexPremiumPools,
        ...fees,
        ...getYieldexPoolData(yieldexPremiumPools, YIELDEX_POOL_PREMIUM),
        poolId: 0,
      },
      [YIELDEX_POOL_HIGH_YIELD]: {
        pools: yieldexHighYieldPools,
        ...fees,
        ...getYieldexPoolData(yieldexHighYieldPools, YIELDEX_POOL_HIGH_YIELD),
        poolId: 0,
      },
    }
  } catch (error) {
    throw error
  }
}

export const getYieldexPoolData = (pools, type) => {
  if (pools && pools.length) {
    let yieldexApy = 0
    if (type === YIELDEX_POOL_HIGH_YIELD_NEW) {
      yieldexApy =
        pools.reduce((sum, val) => sum + val.apy * val.allocation, 0) / 100
    } else if (type === YIELDEX_POOL_PREMIUM_NEW) {
      yieldexApy =
        pools.reduce((sum, val) => sum + val.apy * val.allocation, 0) / 100
    } else if (type === YIELDEX_POOL_STABLE_NEW) {
      yieldexApy = pools.reduce((sum, val) => sum + val.apy, 0) / 5
    } else if (type === YIELDEX_POOL_STABLE) {
      yieldexApy = pools.reduce((sum, val) => sum + val.apy, 0) / 5
    } else if (type === YIELDEX_POOL_PREMIUM) {
      yieldexApy =
        pools.reduce((sum, val) => sum + val.apy * val.allocation, 0) / 100
    } else if (type === YIELDEX_POOL_HIGH_YIELD) {
      yieldexApy =
        pools.reduce((sum, val) => sum + val.apy * val.allocation, 0) / 100
    }

    const rewardsApr =
      pools.reduce((sum, val) => sum + val.apr * parseInt(val.allocation), 0) /
      100

    const tvl = pools.reduce((sum, val) => sum + val.valuesLocked, 0)

    const yieldexApyDaily = yieldexApy / 365
    const rewardsAprDaily = rewardsApr / 365

    const total = yieldexApy + rewardsApr
    const totalDaily = yieldexApy / 365 + rewardsApr / 365

    return {
      yieldexApy,
      rewardsApr,
      tvl,
      yieldexApyDaily,
      rewardsAprDaily,
      total,
      totalDaily,
    }
  } else {
    return {
      yieldexApy: 0,
      rewardsApr: 0,
      tvl: 0,
      yieldexApyDaily: 0,
      rewardsAprDaily: 0,
      total: 0,
      totalDaily: 0,
    }
  }
}

export const getYieldexInternalPoolsData = async (
  poolType,
  mainPool,
  totalAllocPoint,
  rewardsPerBlock
) => {
  if (
    poolType === YIELDEX_POOL_STABLE_NEW ||
    poolType === YIELDEX_POOL_PREMIUM_NEW ||
    poolType === YIELDEX_POOL_HIGH_YIELD_NEW
  ) {
    let filterBy
    if (poolType === YIELDEX_POOL_STABLE_NEW) {
      filterBy = YIELDEX_POOL_STABLE
    } else if (poolType === YIELDEX_POOL_PREMIUM_NEW) {
      filterBy = YIELDEX_POOL_PREMIUM
    } else {
      filterBy = YIELDEX_POOL_HIGH_YIELD
    }
    return await Promise.all(
      YIELDEXPOOLS.filter((pool) => pool.type.includes(filterBy)).map(
        async (pool) => {
          const poolDetails = await mainPool.methods
            .poolInfo(pool.poolId)
            .call()

          const strategyAddr = poolDetails["strat"]
          const contractAddr = poolDetails["want"]
          const poolAllocPoint = parseFloat(poolDetails["allocPoint"])

          const userInfo = await mainPool.methods
            .stakedWantTokens(pool.poolId, CONTRACT[poolType])
            .call()

          const price = await LP.getAssetPrice(pool.name)

          const valuesLocked = (userInfo / 1e18) * price

          let { apy } = await LP.getAPY(
            pool.lp,
            pool.address,
            pool.strategy,
            strategyAddr,
            pool.name
          )

          const tvl = await LP.getTvl(
            pool.lp,
            contractAddr,
            pool.strategy,
            strategyAddr,
            price
          )
          const apr = await LP.getAPR(
            tvl,
            rewardsPerBlock,
            poolAllocPoint,
            totalAllocPoint
          )

          return {
            ...pool,
            apy,
            apr,
            pair: pool.pair.map((val) => ({
              symbol: val,
              address: CONTRACT[val],
            })),
            vaultAddress: strategyAddr,
            valuesLocked,
          }
        }
      )
    )
  } else {
    return await Promise.all(
      YIELDEXPOOLS.filter((pool) => pool.type.includes(poolType)).map(
        async (pool) => {
          const poolDetails = await mainPool.methods
            .poolInfo(pool.poolId)
            .call()

          const strategyAddr = poolDetails["strat"]
          const contractAddr = poolDetails["want"]
          const poolAllocPoint = parseFloat(poolDetails["allocPoint"])

          const userInfo = await mainPool.methods
            .stakedWantTokens(pool.poolId, CONTRACT[poolType])
            .call()

          const price = await LP.getAssetPrice(pool.name)

          const valuesLocked = (userInfo / 1e18) * price

          let { apy } = await LP.getAPY(
            pool.lp,
            pool.address,
            pool.strategy,
            strategyAddr,
            pool.name
          )

          const tvl = await LP.getTvl(
            pool.lp,
            contractAddr,
            pool.strategy,
            strategyAddr,
            price
          )
          const apr = await LP.getAPR(
            tvl,
            rewardsPerBlock,
            poolAllocPoint,
            totalAllocPoint
          )

          return {
            ...pool,
            apy,
            apr,
            pair: pool.pair.map((val) => ({
              symbol: val,
              address: CONTRACT[val],
            })),
            vaultAddress: strategyAddr,
            valuesLocked,
          }
        }
      )
    )
  }
}

export const getYieldexDeposit = async (
  poolId,
  userAddress,
  yieldPoolId,
  poolName,
  poolType
) => {
  let yieldexPool
  if (
    poolType === YIELDEX_POOL_STABLE_NEW ||
    poolType === YIELDEX_POOL_PREMIUM_NEW ||
    poolType === YIELDEX_POOL_HIGH_YIELD_NEW
  ) {
    yieldexPool = await LP.getContract(poolType, poolType)
  } else {
    yieldexPool = await LP.getContract(YIELDEXCONTRACT, poolType)
  }

  try {
    const userShare = await yieldexPool.methods
      .userShare(poolId, userAddress, yieldPoolId)
      .call()

    const deposit =
      ((userShare["0"] * 0.99) / 1e18) * (await LP.getAssetPrice(poolName))

    const valuesLocked =
      (userShare["1"] / 1e18) * (await LP.getAssetPrice(poolName))

    return { deposit, userShare: userShare["0"] / 1e18, valuesLocked }
  } catch (error) {
    console.log("ERRRRRRRRRRRRRRRRRRRRRRRRRR")
    console.log(error)
    throw error
  }
}

const getYieldexTenfiPending = async (
  poolId,
  userAddress,
  yieldPoolId,
  poolType
) => {
  let yieldexPool
  if (
    poolType === YIELDEX_POOL_STABLE_NEW ||
    poolType === YIELDEX_POOL_PREMIUM_NEW ||
    poolType === YIELDEX_POOL_HIGH_YIELD_NEW
  ) {
    yieldexPool = LP.getContract(poolType, poolType)
  } else {
    yieldexPool = await LP.getContract(YIELDEXCONTRACT, poolType)
  }

  try {
    const reward = await yieldexPool.methods
      .returnReward(poolId, userAddress, yieldPoolId)
      .call()

    const pendingTenfiReward = (reward / 1e18) * (await getTenfiPrice())

    return {
      pendingTenfiReward,
      reward: reward / 1e18,
    }
  } catch (error) {
    return {
      pendingTenfiReward: 0,
      reward: 0,
    }
  }
}

export const getAllYieldexUserData = async (userAddress) => {
  const yieldexStablePoolsNew = await getYieldexUserPoolData(
    userAddress,
    YIELDEX_POOL_STABLE_NEW,
    0
  )
  const yieldexStablePools = await getYieldexUserPoolData(
    userAddress,
    YIELDEX_POOL_STABLE,
    0
  )
  const yieldexPremiumPools = await getYieldexUserPoolData(
    userAddress,
    YIELDEX_POOL_PREMIUM,
    0
  )
  const yieldexPremiumPoolsNew = await getYieldexUserPoolData(
    userAddress,
    YIELDEX_POOL_PREMIUM_NEW,
    0
  )
  const yieldexHighYieldPools = await getYieldexUserPoolData(
    userAddress,
    YIELDEX_POOL_HIGH_YIELD,
    0
  )

  const yieldexHighYieldPoolsNew = await getYieldexUserPoolData(
    userAddress,
    YIELDEX_POOL_HIGH_YIELD_NEW,
    0
  )

  const yieldexBiswapYieldPools = await getYieldexUserPoolData(
    userAddress,
    YIELDEX_POOL_BISWAP,
    0
  )

  return {
    [YIELDEX_POOL_HIGH_YIELD_NEW]: {
      pools: yieldexHighYieldPoolsNew,
      ...(await getYieldexUserData(yieldexHighYieldPoolsNew, userAddress)),
    },
    [YIELDEX_POOL_STABLE_NEW]: {
      pools: yieldexStablePoolsNew,
      ...(await getYieldexUserData(yieldexStablePoolsNew, userAddress)),
    },
    [YIELDEX_POOL_PREMIUM_NEW]: {
      pools: yieldexPremiumPoolsNew,
      ...(await getYieldexUserData(yieldexPremiumPoolsNew, userAddress)),
    },
    [YIELDEX_POOL_STABLE]: {
      pools: yieldexStablePools,
      ...(await getYieldexUserData(yieldexStablePools, userAddress)),
    },
    [YIELDEX_POOL_PREMIUM]: {
      pools: yieldexPremiumPools,
      ...(await getYieldexUserData(yieldexPremiumPools, userAddress)),
    },
    [YIELDEX_POOL_HIGH_YIELD]: {
      pools: yieldexHighYieldPools,
      ...(await getYieldexUserData(yieldexHighYieldPools, userAddress)),
    },
    [YIELDEX_POOL_BISWAP]: {
      pools: yieldexBiswapYieldPools,
      ...(await getYieldexUserData(yieldexBiswapYieldPools, userAddress)),
    },
  }
}

export const getYieldexUserData = async (pools, userAddress) => {
  const poolsArray = Object.keys(pools).map((keys) => ({ ...pools[keys] }))
  if (poolsArray && poolsArray.length) {
    const depositedUSD = poolsArray.reduce((sum, val) => sum + val.deposit, 0)
    const pendingUSD = poolsArray.reduce(
      (sum, val) => sum + val.pendingTenfi,
      0
    )
    const pendingTenfi = poolsArray.reduce(
      (sum, val) => sum + val.tenfiReward,
      0
    )
    const availableUSD =
      (await token_Balance(BNB, userAddress)) * (await tokenPrice(BNB))
    return {
      depositedUSD,
      pendingTenfi,
      pendingUSD,
      availableUSD,
    }
  } else {
    return {
      depositedUSD: 0,
      pendingTenfi: 0,
      pendingUSD: 0,
      availableUSD: 0,
    }
  }
}

export const getYieldexUserPoolData = async (userAddress, poolType, poolId) => {
  // const mainPool = await LP.getContract(TENFARM, TENFARM)

  if (
    poolType === YIELDEX_POOL_STABLE_NEW ||
    poolType === YIELDEX_POOL_PREMIUM_NEW ||
    poolType === YIELDEX_POOL_HIGH_YIELD_NEW
  ) {
    let filterBy
    if (poolType === YIELDEX_POOL_PREMIUM_NEW) {
      filterBy = YIELDEX_POOL_PREMIUM
    } else if (poolType === YIELDEX_POOL_STABLE_NEW) {
      filterBy = YIELDEX_POOL_STABLE
    } else {
      filterBy = YIELDEX_POOL_HIGH_YIELD
    }
    return (
      await Promise.all(
        YIELDEXPOOLS.filter((pool) => pool.type.includes(filterBy)).map(
          async (pool, i) => {
            // const poolDetails = await mainPool.methods.poolInfo(pool.poolId).call()
            // const strategyAddr = poolDetails["strat"]

            const { deposit, userShare, valuesLocked } =
              await getYieldexDeposit(
                pool.index,
                userAddress,
                poolId,
                pool.name,
                poolType
              )

            const { pendingTenfiReward, reward } = await getYieldexTenfiPending(
              pool.index,
              userAddress,
              poolId,
              poolType
            )

            // let { apy } = await LP.getAPY(
            //   pool.lp,
            //   pool.address,
            //   pool.strategy,
            //   strategyAddr,
            //   pool.name
            // )

            return {
              name: pool.name,
              id: pool.poolId,
              deposit,
              userShare,
              pendingTenfi: pendingTenfiReward,
              tenfiReward: reward,
              valuesLocked,
            }
          }
        )
      )
    ).reduce((acc, cur) => ({ ...acc, [cur.name]: cur }), {})
  } else {
    return (
      await Promise.all(
        YIELDEXPOOLS.filter((pool) => pool.type.includes(poolType)).map(
          async (pool, i) => {
            // const poolDetails = await mainPool.methods.poolInfo(pool.poolId).call()
            // const strategyAddr = poolDetails["strat"]

            const { deposit, userShare, valuesLocked } =
              await getYieldexDeposit(
                pool.index,
                userAddress,
                poolId,
                pool.name,
                poolType
              )

            const { pendingTenfiReward, reward } = await getYieldexTenfiPending(
              pool.index,
              userAddress,
              poolId,
              poolType
            )

            // let { apy } = await LP.getAPY(
            //   pool.lp,
            //   pool.address,
            //   pool.strategy,
            //   strategyAddr,
            //   pool.name
            // )

            return {
              name: pool.name,
              id: pool.poolId,
              deposit,
              userShare,
              pendingTenfi: pendingTenfiReward,
              tenfiReward: reward,
              valuesLocked,
            }
          }
        )
      )
    ).reduce((acc, cur) => ({ ...acc, [cur.name]: cur }), {})
  }
}

export const getTenLotsRemaining = async (levelId) => {
  const contract = LP.getContract(TENLOTS, TENLOTS)
  try {
    const remaining = await contract.methods.levels(levelId).call()
    return remaining
  } catch (error) {
    throw error
  }
}

export const getTenLotsReward = async (userAddress) => {
  const contract = LP.getContract(TENLOTS, TENLOTS)
  try {
    const reward = await contract.methods.userRewardPerLot(userAddress).call()
    return reward
  } catch (error) {
    return 0
  }
}

export const getPendingFees = async (userAddress) => {
  const contract = LP.getContract(TENLOTS, TENLOTS)
  try {
    const { pendingFee, timestamp } = await contract.methods
      .enterStakingStats(userAddress)
      .call()

    return { pendingFee, timestamp }
  } catch (error) {
    return 0
  }
}

export const getTenLotsStaked = async () => {
  const contract = LP.getContract(TENLOTS, TENLOTS)
  try {
    const totalStaked = await contract.methods.totalStaked().call()
    return totalStaked / 1e18
  } catch (error) {
    return 0
  }
}

export const getTenLotsUserStaked = async (userAddress) => {
  const contract = LP.getContract(TENLOTS, TENLOTS)
  try {
    const { balance, level } = await contract.methods
      .enterStakingStats(userAddress)
      .call()

    return { balance: balance / 1e18, level }
  } catch (error) {
    return 0
  }
}

export const getTenLotsPendingReward = async (userAddress) => {
  const contract = LP.getContract(BUSD, BUSD)
  const tenLotscontract = LP.getContract(TENLOTS, TENLOTS)

  try {
    const balance = await contract.methods
      .balanceOf(CONTRACT[TENLOTS_TRANSFER])
      .call()

    const { level } = await getTenLotsUserStaked(userAddress)

    const { sharePercent } = await tenLotscontract.methods.levels(level).call()
    const { userCount } = await getTenLotsRemaining(level)

    return (
      (parseInt(balance) * parseInt(sharePercent)) / 1000 / parseInt(userCount)
    )
  } catch (error) {
    return 0
  }
}

// sumOf(depositedAmount * apy) / totalDeposit

export const getPoolsUIData = () => {
  const poolsUIData = Object.keys(POOLS)
    .map((key) => {
      let pair = []
      if (POOLS[key].pair.length < 2) {
        pair = [
          ...POOLS[key].pair.map((pair) => ({
            symbol: pair,
            address: CONTRACT[pair],
          })),
          { symbol: undefined, address: undefined },
        ]
      } else {
        pair = POOLS[key].pair.map((pair) => ({
          symbol: pair,
          address: CONTRACT[pair],
        }))
      }

      return {
        ...POOLS[key],
        name: key,
        pair,
        yearly: 0,
        tvl: 0,
        daily: 0,
      }
    })
    .filter(
      (pool) =>
        pool.poolId !== undefined &&
        !constants.poolIdExcluded.includes(pool.poolId)
    )

  return poolsUIData
}

export const getYieldexUIData = () => {
  const uiData = [
    BABY_YIELDEX,
    YIELDEX_POOL_ALPACA_1,
    YIELDEX_POOL_ALPACA_2,
    YIELDEX_POOL_BISWAP,
    YIELDEX_POOL_HIGH_YIELD_NEW,
    YIELDEX_POOL_PREMIUM_NEW,
    YIELDEX_POOL_STABLE_NEW,
    YIELDEX_POOL_HIGH_YIELD,
    YIELDEX_POOL_PREMIUM,
    YIELDEX_POOL_STABLE,
  ].map((type) => {
    let variant
    let variantName

    if (type === YIELDEX_POOL_STABLE || type === YIELDEX_POOL_STABLE_NEW) {
      variant = "SLP"
      variantName = "STABLE LP"
    } else if (
      type === YIELDEX_POOL_PREMIUM ||
      type === YIELDEX_POOL_PREMIUM_NEW
    ) {
      variant = "PLP"
      variantName = "PREMIUM LP"
    } else if (
      type === YIELDEX_POOL_HIGH_YIELD ||
      type === YIELDEX_POOL_HIGH_YIELD_NEW
    ) {
      variant = "HLP"
      variantName = "HIGH YIELD LP"
    } else if (type === YIELDEX_POOL_BISWAP) {
      variant = ""
      variantName = "BISWAP ALLSTAR LP"
    } else if (type === YIELDEX_POOL_ALPACA_1) {
      variant = ""
      variantName = "ALPACA CRYPTO ASSETS"
    } else if (type === YIELDEX_POOL_ALPACA_2) {
      variant = ""
      variantName = "ALPACA STABLE ASSETS"
    } else if (type === BABY_YIELDEX) {
      variant = ""
      variantName = "BABYSWAP GAMEFI"
    }

    return {
      type,
      variant,
      variantName,
      pools: YIELDEXPOOLS.filter((val) =>
        val.type.includes(type.replace("_NEW", ""))
      ).map((pool) => ({
        ...pool,
        pair:
          pool.pair.length < 2
            ? [
                {
                  symbol: pool.pair[0],
                  address: CONTRACT[pool.pair[0]],
                },
                { symbol: undefined, address: undefined },
              ]
            : pool.pair.map((pair) => ({
                symbol: pair,
                address: CONTRACT[pair],
              })),
      })),
    }
  })

  return uiData
}

import {
  APESTATERGY,
  LEND,
  TENSTRATEGY_PCS,
  XLEND,
  XTENFI,
} from "@src/web3/abi";
import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import { ThemeContext } from "../themeContext";

export default function Token(props) {
  const { theme } = React.useContext(ThemeContext);

  const [logoPath, setLogoPath] = useState();

  const {
    className,
    name,
    symbol,
    label,
    size,
    labelStyle,
    imageType,
    marketName,
    marketLableStyle,
  } = props;

  const getTokenLogo = useCallback((symbol) => {
    if (symbol === "TENFI" || symbol === "TEN") {
      if (theme === "dark") {
        return `tendark`;
      } else {
        return `tenlight`;
      }
    } else if (symbol === XTENFI) {
      return `tenlight`;
    } else if (symbol === LEND || symbol === XLEND) {
      return `tenlent`;
    } else if (symbol === "ApeSwap") {
      return APESTATERGY.toLowerCase();
    } else if (symbol === "PancakeSwap") {
      return TENSTRATEGY_PCS.toLowerCase();
    } else {
      return symbol.toLowerCase();
    }
  }, []);

  useEffect(() => {
    if (symbol) {
      import(
        `../../../public/tokens/${getTokenLogo(symbol)}.${
          imageType ? imageType : "png"
        }`
      )
        .then((val) => {
          setLogoPath(val.default);
        })
        .catch(() => {
          import(`../../../public/tokens/${getTokenLogo("TEN")}.${"png"}`).then(
            (val) => {
              setLogoPath(val.default);
            }
          );
        });
    }
  }, [symbol, getTokenLogo, imageType]);

  return (
    <div
      className={`flex flex-row whitespace-nowrap items-center ${className}`}>
      {logoPath ? (
        <>
          <img
            src={logoPath}
            alt={name}
            className={"rounded-full shadow-sm"}
            style={{ width: size, height: size }}
          />
          <div>
            {marketName && (
              <p
                className={`font-bold ml-2 ${
                  marketLableStyle && marketLableStyle
                } `}>
                {marketName}
              </p>
            )}

            <p className={`font-bold ml-2 ${labelStyle && labelStyle} `}>
              {label}
            </p>
          </div>
        </>
      ) : (
        <div
          className="rounded-full bg-blue-400"
          style={{ height: size, width: size }}></div>
      )}
    </div>
  );
}

Token.defaultProps = {
  name: "TEN Finance",
  symbol: "TEN",
  price: "1.123",
  price_BNB: "0.5",
  size: 24,
  label: "TEN",
  address: "0xd15C444F1199Ae72795eba15E8C1db44E47abF62",
};

export function Pair(props) {
  const { pair, size, label, stratergy } = props;

  const getMargin = () => {
    return Math.ceil(size / 7);
  };
  const getWidth = (n) => {
    return Math.ceil(n * size) - n * getMargin();
  };

  const symbolAlreadyExists = {};

  return (
    <div
      style={{ height: "5rem" }}
      className={`${
        props.className && props.className
      } flex flex-row flex-nowrap items-center whitespace-nowrap justify-center`}>
      {pair[0].symbol !== pair[1].symbol && pair[1].symbol !== undefined ? (
        <span
          style={{ height: "5rem" }}
          className={`relative flex items-center`}>
          <div
            className={`relative inline-block`}
            style={{ height: size, width: getWidth(2) }}>
            {pair.map((token, i) => {
              symbolAlreadyExists[token.symbol] = true;
              return (
                <div
                  key={i}
                  className={`absolute`}
                  style={{ width: size, height: size, left: i * (size / 1.5) }}>
                  <Token {...token} size={size} label={""} />
                </div>
              );
            })}
          </div>
          {stratergy && (
            <Token
              size={27}
              symbol={stratergy}
              label={""}
              className={`absolute -right-4 top-2`}
            />
          )}
        </span>
      ) : (
        <Token {...pair[0]} size={size} label={""} />
      )}

      <p className={`font-bold ml-2`}>{label}</p>
    </div>
  );
}

Pair.defaultProps = {
  size: 24,
  pair: [],
};

import {
  GET_LENDING_TOKEN_DATA,
  GET_LENDING_TOKEN_PENDING_DATA,
  GET_LENDING_TOKEN_USER_DATA,
  GET_LENDING_UI_DATA,
  LENDING_TOKEN_DATA_LOADING,
  LENDING_TOKEN_USER_DATA_LOADING,
} from "@src/actions/lending"

const initialState = {
  lendingUIData: [],
  lendingTokenData: {},
  tokenDataLoading: false,
  lendingTokenUserData: {},
  tokenUserDataLoading: false,
  pendingXTenfi: 0,
  pendingxTenfiUsd: 0,
  xTenfiPrice: 0,
}

const lending = (state = initialState, action) => {
  switch (action.type) {
    case GET_LENDING_UI_DATA:
      return { ...state, lendingUIData: action.uiData }
    case LENDING_TOKEN_DATA_LOADING:
      return { ...state, tokenDataLoading: action.loading }
    case GET_LENDING_TOKEN_DATA:
      return {
        ...state,
        lendingTokenData: action.lendingTokenData,
        tokenDataLoading: false,
      }
    case GET_LENDING_TOKEN_PENDING_DATA:
      return {
        ...state,
        lendingPending: action.pendingXTenfi,
        lendingPendingUSD: action.pendingxTenfiUsd,
        xTenfiTokenPrice: action.xTenfiPrice,
        xTenfiBalance: action.xTenfiBalance,
      }
    case LENDING_TOKEN_USER_DATA_LOADING:
      return { ...state, tokenUserDataLoading: action.loading }
    case GET_LENDING_TOKEN_USER_DATA:
      return {
        ...state,
        lendingTokenUserData: action.lendingTokenUserData,
        tokenUserDataLoading: false,
      }
    default:
      return state
  }
}

export default lending

import { lendingTokenMarkets } from "@src/utils/tokenMarketNames"
import {
  COMPTROLLER,
  CONTRACT,
  UNICONTROLLER,
  XBNB,
  XTENFI,
} from "@src/web3/abi"
import { BLOCKS_PER_DAY } from "../constants/index"
import { LendingUtils } from "../Utils/lendingUtils"

export class LendingToken extends LendingUtils {
  tokenName = null
  token = null
  xToken = null

  netRate = 0
  supplyApy = 0
  borrowApy = 0
  supplyDistributedApy = 0
  borrowDistributedApy = 0
  totalSupply = 0
  totalBorrow = 0

  price = 0
  marketLiquidity = 0
  interestPaidPerDay = 0
  reserves = 0
  reserveFactor = 0
  collateralFactor = 0
  exchangeRate = 0

  contract = null
  underlying = null
  underlyingPrice = 0

  tokenPriceList = null

  xTokenMinted = 0
  borrowCap = 0

  netBorrowRate = 0
  netSupplyRate = 0
  xTokenReserves = 0
  xTotalSupply = 0
  xTokenPrice = 0
  supplyList = []
  borrowList = []

  constructor(tokenName) {
    super()
    this.xToken = tokenName
    this.token = lendingTokenMarkets[tokenName]
  }

  getLendingTokenData = async () => {
    try {
      this.contract = this.getContract(this.xToken, this.xToken)

      this.price = await this.getUnderlyingPrice(CONTRACT[this.xToken])

      this.exchangeRate = await this.getExchangeRate(CONTRACT[this.xToken])

      /* GET SUPPLY AND BORROW APY */
      this.supplyApy = await this.getSupplyApy(
        this.xToken,
        CONTRACT[this.xToken]
      )

      this.borrowApy = await this.getBorrowApy(
        this.xToken,
        CONTRACT[this.xToken]
      )

      /* GET SUPPLY AND BORROW TOTAL SUPPLY */
      // this.totalSupply = await this.getCash(CONTRACT[this.xToken])
      await this._getTotalSupply()

      this.xTotalSupply = await this.getTotalSupply(
        this.xToken,
        CONTRACT[this.xToken]
      )

      await this.getTotalBorrow()

      /* GET RESERVE FACTOR */
      await this.getReserveFactor()

      /* GET UNDERLYING */
      this.underlying = await this.getUnderlying(this.xToken)

      /* DISTRIBUTED APY */
      await this.getDistributedApy()

      this.collateralFactor =
        (await this.getCollateralFactor(CONTRACT[this.xToken])) * 100

      await this.getMarketLiquidity()

      await this.getBorrowCap()

      this.xTokenMinted = this.xTotalSupply / 1e8

      this.netSupplyRate = parseFloat(
        this.supplyApy + this.supplyDistributedApy
      )
      this.netBorrowRate = parseFloat(
        this.borrowApy + this.borrowDistributedApy
      )

      await this.getXTokenReserves()

      this.supplyList = await this.getSupplyList(this.xToken)
      this.borrowList = await this.getBorrowList(this.xToken)

      return {
        token: this.token,
        xToken: this.xToken,
        supplyApy: this.supplyApy,
        borrowApy: this.borrowApy,
        totalSupply: this.totalSupply,
        totalBorrow: (this.totalBorrow / 1e18) * this.price,
        reserveFactor: this.reserveFactor,
        underlying: this.underlying,
        supplyDistributedApy: this.supplyDistributedApy,
        borrowDistributedApy: this.borrowDistributedApy,
        exchangeRate: (1 / this.exchangeRate) * 1e28,
        price: this.price,
        collateralFactor: this.collateralFactor,
        marketLiquidity: this.marketLiquidity,
        xTokenMinted: this.xTokenMinted,
        netSupplyRate: this.netSupplyRate,
        netBorrowRate: this.netBorrowRate,
        xTokenReserves: this.xTokenReserves,
        borrowCap: this.borrowCap,
        supplyList: this.supplyList,
        borrowList: this.borrowList,
        noOfSuppliers: this.supplyList.length,
        noOfBorrowers: this.borrowList.length,
      }
    } catch (error) {
      throw error
    }
  }

  getTotalBorrow = async () => {
    try {
      this.totalBorrow = await this.contract.methods.totalBorrows().call()
    } catch (error) {
      throw error
    }
  }

  getReserveFactor = async () => {
    try {
      this.reserveFactor =
        (await this.contract.methods.reserveFactorMantissa().call()) / 1e16
    } catch (error) {
      throw error
    }
  }

  getDistributedApy = async () => {
    const xTenfiPrice = await this.getAssetPrice(CONTRACT[XTENFI])

    const xTenfiSpeed = await this.getXTenfiSpeed(CONTRACT[this.xToken])

    const xTenfiPerDay = (xTenfiSpeed / 1e18) * BLOCKS_PER_DAY

    const assetPrice = await this.getUnderlyingPrice(CONTRACT[this.xToken])

    const exchangeRate =
      (await this.getExchangeRate(CONTRACT[this.xToken])) / 1e28

    const totalSupply = (this.xTotalSupply / 1e8) * exchangeRate

    const totalBorrows =
      (await this.getTotalBorrows(XBNB, CONTRACT[this.xToken])) / 1e18

    // console.log(this.token + "  ASSETNAME")
    // console.log(xTenfiPerDay + " xTenfiPerDay")
    // console.log(xTenfiPrice + " xTenfiPrice")
    // console.log(totalSupply + "  totalSUpply")
    // console.log(totalBorrows + "  totalBorrows")
    // console.log("_________________________")

    if (totalBorrows === 0) {
      this.borrowDistributedApy = 0
    } else {
      this.borrowDistributedApy =
        100 *
        (Math.pow(
          1 + (xTenfiPrice * xTenfiPerDay) / (totalBorrows * assetPrice),
          365
        ) -
          1)
    }

    if (totalSupply === 0) {
      this.supplyDistributedApy = 0
    } else {
      this.supplyDistributedApy =
        (Math.pow(
          1 + (xTenfiPrice * xTenfiPerDay) / (totalSupply * assetPrice),
          365
        ) -
          1) *
        100
    }
  }

  getMarketLiquidity = async () => {
    try {
      const cash = await this.getCash(CONTRACT[this.xToken])

      this.marketLiquidity = cash / 1e18
    } catch (error) {
      throw error
    }
  }

  getBorrowCap = async () => {
    try {
      const contract = this.getContract(COMPTROLLER, UNICONTROLLER)

      this.borrowCap = parseFloat(
        await contract.methods.borrowCaps(CONTRACT[this.xToken]).call()
      )
    } catch (error) {
      throw error
    }
  }

  getXTokenReserves = async () => {
    try {
      const contract = this.getContract(this.xToken, this.xToken)
      const reserves = await contract.methods.totalReserves().call()

      this.xTokenReserves = parseFloat(reserves)
    } catch (error) {
      throw error
    }
  }

  getXtokenPrice = () => {
    return (this.exchangeRate / 1e28) * this.price
  }

  _getTotalSupply = async () => {
    try {
      this.xTokenPrice = this.getXtokenPrice()
      const underlyingBalance =
        (await this.getTotalSupply(this.xToken, CONTRACT[this.xToken])) / 1e8

      this.totalSupply = this.xTokenPrice * underlyingBalance
    } catch (error) {
      throw error
    }
  }
}

import Web3 from "web3"
import { BigNumber } from "bignumber.js"
import { ABI, CONTRACT, PANCAKE, POOLS, TENFI_LP, BNB, BNBBUSD } from "../abi"
import { CHAIN_ID } from "../constants/index"
export class Utils {
  web3 = null
  constructor(web3Provider) {
    if (web3Provider) {
      this.web3 = new Web3(web3Provider)
    } else {
      /*  this.web3 = new Web3(
        new Web3.providers.HttpProvider(constants.web3.httpProvider)
      ) */
      this.web3 = new Web3(
        new Web3.providers.HttpProvider(
          "https://data-seed-prebsc-1-s1.binance.org:8545/"
        )
      )
    }
  }

  getTenfiPrice = async () => {
    try {
      const reservesWbnbBusd = await this.getTokenReserves(
        PANCAKE,
        POOLS[BNBBUSD].address
      )
      const reservesTenffiLp = await this.getTokenReserves(
        PANCAKE,
        CONTRACT[TENFI_LP]
      )
      const wbnbPrice = reservesWbnbBusd[1] / reservesWbnbBusd[0]
      const tenfiLpReserve = reservesTenffiLp[0] / reservesTenffiLp[1]
      const tenfiPrice = wbnbPrice * tenfiLpReserve

      return tenfiPrice
    } catch (error) {
      return 0
    }
  }

  getContract = (abi, address) => {
    try {
      const contract = this.getContractByAddress(abi, CONTRACT[address])
      return contract
    } catch (error) {
      throw error
    }
  }

  getContractByAddress = (abi, address) => {
    try {
      const contract = new this.web3.eth.Contract(ABI[abi], address)
      return contract
    } catch (error) {
      throw error
    }
  }

  getContractByAdressAbi = (abi, address) => {
    try {
      const contract = new this.web3.eth.Contract(abi, address)
      return contract
    } catch (error) {
      throw error
    }
  }

  getTokenReserves = async (abi, address) => {
    try {
      const contract = this.getContractByAddress(abi, address)
      if (contract.methods.getReserves) {
        const contractReserves = await contract.methods.getReserves().call()
        const reserve0 = contractReserves[0]
        const reserve1 = contractReserves[1]
        return [reserve0, reserve1]
      } else {
        return [0, 0]
      }
    } catch (error) {
      return [0, 0]
    }
  }

  getTokenYield = (tokenYield, n) => {
    return (Math.pow(1 + ((tokenYield / 100) * n) / 365 / n, n) - 1) * 100
  }

  getPoolByAddress = (address) => {
    for (const [k, pool] of Object.entries(POOLS)) {
      if (pool.address.toLowerCase() === address.toLowerCase())
        return {
          name: k,
          ...pool,
        }
    }
  }

  getTotalSupply = async (lp, address) => {
    try {
      const contract = this.getContractByAddress(lp, address)
      const totalSupply = await contract.methods.totalSupply().call()

      return totalSupply
    } catch (error) {
      return 0
    }
  }

  checkNetworkId = async () => {
    const web3 = new Web3()

    if (web3 && web3.eth) {
      const chainId = await web3.eth.getChainId()
      if (CHAIN_ID.includes(chainId)) {
        return true
      }
    }
    return false
  }

  toWei = (amount) => {
    const weiAmount = this.web3.utils.toWei(amount.toString(), "ether")
    return weiAmount
  }

  toEther = (amount) => {
    let x = new BigNumber(amount)
    return x.div(1e18).toNumber()
  }

  getUserAssetBalance = async (token, userAddress) => {
    if (token === BNB) {
      try {
        const balance = this.web3.eth.getBalance(userAddress)
        return balance
      } catch (error) {
        throw error
      }
    } else {
      try {
        const tokenInstance = this.getContract(token, token)
        const balance = await tokenInstance.methods
          .balanceOf(userAddress)
          .call()
        return balance / 1e18
      } catch (error) {
        throw error
      }
    }
  }

  getTokenBalance = async (userAddress, lpName, tokenName, tokenPrices) => {
    try {
      const tokenContract = await this.getContractByAddress(
        lpName,
        CONTRACT[tokenName]
      )
      const balance = this.toEther(
        await tokenContract.methods.balanceOf(userAddress).call()
      )

      return {
        name: tokenName,
        balance,
        balance_usd: balance * tokenPrices[tokenName],
      }
    } catch (error) {
      console.log(error)
    }
  }

  getTokenNameByAddress = (address) => {
    try {
      for (const [k, addr] of Object.entries(CONTRACT)) {
        if (addr.toLowerCase() === address.toLowerCase()) return k
      }
    } catch (error) {
      throw error
    }
  }
}

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import Caption from "@components/caption"
import { FiX } from "react-icons/all"
import Button from "@components/button"
import SwapPopup from "@components/popup/swap"
import ExchangePopup from "@components/popup/exchange"
import LpPopup from "@components/popup/lp"
import { POPUP_CLOSE } from "@src/actions/popup"
import { connect } from "react-redux"
import WalletPopup from "@components/popup/wallet"
import TransactionPopup from "@components/popup/transaction"
import LiquidityPopup from "@components/popup/liquidity"
import Yieldexdisclaimer from "./yieldexdisclaimer"
import TenLendSupplyPopup from "./TenLendSupplyPopup"
import TenLendBorrowPopup from "./TenLendBorrowPopup"
import LendingClaimRewardPopup from "./LendingClaimRewardPopup"
import Notification from "@components/notification"

export const SWAP_POPUP = "SWAP_POPUP"
export const EXCHANGE_POPUP = "EXCHANGE_POPUP"
export const LP_POPUP = "LP_POPUP"
export const WALLET_POPUP = "WALLET_POPUP"
export const TRANSACTION_POPUP = "TRANSACTION_POPUP"
export const LIQUIDITY_POPUP = "LIQUIDITY_POPUP"
export const DISCLAIMER_POPUP = "DISCLAIMER_POPUP"
export const TENLEND_SUPPLY_POPUP = "TENLEND_SUPPLY_POPUP"
export const TENLEND_BORROW_POPUP = "TENLEND_BORROW_POPUP"
export const TENLEND_CLAIM_REWARD = "TENLEND_CLAIM_REWARD"

export const POPUP_TYPES = {
  [SWAP_POPUP]: SwapPopup,
  [EXCHANGE_POPUP]: ExchangePopup,
  [LP_POPUP]: LpPopup,
  [WALLET_POPUP]: WalletPopup,
  [TRANSACTION_POPUP]: TransactionPopup,
  [LIQUIDITY_POPUP]: LiquidityPopup,
  [DISCLAIMER_POPUP]: Yieldexdisclaimer,
  [TENLEND_SUPPLY_POPUP]: TenLendSupplyPopup,
  [TENLEND_BORROW_POPUP]: TenLendBorrowPopup,
  [TENLEND_CLAIM_REWARD]: LendingClaimRewardPopup,
}

export function Popup(props) {
  const { caption, corner, dispatch, open, className, onClose } = props

  // console.log(onClose())

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        static
        className={`fixed z-10 inset-0 overflow-y-auto`}
        open={open}
        onClose={() => {
          if (onClose) {
            onClose()
          } else {
            dispatch({ type: POPUP_CLOSE })
          }
        }}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-80 dark:bg-night-100 dark:bg-opacity-80 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
            <div
              className={`inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full 
                            px-6
                            pt-6
                            bg-white text-night-100
                            dark:bg-night-350 dark:text-white dark:border-night-500
                            ${className}
                        `}>
              <Caption variant={"large"}>{caption}</Caption>
              <div className={"absolute right-2 top-2 flex flex-row space-x-4"}>
                {corner}
                <Button
                  variant={"clean"}
                  onClick={() => {
                    if (onClose) {
                      onClose()
                    } else {
                      dispatch({ type: POPUP_CLOSE })
                    }
                  }}>
                  <FiX size={32} />
                </Button>
              </div>
              <Notification />
              <div className={"py-6 h-full w-full"}>{props.children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
function mapStateToProps({ popup }) {
  return {
    popup,
  }
}

export default connect(mapStateToProps)(Popup)

import { combineReducers } from "redux"
import news from "@src/reducers/news"
import tenfi from "@src/reducers/tenfi"
import popup from "@src/reducers/popup"
import wallet from "@src/reducers/wallet"
import notification from "@src/reducers/notification"
import liquidity from "@src/reducers/liquidity"
import yieldex from "@src/reducers/yieldex"
import tenlots from "@src/reducers/tenlots"
import lending from "@src/reducers/lending"
import liquidation from "@src/reducers/liquidation"

const rootReducer = combineReducers({
  tenfi,
  news,
  popup,
  wallet,
  notification,
  liquidity,
  yieldex,
  tenlots,
  lending,
  liquidation,
})

export default rootReducer

import Caption from "@components/caption"
import Input from "@components/input"
import { FiArrowDown } from "react-icons/all"
import Button from "@components/button"
import Popup, { LP_POPUP } from "@components/popup/index"
import React from "react"
import { connect } from "react-redux"

function LpPopup(props) {
  const { dispatch, visible } = props

  return (
    <Popup
      open={visible}
      openStateFn={() => dispatch({ type: "POPUP_CLOSE", popup: LP_POPUP })}
      caption={"Create LP"}>
      <div
        className={
          "flex flex-col w-full items-center space-y-8 dark:text-night-700"
        }>
        <div className={"flex flex-col w-full"}>
          <div className={"flex flex-row justify-between w-full"}>
            <Caption>From</Caption>
            <Caption>
              Balance: <span className={"dark:text-white"}>{"0.00"}</span>
            </Caption>
          </div>
          <div className={"flex flex-row space-x-4"}>
            <Input
              className={"w-full"}
              button={"Max"}
              onButtonClick={() => {}}
            />
            {/* Object.keys(wallet.balances) */}
            {/*<ListboxComponent options={constants.swappable} className={"w-64"} imageDir={"/tokens/"}/>*/}
          </div>
        </div>
        <FiArrowDown size={32} />
        <div className={"flex flex-col w-full"}>
          <div className={"flex flex-row justify-between w-full"}>
            <Caption>To</Caption>
            <Caption>
              Balance: <span className={"dark:text-white"}>{"0.00"}</span>
            </Caption>
          </div>
          <div className={"flex flex-row space-x-4"}>
            {/*<ListboxComponent options={constants.lps} className={"w-full"} imageDir={"/tokens/"}/>*/}
          </div>
        </div>
        <div className={"flex flex-col w-full"}>
          <div className={"flex flex-row justify-between w-full"}>
            <p className={"dark:text-night-700"}>You receive</p>
            <p className={"dark:text-night-900 uppercase text-md font-bold"}>
              {"0.0000000000000000 LP"}
            </p>
          </div>
          <div className={"flex flex-row justify-between w-full"}>
            <p className={"dark:text-night-700"}>Slippage tolerance</p>
            <p className={"dark:text-night-900 uppercase text-md font-bold"}>
              {"5.00 %"}
            </p>
          </div>
          <div className={"flex flex-row justify-between w-full"}>
            <p className={"dark:text-night-700"}>Min. received</p>
            <p className={"dark:text-night-900 uppercase text-md font-bold"}>
              {"0.0000000000000000 LP"}
            </p>
          </div>
        </div>
        <Button variant={"violin"} className={"w-full"}>
          Approve
        </Button>
      </div>
    </Popup>
  )
}

function mapStateToProps({ popup, wallet }) {
  return {
    visible: popup.popups[LP_POPUP],
    wallet,
  }
}

export default connect(mapStateToProps)(LpPopup)

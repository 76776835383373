import { Link } from "react-router-dom"
import React from "react"
import { loadingIcon } from "@components/icons"

const ButtonVariants = {
  white: `rounded-md bg-white dark:border-night-800 dark:text-gray-50 dark:bg-night-400 dark:hover:bg-gray-dark hover:bg-gray-medium shadow-md dark:border-gray-dark border text-gray-900`,
  green: `rounded-md bg-vivid-500 border-vivid-500 hover:bg-vivid-100 py-4 shadow-md hover:border-vivid-500 border-2 text-white`,
  faded:
    `rounded-md border-2 py-4 shadow-md text-white ` +
    `hover:border-night-300 bg-night-700 ` +
    `border-night-700 hover:bg-night-600`,
  disable:
    `rounded-md  text-violin-300 py-3 px-6 border  cursor-default	` +
    `dark:border-violin-100`,
  violin:
    `rounded-md bg-violin-500 text-violin-100 py-3 px-6 border-2 bg-violin-500 text-white` +
    `hover:bg-violin-700 ` +
    `dark:hover:bg-violin-900 dark:border-violin-100`,
  "violin-large":
    `rounded-md py-6 px-8 border-2 ` +
    `bg-violin-100 text-violin-900 hover:bg-violin-700 border-violin-900 border text-4xl ` +
    `dark:hover:bg-violin-700 dark:border-violin-700`,
  "violin-inverted":
    `rounded-md py-3 px-6 ` +
    `bg-gray-300 hover:bg-gray-400 ` +
    `dark:bg-night-500 dark:text-violin-500 dark:hover:bg-night-100 `,
  outline:
    "rounded-md py-3 px-3" +
    "hover:bg-gray-300 " +
    "dark:border-night-500 dark:hover:bg-night-100 dark:border-night-100",
  night:
    "rounded-md border py-3 px-6 border-gray-400 dark:border-night-700 dark:bg-night-300 dark:hover:bg-night-500 dark:text-night-700",
  "violin-night":
    "rounded-md py-3 px-6 " +
    "bg-gray-300 hover:bg-gray-400 " +
    "dark:hover:bg-night-0 dark:bg-night-100 dark:border-night-100 dark:text-white dark:hover:border-night-600",
  clean:
    "border-0 dark:text-night-700 text-night-700 focus:outline-none dark:hover:text-night-900",
  group:
    "py-3 shadow-md px-4 text-xs " +
    "dark:bg-gray-700 dark:text-gray-400 dark:border-night-700 dark:hover:bg-night-600 dark:hover:border-night-300 " +
    "bg-gray-400 text-gray-100 border-gray-500 hover:bg-gray-600 hover:border-gray-300 ",
  "group-active":
    "text-xs bg-night-700 text-white border-night-700 hover:bg-night-600 py-3 shadow-md hover:border-night-700 px-4",
}

const Effects = {
  scaled: "transform hover:-translate-y-1 hover:scale-110 hover:shadow-lg",
}

const borders = "border-2"

function Button(props) {
  const variant = props.disabled
    ? ButtonVariants["disable"]
    : props.variant
    ? ButtonVariants[props.variant]
    : ButtonVariants["violin"]
  const effects = props.effects ? Effects[props.effects] : ""

  const { ref, loading } = props

  const button = (
    <button
      id={props.id}
      {...ref}
      disabled={props.disabled}
      onClick={!loading ? props.onClick : () => {}}
      style={{ outline: "none", ...props.style }}
      className={`${props.border && borders} ${
        props.className
      } ${variant} ${effects}  ${
        props.icon && "flex"
      } transition items-center duration-300 ease-in-out font-bold uppercase text-center outline-none	`}>
      {loading && loadingIcon}
      {props.icon && (
        <div
          className={` ${
            props.iconClassName
          } absolute whitespace-nowrap inline-flex text-center items-center transition duration-500 ${
            loading ? "opacity-0" : ""
          }`}>
          {props.icon ? props.icon : ""}
        </div>
      )}

      <span
        className={`text-center ${props.icon && `w-full`} ${
          props.textColor && props.textColor
        } truncate`}>
        {props.children}
      </span>
    </button>
  )

  return props.href ? (
    props.redirect ? (
      <a href={props.href}>{button}</a>
    ) : (
      <Link to={props.href}>{button}</Link>
    )
  ) : (
    button
  )
}

export default Button

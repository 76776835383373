import {
  lendingTokenMarkets,
  tokenMarketName,
} from "@src/utils/tokenMarketNames"
import {
  COMPTROLLER,
  CONTRACT,
  MULTICALL,
  UNICONTROLLER,
  XBNB,
  XTENFI,
} from "../abi"
import { lendingTokens } from "../lendingdata"
import { LendingUserToken } from "../user/lendingusertoken"
import { LendingUtils } from "../Utils/lendingUtils"
import { LendingToken } from "./lendingtoken"

export class Lending extends LendingUtils {
  getLendingData = async () => {
    try {
      let tokenData = []
      lendingTokens.forEach((token) => {
        const lendingToken = new LendingToken(token)
        tokenData.push(lendingToken.getLendingTokenData())
      })

      tokenData = await Promise.all(tokenData)

      let totalSupply = 0
      let totalBorrow = 0

      let suppliersList = []
      let borrowersList = []

      tokenData.forEach((token) => {
        totalSupply += token["totalSupply"]
        totalBorrow += token["totalBorrow"]
        suppliersList = [...suppliersList, ...token["supplyList"]]
        borrowersList = [...borrowersList, ...token["borrowList"]]
      })

      suppliersList = [...new Set(suppliersList)]
      borrowersList = [...new Set(borrowersList)]

      const maxTotalSupply = [...tokenData]
        .sort((token1, token2) => token2["totalSupply"] - token1["totalSupply"])
        .splice(0, 3)

      const maxTotalBorrow = [...tokenData]
        .sort((token1, token2) => token2["totalBorrow"] - token1["totalBorrow"])
        .splice(0, 3)

      return {
        totalSupply,
        totalBorrow,
        maxTotalSupply,
        maxTotalBorrow,
        totalNoOfSuppliers: suppliersList.length,
        totalNoOfBorrowers: borrowersList.length,
        xTokenData: tokenData.reduce(
          (acc, cur) => ({ ...acc, [cur.token]: cur }),
          {}
        ),
      }
    } catch (error) {
      throw error
    }
  }

  getLendingUserData = async (userAddress) => {
    try {
      let tokenUserData = []
      // const assetsIn = await this.getAssetsIn(userAddress)
      lendingTokens.forEach((token) => {
        const lendingUserToken = new LendingUserToken(
          userAddress,
          token
          // assetsIn
        )
        tokenUserData.push(lendingUserToken.getlendingUserTokenData())
      })
      tokenUserData = await Promise.all(tokenUserData)

      let totalSupplyBalance = 0
      let totalBorrowBalance = 0
      let borrowLimit = 0
      let usedBorrowLimit = 0
      let netApy = 0
      tokenUserData.forEach((token) => {
        totalSupplyBalance += token["supplyBalanceUsd"]
        totalBorrowBalance += token["borrowBalanceUsd"]
        if (!token["isDepricated"] && token["enterMarket"]) {
          borrowLimit += token["borrowLimit"]
        }
        if (!isNaN(token["netApy"])) {
          netApy += token["netApy"]
        }
      })

      usedBorrowLimit = (totalBorrowBalance / borrowLimit) * 100

      if (isNaN(usedBorrowLimit)) {
        usedBorrowLimit = 0
      }

      if (netApy > 0) {
        netApy = (100 * netApy) / totalSupplyBalance
      } else if (netApy < 0) {
        netApy = (100 * netApy) / totalBorrowBalance
      } else {
        netApy = 0
      }

      return {
        borrow: {
          borrowLimit,
          usedBorrowLimit,
        },
        netApy: netApy,
        totalSupplyBalance,
        totalBorrowBalance,
        tokenUserData: tokenUserData.reduce(
          (acc, cur) => ({ ...acc, [cur.token]: cur }),
          {}
        ),
      }
    } catch (error) {
      throw error
    }
  }

  getPendingData = async (userAddress) => {
    try {
      let pendingXTenfi = 0
      let xTenfiPrice = 0
      let pendingxTenfiUsd = 0
      let xTenfiBalance = 0
      try {
        pendingXTenfi =
          userAddress !== null
            ? await this.getPendingRewardsUsingMulticall(userAddress)
            : 0
        xTenfiPrice = await this.getAssetPrice(CONTRACT[XTENFI])
        pendingxTenfiUsd = parseFloat(pendingXTenfi) * parseFloat(xTenfiPrice)
        xTenfiBalance = await this.getUserAssetBalance(XTENFI, userAddress)
      } catch (error) {
      } finally {
        const finalData = {
          pendingXTenfi,
          xTenfiPrice,
          pendingxTenfiUsd,
          xTenfiBalance,
        }
        return finalData
      }
    } catch (error) {
      throw error
    }
  }

  getPendingRewardsUsingMulticall = async (userAddress) => {
    try {
      const multicallContract = this.getContract(MULTICALL, MULTICALL)
      const unitrollerContract = this.getContract(COMPTROLLER, UNICONTROLLER)
      const assetInst = this.getContract(XBNB, XTENFI)

      // /* SUPPLIER ARRAY */

      let xTenfiSupplyStateArr = []
      let xTenfiSpeedsArr = []
      let getBlockNumberArr = []
      let totalSupplyArr = []
      let xTenfiSupplierIndexArr = []
      let xTenfiInitialIndexArr = []
      let balanceOfXTokenArr = []

      // /* BORROWER ARRAY */

      let xTenfiBorrowStateArr = []
      let borrowIndexArr = []
      let totalBorrowsArr = []
      let xTenfiBorrowerIndexArr = []
      let borrowBalanceStoredArr = []

      let targets = []
      let callDatas = []
      let results = []
      let ouput_format = []

      const assetsIn = await this.getAllMarkets()

      assetsIn.forEach(async (asset) => {
        targets.push(CONTRACT[UNICONTROLLER])
        const data = this.web3.eth.abi.encodeFunctionCall(
          unitrollerContract.methods.compSupplyState(asset)._method,
          [asset]
        )
        callDatas.push(data)
        ouput_format.push(
          unitrollerContract.methods.compSupplyState(asset)._method.outputs
        )
      })

      assetsIn.forEach(async (asset) => {
        targets.push(CONTRACT[UNICONTROLLER])
        const data = this.web3.eth.abi.encodeFunctionCall(
          unitrollerContract.methods.compSpeeds(asset)._method,
          [asset]
        )
        callDatas.push(data)
        ouput_format.push(
          unitrollerContract.methods.compSpeeds(asset)._method.outputs
        )
      })

      assetsIn.forEach(async (asset) => {
        targets.push(CONTRACT[UNICONTROLLER])
        const data = this.web3.eth.abi.encodeFunctionCall(
          unitrollerContract.methods.getBlockNumber()._method,
          []
        )
        callDatas.push(data)
        ouput_format.push(
          unitrollerContract.methods.getBlockNumber()._method.outputs
        )
      })

      assetsIn.forEach(async (asset) => {
        targets.push(asset)
        const data = this.web3.eth.abi.encodeFunctionCall(
          assetInst.methods.totalSupply()._method,
          []
        )
        callDatas.push(data)
        ouput_format.push(assetInst.methods.totalSupply()._method.outputs)
      })

      assetsIn.forEach(async (asset) => {
        targets.push(CONTRACT[UNICONTROLLER])
        const data = this.web3.eth.abi.encodeFunctionCall(
          unitrollerContract.methods.compSupplierIndex(asset, userAddress)
            ._method,
          [asset, userAddress]
        )
        callDatas.push(data)
        ouput_format.push(
          unitrollerContract.methods.compSupplierIndex(asset, userAddress)
            ._method.outputs
        )
      })

      assetsIn.forEach(async (asset) => {
        targets.push(CONTRACT[UNICONTROLLER])
        const data = this.web3.eth.abi.encodeFunctionCall(
          unitrollerContract.methods.compInitialIndex()._method,
          []
        )
        callDatas.push(data)
        ouput_format.push(
          unitrollerContract.methods.compInitialIndex()._method.outputs
        )
      })

      assetsIn.forEach(async (asset) => {
        targets.push(asset)
        const data = this.web3.eth.abi.encodeFunctionCall(
          assetInst.methods.balanceOf(userAddress)._method,
          [userAddress]
        )
        callDatas.push(data)
        ouput_format.push(
          assetInst.methods.balanceOf(userAddress)._method.outputs
        )
      })

      assetsIn.forEach(async (asset) => {
        targets.push(CONTRACT[UNICONTROLLER])
        const data = this.web3.eth.abi.encodeFunctionCall(
          unitrollerContract.methods.compBorrowState(asset)._method,
          [asset]
        )
        callDatas.push(data)
        ouput_format.push(
          unitrollerContract.methods.compBorrowState(asset)._method.outputs
        )
      })

      assetsIn.forEach(async (asset) => {
        targets.push(asset)
        const data = this.web3.eth.abi.encodeFunctionCall(
          assetInst.methods.borrowIndex()._method,
          []
        )
        callDatas.push(data)
        ouput_format.push(assetInst.methods.borrowIndex()._method.outputs)
      })

      assetsIn.forEach(async (asset) => {
        targets.push(asset)
        const data = this.web3.eth.abi.encodeFunctionCall(
          assetInst.methods.totalBorrows()._method,
          []
        )
        callDatas.push(data)
        ouput_format.push(assetInst.methods.totalBorrows()._method.outputs)
      })

      assetsIn.forEach(async (asset) => {
        targets.push(CONTRACT[UNICONTROLLER])
        const data = this.web3.eth.abi.encodeFunctionCall(
          unitrollerContract.methods.compBorrowerIndex(asset, userAddress)
            ._method,
          [asset, userAddress]
        )
        callDatas.push(data)
        ouput_format.push(
          unitrollerContract.methods.compBorrowerIndex(asset, userAddress)
            ._method.outputs
        )
      })

      assetsIn.forEach(async (asset) => {
        targets.push(asset)
        const data = this.web3.eth.abi.encodeFunctionCall(
          assetInst.methods.borrowBalanceStored(userAddress)._method,
          [userAddress]
        )
        callDatas.push(data)
        ouput_format.push(
          assetInst.methods.borrowBalanceStored(userAddress)._method.outputs
        )
      })

      const agregated_data = await multicallContract.methods
        .aggregate(targets, callDatas)
        .call()

      const do_split = async (array, n) => {
        return array.length
          ? [array.splice(0, n)].concat(await do_split(array, n))
          : []
      }

      for (let i = 0; i < agregated_data[1].length; i++) {
        results.push(
          this.web3.eth.abi.decodeParameters(
            ouput_format[i],
            agregated_data[1][i]
          )
        )
      }

      const split_arr = await do_split(results, assetsIn.length)

      xTenfiSupplyStateArr = split_arr[0] // index,block
      xTenfiSpeedsArr = split_arr[1]
      getBlockNumberArr = split_arr[2]
      totalSupplyArr = split_arr[3]
      xTenfiSupplierIndexArr = split_arr[4]
      xTenfiInitialIndexArr = split_arr[5]
      balanceOfXTokenArr = split_arr[6]
      xTenfiBorrowStateArr = split_arr[7] //index,block
      borrowIndexArr = split_arr[8]
      totalBorrowsArr = split_arr[9]
      xTenfiBorrowerIndexArr = split_arr[10]
      borrowBalanceStoredArr = split_arr[11]

      let _userXTenfiAccured = await this.getXTenfiAccured(userAddress)

      const getCompAccrueBySupply = async (i, _userXTenfiAccured) => {
        let currentSupplyState = xTenfiSupplyStateArr[i]
        const supplySpeed = parseFloat(xTenfiSpeedsArr[i][0])

        const currentBlockNumberDiff =
          parseFloat(getBlockNumberArr[i][0]) -
          parseFloat(currentSupplyState.block)

        let newXTenfiSupplyIndex = 0

        if (currentBlockNumberDiff > 0 && supplySpeed > 0) {
          const supplyAmount = parseFloat(totalSupplyArr[i][0])
          let _xTenfiAccrued = currentBlockNumberDiff * supplySpeed //borrowspeed = xtenfi per block
          newXTenfiSupplyIndex =
            parseFloat(currentSupplyState.index) +
            (supplyAmount > 0 ? (_xTenfiAccrued / supplyAmount) * 1e36 : 0)
        }

        let currentSupplierIndex = parseFloat(xTenfiSupplierIndexArr[i][0])

        if (currentSupplierIndex === 0 && newXTenfiSupplyIndex > 0) {
          currentSupplierIndex = parseFloat(xTenfiInitialIndexArr[i][0])
        }

        let deltaIndex =
          (parseFloat(newXTenfiSupplyIndex / 1e36) -
            parseFloat(currentSupplierIndex / 1e36)) *
          1e36
        deltaIndex = deltaIndex < 0 ? 0 : deltaIndex
        const userSupplyBalance = parseFloat(balanceOfXTokenArr[i][0])
        const supplierDelta = userSupplyBalance * deltaIndex
        return parseFloat(_userXTenfiAccured) + parseFloat(supplierDelta / 1e36)
      }

      const getCompAccrueByBorrow = async (i, _userXTenfiAccured) => {
        let currentBorrowState = xTenfiBorrowStateArr[i]
        const borrowSpeed = parseFloat(xTenfiSpeedsArr[i][0])
        const currentBlockNumberDiff =
          parseFloat(getBlockNumberArr[i][0]) -
          parseFloat(currentBorrowState.block)
        if (currentBlockNumberDiff > 0 && borrowSpeed > 0) {
          const marketBorrowIndex = parseFloat(borrowIndexArr[i][0])
          const borrowAmount =
            parseFloat(totalBorrowsArr[i][0]) / marketBorrowIndex
          let _xTenfiAccrued = (currentBlockNumberDiff * borrowSpeed) / 1e18 //borrowspeed = xtenfi per block
          let newxTenfiBorrowIndex =
            parseFloat(currentBorrowState.index) +
            (borrowAmount > 0
              ? (parseFloat(_xTenfiAccrued) / borrowAmount) * 1e36
              : 0)

          const currentborrowerIndex = parseFloat(xTenfiBorrowerIndexArr[i][0])
          if (currentborrowerIndex > 0) {
            let deltaIndex =
              (parseFloat(newxTenfiBorrowIndex / 1e36) -
                parseFloat(currentborrowerIndex / 1e36)) *
              1e36

            deltaIndex = deltaIndex < 0 ? 0 : deltaIndex
            const userBorrowBalance = parseFloat(borrowBalanceStoredArr[i][0])
            const borrowerAmount = userBorrowBalance / marketBorrowIndex
            const borrowerDelta = borrowerAmount * deltaIndex

            return (
              parseFloat(_userXTenfiAccured) + parseFloat(borrowerDelta / 1e18)
            )
          }
          return _userXTenfiAccured
        }
        return _userXTenfiAccured
      }

      for (let i = 0; i < assetsIn.length; i++) {
        //update xTenfi borrow index

        _userXTenfiAccured = await getCompAccrueBySupply(i, _userXTenfiAccured)
        _userXTenfiAccured = await getCompAccrueByBorrow(i, _userXTenfiAccured)
      }

      return _userXTenfiAccured.toFixed(0) / 1e18
    } catch (error) {
      throw error
    }
  }
}

export const getLendingUIData = () => {
  try {
    return lendingTokens.map((token) => ({
      marketName: tokenMarketName[lendingTokenMarkets[token]],
      token: lendingTokenMarkets[token],
      xToken: token,
    }))
  } catch (error) {
    console.log(error)
    throw error
  }
}

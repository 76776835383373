import React, { useEffect, useState } from "react"
import Header from "@components/header/index"
import { ReactComponent as Logo } from "@images/LEND-Logo.svg"
import Button from "@components/button/index"
import Toggle from "@components/themeToggle"
import LanguageSelector from "@components/languageSelector/index"
import Container from "@components/container/index"
import Footer from "@components/footer/index"
import SocialIcons from "@components/socialIcons/index"
import Token from "@components/token"
import { numberWithCommas } from "@utils/number"
import { EXCHANGE_POPUP, WALLET_POPUP } from "@src/components/popup/index"
import { useTranslation } from "react-i18next"
import Navigation from "@components/navigation/index"
import { connect } from "react-redux"
import constants from "@src/constants.json"
import {
  connectWalletIfNeeded,
  isWalletConnectedWithDeposits,
} from "@src/actions/wallet"
import { popupOpen } from "@src/actions/popup"
import Badge from "../badge/index"
import { VscThreeBars } from "react-icons/vsc"
import { Fragment } from "react"
import { useLocation } from "react-router-dom"

function PageContainer(props) {
  const [scrollThreshold, setScrollThreshold] = useState(window.scrollY)
  const [width, setWidth] = useState(window.innerWidth)
  const location = useLocation()

  const { t } = useTranslation()
  const { dispatch, tenfi, wallet } = props

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize)
    const throttledListener = () => setScrollThreshold(window.scrollY)
    window.addEventListener("scroll", throttledListener)
    document
      .getElementById("PoolsAppPage")
      .addEventListener("scroll", (scroll) => {
        setScrollThreshold(scroll.target.scrollTop)
      })

    return () => {
      window.removeEventListener("resize", handleWindowResize)
      window.removeEventListener("scroll", throttledListener)
    }
  }, [])

  const centerEllipse = (walletAddress) => {
    if (width <= 683 && wallet.wallet)
      return `${walletAddress.substring(
        0,
        Math.round(width * 0.013) < 5 ? 5 : Math.round(width * 0.013)
      )}...${walletAddress.substring(
        walletAddress.length - 6,
        walletAddress.length
      )}`
    else if (width >= 1200 && wallet.wallet)
      return `${walletAddress.substring(0, 5)}...${walletAddress.substring(
        walletAddress.length - 6,
        walletAddress.length
      )}`
    else if (width > 683 && width < 1200 && wallet.wallet) return walletAddress
    else return walletAddress
  }

  const [drop, setDrop] = useState(false)

  const navLinks = [
    { name: "Staking", link: "https://app.ten.finance/", redirect: true },
    {
      name: "Lending",
      link: "/",
      redirect: false,
    },
    {
      name: "VESTING",
      link: "",
      redirect: false,
    },

    { name: "Liquidations", link: "", redirect: false },
    { name: "Swap", link: "", redirect: false },
  ]

  return (
    <div
      id='PoolsAppPage'
      className='dark:bg-night-40 bg-gray-1200 flex flex-col transition-all text-gray-700 dark:text-night-900 '
      style={{
        minHeight: "100vh",
      }}>
      <div className='px-2 md:px-20'>
        <Badge />
      </div>

      <div
        className={`px-2 md:px-20 h-auto relative lg:fixed top-0 left-0 right-0 w-full xl:py-4 2xl:py-5 ${
          scrollThreshold > 5
            ? `lg:shadow-xl lg:backdrop-filter lg:backdrop-blur-3xl`
            : `lg:bg-transparent`
        } `}
        style={{ zIndex: 2 }}>
        <Header
          logo={Logo}
          left={
            <>
              <span className={"hidden sm:flex items-center "}>
                {navLinks.map((item, i) => (
                  <Fragment key={i}>
                    <Button
                      textColor={
                        location.pathname === item.link && "text-violin-1250"
                      }
                      className={`text-xs mr-2 md:mr-3 transform hover:-translate-y-1 hover:scale-110 md:m-2 fill-current transition duration-300 ease-in-out`}
                      variant={`clean`}
                      href={item.link}
                      redirect={item.redirect}>
                      {item.name}
                    </Button>
                  </Fragment>
                ))}
              </span>
              <span className={"flex sm:hidden"}>
                <VscThreeBars
                  onClick={() => setDrop(!drop)}
                  className='text-gray-500 dark:text-gray-400 text-2xl cursor-pointer'
                />
              </span>
            </>
          }>
          <span
            className={`flex px-2 justify-center flex-col sm:hidden w-full bg-gray-200 dark:bg-night-300  rounded-md overflow-hidden transition-height ease-in-out duration-500 ${
              drop ? "h-56" : "h-0"
            }`}>
            {navLinks.map((item, i) => (
              <Fragment key={i}>
                <Button
                  className={
                    "flex mb-1 w-full h-9 text-xs shadow-none py-0 px-6 dark:text-white border-violin-500 lg:w-auto bg-white dark:bg-night-40"
                  }
                  textColor={
                    location.pathname === item.link && "text-violin-1250"
                  }
                  variant={"white "}
                  href={item.link}
                  redirect={item.redirect}
                  onClick={() => setDrop(false)}>
                  {item.name}
                </Button>
              </Fragment>
            ))}
          </span>

          <Button
            className={
              "w-full h-11 text-xs shadow-none py-0 px-6 border-violin-500 lg:w-auto bg-white dark:bg-night-300 normal-case"
            }
            variant={"white"}
            icon={
              <Token
                size={28}
                symbol={"tenlent"}
                label={" "}
                labelStyle={`text-green-400 normal-case`}
              />
            }
            iconClassName={`w-12 lg:static`}
            textColor={"ml-4 flex justify-end lg:block"}
            disabled={true}
            onClick={
              !wallet.isConnected
                ? () => dispatch(connectWalletIfNeeded())
                : () => dispatch(popupOpen(EXCHANGE_POPUP))
            }>
            BUY LEND
          </Button>
          <Button
            id={"connectWalletID"}
            style={{ pointerEvents: "none" }}
            className={`h-11 text-xs dark:text-white font-light w-full lg:w-auto`}
            textColor={`text-white`}
            onClick={
              !wallet.isConnected
                ? () => dispatch(connectWalletIfNeeded())
                : () => dispatch(popupOpen(WALLET_POPUP))
            }>
            {wallet.isConnected
              ? centerEllipse(wallet.wallet)
              : `${t("Connect")} Wallet`}
          </Button>
          <Toggle />
          <LanguageSelector />
        </Header>
      </div>
      <Container className={"flex flex-col px-2 lg:mt-28"}>
        <main className={"mb-auto flex flex-col flex-1 pt-4"}>
          {props.children}
        </main>
      </Container>
      <Container className='flex flex-col flex-1 px-2 '>
        <Footer className={"flex-1 py-6 flex flex-col justify-end"}>
          <SocialIcons className={"justify-center mb-3 "} />
          <Navigation className='text-sm font-normal' />
          <div className={"text-center text-xs"}>{constants.copyright}</div>
        </Footer>
      </Container>
    </div>
  )
}

function mapStateToProps({ tenfi, popup, wallet, notification }) {
  return {
    tenfi,
    popup,
    wallet,
    notification,
  }
}

export default connect(mapStateToProps)(PageContainer)

import Caption from "@components/caption";
import React from "react";

const CardVariants = {
    'bordered': 'border rounded-md px-4 py-4 dark:border-night-500 dark:text-gray-400',
    'clean': ''
};

function Card(props) {
    const variant = props.variant ? CardVariants[props.variant] : CardVariants["bordered"];
    const forcedCorner = props.forcedCorner ? "absolute" : "sm:w-auto xs:w-full block md:absolute"

    return <div className={`h-full relative ${variant} ${props.className} `}>
        <Caption>{props.caption}</Caption>
        <div className={"space-x-2 w-full pt-4 whitespace-nowrap"}>
            <p className="inline-block text-gray-700 dark:text-white lg:text-3xl md:text-5xl text-4xl font-bold">{props.largeContent}</p>
            {props.smallContent &&
            <p className="inline-block text-gray-400 dark:text-night-700 font-bold">{props.smallContent}</p>
            }
        </div>
        {props.corner &&
        <div className={` ${forcedCorner} right-0 top-0`}>
            {props.corner}
        </div>}
        {props.children}
    </div>
}

export default Card;
import {
  BANANA,
  BISWAP,
  BSW,
  CAKE,
  TENFI,
  BNB,
  YIELDEXCONTRACT,
  YIELDEXPOOLS,
  YIELDEX_POOL_BISWAP,
  YIELDEX_POOL_HIGH_YIELD,
  YIELDEX_POOL_HIGH_YIELD_NEW,
  YIELDEX_POOL_PREMIUM,
  YIELDEX_POOL_PREMIUM_NEW,
  YIELDEX_POOL_STABLE,
  YIELDEX_POOL_STABLE_NEW,
  YIELDEX_POOL_ALPACA_1,
  YIELDEX_POOL_ALPACA_2,
  BABY_YIELDEX,
} from "../abi"
import { beltPools } from "../constants/index"
import { token_Balance } from "../transactions"
import { Utils } from "../Utils/index"
import constants from "@src/constants.json"

export class UserYieldex {
  userAddress
  userDepositedPools = {}
  availableUSD = 0
  depositedUSD = 0
  pendingTenfi = 0
  pendingUSD = 0
  yieldexStable = []
  yieldexPremium = []
  yieldexHighYield = []
  yieldexBiswap = []
  yieldexAlpaca1 = []
  yieldexAlpaca2 = []
  tokenPrices
  provider
  utility
  constructor(userAddress, tokenPrices, provider) {
    this.userAddress = userAddress
    this.tokenPrices = tokenPrices
    this.provider = provider
    this.utility = new Utils(provider)
  }

  initilaizeYieldexData = () => {
    this.yieldexStable = YIELDEXPOOLS.filter((pool) =>
      pool.type.includes(YIELDEX_POOL_STABLE)
    )
    this.yieldexPremium = YIELDEXPOOLS.filter((pool) =>
      pool.type.includes(YIELDEX_POOL_PREMIUM)
    )
    this.yieldexHighYield = YIELDEXPOOLS.filter((pool) =>
      pool.type.includes(YIELDEX_POOL_HIGH_YIELD)
    )
    this.yieldexBiswap = YIELDEXPOOLS.filter((pool) =>
      pool.type.includes(YIELDEX_POOL_BISWAP)
    )

    this.yieldexAlpaca1 = YIELDEXPOOLS.filter((pool) =>
      pool.type.includes(YIELDEX_POOL_ALPACA_1)
    )

    this.yieldexAlpaca2 = YIELDEXPOOLS.filter((pool) =>
      pool.type.includes(YIELDEX_POOL_ALPACA_2)
    )

    this.yieldexBaby = YIELDEXPOOLS.filter((pool) =>
      pool.type.includes(BABY_YIELDEX)
    )
  }

  getUserDepositedPools = async () => {
    this.initilaizeYieldexData()
    const yieldexData = await Promise.all(
      [
        BABY_YIELDEX,
        YIELDEX_POOL_ALPACA_1,
        YIELDEX_POOL_ALPACA_2,
        YIELDEX_POOL_STABLE,
        YIELDEX_POOL_PREMIUM,
        YIELDEX_POOL_HIGH_YIELD,
        YIELDEX_POOL_STABLE_NEW,
        YIELDEX_POOL_PREMIUM_NEW,
        YIELDEX_POOL_HIGH_YIELD_NEW,
        YIELDEX_POOL_BISWAP,
      ].map(async (type) => {
        let contract
        let poolArray

        if (
          type.includes("NEW") ||
          type === YIELDEX_POOL_ALPACA_1 ||
          type === YIELDEX_POOL_ALPACA_2 ||
          type === YIELDEX_POOL_BISWAP ||
          type === BABY_YIELDEX
        ) {
          contract = this.utility.getContract(type, type)
        } else {
          contract = this.utility.getContract(YIELDEXCONTRACT, type)
        }

        if (
          type === YIELDEX_POOL_PREMIUM ||
          type === YIELDEX_POOL_PREMIUM_NEW
        ) {
          poolArray = this.yieldexPremium
        } else if (
          type === YIELDEX_POOL_HIGH_YIELD ||
          type === YIELDEX_POOL_HIGH_YIELD_NEW
        ) {
          poolArray = this.yieldexHighYield
        } else if (
          type === YIELDEX_POOL_STABLE ||
          type === YIELDEX_POOL_STABLE_NEW
        ) {
          poolArray = this.yieldexStable
        } else if (type === YIELDEX_POOL_BISWAP) {
          poolArray = this.yieldexBiswap
        } else if (type === YIELDEX_POOL_ALPACA_1) {
          poolArray = this.yieldexAlpaca1
        } else if (type === YIELDEX_POOL_ALPACA_2) {
          poolArray = this.yieldexAlpaca2
        } else if (type === BABY_YIELDEX) {
          poolArray = this.yieldexBaby
        }

        const yieldexPools = new YieldexPool(
          contract,
          this.userAddress,
          this.tokenPrices,
          poolArray,
          this.provider,
          0
        )

        const yieldexUserPoolsData =
          await yieldexPools.getYieldexUserPoolsDeposit()
        let depositedUSD = 0
        let pendingTenfi = 0
        let pendingUSD = 0
        let availableUSD = 0
        let balance = token_Balance(BNB, this.userAddress)

        yieldexUserPoolsData.forEach((pool) => {
          depositedUSD += pool.deposit
          pendingTenfi += pool.tenfiReward
          pendingUSD += pool.pendingTenfi
          availableUSD += balance * this.tokenPrices[BNB]
        })

        return {
          [type]: {
            depositedUSD,
            pendingTenfi,
            pendingUSD,
            availableUSD,
            pools: yieldexUserPoolsData.reduce(
              (acc, cur) => ({ ...acc, [cur.name]: cur }),
              {}
            ),
          },
        }
      })
    )

    return yieldexData
  }
}

class YieldexPool {
  contractInstance
  tokenPrices
  utility
  pools
  yieldexPoolId
  userAddress
  beltPools = [...beltPools]

  constructor(
    contractInstance,
    userAddress,
    tokenPrices,
    pools,
    provider,
    yieldexPoolId
  ) {
    this.contractInstance = contractInstance
    this.tokenPrices = tokenPrices
    this.pools = pools
    this.utility = new Utils(provider)
    this.yieldexPoolId = yieldexPoolId
    this.userAddress = userAddress
  }

  getYieldexUserData = async (pool, userAddress, yieldPoolId) => {
    try {
      let userShare
      try {
        userShare = await this.contractInstance.methods
          .userShare(pool.index, userAddress, yieldPoolId)
          .call()
      } catch (error) {
        throw error
      }

      let reward
      try {
        reward = await this.contractInstance.methods
          .returnReward(pool.index, userAddress, yieldPoolId.toString())
          .call()
      } catch (error) {
        reward = 0
      }

      let deposit

      try {
        deposit =
          ((userShare["0"] * 0.99) / 1e18) * (await this.getAssetPrice(pool))
      } catch (error) {
        throw error
      }

      let valuesLocked
      try {
        valuesLocked =
          (userShare["1"] / 1e18) * (await this.getAssetPrice(pool))
      } catch (error) {
        throw error
      }

      const pendingTenfiReward = (reward / 1e18) * this.tokenPrices[TENFI]

      return {
        deposit,
        pendingTenfiReward,
        userShare: userShare["0"] / 1e18,
        valuesLocked,
        reward: reward / 1e18,
      }
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  getYieldexUserPoolsDeposit = async () => {
    return await Promise.all(
      this.pools.map(async (pool) => {
        const { deposit, pendingTenfiReward, userShare, valuesLocked, reward } =
          await this.getYieldexUserData(
            pool,
            this.userAddress,
            this.yieldexPoolId
          )

        return {
          name: pool.name,
          id: pool.poolId,
          deposit,
          pendingTenfi: pendingTenfiReward,
          userShare,
          valuesLocked,
          tenfiReward: reward,
        }
      })
    )
  }

  getAssetPrice = async (pool) => {
    let assetPrice = 0
    if (this.beltPools.includes(pool.name)) {
      assetPrice = this.tokenPrices[pool.name]
    } else if (constants.alpacaTokens.includes(pool.name)) {
      if (this.tokenPrices[pool.name]) {
        assetPrice = this.tokenPrices[pool.name]
      } else {
        assetPrice = 0
      }
      return assetPrice
    } else if (
      pool.name === BANANA ||
      pool.name === BSW ||
      pool.name === CAKE ||
      pool.name === TENFI
    ) {
      assetPrice = this.tokenPrices[pool.name]
      return assetPrice
    } else
      try {
        switch (pool.name) {
          case BISWAP:
            assetPrice = this.tokenPrices[BSW]
            break
          default:
            const [reserve0, reserve1] = await this.utility.getTokenReserves(
              pool.lp,
              pool.address
            )

            const totalSupply = await this.utility.getTotalSupply(
              pool.lp,
              pool.address
            )

            assetPrice =
              (reserve0 * this.tokenPrices[pool.pair[0]] +
                reserve1 * this.tokenPrices[pool.pair[1]]) /
              totalSupply
        }

        return assetPrice
      } catch (error) {
        return assetPrice
      }
  }
}

import Web3 from "web3"
import constants from "@src/constants.json"
import axios from "@src/../node_modules/axios/index"
import { Utils } from "../Utils/index"
import { Token } from "../provider/Tokens"

import {
  BUSD,
  ICO1,
  PANCAKE,
  TENFARM,
  TENFI,
  TENFIV1,
  TENTOKEN,
  BNB,
  YIELDEXPOOLS,
  YIELDEX_POOL_HIGH_YIELD,
  YIELDEX_POOL_PREMIUM,
  YIELDEX_POOL_STABLE,
} from "../abi"

import { UserYieldex } from "./UserYieldex"
import { UserPool } from "./UserPools"

export class User {
  web3
  beltTokensData = []
  alpacaTokensData = []
  tokenPrices = {}
  userPoolsList = []
  provider
  utility
  pools
  deposited_usd
  rewards
  rewards_usd
  yieldexStable
  yieldexPremium
  yieldexHighYield
  userAddress

  constructor(userAddress) {
    if (window.ethereum) {
      this.provider = window.ethereum
    } else {
      this.provider = constants.web3.httpProvider
    }
    this.web3 = new Web3(this.provider)
    this.utility = new Utils(this.provider)
    this.userAddress = userAddress
  }

  initializeData = async (provider) => {
    try {
      this.beltTokensData = (
        await axios.get("https://s.belt.fi/info/all.json")
      ).data
    } catch (error) {
      this.beltTokensData = null
    }

    try {
      this.alpacaTokensData = (
        await axios.get(
          "https://alpaca-static-api.s3.amazonaws.com/v1/landing/summary.json",
          { timeout: 5000 }
        )
      ).data
    } catch (error) {
      this.alpacaTokensData = null
    }

    const tokens = new Token(
      provider,
      this.beltTokensData,
      this.alpacaTokensData
    )

    const tokenList = await tokens.getTokenPrice()

    this.tokenPrices = { ...this.tokenPrices, ...tokenList }
  }

  getUserValutsData = async () => {
    if (this.userAddress) {
      try {
        const tenFarmContract = this.utility.getContract(TENFARM, TENFARM)
        await this.initializeData(this.provider)
        for (let i = 0; i < 84; i++) {
          if (constants.poolIdExcluded.includes(i)) {
            continue
          }
          const userPools = new UserPool(
            this.provider,
            i,
            this.tokenPrices,
            this.beltTokensData,
            this.alpacaTokensData
          )
          this.userPoolsList.push(
            userPools.getUserPoolData(tenFarmContract, this.userAddress)
          )
        }

        let pools = await Promise.all(this.userPoolsList)

        const tokenBalance = {
          [BUSD]: await this.utility.getTokenBalance(
            this.userAddress,
            PANCAKE,
            BUSD,
            this.tokenPrices
          ),
          [BNB]: await this.utility.getTokenBalance(
            this.userAddress,
            PANCAKE,
            BNB,
            this.tokenPrices
          ),
          [TENFIV1]: await this.utility.getTokenBalance(
            this.userAddress,
            ICO1,
            TENFIV1,
            this.tokenPrices
          ),
          [TENFI]: await this.utility.getTokenBalance(
            this.userAddress,
            TENTOKEN,
            TENFI,
            this.tokenPrices
          ),
        }
        let deposited_usd = 0
        let rewards = 0
        let rewards_usd = 0

        pools.forEach((pool) => {
          deposited_usd += isNaN(pool.deposit_usd) ? 0 : pool.deposit_usd
          rewards += pool.pending
          rewards_usd += pool.pending_usd
        })

        return {
          pools: pools.reduce((acc, cur) => ({ ...acc, [cur.name]: cur }), {}),
          tokenBalance,
          deposited_usd,
          rewards,
          rewards_usd,
        }
      } catch (error) {
        throw error
      }
    } else {
      throw new Error("Wallet Address not found")
    }
  }

  initilaizeYieldexData = () => {
    this.yieldexStable = YIELDEXPOOLS.filter((pool) =>
      pool.type.includes(YIELDEX_POOL_STABLE)
    )
    this.yieldexPremium = YIELDEXPOOLS.filter((pool) =>
      pool.type.includes(YIELDEX_POOL_PREMIUM)
    )
    this.yieldexHighYield = YIELDEXPOOLS.filter((pool) =>
      pool.type.includes(YIELDEX_POOL_HIGH_YIELD)
    )
  }

  getYieldexDeposit = async (
    poolId,
    userAddress,
    yieldPoolId,
    poolName,
    contractInstance
  ) => {
    try {
      const userShare = await contractInstance.methods
        .userShare(poolId, userAddress, yieldPoolId)
        .call()

      const deposit =
        ((userShare["0"] * 0.99) / 1e18) * this.tokenPrices[poolName]

      const valuesLocked = (userShare["1"] / 1e18) * this.tokenPrices[poolName]

      return { deposit, userShare: userShare["0"] / 1e18, valuesLocked }
    } catch (error) {
      throw error
    }
  }

  getPoolsWithUserDeposits = async (
    pools,
    yieldexPoolId,
    userAddress,
    contractInstance
  ) => {
    pools.map(async (pool) => {
      const { deposit, userShare, valuesLocked } = await this.getYieldexDeposit(
        pool.index,
        userAddress,
        yieldexPoolId,
        pool.name,
        contractInstance
      )

      const { pendingTenfiReward, reward } = await this.getYieldexTenfiPending(
        pool.index,
        userAddress,
        yieldexPoolId,
        contractInstance
      )

      return {
        name: pool.name,
        id: pool.poolId,
        deposit,
        userShare,
        pendingTenfi: pendingTenfiReward,
        tenfiReward: reward,
        valuesLocked,
      }
    })
  }

  getUserYieldex = async () => {
    await this.initializeData(this.provider)
    const userYieldex = new UserYieldex(
      this.userAddress,
      this.tokenPrices,
      this.provider
    )
    return Object.assign({}, ...(await userYieldex.getUserDepositedPools()))
  }
}

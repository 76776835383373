import {
  GOT_BALANCE,
  NOT_WALLET_CONNECT,
  REQUEST_BALANCE,
  REQUEST_WALLET_CONNECT,
  REQUEST_WALLET_CONNECT_FAILED,
  REQUEST_WALLET_DISCONNECT,
  SUCCESS_WALLET_CONNECT,
} from "@src/actions/wallet"

const initialState = {
  isConnecting: false,
  isConnected: false,
  wallet: null,
  receivedAt: Date.now(),
  isLoadingBalance: false,
  isBalanceLoaded: false,
  provider: null,
  balanceLoadedAt: 0,
  balance: {
    pools: {},
    yieldexPools: {},
  },
}

function wallet(state = initialState, action) {
  switch (action.type) {
    case REQUEST_WALLET_CONNECT:
      return Object.assign({}, state, {
        provider: action.provider,
        isConnecting: true,
        isConnected: false,
      })
    case REQUEST_BALANCE:
      return Object.assign({}, state, {
        balanceLoadedAt: 0,
        balance: { ...state.balance },
        isLoadingBalance: true,
        isBalanceLoaded: false,
      })
    case GOT_BALANCE:
      return Object.assign({}, state, {
        isFetchingUserParams: false,
        isLoadingBalance: false,
        isBalanceLoaded: true,
        balanceLoadedAt: Date.now(),
        balance: action.balance,
      })
    case SUCCESS_WALLET_CONNECT:
      return Object.assign({}, state, {
        isConnected: true,
        isConnecting: false,
        wallet: action.wallet,
      })
    case REQUEST_WALLET_CONNECT_FAILED:
    case REQUEST_WALLET_DISCONNECT:
    case NOT_WALLET_CONNECT:
      return Object.assign({}, state, {
        ...initialState,
        isConnecting: action.isConnecting,
        isConnected: action.isConnected,
      })
    default:
      return state
  }
}

export default wallet

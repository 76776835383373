import { belt4BELT, beltBNB, beltBTC, beltETH, PANCAKE, TENTOKEN } from "../abi"

export const NonCompoundedPools = [
  "0xd15C444F1199Ae72795eba15E8C1db44E47abF62",
  "0xac6EE351e2E9108f03c7F5c49296505B8F336Be3",
  "0x09F39f9B7d6395155396Fed7347620dD54Da1dc6",
]

export const beltPools = [beltBTC, belt4BELT, beltBNB, beltETH]

export const CHAIN_ID = [56, 97]

export const BLOCKS_PER_DAY = 28800

export const REWARD_PER_BLOCK = 10000000000

export const CAKES_PER_BLOCK = 40

export const BANANA_PER_BLOCK = 10

export const BSW_PER_BLOCK = 28

export const BABY_PER_BLOCK = 12

export const GAS_FEE = 0.3

export const NETWORK_FEE = 0.5

export const DEPOSIT_FEE = 0.1

export const WITHDRAW_FEE = 0.0

export const BURN_FEE = {
  [TENTOKEN]: 1.5,
  [PANCAKE]: 3.0,
}

export const lendingMaxBorrowLimit = 90

export const lendingMaxAmount =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935"

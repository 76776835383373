import Popup, { TENLEND_BORROW_POPUP } from "@components/popup/index"
import React from "react"
import { connect, useSelector } from "react-redux"
import { popupClose } from "@src/actions/popup"
import { useState } from "react"
import Token from "../token/index"
import Tab from "../tab/index"
import Button from "../button/index"
import Input from "../input/index"
import { ThemeContext } from "../themeContext"
import { useContext } from "react"
import {
  enableSupplyAction,
  lendingBorrowAction,
  lendingRepayAction,
} from "@src/actions/lending"
import { floatNumRegex } from "@src/utils/regexConstants"
import { lendingMaxAmount } from "@src/web3/constants/index"
import { toWei } from "@src/web3/provider"

function TenLendBorrowPopup(props) {
  const { dispatch, visible, tokenData } = props
  const { theme } = useContext(ThemeContext)

  const [borrowTab, setBorrowTab] = useState(true)
  const [borrowValue, setBorrowValue] = useState("")
  const [repayValue, setRepayValue] = useState("")
  const [maxRepaySelected, setMaxRepaySelected] = useState(false)

  const { token, xToken } = tokenData

  const { lendingTokenData, lendingTokenUserData } = useSelector(
    (state) => state.lending
  )

  const { tokenUserData, borrow, totalBorrowBalance } = lendingTokenUserData

  const { xTokenData } = lendingTokenData

  const handleBorrowDisableOnInputValue = () => {
    /*
     * Disables borrow button if input amount is greater than the maximum amount
     * Disables borrow button if input amount is greater then borrow limit
     */

    if (borrow && borrow["borrowLimit"]) {
      const availableToBorrow = borrow["borrowLimit"] * 0.9 - totalBorrowBalance

      const maxBorrow = availableToBorrow / xTokenData[token]["price"]

      if (
        parseFloat((maxBorrow.toFixed(8) - (1e-8).toFixed(8)).toFixed(8)) <
        parseFloat(borrowValue)
      ) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  return (
    <Popup open={visible} openStateFn={() => dispatch(popupClose())}>
      <div className={"flex flex-col"}>
        <span className={"text-2xl font-semibold"}>
          {tokenData.marketName && tokenData.marketName}
        </span>
        {borrowTab ? (
          <div className='flex items-center justify-center relative'>
            <div style={{ width: "80%" }}>
              <Input
                placeholder={"0.00"}
                variant={"clean"}
                value={borrowValue}
                className={`border-none bg-white dark:bg-white h-56 text-5xl text-purple-100 text-2xl text-center`}
                style={{
                  background: theme === "light" ? "#fff" : "#16232E",
                  boxShadow: "none",
                  fontWeight: "bold",
                  color: "#A061F8",
                }}
                onChange={(val) => {
                  if (val === "") setBorrowValue("")
                  if (floatNumRegex.test(val) && val !== "") setBorrowValue(val)
                }}
              />
            </div>

            <Button
              onClick={() => {
                // if (borrow && borrow["borrowLimit"]) {
                //   const availableToBorrow =
                //     borrow["borrowLimit"] * 0.9 - totalBorrowBalance
                //   const maxBorrow =
                //     availableToBorrow / xTokenData[token]["price"]
                //   if (
                //     (maxBorrow.toFixed(8) - (1e-8).toFixed(8)).toFixed(8) > 0
                //   ) {
                //     setBorrowValue(
                //       (maxBorrow.toFixed(8) - (1e-8).toFixed(8)).toFixed(8)
                //     )
                //   }
                // }
              }}
              variant={"clean"}
              className={"absolute right-2"}>
              MAX
            </Button>
          </div>
        ) : (
          <div className='flex flex-col items-center justify-center relative'>
            {tokenUserData &&
            tokenUserData[token] &&
            tokenUserData[token]["supplyAllowance"] ? (
              <div className='flex items-center justify-center relative'>
                <div style={{ width: "80%" }}>
                  <Input
                    placeholder={"0.00"}
                    variant={"clean"}
                    value={repayValue}
                    className={`border-none bg-white dark:bg-white h-56 text-5xl text-purple-100 text-2xl text-center`}
                    style={{
                      background: theme === "light" ? "#fff" : "#16232E",
                      boxShadow: "none",
                      fontWeight: "bold",
                      color: "#A061F8",
                    }}
                    onChange={(val) => {
                      setMaxRepaySelected(false)
                      if (val === "") setRepayValue("")
                      if (floatNumRegex.test(val) && val !== "")
                        setRepayValue(val)
                    }}
                  />
                </div>

                <Button
                  onClick={() => {
                    // if (
                    //   tokenUserData &&
                    //   tokenUserData[token] &&
                    //   tokenUserData[token]["borrowBalance"]
                    // ) {
                    //   setRepayValue(
                    //     parseFloat(
                    //       tokenUserData[token]["borrowBalance"]
                    //     ).toFixed(8)
                    //   )
                    //   setMaxRepaySelected(true)
                    // }
                  }}
                  variant={"clean"}
                  className={"absolute right-2"}>
                  MAX
                </Button>
              </div>
            ) : (
              <>
                <Token
                  size={"6rem"}
                  symbol={tokenData.token && tokenData.token}
                  label={""}
                />
                <p
                  className={
                    "mt-5 mb-20 font-normal text-sm text-center w-80 text-gray-1280"
                  }>
                  To Supply or Repay Chainlink to the Compound Protocol, you
                  need to enable it first.
                </p>
              </>
            )}
          </div>
        )}

        <div className={"flex"}>
          <Tab
            className={"w-full text-center"}
            active={borrowTab}
            onClick={() => setBorrowTab(true)}>
            BORROW
          </Tab>
          <Tab
            className={"w-full text-center"}
            active={!borrowTab}
            onClick={() => setBorrowTab(false)}>
            REPAY
          </Tab>
        </div>
        <div className={"flex flex-col mt-11 px-2"}>
          <span className={"font-normal text-sm text-gray-1280"}>
            Supply Rates
          </span>
          <div
            className={
              "flex items-center justify-between text-sm font-semibold my-7"
            }>
            <span className={"flex items-center"}>
              <Token
                symbol={tokenData.token && tokenData.token}
                size={"2rem"}
                label={""}
                className={"mr-5"}
              />
              Borrow APY
            </span>
            {xTokenData &&
              xTokenData[token] &&
              parseFloat(xTokenData[token]["borrowApy"]).toFixed(2)}{" "}
            %
          </div>
          <div
            className={
              "flex items-center justify-between text-sm font-semibold border-t dark:border-night-500 pt-7"
            }>
            <span className={"flex items-center"}>
              <Token
                symbol={"tenyield"}
                size={"2rem"}
                label={""}
                className={"mr-5"}
              />
              Distribution APY
            </span>
            {xTokenData &&
              xTokenData[token] &&
              parseFloat(xTokenData[token]["borrowDistributedApy"]).toFixed(
                2
              )}{" "}
            %
          </div>
        </div>

        {(borrowTab ||
          (tokenUserData &&
            tokenUserData[token] &&
            tokenUserData[token]["supplyAllowance"])) && (
          <div className={"mt-8 w-full flex flex-col"}>
            <span className={"font-normal text-sm text-gray-1280"}>
              Borrow Limits
            </span>
            <div
              className={
                "flex items-center justify-between text-sm font-semibold my-7"
              }>
              <span className={"flex items-center"}>Borrow Limit</span>$ {""}
              {borrow &&
                borrow["borrowLimit"] &&
                parseFloat(borrow["borrowLimit"]).toFixed(2)}{" "}
            </div>
            <div
              className={
                "flex items-center justify-between text-sm font-semibold border-t dark:border-night-500 pt-7 mb-8"
              }>
              <span className={"flex items-center"}>Borrow Limit Used</span>
              {borrow &&
                borrow["borrowLimit"] &&
                parseFloat(borrow["usedBorrowLimit"]).toFixed(4)}{" "}
              %
            </div>
          </div>
        )}

        {borrowTab ? (
          <Button
            disabled={
              borrowValue === "" ||
              parseFloat(borrowValue) <= 0 ||
              handleBorrowDisableOnInputValue()
                ? true
                : false
            }
            className={`h-11 text-xs font-light w-full lg:w-auto`}
            textColor={`text-night-10 dark:text-white`}
            onClick={() => {
              // dispatch(lendingBorrowAction(xToken, borrowValue))
            }}>
            Borrow
          </Button>
        ) : tokenUserData &&
          tokenUserData[token] &&
          tokenUserData[token]["supplyAllowance"] ? (
          <Button
            disabled={
              repayValue === "" ||
              parseFloat(repayValue) <= 0 ||
              parseFloat(tokenUserData[token]["borrowBalance"].toFixed(8)) <
                parseFloat(repayValue)
                ? true
                : false
            }
            className={`h-11 text-xs   font-light w-full lg:w-auto`}
            textColor={`text-night-10 dark:text-white`}
            onClick={() => {
              // if (maxRepaySelected) {
              //   dispatch(lendingRepayAction(xToken, lendingMaxAmount))
              // } else {
              //   dispatch(lendingRepayAction(xToken, toWei(repayValue)))
              // }
            }}>
            Repay
          </Button>
        ) : (
          <div className={"mt-8 w-full flex flex-col"}>
            <Button
              className={`h-11 text-xs dark:text-white font-light w-full lg:w-auto`}
              textColor={`text-white`}
              onClick={() => {
                /* dispatch(enableSupplyAction(token, xToken)) */
              }}>
              Enable
            </Button>
          </div>
        )}

        {borrowTab ? (
          <div className={"w-full flex flex-col"}>
            <div
              className={
                "mt-8 flex justify-between font-normal text-sm text-center text-gray-1280"
              }>
              <span>Current Borrowing</span>
              <span>
                <span className={"text-night-10 dark:text-white mr-1"}>
                  {tokenUserData &&
                    tokenUserData[token] &&
                    parseFloat(tokenUserData[token]["borrowBalance"]).toFixed(
                      2
                    )}
                </span>
                {tokenData.token && tokenData.token}
              </span>
            </div>
          </div>
        ) : (
          <div className={"w-full flex flex-col"}>
            <div
              className={
                "mt-8 flex justify-between font-normal text-sm text-center text-gray-1280"
              }>
              <span>Wallet Balance</span>
              <span>
                <span className={"text-night-10 dark:text-white mr-1"}>
                  {tokenUserData &&
                    tokenUserData[token] &&
                    parseFloat(tokenUserData[token]["tokenBalance"]).toFixed(2)}
                </span>
                {token}
              </span>
            </div>
          </div>
        )}
      </div>
    </Popup>
  )
}

function mapStateToProps({ popup, wallet }) {
  return {
    wallet,
    visible: popup.popups[TENLEND_BORROW_POPUP],
    tokenData: popup.token,
  }
}

export default connect(mapStateToProps)(TenLendBorrowPopup)

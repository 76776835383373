import ReactTooltip from "react-tooltip"
import React from "react"
import { MdHelp } from "react-icons/all"

function Tooltip(props) {
  return (
    <div className={`${props.className} text-violin-500 dark:text-violin-500`}>
      <a data-tip={props.name} href={"/#"}>
        <MdHelp size={props.iconSize || 24} />
      </a>
      <ReactTooltip place={"left"} type={"dark"} effect={"solid"} />
    </div>
  )
}

export default Tooltip

function Footer(props) {
  return (
    <footer
      style={{ pointerEvents: "none" }}
      id='footerID'
      className={`${props.className} dark:text-night-700`}>
      {props.children}
    </footer>
  )
}

export default Footer

const CaptionVariants = {
  normal: "lg:text-sm xl:text-md md:text-sm xs:text-xs",
  large: "text-xl",
}

function Caption(props) {
  const variant = props.variant
    ? CaptionVariants[props.variant]
    : CaptionVariants["normal"]

  return (
    <label
      htmlFor={props.htmlFor}
      className={`${variant} dark:text-night-700 block  tracking-wide text-gray-500 font-bold  whitespace-nowrap ${props.className}`}>
      {props.children}
    </label>
  )
}

export default Caption

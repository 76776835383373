import {
  NOTIFICATION_CLOSE,
  NOTIFICATION_OPEN,
} from "@src/actions/notification"
import { ICON_LOADING } from "@components/icons"

const initialState = {
  isOpen: false,
  props: {
    title: "Loading your balances",
    message: "Please wait until balances are loaded",
    icon: ICON_LOADING,
  },
}

export default function notification(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_OPEN: {
      return {
        ...state,
        isOpen: true,
        props: action.props,
      }
    }
    case NOTIFICATION_CLOSE: {
      return {
        ...state,
        isOpen: false,
      }
    }
    default:
      return { ...state }
  }
}

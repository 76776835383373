import {
  CLEAR_VALUE,
  CONVERT,
  DEADLINE,
  GET_LP,
  INP1PERINP2,
  INP2PERINP1,
  INPUT,
  INPUT1BALANCE,
  INPUT2BALANCE,
  LIQUIDITYALLOWANCE,
  LIQUIDITYTRANSACTION,
  POOLSHARE,
  SLIPPAGE,
} from "@src/actions/liquidity"

const initialState = {
  input1: "",
  input2: "",
  inp1PerInp2: null,
  inp2PerInp1: null,
  poolShare: null,
  input1Balance: null,
  input2Balance: null,
  slippage: 0.5,
  deadline: 20,
  balance: 0,
  youReceive: 0,
  minReceived: 0,
  share: 0,
  input: "",
  allowance: false,
  transaction: false,
  amountsOut0: 0,
  amountsOut1: 0,
}

const liquidity = (state = initialState, action) => {
  switch (action.type) {
    case SLIPPAGE:
      return { ...state, slippage: action.slippage }
    case DEADLINE:
      return { ...state, deadline: action.deadline }
    case CONVERT:
      return { ...state }
    case INP1PERINP2:
      return { ...state, inp1PerInp2: action.inp1PerInp2 }
    case INP2PERINP1:
      return { ...state, inp2PerInp1: action.inp2PerInp1 }
    case POOLSHARE:
      return { ...state, poolShare: action.poolShare }

    case INPUT1BALANCE:
      return { ...state, input1Balance: action.input1Balance }
    case INPUT2BALANCE:
      return { ...state, input2Balance: action.input2Balance }
    case INPUT:
      return { ...state, input: action.amount }
    case GET_LP:
      return {
        ...state,
        youReceive: action.liquidity,
        minReceived: action.minliquidity,
        share: action.share,
        amountsOut0: action.amountsOut0,
        amountsOut1: action.amountsOut1,
      }
    case LIQUIDITYALLOWANCE:
      return {
        ...state,
        allowance: action.allowanceStatus,
      }
    case LIQUIDITYTRANSACTION:
      return {
        ...state,
        transaction: action.transaction,
      }
    case CLEAR_VALUE:
      return {
        ...state,
        slippage: 0.5,
        deadline: 20,
        balance: 0,
        youReceive: 0,
        minReceived: 0,
        share: 0,
        input: "",
        allowance: false,
        transaction: false,
        amountsOut0: 0,
        amountsOut1: 0,
      }
    default:
      return { ...state }
  }
}

export default liquidity

import { getYieldexUIData, toWei } from "@web3/provider"
import {
  claimYieldex,
  depositYieldex,
  withdrawYieldex,
} from "@web3/transactions"
import { notificationClose, notificationOpen } from "@src/actions/notification"
import { ICON_LOADING } from "@components/icons"
import {
  connectWalletIfNeeded,
  requestBalance,
  UPDATE_INTERVAL,
} from "./wallet"
import { Yieldex } from "@src/web3/provider/Yieldex"

export const REQUEST_YIELDEX_PARAMS = "REQUEST_YIELDEX_PARAMS"
export const RECEIVE_YIELDEX_PARAMS = "RECEIVE_YIELDEX_PARAMS"
export const FETCH_YIELDEX_UI_DATA = "FETCH_YIELDEX_UI_DATA"

export const withdrawYieldexAction = (
  poolId,
  amount,
  percentage,
  ticker,
  poolType
) => {
  return async (dispatch, getState) => {
    const { wallet } = getState()
    if (wallet.wallet) {
      dispatch(
        notificationOpen({
          title: "Transaction in progress",
          message: "Please Wait",
          icon: ICON_LOADING,
        })
      )
      try {
        await withdrawYieldex(
          poolId,
          wallet.wallet,
          percentage * 10,
          ticker,
          poolType
        )
        dispatch(notificationClose())
      } catch (error) {
        dispatch(notificationClose())
        dispatch(
          notificationOpen({
            title: "Transaction in progress",
            message: `${error.message}`,
          })
        )
      }
      dispatch(getYieldexPoolDataAction())
      dispatch(requestBalance(wallet.wallet))
    } else {
      dispatch(connectWalletIfNeeded())
    }
  }
}

export const depositYieldexAction = (poolId, amount, ticker, poolType) => {
  return async (dispatch, getState) => {
    const { wallet } = getState()
    if (wallet.wallet) {
      dispatch(
        notificationOpen({
          title: "Transaction in progress",
          message: "Please Wait",
          icon: ICON_LOADING,
        })
      )
      try {
        await depositYieldex(
          poolId,
          toWei(amount),
          wallet.wallet,
          ticker,
          poolType
        )
        dispatch(notificationClose())
      } catch (error) {
        dispatch(notificationClose())
        dispatch(
          notificationOpen({
            title: "Error",
            message: `${error.message}`,
          })
        )
      }
      dispatch(getYieldexPoolDataAction())
      dispatch(requestBalance(wallet.wallet))
    } else {
      dispatch(connectWalletIfNeeded())
    }
  }
}

export const claimYieldexAction = (poolId, poolType) => {
  return async (dispatch, getState) => {
    const { wallet } = getState()
    if (wallet.wallet) {
      dispatch(
        notificationOpen({
          title: "Transaction in progress",
          message: "Please Wait",
          icon: ICON_LOADING,
        })
      )
      try {
        await claimYieldex(poolId, wallet.wallet, poolType)
        dispatch(notificationClose())
      } catch (error) {
        dispatch(notificationClose())
        dispatch(
          notificationOpen({
            title: "Error",
            message: `${error.message}`,
          })
        )
      }
      dispatch(getYieldexPoolDataAction())
      dispatch(requestBalance(wallet.wallet))
    } else {
      dispatch(connectWalletIfNeeded())
    }
  }
}

export const yieldexPoolsUIDataAction = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_YIELDEX_UI_DATA,
      yieldexUIData: getYieldexUIData(),
    })
  }
}

export const getYieldexPoolDataAction = () => {
  return async (dispatch) => {
    dispatch({
      type: REQUEST_YIELDEX_PARAMS,
      pools: 3,
    })

    let yieldex = new Yieldex()

    try {
      let poolsData = await yieldex.getAllYieldexPoolData()
      dispatch({
        type: RECEIVE_YIELDEX_PARAMS,
        params: {
          yieldexPoolsDetails: poolsData,
        },
        receivedAt: Date.now(),
      })
      poolsData = null
    } catch (error) {
      setTimeout(() => {
        dispatch(getYieldexPoolDataAction())
      }, [10000])
    }

    yieldex = null
  }
}

function shouldFetchYieldexParams(state) {
  // const params = state.yieldex.params

  // const initialLoad = params.platformData.tvl === 0
  const updateNeeded = Date.now() - state.yieldex.receivedAt > UPDATE_INTERVAL

  return updateNeeded && !state.yieldex.isFetching
}

export function fetchYieldexParamsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchYieldexParams(getState())) {
      return dispatch(getYieldexPoolDataAction())
    }
  }
}

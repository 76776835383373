import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import { ThemeProvider } from "@components/themeContext"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { Provider } from "react-redux"
import thunk from "redux-thunk"
import { applyMiddleware, createStore } from "redux"
import reducer from "./reducers"
import { createLogger } from "redux-logger"
import "@src/utils/languageTranslation"
// import { lazyLoadFunWallet } from "./utils/funWalletUtils"

const middleware = [thunk]
if (process.env.NODE_ENV !== "production") {
  middleware.push(createLogger())
}

// lazyLoadFunWallet()

const store = createStore(reducer, applyMiddleware(...middleware))

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

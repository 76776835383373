import { connect } from "react-redux"
import { FiX } from "react-icons/all"
import Button from "@components/button"
import { notificationClose } from "@src/actions/notification"
import icons from "@components/icons"

function Notification(_props) {
  const { notification, dispatch } = _props

  const { isOpen, props } = notification

  return (
    <div
      className={`fixed w-10/12	md:w-6/12	lg:w-30rem z-50 top-10 left-1/2 -translate-x-1/2 transform pointer-events-auto duration-300 shadow-xl ${
        isOpen ? `` : "-translate-y-80"
      }`}>
      <div
        className='px-8 py-3 leading-normal text-night-100 bg-white rounded-lg shadow-lg relative flex flex-row space-x-4 items-center'
        role='alert'>
        <div className={`relative w-10`}>{props.icon && icons[props.icon]}</div>
        <div className={`flex-1`}>
          <p className='font-bold'>{props.title}</p>
          <p>{props.message}</p>
        </div>
        <div className={``} onClick={() => dispatch(notificationClose())}>
          <Button variant={"clean"} className={``}>
            <FiX />
          </Button>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({ notification }) {
  return {
    notification,
  }
}

export default connect(mapStateToProps)(Notification)

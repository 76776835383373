import {
  EXCHANGE_POPUP,
  LP_POPUP,
  SWAP_POPUP,
  LIQUIDITY_POPUP,
} from "@components/popup"
import { connectWalletIfNeeded } from "@src/actions/wallet"
import {
  TENLEND_SUPPLY_POPUP,
  TENLEND_BORROW_POPUP,
  TENLEND_CLAIM_REWARD,
} from "@src/components/popup/index"

export const POPUP_OPEN = "POPUP_OPEN"
export const POPUP_CLOSE = "POPUP_CLOSE"

export function popupOpen(popupName, token) {
  return (dispatch, getState) => {
    const state = getState()
    if (
      [
        EXCHANGE_POPUP,
        LP_POPUP,
        SWAP_POPUP,
        LIQUIDITY_POPUP,
        TENLEND_SUPPLY_POPUP,
        TENLEND_BORROW_POPUP,
        TENLEND_CLAIM_REWARD,
      ].includes(popupName) &&
      !state.wallet.wallet
    ) {
      dispatch(connectWalletIfNeeded())
    } else {
      dispatch({
        type: POPUP_OPEN,
        popup: popupName,
        token,
      })
    }
  }
}
export function popupClose() {
  return (dispatch) => {
    dispatch({
      type: POPUP_CLOSE,
    })
  }
}

import {
  FUNFAIR_PROVIDER,
  getAccounts,
  // getUserData,
  getWeb3Instance,
  loginWalletEnable,
  logoutWallet,
  walletWasLoggedOut,
} from "@web3/provider"
import {
  getApproval,
  handleOnDeposit,
  handleOnWithdraw,
  tokenSwapV1V2,
  TRANSACTION_ERROR,
  TRANSACTION_RECEIPT,
} from "@web3/transactions"
import { popupOpen } from "@src/actions/popup"
import { WALLET_POPUP } from "@components/popup"
import { notificationClose, notificationOpen } from "@src/actions/notification"
import { ICON_LOADING } from "@components/icons"
import { funFairlogout } from "@src/utils/funWalletUtils"
import { User } from "@src/web3/user/User"

export const REQUEST_WALLET_CONNECT = "REQUEST_WALLET_CONNECT"
export const REQUEST_WALLET_CONNECT_FAILED = "REQUEST_WALLET_CONNECT_FAILED"
export const REQUEST_WALLET_DISCONNECT = "REQUEST_WALLET_DISCONNECT"
export const SUCCESS_WALLET_CONNECT = "SUCCESS_WALLET_CONNECT"
export const NOT_WALLET_CONNECT = "NOT_WALLET_CONNECT"

export const SWITCH_WALLET = "SWITCH_WALLET"

export const REQUEST_BALANCE = "REQUEST_BALANCE"
export const GOT_BALANCE = "GOT_BALANCE"

export const UPDATE_INTERVAL = 1000 * 30

// function that opens window and sets state for waiting for wallet
export function requestWalletConnect(providerName) {
  return (dispatch) => {
    dispatch({
      type: REQUEST_WALLET_CONNECT,
      provider: providerName,
    })

    localStorage.setItem("provider-name", providerName)

    /* if (localStorage.getItem("provider-name") === FUNFAIR_PROVIDER) {
      getAccounts("", providerName)
        .then((acc) => {
          getWeb3Instance(providerName).then(() => {
            loginWalletEnable()
            dispatch(walletConnected(acc[0]))
          })
        })
        .catch((err) => {
          dispatch(walletDisconnected())
          dispatch(
            notificationOpen({
              title: "Error",
              message: "Error in connecting to wallet",
            })
          )
          dispatch(requestWalletConnectFailed(err))
        })
    } else { */
    getWeb3Instance(providerName)
      .then((web3) => {
        getAccounts(web3, providerName)
          .then((acc) => {
            loginWalletEnable()
            dispatch(walletConnected(acc[0]))
            /* dispatch(
              walletConnected("0xaDaF1B1f5Ef8Aa816633d22eb468DA8093B718d1")
            ) */
            /* dispatch(
              walletConnected("0xaDC83042Db3a395E8e580A785eB0310B9aF9a6a3")
            ) */
            /* dispatch(
              walletConnected("0x879035b09b9bc3e445e8adc72364da67d773d6c6")
            ) */
            /* dispatch(
              walletConnected("0x3d9bf4b755dbb6bf01a95e864d456278902a633c")
            ) */
          })
          .catch((err) => {
            dispatch(walletDisconnected())
            dispatch(
              notificationOpen({
                title: "Error",
                message: "Error in connecting to wallet",
              })
            )
            dispatch(requestWalletConnectFailed(err))
          })
        // onWalletChanged().then((acc) => dispatch(requestWalletDisconnect(acc)))
      })
      .catch((err) => {
        dispatch(walletDisconnected())
        dispatch(
          notificationOpen({
            title: "Error",
            message: err,
          })
        )
      })
  }
  // }
}

export function connectExistingWallet(userAddress) {
  return (dispatch, getState) => {
    if (shouldConnectWallet(getState()) && !walletWasLoggedOut())
      dispatch(walletConnected(userAddress))
  }
}

function requestWalletConnectFailed(msg) {
  return {
    type: REQUEST_WALLET_CONNECT_FAILED,
    reason: msg,
  }
}

export function requestWalletDisconnect(wallet) {
  return (dispatch) => {
    if (localStorage.getItem("provider-name") === FUNFAIR_PROVIDER) {
      funFairlogout()
    }

    clearInterval(window.pendingTenfiInterval)

    logoutWallet().then(() =>
      dispatch({
        type: REQUEST_WALLET_DISCONNECT,
        wallet: wallet,
      })
    )
  }
}

export function walletConnected(wallet) {
  return (dispatch) => {
    dispatch({
      type: SUCCESS_WALLET_CONNECT,
      wallet: wallet,
      receivedAt: Date.now(),
    })

    dispatch(getBalanceIfNeeded())
  }
}

export function walletDisconnected(wallet) {
  return {
    type: NOT_WALLET_CONNECT,
    wallet: null,
    receivedAt: Date.now(),
    isConnected: false,
    isConnecting: false,
  }
}

export function switchWallet(newWallet) {
  return (dispatch, getState) => {
    loginWalletEnable()
    // dispatch(requestWalletDisconnect(getState().wallet.wallet))
    loginWalletEnable()
    dispatch(walletConnected(newWallet))
    const providerName = localStorage.getItem("provider-name")
    dispatch(requestWalletConnect(providerName))
  }
}

function shouldConnectWallet(state) {
  return !state.wallet.wallet
}

export function connectWalletIfNeeded() {
  return (dispatch, getState) => {
    if (shouldConnectWallet(getState())) {
      dispatch(popupOpen(WALLET_POPUP))
    }
  }
}

export function requestBalance(userAddress) {
  return async (dispatch) => {
    let user = new User(userAddress)

    try {
      let valutsData = await user.getUserValutsData()
      let yieldexPools = await user.getUserYieldex()
      dispatch({
        type: GOT_BALANCE,
        balance: {
          ...valutsData,
          yieldexPools,
        },
      })

      valutsData = null
      yieldexPools = null
    } catch (error) {
      setTimeout(() => {
        dispatch(requestBalance())
      }, [10000])
    }

    user = null

    // getUserData(userAddress)
    //   .then((r) => {
    //     console.log(r)
    //     console.log("LPLPPPPPPPPPPPPPPPPPPPPPPPPP")
    //     dispatch({
    //       type: GOT_BALANCE,
    //       balance: r,
    //     })
    //     // dispatch(notificationClose())
    //   })
    //   .catch((err) => {
    //     // console.log(err)
    //     /**Error  err.message.message*/
    //     // dispatch(
    //     //   notificationOpen({
    //     //     title: "Error",
    //     //     message: err.message.message,
    //     //   })
    //     // )
    //   })

    //getUserData(userAddress).then(result => console.log)
  }
}

function shouldLoadBalance(state) {
  const params = state.wallet
  const initialLoad = !state.wallet.isBalanceLoaded
  const updateNeeded = Date.now() - params.balanceLoadedAt > UPDATE_INTERVAL
  return (initialLoad || updateNeeded) && !state.wallet.isLoadingBalance
}

export function getBalanceIfNeeded() {
  return (dispatch, getState) => {
    if (shouldLoadBalance(getState())) {
      dispatch({ type: REQUEST_BALANCE })
      // dispatch(
      //   notificationOpen({
      //     title: "Loading your balances",
      //     message: "Please wait until balances are loaded",
      //     icon: ICON_LOADING,
      //   })
      // )
      dispatch(requestBalance(getState().wallet.wallet))
      window.pendingTenfiInterval = setInterval(() => {
        dispatch(requestBalance(getState().wallet.wallet))
      }, UPDATE_INTERVAL)
    }
  }
}

function handleTransactionResult(address, status, props) {
  return (dispatch) => {
    switch (status) {
      case TRANSACTION_RECEIPT:
        dispatch(notificationClose())
        dispatch(requestBalance(address))
        break
      case TRANSACTION_ERROR:
        dispatch(
          notificationOpen({
            title: props.error && props.error.message,
          })
        )
        break
      default:
        break
    }
  }
}

export function withdraw(id, amount, address) {
  if (!address) {
    return (dispatch) => {
      dispatch(connectWalletIfNeeded())
    }
  }
  return (dispatch) => {
    ;(function (dispatch) {
      dispatch(
        notificationOpen({
          title: "Transaction in progress",
          message: "Keep waiting",
          icon: ICON_LOADING,
        })
      )
      handleOnWithdraw(id, amount, address, (status, args) =>
        dispatch(handleTransactionResult(address, status, args))
      ).then((eventType, args) => {
        dispatch(requestBalance(address))
        // console.log(eventType, args)
      })
    })(dispatch)
  }
}

export function deposit(id, amount, address) {
  if (!address) {
    return (dispatch) => {
      dispatch(connectWalletIfNeeded())
    }
  }
  return (dispatch) => {
    ;(function (dispatch) {
      dispatch(
        notificationOpen({
          title: "Transaction in progress",
          message: "Keep waiting",
          icon: ICON_LOADING,
        })
      )
      handleOnDeposit(id, amount.toString(), address, (status, args) =>
        dispatch(handleTransactionResult(address, status, args))
      )
        .then((eventType, args) => {
          dispatch(requestBalance(address))
          console.log(eventType, args)
        })
        .catch((error) => {
          dispatch(notificationClose())
          dispatch(
            notificationOpen({
              title: "Error",
              message: "Error in transaction",
            })
          )
          /* console.log(error)
          dispatch(
            notificationOpen({
              title: "Error",
              message: error.message,
            })
          ) */
        })
    })(dispatch)
  }
}

export function withdrawAll(amount, address) {
  if (!address) {
    return (dispatch) => {
      dispatch(connectWalletIfNeeded())
    }
  }
  return (dispatch, getState) => {
    const state = getState()

    Object.entries(state.wallet.balance.pools).map((poolPair) => {
      if (poolPair[1].pending > 0)
        dispatch(withdraw(poolPair[1].id, 0, address))
      return poolPair
    })
  }
}

export function isWalletConnectedWithDeposits(wallet) {
  return (
    wallet &&
    wallet.wallet &&
    wallet.balance &&
    Object.keys(wallet.balance.pools).length > 0
  )
}

export function approve(pool, token, address) {
  if (!address) {
    return (dispatch) => {
      dispatch(connectWalletIfNeeded())
    }
  }
  return (dispatch) => {
    ;(function (dispatch) {
      dispatch(
        notificationOpen({
          title: "Approval in progress",
          message: "Keep waiting",
          icon: ICON_LOADING,
        })
      )
      getApproval(pool, token, address, (status, args) =>
        dispatch(handleTransactionResult(address, status, args))
      ).then((eventType, args) => {
        console.log(eventType, args)
      })
    })(dispatch)
  }
}

export function swapv1v2(pool, token, amount, address) {
  if (!address) {
    return (dispatch) => {
      dispatch(connectWalletIfNeeded())
    }
  }
  return (dispatch) => {
    ;(function (dispatch) {
      dispatch(
        notificationOpen({
          title: "Swap in progress",
          message: "Keep waiting",
          icon: ICON_LOADING,
        })
      )
      tokenSwapV1V2(pool, token, amount, address, (status, args) =>
        dispatch(handleTransactionResult(address, status, args))
      ).then((eventType, args) => {
        console.log(eventType, args)
      })
    })(dispatch)
  }
}

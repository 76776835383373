import {
  aUST,
  BANANA,
  BNB,
  BTCB,
  BUSD,
  CAKE,
  DAI,
  ETH,
  LEND,
  TENFI,
  TUSD,
  USDC,
  USDT,
  UST,
  XaUST,
  XBANANA,
  XBNB,
  XBTCB,
  XBUSD,
  XCAKE,
  XDAI,
  XETH,
  XLEND,
  XTENFI,
  XTUSD,
  XUSDC,
  XUSDT,
  XUST,
} from "@src/web3/abi";

export const tokenMarketName = {
  [BNB]: "Binance Coin",
  [ETH]: "Ether",
  [USDT]: "Tether",
  [BTCB]: "Bitcoin",
  [BUSD]: "Binance USD",
  [USDC]: "USD Coin",
  [UST]: "Terra USD",
  [CAKE]: "Cake",
  [BANANA]: "Banana",
  [DAI]: "Dai",
  [TUSD]: "TrueUSD",
  [aUST]: "aUST",
  [LEND]: "LEND",
  [TENFI]: "TENFI",
};

export const lendingTokenMarkets = {
  [XBUSD]: BUSD,
  [XBTCB]: BTCB,
  [XUST]: UST,
  [XCAKE]: CAKE,
  [XBANANA]: BANANA,
  [XTUSD]: TUSD,
  [XBNB]: BNB,
  [XETH]: ETH,
  [XUSDT]: USDT,
  [XUSDC]: USDC,
  [XDAI]: DAI,
  [XaUST]: aUST,
  [XLEND]: LEND,
  [XTENFI]: TENFI,
};

import { Fragment, useState } from "react"
import Caption from "@components/caption"
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid"
import { Listbox, Transition } from "@headlessui/react"
import Token from "@components/token"

function ListboxItem(props) {
  let tokenImg = ``
  if (props.imageDir) {
    if (props.item.includes(" ")) {
      const tokens = props.item.split(" ")[0].split("-")
      tokenImg = tokens.map(
        (token) => `${props.imageDir}${[token.toLowerCase()]}.png`
      )
    } else {
      tokenImg = [`${props.imageDir}${[props.item.toLowerCase()]}.png`]
    }
  }

  return (
    <Token
      name={props.item}
      symbol={props.item}
      label={props.item}
      size={24}
      tokens={props.imageDir ? tokenImg : []}
    />
  )
}

function ListboxComponent(props) {
  const { options, imageDir, onChange, value, listBoxButtonStyle } = props

  const [selectedOption, setSelected] = useState(value)

  const opts = options

  return (
    <div className={props.className}>
      {props.caption && <Caption>{props.caption}</Caption>}
      {opts.length > 0 && (
        <Listbox
          value={value || selectedOption}
          onChange={(val) => {
            setSelected(val)
            onChange && onChange(val)
          }}>
          <div className='relative'>
            <Listbox.Button
              style={listBoxButtonStyle}
              className={`border w-full flex rounded-md rounded focus:outline-none dark:border-night-700 ${
                props.buttonHeight ? props.buttonHeight : `h-12`
              }`}>
              <span className='flex items-center truncate text-left text-sm h-full w-full pl-4 font-semibold'>
                {imageDir &&
                selectedOption &&
                selectedOption.toLowerCase() !== "all" ? (
                  <ListboxItem item={value} imageDir={imageDir} />
                ) : (
                  selectedOption
                )}
              </span>
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <SelectorIcon
                  className='w-5 h-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <Listbox.Options className='z-50 w-full border dark:border-night-700 max-h-80 dark:bg-night-500 dark:border-night-500 dark:text-night-900 absolute py-1 mt-3 overflow-auto text-base bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {opts.map((option, optionIdx) => (
                  <Listbox.Option
                    key={optionIdx}
                    className={({ active }) =>
                      `${
                        active
                          ? "text-gray-200 dark:text-night-900 bg-night-100"
                          : "text-gray-500 dark:text-night-900"
                      }
                          cursor-pointer select-none relative py-2 pl-10 pr-4`
                    }
                    value={option}>
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`${
                            selected ? "font-medium" : "font-normal"
                          } inline-block`}>
                          {imageDir && option.toLowerCase() !== "all" ? (
                            <ListboxItem item={option} imageDir={imageDir} />
                          ) : (
                            option
                          )}
                        </span>
                        {selected ? (
                          <span
                            className={`${
                              active ? "text-amber-600" : "text-amber-600"
                            } absolute inset-y-0 left-0 flex items-center pl-3`}>
                            <CheckIcon className='w-5 h-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      )}
    </div>
  )
}

export default ListboxComponent

import Popup, { TENLEND_CLAIM_REWARD } from "@components/popup/index"
import React from "react"
import { connect } from "react-redux"
import { popupClose } from "@src/actions/popup"
import Button from "../button/index"
import Token from "../token/index"
import { lendingClaimAction } from "@src/actions/lending"
import { commaFy, convertNumFormat } from "@src/utils/number"

function LendingClaimRewardPopup(props) {
  const { dispatch, visible, wallet, lending } = props

  const { lendingPending, xTenfiTokenPrice, xTenfiBalance } = lending

  return wallet.wallet ? (
    <Popup
      open={visible}
      openStateFn={() => dispatch(popupClose())}
      caption={`Claim Rewards`}>
      <div className='mt-4 flex flex-col items-center justify-center'>
        <Token
          size={60}
          label={``}
          symbol={"tenlent"}
          labelStyle={`text-green-400`}
        />
        <span className='mt-4 font-semibold text-2xl'>
          {commaFy(convertNumFormat(lendingPending + xTenfiBalance, 2))}
        </span>
        <span className='mt-2 mb-6 font-bold text-xl text-gray-1280'>
          $
          {commaFy(
            convertNumFormat(
              (lendingPending + xTenfiBalance) * xTenfiTokenPrice,
              2
            )
          )}
        </span>

        <span className='mt-2 pb-2 flex justify-between w-full border-b dark:border-night-500'>
          <span className='font-normal text-center text-gray-1280'>
            Wallet Balance
          </span>{" "}
          {commaFy(convertNumFormat(xTenfiBalance, 2))}
        </span>
        <span className='mt-4 pb-2 flex justify-between w-full border-b dark:border-night-500'>
          <span className='font-normal text-center text-gray-1280'>
            Unclaimed Balance
          </span>{" "}
          {commaFy(convertNumFormat(lendingPending, 2))}
        </span>
        <span className='mt-4 pb-2 mb-8 flex justify-between w-full border-b dark:border-night-500'>
          <span className='font-normal text-center text-gray-1280'>Price</span>$
          {commaFy(convertNumFormat(xTenfiTokenPrice, 2))}
        </span>

        <Button
          className={`h-11 text-xs dark:text-white font-light w-auto`}
          textColor={`text-white`}
          /* onClick={() => dispatch(lendingClaimAction())} */
        >
          Claim {commaFy(convertNumFormat(lendingPending, 2))} XTENFI
        </Button>
      </div>
    </Popup>
  ) : (
    <div />
  )
}

function mapStateToProps({ popup, wallet, lending }) {
  return {
    wallet,
    visible: popup.popups[TENLEND_CLAIM_REWARD],
    lending,
  }
}

export default connect(mapStateToProps)(LendingClaimRewardPopup)

import Popup, { DISCLAIMER_POPUP } from "@components/popup/index"
import React from "react"
import { connect, useSelector } from "react-redux"
import { popupClose } from "@src/actions/popup"
import { useTranslation } from "react-i18next"

function YieldexDisclaimer(props) {
  const { dispatch, visible, tokenData } = props
  const { type, poolId, withdrawFn } = tokenData
  // console.log(tokenData)
  const { wallet } = useSelector((state) => state.wallet)
  const { t } = useTranslation()

  return (
    <Popup
      open={visible}
      onClose={() => {
        if (withdrawFn) {
          const { withdraw, id, amount, address } = withdrawFn
          dispatch(withdraw(id, amount, address))
        }
        dispatch(popupClose())
      }}
      className={"dark:bg-white w-full"}>
      {type === "YIELDEX" && (
        <div className={`flex flex-col items-center`}>
          <span className={`text-lg	text-justify`}>
            <strong className={`mr-2`}>Attention:</strong>
            You are about to enter the YIELDEX, an innovative, first of its kind
            vault that with one click allows you to Yield Farm with multiple
            LPs!
          </span>
          <span className={`text-lg	my-4 text-justify`}>
            Therefore, in the background, each one click YIELDEX deposit &
            withdrawal will have anywhere from 100 to 200 transactions on the
            blockchain, and everything on the blockchain uses gas. Thus, the gas
            fees are merely the standard BSC fees you would pay if all the hard
            work the YIELDEX is doing were done by yourself. YIELDEX simplifies
            this process and saves you time and earns you more with one click!
          </span>
          <span className={`w-full  my-2 text-justify	`}>
            Discover the power of the YIELDEX!
          </span>
          <div
            className={`h-8 flex mt-4 items-center w-full justify-center relative text-justify	`}>
            <input
              onChange={(e) => {
                localStorage.setItem("yieldex_disclaimer", e.target.checked)
              }}
              className={"sm:absolute"}
              type='checkbox'
              style={{
                left: "7rem",
                borderRadius: "5px",
                outline: "none",
                marginRight: "1rem",
              }}
            />
            Don't Remind Me Again
          </div>
        </div>
      )}

      {type === "WITHDRAW" && (
        <div className={`flex flex-col items-center`}>
          <span className={`text-lg	text-justify`}>
            <strong className={`mr-2`}>{t("WARNING")} </strong>
            {t("You_are_about_to_withdraw_from_a_TENFI_Vault")}
          </span>
          <span className={`text-lg	my-4 text-justify`}>
            {t(
              "If_you_have claimed TEN Lots, you will lose them if you withdraw, remove, un-stake from your TENFI Vault,_regardless_of_amount."
            )}
          </span>
          <span className={`w-full  my-2 text-justify	`}>
            {t(
              "Withdraw_from_your_TEN_Lots_FIRST_if_you_intend_to_withdraw_from_your_TENFI_Vault."
            )}
          </span>
          <div
            className={`h-8 flex mt-4 items-center w-full justify-center relative text-justify	`}>
            <input
              onChange={(e) => {
                const disclaimerObj = localStorage.getItem(
                  "tenfi_valuts_disclaimer"
                )
                if (disclaimerObj) {
                  const obj = JSON.parse(disclaimerObj)

                  let newObj

                  if (obj[wallet]) {
                    newObj = {
                      ...obj,
                      [wallet]: { ...obj[wallet], [poolId]: e.target.checked },
                    }
                  } else {
                    newObj = {
                      ...obj,
                      [wallet]: { [poolId]: e.target.checked },
                    }
                  }

                  localStorage.setItem(
                    "tenfi_valuts_disclaimer",
                    JSON.stringify(newObj)
                  )
                } else {
                  const obj = { [wallet]: { [poolId]: e.target.checked } }
                  localStorage.setItem(
                    "tenfi_valuts_disclaimer",
                    JSON.stringify(obj)
                  )
                }
              }}
              className={"sm:absolute"}
              type='checkbox'
              style={{
                left: "7rem",
                borderRadius: "5px",
                outline: "none",
                marginRight: "1rem",
              }}
            />
            {t("Don't_Remind_Me_Again")}
          </div>
        </div>
      )}
    </Popup>
  )
}

function mapStateToProps({ popup }) {
  return {
    visible: popup.popups[DISCLAIMER_POPUP],
    tokenData: popup.token,
  }
}

export default connect(mapStateToProps)(YieldexDisclaimer)

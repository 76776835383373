export const languageCodes = [
  {
    code: "en-US",
    name: "English",
  },
  {
    code: "zh-CN",
    name: "Chinese",
  },
  {
    code: "zh-HK",
    name: "Traditional Chinese",
  },
  {
    code: "id-ID",
    name: "Bahasa Indonesia",
  },
  {
    code: "ko-KR",
    name: "Korean",
  },
  {
    code: "th-TH",
    name: "Thai",
  },
  {
    code: "fr-FR",
    name: "French",
  },
  {
    code: "es-ES",
    name: "Spanish",
  },
  {
    code: "ja-JP",
    name: "Japanese",
  },
  {
    code: "pt-BR",
    name: "Brazil Portuguese",
  },
  {
    code: "vi-VN",
    name: "Vietnamese",
  },
  {
    code: "ru-RU",
    name: "Russian",
  },
]

import Button from "@components/button"
import { FiSettings, FiArrowRight } from "react-icons/all"
import Caption from "@components/caption"
import Input from "@components/input"
import ListboxComponent from "@components/listbox"
import constants from "@src/constants.json"
import Popup, { LIQUIDITY_POPUP } from "@components/popup/index"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { popupClose } from "@src/actions/popup"

import { isWalletConnectedWithDeposits } from "@src/actions/wallet"
import {
  approveLiquidityTransaction,
  CLEAR_VALUE,
  createLiquidityLP,
  getLp,
  INPUT,
  setSlippage,
} from "@src/actions/liquidity"
import Token, { Pair } from "../token/index"
import { token_Balance } from "@src/web3/transactions"
import {
  belt4BELT,
  beltBNB,
  beltBTC,
  beltETH,
  BPBNB,
  BTCB,
  BUSD,
  ETH,
  SPSBNB,
  TENFI,
  BNBBETA,
  BNBHERO,
  BNBTENFI_BI,
} from "@src/web3/abi"
import { useTranslation } from "react-i18next"
import { useCallback } from "react"
import { useMemo } from "react"

function LiquidityPopup(props) {
  const { dispatch, visible, liquidity, wallet, tokenData } = props
  const [details, setDetails] = useState(false)
  const [fromToken, setFromToken] = useState()
  const [tokenBalance, setTokenBalance] = useState(0)
  const { t } = useTranslation()

  const tokenToBeHiddenPair = useMemo(
    () => [
      { name: "TRX", hide: "BUSD" },
      { name: "ALPHA", hide: "BUSD" },
      { name: "TUSD", hide: "BNB" },
      { name: "USDC", hide: "BNB" },
      { name: "VAI", hide: "BNB" },
      { name: "LINK", hide: "BUSD" },
      { name: "UNI", hide: "BUSD" },
      { name: "XRP", hide: "BUSD" },
      { name: "beltETH", hide: "BUSD" },
      { name: "ADA", hide: "BUSD" },
      { name: "DOT", hide: "BUSD" },
      { name: "ETH", hide: "BUSD" },
      { name: "TWT", hide: "BUSD" },
      { name: "AXS", hide: "BUSD" },
      { name: "C98", hide: "BUSD" },
      { name: "POTS", hide: "BNB" },
      { name: "SKILL", hide: "BUSD" },
      { name: "UST", hide: "BNB" },
    ],
    []
  )

  useEffect(() => {
    ;(async () => {
      if (wallet.wallet && fromToken)
        setTokenBalance(await token_Balance(fromToken, wallet.wallet))
    })()
  }, [fromToken, wallet.wallet])

  const getOptions = useCallback(
    (tokenData) => {
      if (
        tokenData.name === BPBNB ||
        tokenData.name === SPSBNB ||
        tokenData.name === BNBBETA ||
        tokenData.name === BNBHERO
      ) {
        return [...constants.swappable].filter((val) => val !== BUSD)
      } else if (tokenData && tokenData.pair) {
        if (tokenData.name === BNBTENFI_BI) {
          return [...constants.swappable, TENFI].filter((val) => val !== BUSD)
        } else if (tokenData.pair.map((val) => val.symbol).includes(TENFI)) {
          /* FOR PAIR CONTAINING TENFI ADD TENFI OPTION */
          return [...constants.swappable, TENFI]
        } else if (tokenData.pair.map((val) => val.symbol).includes(beltBTC)) {
          /* FOR beltBTC ADD BTCB OPTION */
          return [...constants.swappable, BTCB]
        } else if (tokenData.pair.map((val) => val.symbol).includes(beltETH)) {
          /* FOR beltETH ETH OPTION*/
          return [...constants.swappable, ETH].filter((val) => val !== BUSD)
        } else if (
          tokenData.name === "USDTBTCB_BI" ||
          tokenData.name === "BTCBBNB_BI" ||
          tokenData.name === "BSBNB_BI"
        ) {
          return [...constants.swappable].filter((val) => val !== BUSD)
        } else if (
          tokenToBeHiddenPair.find(
            (val) => val.name === tokenData.pair[0].symbol
          )
        ) {
          /* FOR tokenToBeHiddenPair MATCHES PAIR 0 */
          return constants.swappable.filter(
            (val) =>
              val !==
              tokenToBeHiddenPair[
                tokenToBeHiddenPair.findIndex(
                  (x) => x.name === tokenData.pair[0].symbol
                )
              ].hide
          )
        } else if (
          tokenToBeHiddenPair.find(
            (val) => val.name === tokenData.pair[1].symbol
          )
        ) {
          /* FOR tokenToBeHiddenPair MATCHES PAIR 1 */
          return constants.swappable.filter(
            (val) =>
              val !==
              tokenToBeHiddenPair[
                tokenToBeHiddenPair.findIndex(
                  (x) => x.name === tokenData.pair[1].symbol
                )
              ].hide
          )
        } else {
          /* RETURN constants.swappable AS IT IS */
          return constants.swappable
        }
      } else {
        return []
      }
    },
    [tokenToBeHiddenPair]
  )

  useEffect(() => {
    if (tokenData) {
      setFromToken(getOptions(tokenData)[0])
    }

    if (visible)
      dispatch({
        type: CLEAR_VALUE,
      })
  }, [visible, dispatch, tokenData, getOptions])

  if (tokenData) {
    const { pair } = tokenData
    const beltPools = [belt4BELT, beltBNB, beltBTC, beltETH, "4Belt"]

    const isBelt = tokenData
      ? beltPools.find((val) => val === tokenData.label)
      : false

    return isWalletConnectedWithDeposits(wallet) && tokenData ? (
      <Popup
        open={visible}
        openStateFn={() => dispatch(popupClose())}
        caption={"TENZap"}
        corner={
          <Button variant={"clean"} onClick={() => setDetails(!details)}>
            <FiSettings size={24} />
          </Button>
        }>
        <div
          className={
            "flex flex-col w-full items-center space-y-3.5 dark:text-night-700"
          }>
          <div
            className={`overflow-hidden w-full flex flex-col space-y-2 transition-height duration-200 ${
              details ? "h-24" : "h-0"
            }`}>
            <Caption>{t("Slippage_tolerance")}</Caption>
            <div className={"flex flex-row items-center pl-1 "}>
              <div className={`w-full flex mr-3 `}>
                <Button
                  className={`w-full text-xs shadow-none py-3 h-12 ${
                    liquidity.slippage === 0.1 && `bg-violin-500`
                  }`}
                  style={{
                    borderTopLeftRadius: "0.5rem",
                    borderBottomLeftRadius: "0.5rem",
                    borderBottomRightRadius: "0px",
                    borderTopRightRadius: "0px",
                    backgroundColor:
                      liquidity.slippage === 0.1 ? "#938CEE" : "",
                  }}
                  onClick={() => {
                    dispatch(setSlippage(0.1))
                    dispatch(
                      getLp(
                        liquidity.input,
                        fromToken,
                        isBelt ? tokenData.name : tokenData.address,
                        0.1,
                        isBelt,
                        tokenData && tokenData.tokenName === "4Belt"
                          ? true
                          : false
                      )
                    )
                  }}
                  variant={"white"}>
                  0.1 %
                </Button>
                <Button
                  className={`w-full text-xs shadow-none py-3 h-12 ${
                    liquidity.slippage === 0.5 && `bg-violin-500`
                  }`}
                  style={{
                    borderRadius: "0px",
                    backgroundColor:
                      liquidity.slippage === 0.5 ? "#938CEE" : "",
                  }}
                  onClick={() => {
                    dispatch(setSlippage(0.5))
                    dispatch(
                      getLp(
                        liquidity.input,
                        fromToken,
                        isBelt ? tokenData.name : tokenData.address,
                        0.5,
                        isBelt,
                        tokenData && tokenData.tokenName === "4Belt"
                          ? true
                          : false
                      )
                    )
                  }}
                  variant={"white"}>
                  0.5 %
                </Button>
                <Button
                  className={`w-full text-xs shadow-none py-3 h-12 ${
                    liquidity.slippage === 1 && `bg-violin-500`
                  }`}
                  style={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0.5rem",
                    borderTopRightRadius: "0.5rem",
                    backgroundColor: liquidity.slippage === 1 ? "#938CEE" : "",
                  }}
                  onClick={() => {
                    dispatch(setSlippage(1))
                    dispatch(
                      getLp(
                        liquidity.input,
                        fromToken,
                        isBelt ? tokenData.name : tokenData.address,
                        1,
                        isBelt,
                        tokenData && tokenData.tokenName === "4Belt"
                          ? true
                          : false
                      )
                    )
                  }}
                  variant={"white"}>
                  1 %
                </Button>
              </div>

              <Input
                placeholder={"5"}
                id={"slippage"}
                className={"w-24 h-12"}
                value={liquidity.slippage}
                onChange={(val) => {
                  dispatch(setSlippage(val))
                  dispatch(
                    getLp(
                      liquidity.input,
                      fromToken,
                      isBelt ? tokenData.name : tokenData.address,
                      val,
                      isBelt,
                      tokenData && tokenData.tokenName === "4Belt"
                        ? true
                        : false
                    )
                  )
                }}
              />
            </div>
          </div>
          <div className={`flex flex-col	w-full`}>
            <ListboxComponent
              options={getOptions(tokenData)}
              className={"w-full"}
              imageDir={"/tokens/"}
              value={fromToken}
              onChange={(val) => {
                setFromToken(val)
                dispatch(
                  getLp(
                    liquidity.input,
                    val,
                    isBelt ? tokenData.name : tokenData.address,
                    liquidity.slippage,
                    isBelt,
                    tokenData && tokenData.tokenName === "4Belt" ? true : false
                  )
                )
              }}
            />
            <div className='mt-4' />
            <Input
              placeholder='0.0'
              value={liquidity.input}
              className={"w-full h-12 pl-14"}
              buttonLeft={true}
              onChange={(val) => {
                dispatch(
                  getLp(
                    val,
                    fromToken,
                    isBelt ? tokenData.name : tokenData.address,
                    liquidity.slippage,
                    isBelt,
                    tokenData && tokenData.tokenName === "4Belt" ? true : false
                  )
                )
              }}
              button={"Max"}
              onButtonClick={async () => {
                let balance
                if (fromToken === "BNB") {
                  if (tokenBalance <= 0.01) {
                    balance = 0
                  } else {
                    balance = (tokenBalance - 0.01).toFixed(9)
                  }
                } else {
                  balance = tokenBalance.toFixed(9)
                }
                dispatch({
                  type: INPUT,
                  amount: balance,
                })
                dispatch(
                  getLp(
                    balance,
                    fromToken,
                    isBelt ? tokenData.name : tokenData.address,
                    liquidity.slippage,
                    isBelt,
                    tokenData && tokenData.tokenName === "4Belt" ? true : false
                  )
                )
              }}
            />
          </div>
          <div className={"flex justify-end	 w-full"}>
            <Caption>
              {t("Balance")}:{" "}
              <span className={"dark:text-white"}>
                {tokenBalance.toFixed(9)}
              </span>
            </Caption>
          </div>
          {Object.keys(tokenData).length &&
            !(
              (tokenData.pair[0].symbol === "beltBNB" && fromToken === "BNB") ||
              (tokenData.pair[0].symbol === "beltETH" && fromToken === "ETH") ||
              (tokenData.pair[0].symbol === "beltBTC" &&
                fromToken === "BTCB") ||
              (tokenData.tokenName === "4Belt" && fromToken === "BUSD")
            ) && (
              <>
                {pair && isBelt ? (
                  <div className='flex justify-between w-full'>
                    <div className='flex flex-1 items-center'>
                      <Token symbol={fromToken} label={fromToken} size={25} />
                      <div className='w-2' />
                      <FiArrowRight size={30} />
                      <div className='w-2' />
                      {tokenData.tokenName === "4Belt" ? (
                        <Token symbol={"BUSD"} label={"BUSD"} size={25} />
                      ) : (
                        <Token
                          symbol={tokenData.pair[0].symbol.split("belt")[1]}
                          label={tokenData.pair[0].symbol.split("belt")[1]}
                          size={25}
                        />
                      )}
                    </div>
                    <h4 className='font-black text-base flex items-center justify-end'>
                      {parseFloat(liquidity.amountsOut0).toFixed(9)}
                    </h4>
                  </div>
                ) : (
                  <div className='flex flex-col w-full'>
                    <div className='flex flex-1 mb-4 justify-between items-center	'>
                      {tokenData && tokenData.pair && tokenData.pair[0] && (
                        <Token
                          symbol={tokenData.pair[0].symbol}
                          label={tokenData.pair[0].symbol}
                        />
                      )}
                      <h4 className={"font-black text-base"}>
                        {parseFloat(liquidity.amountsOut0).toFixed(9)}
                      </h4>
                    </div>
                    <div className='flex flex-1 justify-between items-center'>
                      {tokenData && tokenData.pair && tokenData.pair[1] && (
                        <Token
                          symbol={tokenData.pair[1].symbol}
                          label={tokenData.pair[1].symbol}
                        />
                      )}
                      <h4 className={"font-black text-base"}>
                        {parseFloat(liquidity.amountsOut1).toFixed(9)}
                      </h4>
                    </div>
                  </div>
                )}
              </>
            )}

          <img
            src='./arrowdown.svg'
            alt='Arraw Down'
            style={{ height: "100px", width: "100px" }}
          />
          <div className={"flex flex-row space-x-4 w-full"}>
            {pair && tokenData.tokenName === "4Belt" ? (
              <Token
                symbol={tokenData.name}
                label={tokenData.label}
                size={50}
              />
            ) : (
              <Pair
                pair={pair}
                label={tokenData && tokenData.label}
                size={48}
              />
            )}
          </div>
          <div className={"flex justify-end	 w-full"}>
            <Caption>
              {t("Balance")}:{" "}
              <span className={"dark:text-white"}>
                {tokenData &&
                  tokenData.name &&
                  wallet.balance.pools[tokenData.name] &&
                  wallet.balance.pools[tokenData.name].available.toFixed(9)}
              </span>
            </Caption>
          </div>
          <div className='flex w-full justify-between	'>
            <div className='text-base'> {t("You_receive")}</div>
            <Caption>
              ≈
              {typeof liquidity.youReceive === "number"
                ? liquidity.youReceive.toFixed(9)
                : liquidity.youReceive}
              LP
            </Caption>
          </div>
          <div className='w-full border-t-2	'></div>
          <div className='flex w-full justify-between	'>
            <div className='text-base'>{t("Slippage_tolerance")}</div>
            <Caption>{liquidity.slippage}%</Caption>
          </div>
          <div className='flex w-full justify-between	'>
            <div className='text-base'>{t("Min_received")}</div>
            <Caption>
              {typeof liquidity.minReceived === "number"
                ? liquidity.minReceived.toFixed(9)
                : liquidity.minReceived}{" "}
              LP
            </Caption>
          </div>
          <div className='flex w-full justify-between	'>
            <div className='text-base'>
              {isBelt ? "Belt Fees" : `${t("Share")}`}
            </div>
            <Caption>
              {isBelt
                ? "0.00% ~ 0.11"
                : liquidity.share < 0.01
                ? "<0.01"
                : liquidity.share}
              %
            </Caption>
          </div>
          {liquidity.transaction ? (
            <Button
              className={`w-full text-xs shadow-none py-3 h-12`}
              variant={"violin"}
              onClick={() => dispatch(popupClose())}>
              {t("Completed")}
            </Button>
          ) : (
            <Button
              variant={"violin"}
              className={`w-full text-xs shadow-none py-3 h-12`}
              disabled={
                !liquidity.input ||
                parseFloat(liquidity.input) <= 0 ||
                parseFloat(liquidity.input) >
                  parseFloat(tokenBalance.toFixed(9))
              }
              onClick={() => {
                liquidity.allowance
                  ? dispatch(
                      createLiquidityLP(
                        liquidity.input,
                        tokenData.address,
                        fromToken,
                        liquidity.minReceived,
                        isBelt,
                        tokenData && tokenData.tokenName === "4Belt"
                          ? true
                          : false
                      )
                    )
                  : dispatch(approveLiquidityTransaction(fromToken))
              }}>
              {liquidity.allowance ? `${t("Create_LP")}` : `${t("Approve")}`}
            </Button>
          )}
        </div>
      </Popup>
    ) : (
      <div />
    )
  } else return <div />
}

function mapStateToProps({ popup, liquidity, wallet }) {
  return {
    liquidity,
    wallet,
    visible: popup.popups[LIQUIDITY_POPUP],
    tokenData: popup.token,
  }
}

export default connect(mapStateToProps)(LiquidityPopup)

import React from "react"
import { ReactComponent as TelegramIcon } from "@images/icons/telegram.svg"
import { ReactComponent as MediumIcon } from "@images/icons/medium.svg"
import { ReactComponent as GithubIcon } from "@images/icons/github.svg"
import { ReactComponent as TwitterIcon } from "@images/icons/twitter.svg"
import constants from "@src/constants.json"

const icons = {
  telegram: TelegramIcon,
  medium: MediumIcon,
  github: GithubIcon,
  twitter: TwitterIcon,
}

function SocialIcons(props) {
  return (
    <div className={`flex ${props.className}`}>
      {constants.socialIcons.map((icon) => {
        const IconElem = icons[icon.name]
        return (
          <a
            href={icon.url}
            className={
              "transform hover:-translate-y-1 hover:scale-110 m-2 text-night-700 hover:text-night-900 fill-current transition duration-300 ease-in-out"
            }
            target={"_blank"}
            rel='noopener noreferrer'
            key={icon.name}>
            <IconElem className={"w-6 h-6"} />
          </a>
        )
      })}
    </div>
  )
}

export default SocialIcons

import {
  getMainPoolLength,
  getPoolsUIData,
  getTenfiPrice,
} from "@web3/provider"
import { Vaults } from "@src/web3/provider/Valuts"
import { TENFI } from "@src/web3/abi"
import constants from "@src/constants.json"

export const REQUEST_TENFI_PARAMS = "REQUEST_TENFI_PARAMS"
export const REQUEST_TENFI_PARAMS_DATA = "REQUEST_TENFI_PARAMS_DATA"
export const RECEIVE_TENFI_PARAMS = "RECEIVE_TENFI_PARAMS"
export const FETCH_TENFI_UI_DATA = "FETCH_TENFI_UI_DATA"
export const GET_TENFI_PRICE = "GET_TENFI_PRICE"

const UPDATE_INTERVAL = 1000 * 30 // each 30s

export const getTenfiPriceAction = () => {
  return async (dispatch) => {
    try {
      const price = await getTenfiPrice()
      dispatch({
        type: GET_TENFI_PRICE,
        tenfiPrice: price,
      })
    } catch (error) {
      dispatch({
        type: GET_TENFI_PRICE,
        tenfiPrice: 0,
      })
    }
  }
}

export function requestTenfiParams() {
  return (dispatch) => {
    getMainPoolLength().then((len) =>
      dispatch({
        type: REQUEST_TENFI_PARAMS,
        pools: parseInt(len),
      })
    )
  }
}

function receiveTenfiParams(json) {
  return {
    type: RECEIVE_TENFI_PARAMS,
    params: json,
    receivedAt: Date.now(),
  }
}

export const getPoolsUIDataAction = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_TENFI_UI_DATA,
      poolsUIData: getPoolsUIData(),
    })
  }
}

export function fetchTenfiParams() {
  return async (dispatch) => {
    dispatch({
      type: REQUEST_TENFI_PARAMS_DATA,
    })

    let vaults = new Vaults()

    try {
      let poolsDetails = await vaults.getPoolsList()
      let platformData = {
        tvl: 0,
        tenfi_usd: vaults.tokenPrices[TENFI],
        tenfiStaked: 0,
      }
      let tenfiTotalSupply
      poolsDetails.forEach((pool) => {
        if (constants.tenfiVaultsIds.includes(pool.id)) {
          platformData.tenfiStaked += parseFloat(pool.tenfiStaked)
        }
        if (pool.id === 14) {
          tenfiTotalSupply = pool.vaultStaked
        }
        platformData.tvl += pool["tvl"]
      })

      let poolsData = {
        platformData: {
          ...platformData,
          tenfiStaked: (platformData.tenfiStaked / tenfiTotalSupply) * 100,
        },
        poolsDetails: [...poolsDetails].reduce(
          (acc, cur) => ({ ...acc, [cur.id]: cur }),
          {}
        ),
      }

      dispatch(receiveTenfiParams(poolsData))

      poolsDetails = null
      platformData = null
      poolsData = null
    } catch (error) {
      setTimeout(() => {
        dispatch(fetchTenfiParams())
      }, [10000])
    }

    vaults = null
  }
}

function shouldFetchTenfiParams(state) {
  const params = state.tenfi.params

  const initialLoad = params.platformData.tvl === 0
  const updateNeeded = Date.now() - state.tenfi.receivedAt > UPDATE_INTERVAL

  return initialLoad || updateNeeded || !state.tenfi.isFetchingData
}

export function fetchTenfiParamsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchTenfiParams(getState())) {
      return dispatch(fetchTenfiParams())
    }
  }
}

import {
  GET_LIQUIDATION_DATA,
  LIQUIDATION_DATA_FAILED,
  LIQUIDATION_DATA_LOADING,
} from "@src/actions/liquidation"

const initialState = {
  liquidationData: null,
  liquidationLoading: false,
  liquidaitonFailed: false,
}

const liquidation = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIQUIDATION_DATA:
      return {
        ...state,
        liquidationData: action.liquidationData,
        liquidationLoading: false,
        liquidaitonFailed: false,
      }
    case LIQUIDATION_DATA_LOADING:
      return {
        ...state,
        liquidationLoading: true,
        liquidaitonFailed: false,
      }
    case LIQUIDATION_DATA_FAILED:
      return {
        ...state,
        liquidationLoading: false,
        liquidaitonFailed: action.error,
      }

    default:
      return state
  }
}

export default liquidation

export const NOTIFICATION_OPEN = 'NOTIFICATION_OPEN'
export const NOTIFICATION_CLOSE = 'NOTIFICATION_CLOSE'

export function notificationOpen(props) {
    return dispatch => {
        dispatch({
            type: NOTIFICATION_OPEN,
            props: {...props}
        })
    }
}

export function notificationClose() {
    return dispatch => {
        dispatch({
            type: NOTIFICATION_CLOSE
        })
    }
}

export function notificationToggle() {
    return (dispatch, getState) => {
        console.log("here")
        const isOpen = getState().notification.isOpen

        dispatch(isOpen ? notificationClose() : notificationOpen({}))
    }
}
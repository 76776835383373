import axios from "@src/../node_modules/axios/index"
import {
  ABI,
  beltBNB,
  beltBTC,
  beltBTCLP,
  beltETH,
  BTCB,
  CONTRACT,
  ETH,
  ICO1,
  ICO2,
  PANCAKE,
  PCSROUTER,
  PCSROUTERABI,
  SWAMPFINANCE,
  TENFARM,
  TENTOKEN,
  YIELDEXCONTRACT,
  _4BELT,
  getPoolByAddress,
  APESTATERGY,
  APESWAPROUTER,
  BISTATERGY,
  BSWROUTER,
  ZEROADDRESS,
  BUSD,
  BNB,
  TENLOTS,
  YIELDEX_POOL_STABLE_NEW,
  YIELDEX_POOL_PREMIUM_NEW,
  YIELDEX_POOL_HIGH_YIELD_NEW,
  YIELDEX_POOL_ALPACA_1,
  YIELDEX_POOL_ALPACA_2,
  BABY_YIELDEX,
  YIELDEX_POOL_BISWAP,
  USDT,
  COMPTROLLER,
  UNICONTROLLER,
  XBNB,
} from "@web3/abi"
import {
  fromLp,
  GAS,
  GAS_PRICE,
  getWeb3,
  getWeb3Instance,
  LP,
  toEther,
  toGwei,
  toLp,
  toWei,
  web3Provider,
} from "@web3/provider"
import constants from "@src/constants.json"
import { LendingUtils } from "./Utils/lendingUtils"
import { lendingTokenMarkets } from "@src/utils/tokenMarketNames"

export const ZERO_ADDRESS = "0000000000000000000000000000000000000000"
export const APPROVAL_LIMIT =
  "100000000000000000000000000000000000000000000000000000000000000000000000000000"

export const MAX_INT =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935"

export const TRANSACTION_HASH = "TRANSACTION_HASH"
export const TRANSACTION_RECEIPT = "TRANSACTION_RECEIPT"
export const TRANSACTION_CONFIRMATION = "TRANSACTION_CONFIRMATION"
export const TRANSACTION_ERROR = "TRANSACTION_ERROR"

export const CONFIRMATION_LIMIT = 24

export const getCurrentApproval = async (poolId, userAddress) => {
  try {
    const tenfarmInstance = await LP.getContract(TENFARM, TENFARM)
    const poolDetails = await tenfarmInstance.methods.poolInfo(poolId).call()
    const pool = poolId !== 0 ? PANCAKE : TENTOKEN
    const lpPool = await LP.getContractByAddress(pool, poolDetails["want"])

    return toEther(
      await lpPool.methods.allowance(userAddress, CONTRACT[TENFARM]).call()
    )
  } catch (err) {
    console.log(err)
  }
}

export const getAllowance = async (pool, token, userAddress) => {
  const lpPool = await LP.getContractByAddress(pool, CONTRACT[token])

  // hotfix for BSC?
  getWeb3().utils.isAddress(userAddress)

  return await lpPool.methods.allowance(userAddress, CONTRACT[pool]).call()
}

export const getApproval = async (pool, token, userAddress, callback) => {
  try {
    // const allvalue = await getAllowance(pool, token, userAddress)

    const lp = await LP.getContract(ICO1, token)

    // hotfix for BSC?
    getWeb3().utils.isAddress(userAddress)

    // noinspection JSCheckFunctionSignatures
    await lp.methods
      .approve(CONTRACT[pool], APPROVAL_LIMIT)
      .send({
        from: userAddress,
        gasPrice: toGwei(GAS_PRICE),
        gas: GAS,
      })
      .on("receipt", (receipt) =>
        callback(TRANSACTION_RECEIPT, {
          receipt: receipt,
        })
      )
      .on("error", (error) =>
        callback(TRANSACTION_ERROR, {
          error: error,
        })
      )
  } catch (err) {
    callback(TRANSACTION_ERROR, {
      error: err,
    })
  }
}

export const tokenSwapV1V2 = async (
  pool,
  token,
  amount,
  userAddress,
  callback
) => {
  try {
    const isAllowed = await getAllowance(pool, token, userAddress)

    let finalAmt = 0

    if (parseFloat(fromLp(isAllowed)) < parseFloat(amount)) {
      finalAmt = amount - parseFloat(fromLp(isAllowed))
    }
    const lp = await LP.getContract(ICO2, ICO2)
    const lpAmount = toLp(amount.toString())

    if (finalAmt >= 0) {
      await lp.methods
        .buy(lpAmount)
        .send({ from: userAddress })
        .on("receipt", (receipt) =>
          callback(TRANSACTION_RECEIPT, {
            receipt: receipt,
          })
        )
        .on("error", (error) =>
          callback(TRANSACTION_ERROR, {
            error: error,
          })
        )
    }
  } catch (err) {
    callback(TRANSACTION_ERROR, {
      error: err,
    })
  }
}

export const handleOnWithdraw = async (
  poolId,
  amount,
  userAddress,
  callback
) => {
  try {
    // hotfix for BSC?

    getWeb3().utils.isAddress(userAddress)

    const withdrawAmount = parseFloat(amount)
    const farmInstance = await LP.getContract(TENFARM, TENFARM)
    let getCurrentDeposit = parseFloat(
      await LP.getCurrentLpDeposit(userAddress, poolId)
    )

    if (
      parseFloat(parseFloat(withdrawAmount).toFixed(12)) <=
      parseFloat(parseFloat(getCurrentDeposit).toFixed(12))
    ) {
      farmInstance.methods
        .withdraw(poolId, toWei(amount))
        .send({ from: userAddress })
        .on("receipt", (receipt) =>
          callback(TRANSACTION_RECEIPT, {
            receipt: receipt,
          })
        )
        .on(
          "confirmation",
          (confirmationNumber, receipt) =>
            confirmationNumber === CONFIRMATION_LIMIT &&
            callback(TRANSACTION_CONFIRMATION, {
              confirmationNumber: confirmationNumber,
              receipt: receipt,
            })
        )
        .on("error", (error) => {
          console.log(error)
          callback(TRANSACTION_ERROR, {
            error: error,
          })
        })
    }
  } catch (err) {
    console.log(err)
    callback(TRANSACTION_ERROR, {
      error: err.message,
    })
  }
}

export const handleClaim = async (poolId, userAddress, callback) => {
  try {
    await handleOnWithdraw(poolId, 0, userAddress, callback)
  } catch (error) {
    throw error
  }
}

export const getContractApproval = async (
  userAddress,
  tickerAddress,
  ticker,
  contract
) => {
  try {
    // hotfix for BSC?
    getWeb3().utils.isAddress(userAddress)

    const lp = await LP.getContractByAddress(ticker, tickerAddress)
    return await lp.methods
      .approve(contract, APPROVAL_LIMIT)
      .send({ from: userAddress })
  } catch (err) {
    throw err
  }
}

export const approvePanCakeRouter = async (userAddress, lpAddress) => {
  try {
    // hotfix for BSC?
    getWeb3().utils.isAddress(userAddress)

    const lp = await LP.getContractByAddress(CONTRACT[PANCAKE], lpAddress)
    await lp.methods
      .approve(CONTRACT[TENFARM], APPROVAL_LIMIT)
      .send({ from: userAddress })
  } catch (err) {}
}

export const handleOnDeposit = async (
  poolId,
  amount,
  userAddress,
  callback
) => {
  try {
    // hotfix for BSC?
    getWeb3().utils.isAddress(userAddress)

    const tenfarmInstance = await LP.getContractByAddress(
      TENFARM,
      CONTRACT[TENFARM]
    )
    const poolDetails = await tenfarmInstance.methods.poolInfo(poolId).call()
    const lpAddress = poolDetails["want"]
    let getAllowance = await getCurrentApproval(poolId, userAddress)

    const lpProvider = poolId !== 0 ? PANCAKE : TENTOKEN

    const lp = await LP.getContractByAddress(lpProvider, lpAddress)

    if (
      parseFloat(parseFloat(amount).toFixed(12)) >
      parseFloat(parseFloat(getAllowance).toFixed(12))
    ) {
      await lp.methods
        .approve(CONTRACT[TENFARM], APPROVAL_LIMIT)
        .send({ from: userAddress })
        .on("receipt", (receipt) =>
          callback(TRANSACTION_RECEIPT, {
            receipt: receipt,
          })
        )
        .on("confirmation", (confirmationNumber, receipt) =>
          callback(TRANSACTION_CONFIRMATION, {
            confirmationNumber: confirmationNumber,
            receipt: receipt,
          })
        )
        .on("error", (error) => {
          callback(TRANSACTION_ERROR, {
            error: error,
          })
          throw error
        })
    }

    await tenfarmInstance.methods
      .deposit(poolId, toWei(amount))
      .send({ from: userAddress })
      .on("receipt", (receipt) =>
        callback(TRANSACTION_RECEIPT, {
          receipt: receipt,
        })
      )
      .on(
        "confirmation",
        (confirmationNumber, receipt) =>
          confirmationNumber === CONFIRMATION_LIMIT &&
          callback(TRANSACTION_CONFIRMATION, {
            confirmationNumber: confirmationNumber,
            receipt: receipt,
          })
      )
      .on("error", (error) => {
        callback(TRANSACTION_ERROR, {
          error: error,
        })
        throw error
      })
  } catch (err) {
    throw err
  }
}

/* export const TENFIBUSDLP = "0xac6EE351e2E9108f03c7F5c49296505B8F336Be3"
export const TENFIBNBLP = "0x09F39f9B7d6395155396Fed7347620dD54Da1dc6"
export const TENFI = "0xd15C444F1199Ae72795eba15E8C1db44E47abF62" */
// export const BNB = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"

//  Get the balance of connected account
//  Get the balance of the selected token

export const tokenBalance = async (tokenAddress, userAddress, providerName) => {
  try {
    if (userAddress === null) {
      return toEther(0)
    } else if (tokenAddress === CONTRACT[BNB]) {
      const web3 = await getWeb3Instance(providerName)
      const balance = await web3.eth.getBalance(userAddress)

      return toEther(balance)
    } else {
      const TOKEN_INSTANCE = await LP.getContractByAddress(
        PANCAKE,
        tokenAddress
      )
      // (await getWeb3Instance()).eth.Contract(PANCAKE,tokenAddress);
      const balance = await TOKEN_INSTANCE.methods.balanceOf(userAddress).call()
      if (balance === 0) {
        return 0
      }
      return toEther(balance)
    }
  } catch (err) {
    console.log(err)
  }
}

export const checkAllowance = async (
  userAddress,
  tokenAddress,
  tokenAbi,
  value,
  contract
) => {
  const token = await LP.getContractByAddress(tokenAbi, tokenAddress)

  const allowed = await token.methods.allowance(userAddress, contract).call()

  if (allowed > value * 1e18) return true
  else return false
}

export const token_Balance = async (tokenName, userAddress) => {
  let balance
  if (tokenName === BNB) {
    balance = await (await web3Provider()).eth.getBalance(userAddress)
  } else {
    const token = await LP.getContractByAddress(tokenName, CONTRACT[tokenName])
    balance = await token.methods.balanceOf(userAddress).call()
  }

  return parseFloat(balance / 1e18)
}

export const returnAmountOfToken = async (
  amountInEth,
  tokenA,
  tokenB,
  filledToken
) => {
  amountInEth = amountInEth.toString()
  if (parseFloat(amountInEth)) {
  } else return
  try {
    /*
     * filledToken = true  indicates send amount is already filled
     * filledToken = false  indicates receive amount is already filled
     */
    let tokenPath
    let priceOfToken
    tokenPath = [tokenA, tokenB]

    let ROUTER_INSTANCE

    if (tokenA === CONTRACT[BNB]) {
      ROUTER_INSTANCE = await LP.getContractByAddress(
        PCSROUTERABI,
        CONTRACT[BSWROUTER]
      )
    } else {
      ROUTER_INSTANCE = await LP.getContractByAddress(
        PCSROUTERABI,
        CONTRACT[PCSROUTER]
      )
    }

    if (!filledToken) {
      priceOfToken = await ROUTER_INSTANCE.methods
        .getAmountsIn(toWei(amountInEth), tokenPath)
        .call()
      priceOfToken = priceOfToken[0]
    } else {
      priceOfToken = await ROUTER_INSTANCE.methods
        .getAmountsOut(toWei(amountInEth), tokenPath)
        .call()
      priceOfToken = priceOfToken[tokenPath.length - 1]
    }

    return toEther(priceOfToken)
  } catch (err) {
    console.log(err)
  }
}

export const swapFee = (fromAmount) => {
  return (parseFloat(fromAmount) * 0.0025).toFixed(10)
}

export const handlePriceImpact = async (amount1, lpAddressAB) => {
  let sendAmount = amount1
  let [reserve0, reserve1] = await LP.getLpTokenReserves(PANCAKE, lpAddressAB)
  reserve0 = reserve0 / 1e18
  reserve1 = reserve1 / 1e18

  const constantProduct = reserve1 * reserve0
  let marketPrice = reserve0 / reserve1
  const tokenA_ReservesAfterSwap = parseFloat(reserve0) + parseFloat(sendAmount)
  const tokenB_ReservesAfterSwap = parseFloat(
    constantProduct / tokenA_ReservesAfterSwap
  )

  const tokenB_Received = reserve1 - tokenB_ReservesAfterSwap

  const pricePaidPerTokenB =
    parseFloat(sendAmount) / parseFloat(tokenB_Received)

  const impact = ((pricePaidPerTokenB - marketPrice) / marketPrice) * 100

  if (!!impact && impact !== "undefined" && !isNaN(impact)) {
    return parseFloat(impact).toFixed(2)
  } else {
    return 0
  }
}

export const returnMinumumAmount = (slippage, amountOut) => {
  const amountOutMin = amountOut - amountOut * (slippage / 100)
  return amountOutMin.toFixed(3)
}

export const handleSwap = async (
  token0,
  token1,
  sendAmount,
  receiveAmount,
  userAddress,
  deadline
) => {
  try {
    let path
    let tokenA = token0
    let tokenB = token1
    let amountInEth = sendAmount
    let amountOutEth = receiveAmount
    let to = userAddress
    path = [tokenA, tokenB]
    let ROUTER_INSTANCE
    if (token0 === CONTRACT[BNB]) {
      ROUTER_INSTANCE = await LP.getContractByAddress(
        PCSROUTERABI,
        CONTRACT[BSWROUTER]
      )
    } else {
      ROUTER_INSTANCE = await LP.getContractByAddress(
        PCSROUTERABI,
        CONTRACT[PCSROUTER]
      )
    }

    let amountInWei = toWei(amountInEth)
    let amountOutWei = toWei(amountOutEth)
    const DEADLINE = deadline * 60
    let result
    if (tokenA === CONTRACT[BNB])
      result = await ROUTER_INSTANCE.methods
        .swapExactETHForTokens(
          amountOutWei,
          path,
          to,
          Math.floor(Date.now() / 1000) + DEADLINE
        )
        .send({
          from: to,
          value: amountInWei,
        })
    else if (tokenB === CONTRACT[BNB])
      result = await ROUTER_INSTANCE.methods
        .swapExactETHForTokens(
          amountInWei,
          path,
          to,
          Math.floor(Date.now() / 1000) + DEADLINE
        )
        .send({
          from: to,
          value: amountOutWei,
        })
    else {
      result = await ROUTER_INSTANCE.methods
        .swapExactTokensForTokens(
          amountInWei,
          amountOutWei,
          path,
          to,
          Math.floor(Date.now() / 1000) + DEADLINE
        )
        .send({ from: to })
    }
    return result
  } catch (err) {
    throw err
  } finally {
  }
}

const calcMinLiquidity = async (
  a,
  b,
  slippage,
  totalSupply,
  amountsOut0,
  amountsOut1
) => {
  const liquidity = Math.min(a, b) / 1e18
  const minliquidity = (Math.min(a, b) * (1 - slippage / 100)) / 1e18
  const share =
    parseFloat(liquidity) / (totalSupply / 1e18 + parseFloat(liquidity))

  return {
    share,
    liquidity,
    minliquidity,
    amountsOut0,
    amountsOut1,
  }
}

const apePools = [
  "0x8b6ecea3e9bd6290c2150a89af6c69887aaf1870",
  "0xc087c78abac4a0e900a327444193dbf9ba69058e",
  "0x2e707261d086687470B515B320478Eb1C88D49bb",
  "0x7Bd46f6Da97312AC2DBD1749f82E202764C0B914",
  "0x51e6d27fa57373d8d4c256231241053a70cb1d93",
  "0xf65c1c0478efde3c19b49ecbe7acc57bb6b1d713",
]

const bswPools = [
  "0x6216e04cd40db2c6fbed64f1b5830a98d3a91740",
  "0x9e78183dd68cc81bc330caf3ef84d354a58303b5",
  "0x46492b26639df0cda9b2769429845cb991591e0a",
  "0x1483767e665b3591677fd49f724bf7430c18bf83",
  "0x5bf6941f029424674bb93a43b79fc46bf4a67c21",
  "0x8840c6252e2e86e545defb6da98b2a0e26d8c1ba",
  "0xc7e9d76ba11099af3f330ff829c5f442d571e057",
  "0xa987f0b7098585c735cd943ee07544a84e923d1d",
  "0x63b30de1a998e9e64fd58a21f68d323b9bcd8f85",
  "0x16Fe21c91c426E603977b1C6EcD59Fc510a518C2",
  "0xe7fbB8bd95322618e925affd84D7eC0E32DC0e57",
  "0x8860922Eb2795aB0D57363653Dd7EBf18D7c0A42",
  "0x153dC2eBcB551799b13D4E6Ff84fC34C7AEDf241",
  "0xf9FAdb9222848Cde36c0C06cF88776DC41937083",
  "0xDA8ceb724A06819c0A5cDb4304ea0cB27F8304cF",
  "0xacaac9311b0096e04dfe96b6d87dec867d3883dc",
]

export const getLPData = async (amount, asset, LPAddress, slippage) => {
  const web3 = web3Provider()
  let router
  if (bswPools.includes(LPAddress.toLowerCase())) {
    router = await LP.getContractByAddress(PCSROUTERABI, CONTRACT[BSWROUTER])
  } else if (apePools.includes(LPAddress.toLowerCase())) {
    router = await LP.getContractByAddress(
      PCSROUTERABI,
      CONTRACT[APESWAPROUTER]
    )
  } else {
    router = await LP.getContractByAddress(PCSROUTERABI, CONTRACT[PCSROUTER])
  }
  const half = (amount / 2).toFixed(18)
  const lp = await LP.getContractByAddress(PANCAKE, LPAddress)
  const token0 = await lp.methods.token0().call()
  const token1 = await lp.methods.token1().call()
  if (
    token0.toLowerCase() === CONTRACT[`${asset}`].toLowerCase() ||
    token1.toLowerCase() === CONTRACT[`${asset}`].toLowerCase()
  ) {
    if (token1.toLowerCase() === CONTRACT[`${asset}`].toLowerCase()) {
      const amountsOut0 = await router.methods
        .getAmountsOut(web3.utils.toWei(half), [
          CONTRACT[`${asset}`],
          `${token0}`,
        ])
        .call()
      const { _reserve0, _reserve1 } = await lp.methods.getReserves().call()
      const totalSupply = await lp.methods.totalSupply().call()
      const a = (amountsOut0[1] * totalSupply) / _reserve0
      const b = (web3.utils.toWei(half) * totalSupply) / _reserve1
      return await calcMinLiquidity(
        a,
        b,
        slippage,
        totalSupply,
        parseFloat(amountsOut0[1]),
        parseFloat(half).toFixed(3)
      )
    } else {
      const amountsOut1 = await router.methods
        .getAmountsOut(web3.utils.toWei(half), [
          CONTRACT[`${asset}`],
          `${token1}`,
        ])
        .call()
      const { _reserve0, _reserve1 } = await lp.methods.getReserves().call()
      const totalSupply = await lp.methods.totalSupply().call()
      const a = (web3.utils.toWei(half) * totalSupply) / _reserve0
      const b = (amountsOut1[1] * totalSupply) / _reserve1
      return await calcMinLiquidity(
        a,
        b,
        slippage,
        totalSupply,
        parseFloat(half).toFixed(3),
        parseFloat(amountsOut1[1])
      )
    }
  } else {
    const amountsOut0 = await router.methods
      .getAmountsOut(web3.utils.toWei(half), [
        CONTRACT[`${asset}`],
        `${token0}`,
      ])
      .call()
    const amountsOut1 = await router.methods
      .getAmountsOut(web3.utils.toWei(half), [
        CONTRACT[`${asset}`],
        `${token1}`,
      ])
      .call()
    const { _reserve0, _reserve1 } = await lp.methods.getReserves().call()
    const totalSupply = await lp.methods.totalSupply().call()
    const a = (amountsOut0[1] * totalSupply) / _reserve0
    const b = (amountsOut1[1] * totalSupply) / _reserve1
    return await calcMinLiquidity(
      a,
      b,
      slippage,
      totalSupply,
      parseFloat(amountsOut0[1]),
      parseFloat(amountsOut1[1])
    )
  }
}

const zapIn = async (
  ticker,
  address,
  amount,
  lpAddress,
  token0Path,
  token1Path,
  minliquidity,
  userAddress
) => {
  const SwampContract = await LP.getContractByAddress(
    SWAMPFINANCE,
    CONTRACT[SWAMPFINANCE]
  )
  const pool = getPoolByAddress(lpAddress)
  if (ticker === BNB) {
    try {
      return SwampContract.methods
        .zapIn(
          address,
          amount,
          lpAddress,
          token0Path,
          token1Path,
          minliquidity,
          CONTRACT[
            pool.strategy === APESTATERGY
              ? APESWAPROUTER
              : pool.strategy === BISTATERGY
              ? BSWROUTER
              : PCSROUTER
          ]
        )
        .send({
          from: userAddress,
          value: amount,
        })
    } catch (error) {
      console.log(error)
      return error
    }
  } else {
    try {
      return SwampContract.methods
        .zapIn(
          address,
          amount,
          lpAddress,
          token0Path,
          token1Path,
          minliquidity,
          CONTRACT[
            pool.strategy === APESTATERGY
              ? APESWAPROUTER
              : pool.strategy === BISTATERGY
              ? BSWROUTER
              : PCSROUTER
          ]
        )
        .send({
          from: userAddress,
        })
    } catch (error) {
      console.log(error)
      return error
    }
  }
}

export const createLP = async (
  amount,
  lpAddress,
  ticker,
  minliquidity,
  userAddress
) => {
  const web3 = web3Provider()
  const contract = await LP.getContractByAddress(PANCAKE, lpAddress)
  const token0 = await contract.methods.token0().call()
  const token1 = await contract.methods.token1().call()
  if (ticker === BNB) {
    if (token0 === CONTRACT[BNB] || token1 === CONTRACT[BNB]) {
      if (token1 === CONTRACT[BNB]) {
        return await zapIn(
          ticker,
          ZERO_ADDRESS,
          web3.utils.toWei(`${amount}`),
          lpAddress,
          [CONTRACT[BNB], `${token0}`],
          [`${token1}`],
          `${web3.utils.toWei(minliquidity.toFixed(18))}`,
          userAddress
        )
      } else {
        return await zapIn(
          ticker,
          ZERO_ADDRESS,
          web3.utils.toWei(`${amount}`),
          lpAddress,
          [`${token0}`],
          [CONTRACT[BNB], `${token1}`],
          `${web3.utils.toWei(minliquidity.toFixed(18))}`,
          userAddress
        )
      }
    } else {
      return await zapIn(
        ticker,
        ZERO_ADDRESS,
        web3.utils.toWei(`${amount}`),
        lpAddress,
        [CONTRACT[BNB], `${token0}`],
        [CONTRACT[BNB], `${token1}`],
        `${web3.utils.toWei(minliquidity.toFixed(18))}`,
        userAddress
      )
    }
  } else {
    if (token0 === CONTRACT[`${ticker}`] || token1 === CONTRACT[`${ticker}`]) {
      if (token1 === CONTRACT[`${ticker}`]) {
        if (token0 === CONTRACT[BNB]) {
          return await zapIn(
            ticker,
            CONTRACT[`${ticker}`],
            web3.utils.toWei(`${amount}`),
            lpAddress,
            [CONTRACT[`${ticker}`], CONTRACT[BNB]],
            [],
            `${web3.utils.toWei(minliquidity.toFixed(18))}`,
            userAddress
          )
        } else {
          return await zapIn(
            ticker,
            CONTRACT[`${ticker}`],
            web3.utils.toWei(`${amount}`),
            lpAddress,
            [CONTRACT[`${ticker}`], `${token0}`],
            [],
            `${web3.utils.toWei(minliquidity.toFixed(18))}`,
            userAddress
          )
        }
      } else {
        return await zapIn(
          ticker,
          CONTRACT[`${ticker}`],
          web3.utils.toWei(`${amount}`),
          lpAddress,
          [],
          [CONTRACT[`${ticker}`], `${token1}`],
          `${web3.utils.toWei(minliquidity.toFixed(18))}`,
          userAddress
        )
      }
    } else {
      if (token0 === CONTRACT[BNB]) {
        return await zapIn(
          ticker,
          CONTRACT[`${ticker}`],
          web3.utils.toWei(`${amount}`),
          lpAddress,
          [CONTRACT[`${ticker}`], CONTRACT[BNB]],
          [CONTRACT[`${ticker}`], `${token1}`],
          `${web3.utils.toWei(minliquidity.toFixed(18))}`,
          userAddress
        )
      } else if (token1 === CONTRACT[BNB]) {
        return await zapIn(
          ticker,
          CONTRACT[`${ticker}`],
          web3.utils.toWei(`${amount}`),
          lpAddress,
          [CONTRACT[`${ticker}`], `${token0}`],
          [CONTRACT[`${ticker}`], CONTRACT[BNB]],
          `${web3.utils.toWei(minliquidity.toFixed(18))}`,
          userAddress
        )
      } else {
        return await zapIn(
          ticker,
          CONTRACT[`${ticker}`],
          web3.utils.toWei(`${amount}`),
          lpAddress,
          [CONTRACT[`${ticker}`], `${token0}`],
          [CONTRACT[`${ticker}`], `${token1}`],
          `${web3.utils.toWei(minliquidity.toFixed(18))}`,
          userAddress
        )
      }
    }
  }
}

export const getAmountsOut = async (from, to, amount) => {
  const web3 = web3Provider()
  const router = await LP.getContractByAddress(
    PCSROUTERABI,
    CONTRACT[PCSROUTER]
  )
  const amountOut = await router.methods
    .getAmountsOut(web3.utils.toWei(amount), [from, to])
    .call()
  return amountOut[1]
}

export const getLPDataBelt = async (ticker, lP, amount, slippage) => {
  const web3 = web3Provider()
  if (lP === beltBNB && ticker === BNB) {
    const contract = new web3.eth.Contract(ABI[beltBTCLP], CONTRACT[lP])
    const price = toEther(await contract.methods.getPricePerFullShare().call())
    const minRecieved = ((amount / price) * (1 - slippage / 100)).toFixed(18)
    const recieved = (amount / price).toFixed(18)
    return {
      amount,
      recieved,
      minRecieved,
    }
  } else if (lP === beltETH && ticker === "ETH") {
    const contract = new web3.eth.Contract(ABI[beltBTCLP], CONTRACT[lP])
    const price = toEther(await contract.methods.getPricePerFullShare().call())
    const minRecieved = ((amount / price) * (1 - slippage / 100)).toFixed(18)
    const recieved = (amount / price).toFixed(18)
    return {
      amount,
      recieved,
      minRecieved,
    }
  } else if (lP === beltBTC && ticker === "BTCB") {
    const contract = new web3.eth.Contract(ABI[beltBTCLP], CONTRACT[lP])
    const price = toEther(await contract.methods.getPricePerFullShare().call())
    const minRecieved = ((amount / price) * (1 - slippage / 100)).toFixed(18)
    const recieved = (amount / price).toFixed(18)
    return {
      amount,
      recieved,
      minRecieved,
    }
  } else {
    let swapAmount, price
    if (lP === beltBNB) {
      swapAmount = await getAmountsOut(CONTRACT[ticker], CONTRACT[BNB], amount)
      const contract = new web3.eth.Contract(ABI[beltBTCLP], CONTRACT[lP])
      price = toEther(await contract.methods.getPricePerFullShare().call())
    } else if (lP === beltBTC) {
      swapAmount = await getAmountsOut(CONTRACT[ticker], CONTRACT[BTCB], amount)
      const contract = new web3.eth.Contract(ABI[beltBTCLP], CONTRACT[lP])
      price = toEther(await contract.methods.getPricePerFullShare().call())
    } else if (lP === beltETH) {
      swapAmount = await getAmountsOut(CONTRACT[ticker], CONTRACT[ETH], amount)
      const contract = new web3.eth.Contract(ABI[beltBTCLP], CONTRACT[lP])
      price = toEther(await contract.methods.getPricePerFullShare().call())
    }
    let x = toEther(swapAmount)
    const minRecieved = (x / price) * (1 - slippage / 100)
    const recieved = x / price
    return {
      swapAmount,
      recieved,
      minRecieved,
    }
  }
}

export const zapInBelt = async (
  fromTokenAddress,
  amount,
  lpAddress,
  token0,
  token0Path,
  minPoolTokens,
  token0RouterAddress,
  userAddress,
  ticker
) => {
  const SwampContract = await LP.getContractByAddress(
    SWAMPFINANCE,
    CONTRACT[SWAMPFINANCE]
  )
  if (ticker === BNB) {
    try {
      return SwampContract.methods
        .zapInBelt(
          fromTokenAddress,
          amount,
          lpAddress,
          token0,
          token0Path,
          minPoolTokens,
          token0RouterAddress
        )
        .send({
          from: userAddress,
          value: amount,
        })
    } catch (error) {
      return error
    }
  } else {
    try {
      return SwampContract.methods
        .zapInBelt(
          fromTokenAddress,
          amount,
          lpAddress,
          token0,
          token0Path,
          minPoolTokens,
          token0RouterAddress
        )
        .send({
          from: userAddress,
        })
    } catch (error) {
      return error
    }
  }
}

export const createBeltLP = async (
  amount,
  lpAddress,
  ticker,
  minPoolTokens,
  userAddress
) => {
  const web3 = web3Provider()
  const contract = await LP.getContractByAddress(beltBTCLP, lpAddress)
  const token0 = await contract.methods.token().call()

  if (ticker === BNB && token0 === CONTRACT[BNB]) {
    return await zapInBelt(
      ZERO_ADDRESS,
      web3.utils.toWei(`${amount}`),
      lpAddress,
      token0,
      [],
      web3.utils.toWei(`${minPoolTokens}`),
      CONTRACT[PCSROUTER],
      userAddress,
      ticker
    )
  } else {
    if (ticker === BNB) {
      return await zapInBelt(
        ZERO_ADDRESS,
        web3.utils.toWei(`${amount}`),
        lpAddress,
        token0,
        [CONTRACT[`${ticker}`], token0],
        web3.utils.toWei(`${minPoolTokens}`),
        CONTRACT[PCSROUTER],
        userAddress,
        ticker
      )
    } else {
      return await zapInBelt(
        CONTRACT[`${ticker}`],
        web3.utils.toWei(`${amount}`),
        lpAddress,
        token0,
        [CONTRACT[`${ticker}`], token0],
        web3.utils.toWei(`${minPoolTokens}`),
        CONTRACT[PCSROUTER],
        userAddress,
        ticker
      )
    }
  }
}

export const get4BeltLP = async (amount, ticker, slippage) => {
  const web3 = web3Provider()
  const router = LP.getContractByAddress(PCSROUTERABI, CONTRACT[PCSROUTER])
  const beltInstance = LP.getContractByAddress(_4BELT, CONTRACT[_4BELT])
  let amountsOut0 = 0
  let returnLiquidity
  if (ticker === BUSD) {
    amountsOut0 = web3.utils.toWei(amount)
    returnLiquidity = toEther(
      await beltInstance.methods
        .calc_token_amount([amountsOut0, 0, 0, 0], true)
        .call()
    )
  } else {
    amountsOut0 = await router.methods
      .getAmountsOut(web3.utils.toWei(amount), [
        CONTRACT[ticker],
        CONTRACT["BUSD"],
      ])
      .call()
    returnLiquidity = toEther(
      await beltInstance.methods
        .calc_token_amount([amountsOut0[1], 0, 0, 0], true)
        .call()
    )
  }

  const amountIn =
    returnLiquidity - 0.01224038251890815166348665098449 * returnLiquidity
  return {
    liquidity: amountIn,
    minLiquidity: (1 - slippage / 100) * amountIn,
    amountOut0: amountsOut0,
  }
}

export const create4BeltLP = async (
  amount,
  lpAddress,
  ticker,
  minliquidity,
  userAddress
) => {
  const web3 = web3Provider()

  const SwampContract = await LP.getContractByAddress(
    SWAMPFINANCE,
    CONTRACT[SWAMPFINANCE]
  )

  if (ticker === BNB) {
    const token0Path = [CONTRACT[BNB], CONTRACT[BUSD]]
    try {
      return SwampContract.methods
        .zapIn4Belt(
          ZERO_ADDRESS,
          web3.utils.toWei(amount.toString()),
          lpAddress,
          token0Path,
          web3.utils.toWei(minliquidity.toString()),
          CONTRACT[PCSROUTER]
        )
        .send({ from: userAddress, value: web3.utils.toWei(amount.toString()) })
    } catch (error) {
      throw error
    }
  } else if (ticker === BUSD) {
    const token0Path = []

    try {
      return SwampContract.methods
        .zapIn4Belt(
          CONTRACT[ticker],
          web3.utils.toWei(amount.toString()),
          lpAddress,
          token0Path,
          web3.utils.toWei(minliquidity.toString()),
          CONTRACT[PCSROUTER]
        )
        .send({ from: userAddress })
    } catch (error) {
      throw error
    }
  } else {
    const token0Path = [CONTRACT[ticker], CONTRACT[BUSD]]
    try {
      return SwampContract.methods
        .zapIn4Belt(
          CONTRACT[ticker],
          web3.utils.toWei(amount.toString()),
          lpAddress,
          token0Path,
          web3.utils.toWei(minliquidity.toString()),
          CONTRACT[PCSROUTER]
        )
        .send({ from: userAddress })
    } catch (error) {
      throw error
    }
  }
}

export const depositYieldex = async (
  poolId = 0,
  amount,
  userAddress,
  ticker,
  poolType,
  slippage = 995
) => {
  let contract
  if (
    poolType === YIELDEX_POOL_STABLE_NEW ||
    poolType === YIELDEX_POOL_PREMIUM_NEW ||
    poolType === YIELDEX_POOL_HIGH_YIELD_NEW ||
    poolType === YIELDEX_POOL_ALPACA_1 ||
    poolType === YIELDEX_POOL_ALPACA_2 ||
    poolType === BABY_YIELDEX ||
    poolType === YIELDEX_POOL_BISWAP
  ) {
    contract = LP.getContractByAddress(poolType, CONTRACT[poolType])
  } else {
    contract = LP.getContractByAddress(YIELDEXCONTRACT, CONTRACT[poolType])
  }

  // const contract = LP.getContractByAddress(YIELDEXCONTRACT, CONTRACT[poolType])

  if (
    poolType === YIELDEX_POOL_ALPACA_1 ||
    poolType === YIELDEX_POOL_ALPACA_2
  ) {
    if (ticker === BNB)
      try {
        await contract.methods
          .deposit(poolId, CONTRACT[ZEROADDRESS], slippage)
          .send({ from: userAddress, value: amount })
      } catch (error) {
        throw error
      }
    else if (ticker === BUSD) {
      try {
        await contract.methods
          .deposit(poolId, CONTRACT[ticker], slippage)
          .send({ from: userAddress })
      } catch (error) {
        throw error
      }
    }
  } else {
    if (ticker === BNB)
      try {
        await contract.methods
          .deposit(poolId, CONTRACT[ZEROADDRESS], amount, slippage)
          .send({ from: userAddress, value: amount })
      } catch (error) {
        throw error
      }
    else if (ticker === BUSD || ticker === USDT) {
      try {
        await contract.methods
          .deposit(poolId, CONTRACT[ticker], amount, slippage)
          .send({ from: userAddress })
      } catch (error) {
        throw error
      }
    }
  }
}

export const withdrawYieldex = async (
  poolId = 0,
  userAddress,
  percentage,
  ticker,
  poolType,
  slippage = 995
) => {
  let yieldexContractInstance
  if (
    poolType === YIELDEX_POOL_STABLE_NEW ||
    poolType === YIELDEX_POOL_PREMIUM_NEW ||
    poolType === YIELDEX_POOL_HIGH_YIELD_NEW ||
    poolType === YIELDEX_POOL_ALPACA_1 ||
    poolType === YIELDEX_POOL_ALPACA_2 ||
    poolType === BABY_YIELDEX ||
    poolType === YIELDEX_POOL_BISWAP
  ) {
    yieldexContractInstance = LP.getContract(poolType, poolType)
  } else {
    yieldexContractInstance = LP.getContract(YIELDEXCONTRACT, poolType)
  }

  // const yieldexContractInstance = LP.getContract(YIELDEXCONTRACT, poolType)
  if (ticker === BNB || ticker === BUSD || ticker === USDT)
    try {
      await yieldexContractInstance.methods
        .withdraw(poolId, percentage, CONTRACT[ticker], slippage)
        .send({ from: userAddress })
    } catch (error) {
      throw error
    }
  else {
    /* IF TICKER IS LP */
    try {
      await yieldexContractInstance.methods
        .withdraw(poolId, percentage, CONTRACT[ZEROADDRESS], 0)
        .send({ from: userAddress })
    } catch (error) {
      throw error
    }
  }
}

export const claimYieldex = async (poolId, userAddress, poolType) => {
  let yieldexContract

  if (
    poolType === YIELDEX_POOL_STABLE_NEW ||
    poolType === YIELDEX_POOL_PREMIUM_NEW ||
    poolType === YIELDEX_POOL_HIGH_YIELD_NEW ||
    poolType === YIELDEX_POOL_ALPACA_1 ||
    poolType === YIELDEX_POOL_ALPACA_2 ||
    poolType === BABY_YIELDEX ||
    poolType === YIELDEX_POOL_BISWAP
  ) {
    yieldexContract = LP.getContract(poolType, poolType)
  } else {
    yieldexContract = LP.getContract(YIELDEXCONTRACT, poolType)
  }
  try {
    await yieldexContract.methods
      .withdraw(poolId, 0, CONTRACT[ZEROADDRESS], 0)
      .send({ from: userAddress })
  } catch (error) {
    throw error
  }
}

export const tenLotsStake = async (userAddress) => {
  const contract = LP.getContract(TENLOTS, TENLOTS)
  try {
    await contract.methods.enterStaking().send({ from: userAddress })
    try {
      const userEntered = await contract.methods.userEntered(userAddress).call()
      const { level } = await contract.methods
        .enterStakingStats(userAddress)
        .call()

      if (userEntered) {
        if (level === "0") {
          return "You Have Entered TEN LOTS at Bronze Level"
        } else if (level === "1") {
          return "You Have Entered TEN LOTS at Silver Level"
        } else {
          return "You Have Entered TEN LOTS at Gold Level"
        }
      } else {
        return "You Have Not Entered TenLots"
      }
    } catch (error) {
      throw new Error("Enter Tenlots")
    }
  } catch (error) {
    throw error
  }
}

export const tenLotsUnstake = async (userAddress, reward) => {
  const contract = LP.getContract(TENLOTS, TENLOTS)

  try {
    const res = await axios.get(
      `${constants.tenLotsUnstakeLink}TenLots/ten-lots/${userAddress}`
    )

    const stakingStats = await contract.methods
      .enterStakingStats(userAddress)
      .call()
    const dataFound = [...res.data].find(
      (val) => val.timeStamp > stakingStats.timestamp
    )
    if (dataFound) {
      try {
        const res = await axios.post(
          `${constants.tenLotsUnstakeLink}TenLots/ten-lots/checkuser`,
          {
            address: userAddress,
            penalty: true,
            amount: reward,
          }
        )
        if (res.data === "error") {
          throw new Error("Enter Staking")
        }
      } catch (error) {
        throw error
      }
    } else {
      try {
        try {
          const res = await axios.post(
            `${constants.tenLotsUnstakeLink}TenLots/ten-lots/checkuser`,
            {
              address: userAddress,
              penalty: false,
              amount: reward,
            }
          )
          if (res.data === "error") {
            throw new Error("Enter Staking")
          }
        } catch (error) {
          throw error
        }
        const { pendingFee } = await contract.methods
          .enterStakingStats(userAddress)
          .call()
        await contract.methods
          .claim()
          .send({ from: userAddress, value: pendingFee })
      } catch (error) {
        throw error
      }
    }
  } catch (error) {
    throw error
  }
}

export const tenLotsUserNotFound = async (userAddress, reward) => {
  const contract = LP.getContract(TENLOTS, TENLOTS)
  try {
    try {
      const { pendingFee } = await contract.methods
        .enterStakingStats(userAddress)
        .call()
      const res = await axios.post(
        `${constants.tenLotsUnstakeLink}TenLots/ten-lots/checkuser`,
        {
          address: userAddress,
          penalty: false,
          amount: reward,
        }
      )

      if (res.data === "error") {
        throw new Error("Enter Staking")
      } else {
        await contract.methods.claim().send({
          from: userAddress,
          value: parseInt(pendingFee) + 716338000000000,
        })
      }
    } catch (error) {
      throw error
    }
  } catch (error) {
    throw error
  }
}

export const tenLotsUserFound = async (userAddress, reward) => {
  const contract = LP.getContract(TENLOTS, TENLOTS)

  try {
    const stakingStats = await contract.methods
      .enterStakingStats(userAddress)
      .call()
    const res = await axios.post(
      `${constants.tenLotsUnstakeLink}TenLots/ten-lots/checkuser`,
      {
        address: userAddress,
        penalty: true,
        amount: reward,
      }
    )
    if (res.data === "error") {
      throw new Error("Enter Staking")
    } else {
      const user = await axios.get(
        `${constants.tenLotsUnstakeLink}TenLots/ten-lots/${userAddress}`
      )

      const dataFound = [...user.data].find(
        (val) => val.timeStamp > stakingStats.timestamp
      )
      return dataFound
    }
  } catch (error) {
    throw error
  }
}

export const tenLotsUserEntered = async (userAddress) => {
  const contract = LP.getContract(TENLOTS, TENLOTS)
  try {
    const result = await contract.methods.userEntered(userAddress).call()
    return result
  } catch (error) {
    return false
  }
}

export const tenLotsCheckTransaction = async (userAddress, reward) => {
  const contract = LP.getContract(TENLOTS, TENLOTS)

  try {
    const { timestamp } = await contract.methods
      .enterStakingStats(userAddress)
      .call()

    if (
      parseInt(Date.now() / 1000) -
        (await contract.methods.claimCoolDown(userAddress).call()) >
      43200
    ) {
      if (timestamp !== "0") {
        const res = await axios.get(
          `${constants.tenLotsUnstakeLink}TenLots/ten-lots/${userAddress}`
        )

        const dataFound = [...res.data].find(
          (val) => val.timeStamp > parseInt(timestamp)
        )

        return dataFound
      } else {
        throw new Error("Enter Staking")
      }
    } else {
      throw new Error("Next Claim After 12 Hours")
    }
  } catch (error) {
    throw error
  }
}

/* TENLEND TRANSACTIONS */

export const lendingSupplyEnable = async (token, xToken, userAddress) => {
  try {
    const contract = LP.getContract(token, token)

    await contract.methods
      .approve(CONTRACT[xToken], MAX_INT)
      .send({ from: userAddress })
  } catch (error) {
    throw error
  }
}

export const lendingEnterMarket = async (xToken, underlying, userAddress) => {
  try {
    const contract = LP.getContract(COMPTROLLER, UNICONTROLLER)
    const cToken = [CONTRACT[xToken], underlying]
    await contract.methods.enterMarkets(cToken).send({ from: userAddress })
  } catch (error) {
    console.log(error.message)
    throw error
  }
}

export const lendingExitMarket = async (xToken, userAddress) => {
  try {
    const contract = LP.getContract(COMPTROLLER, UNICONTROLLER)
    await contract.methods
      .exitMarket(CONTRACT[xToken])
      .send({ from: userAddress })
  } catch (error) {
    throw error
  }
}

export const lendingSupply = async (xToken, amount, userAddress) => {
  try {
    const contract = LP.getContract(xToken, xToken)
    await contract.methods.mint(toWei(amount)).send({
      from: userAddress,
    })
  } catch (error) {
    throw error
  }
}

export const lendingWithdraw = async (xToken, amount, userAddress) => {
  try {
    const contract = LP.getContract(xToken, xToken)
    await contract.methods.redeemUnderlying(toWei(amount)).send({
      from: userAddress,
    })
  } catch (error) {
    throw error
  }
}

export const lendingBorrow = async (xToken, amount, userAddress) => {
  try {
    const contract = LP.getContract(xToken, xToken)
    await contract.methods.borrow(toWei(amount)).send({
      from: userAddress,
    })
  } catch (error) {
    throw error
  }
}

export const lendingRepay = async (xToken, amount, userAddress) => {
  try {
    const contract = LP.getContract(xToken, xToken)
    await contract.methods.repayBorrow(amount).send({
      from: userAddress,
    })
  } catch (error) {
    throw error
  }
}

export const lendingClaim = async (userAddress, suppliedAssets) => {
  try {
    const contract = LP.getContract(COMPTROLLER, UNICONTROLLER)
    const lendUtilsObj = new LendingUtils()
    const assetsIn = await lendUtilsObj.getAssetsIn(userAddress)

    const holders = [userAddress]
    let xTokens = []
    let suppliers = false
    let borrowers = false

    if (suppliedAssets.length > 0) {
      suppliers = true
      suppliedAssets.forEach((element) => {
        xTokens.push(element.toLowerCase())
      })
    }

    if (assetsIn.length > 0) {
      borrowers = true
      assetsIn.forEach((element) => {
        xTokens.push(element.toLowerCase())
      })
    }

    xTokens = [...new Set(xTokens)]
    // console.log(holders, xTokens, borrowers, suppliers)
    await contract.methods
      .claimComp(holders, xTokens, borrowers, suppliers)
      .send({
        from: userAddress,
      })
  } catch (error) {
    throw error
  }
}

export const liquidationLiquidateAccount = async (
  xTokenAddress,
  userAddress,
  borrower,
  xTokenCollateral,
  repayAmountInEth,
  xToken
) => {
  try {
    if (xTokenAddress === CONTRACT[BNB]) {
      const contract = LP.getContractByAddress(XBNB, xTokenAddress)
      await contract.methods.liquidateBorrow(borrower, xTokenCollateral).send({
        from: userAddress,
        value: toWei(repayAmountInEth),
      })
    } else {
      const contract1 = LP.getContract(xToken, xToken)
      const token = lendingTokenMarkets[xToken]
      const contract2 = LP.getContract(token, token)
      console.log(userAddress, xTokenAddress)
      const allowanceVal = toEther(
        await contract2.methods.allowance(userAddress, xTokenAddress).call()
      )
      if (parseFloat(repayAmountInEth) > parseFloat(allowanceVal)) {
        await lendingSupplyEnable(token, xToken, userAddress)
      }
      console.log(borrower, toWei(repayAmountInEth), xTokenCollateral)
      await contract1.methods
        .liquidateBorrow(borrower, toWei(repayAmountInEth), xTokenCollateral)
        .send({
          from: userAddress,
        })
    }
  } catch (error) {
    throw error
  }
}

import i18next from "i18next"

function Header(props) {
  return (
    <nav
      className={`${props.className} flex items-center justify-between flex-wrap lg:px-4 -mx-2`}>
      <div
        className='flex items-center flex-shrink-0 text-white pb-3 pt-4 my-2 px-2 w-full overflow-hidden w-full justify-between
           lg:w-7/12 lg:space-x-8 lg:flex-row lg:justify-start'>
        <a
          href={
            process.env.NODE_ENV === "production"
              ? `https://ten.finance/${i18next.language}`
              : `http://localhost:3000/${i18next.language}`
          }>
          <props.logo
            className={"w-24 h-7 md:w-36 md:h-8"}
            // style={{ width: 140, height: 32 }}
          />
        </a>
        {props.left}
      </div>
      <div
        className='flex align-middle items-center justify-center w-auto w-full flex-col space-y-2 px-2
          lg:space-x-2 lg:space-y-0 lg:w-5/12 lg:justify-end lg:flex-row'>
        {props.children}
      </div>
    </nav>
  )
}

export default Header

import Button from "@components/button"
import { FiSettings } from "react-icons/all"
import Caption from "@components/caption"
import Input from "@components/input"
import ListboxComponent from "@components/listbox"
import constants from "@src/constants.json"
import Popup, { EXCHANGE_POPUP } from "@components/popup/index"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { popupClose } from "@src/actions/popup"
import { BUSD, CONTRACT, PCSROUTER, TENFI, BNB } from "@web3/abi"
import {
  isWalletConnectedWithDeposits,
  requestBalance,
} from "@src/actions/wallet"
import { useTranslation } from "react-i18next"
import { floatNumRegex } from "@src/utils/regexConstants"
import {
  checkAllowance,
  getContractApproval,
  handlePriceImpact,
  handleSwap,
  returnAmountOfToken,
  returnMinumumAmount,
  swapFee,
  token_Balance,
} from "@src/web3/transactions"
import { ICON_LOADING } from "../icons/index"
import { notificationClose, notificationOpen } from "@src/actions/notification"

function ExchangePopup(props) {
  const { dispatch, visible, wallet } = props
  const [slippage, setSlippage] = useState(5)
  const [deadline, setDeadLine] = useState(10)
  const [fromInput, setFromInput] = useState("0")
  const [toInput, setToInput] = useState("0")
  const [fromTicker, setFromTicker] = useState(constants.swappable[0])
  const [minReceived, setMinReceived] = useState(0)
  const [priceImpact, setPriceImpact] = useState(0)
  const [lpFee, setLpFee] = useState(0)
  const [details, setDetails] = useState(false)
  const [userTickerBalance, setUserTickerBalance] = useState(0)
  const [tokenPerTenfi, setTokenPerTenfi] = useState(0)
  const [tenfiBalance, setTenfiBalance] = useState(0)
  const [approved, setApproved] = useState(false)
  const [completed, setCompleted] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    ;(async () => {
      setTokenPerTenfi(
        await returnAmountOfToken(
          "1",
          CONTRACT[fromTicker],
          CONTRACT[TENFI],
          false
        )
      )
      if (wallet.wallet) {
        setUserTickerBalance(await token_Balance(fromTicker, wallet.wallet))
        setTenfiBalance(await token_Balance(TENFI, wallet.wallet))
      }
    })()

    return () => {
      setTokenPerTenfi(0)
      setUserTickerBalance(0)
      setTenfiBalance(0)
    }
  }, [fromTicker, wallet.wallet])

  const handleFromInput = async (value) => {
    if (floatNumRegex.test(value) || value === "") {
      if (value === "") {
        setFromInput(value)
        setToInput(value)
        setMinReceived(0)
        setPriceImpact(0)
        setLpFee(0)
      } else {
        setFromInput(value)
        const toInputValue = await returnAmountOfToken(
          value,
          CONTRACT[fromTicker],
          CONTRACT[TENFI],
          true
        )
        setToInput(toInputValue)
        setMinReceived(returnMinumumAmount(slippage, toInputValue))
        if (fromTicker === BNB) {
          setPriceImpact(await handlePriceImpact(value, CONTRACT["TENFIBNB"]))
        }
        if (fromTicker === BUSD) {
          setPriceImpact(
            await handlePriceImpact(toInputValue, CONTRACT["TENFIBNB"])
          )
        }
        setLpFee(swapFee(value))

        if (wallet.wallet) {
          if (fromTicker === BNB) {
            setApproved(true)
          } else {
            setApproved(
              await checkAllowance(
                wallet.wallet,
                CONTRACT[fromTicker],
                fromTicker,
                value,
                CONTRACT[PCSROUTER]
              )
            )
          }
        }
      }
    }
  }

  const handleToInput = async (value) => {
    if (floatNumRegex.test(value) || value === "") {
      if (value === "") {
        setToInput(value)
        setFromInput(value)
        setMinReceived(0)
        setPriceImpact(0)
        setLpFee(0)
      } else {
        setToInput(value)
        const fromTokenValue = await returnAmountOfToken(
          value,
          CONTRACT[fromTicker],
          CONTRACT[TENFI],
          false
        )
        setFromInput(fromTokenValue)
        setMinReceived(returnMinumumAmount(slippage, value))
        if (fromTicker === BNB) {
          setPriceImpact(
            await handlePriceImpact(fromTokenValue, CONTRACT["TENFIBNB"])
          )
        }
        if (fromTicker === BUSD) {
          setPriceImpact(await handlePriceImpact(value, CONTRACT["TENFIBNB"]))
        }
        setLpFee(swapFee(fromTokenValue))

        if (wallet.wallet) {
          if (fromTicker === BNB) {
            setApproved(true)
          } else {
            setApproved(
              await checkAllowance(
                wallet.wallet,
                CONTRACT[fromTicker],
                fromTicker,
                fromTokenValue,
                CONTRACT[PCSROUTER]
              )
            )
          }
        }
      }
    }
  }

  const buyTransaction = async () => {
    dispatch(
      notificationOpen({
        title: "Transaction in progress",
        message: "Please Wait",
        icon: ICON_LOADING,
      })
    )
    try {
      await handleSwap(
        CONTRACT[fromTicker],
        CONTRACT[TENFI],
        fromInput,
        minReceived,
        wallet.wallet,
        deadline
      )
      dispatch(requestBalance(wallet.wallet))
      dispatch(notificationClose())
      setCompleted(true)
    } catch (error) {
      dispatch(
        notificationOpen({
          title: "Error",
          message: `${error.message}`,
        })
      )
    }
  }

  const approveTransaction = async () => {
    dispatch(
      notificationOpen({
        title: "Transaction in progress",
        message: "Please Wait",
        icon: ICON_LOADING,
      })
    )
    try {
      await getContractApproval(
        wallet.wallet,
        CONTRACT[fromTicker],
        fromTicker,
        CONTRACT[PCSROUTER]
      )
      dispatch(notificationClose())
      setApproved(true)
    } catch (error) {
      dispatch(
        notificationOpen({
          title: "Error",
          message: `${error.message}`,
        })
      )
    }
  }

  return isWalletConnectedWithDeposits(wallet) ? (
    <Popup
      open={visible}
      openStateFn={() => dispatch(popupClose())}
      caption={`${t("Exchange")}`}
      corner={
        <Button variant={"clean"} onClick={() => setDetails(!details)}>
          <FiSettings size={24} />
        </Button>
      }>
      <div
        className={
          "flex flex-col w-full items-center space-y-8 dark:text-night-700"
        }>
        <div
          className={`overflow-hidden w-full flex flex-col space-y-2 transition-height duration-200 ${
            details ? "h-48" : "h-0"
          }`}>
          <Caption> {t("Slippage_tolerance")} </Caption>
          <div className={"flex justify-center items-center pl-1"}>
            <div className='w-full flex mr-3 '>
              <Button
                className={`w-full text-xs shadow-none py-3 h-12 ${
                  slippage === 0.1 && `bg-violin-500`
                }`}
                style={{
                  borderTopLeftRadius: "0.5rem",
                  borderBottomLeftRadius: "0.5rem",
                  borderBottomRightRadius: "0px",
                  borderTopRightRadius: "0px",
                  backgroundColor: slippage === 0.1 ? "#938CEE" : "",
                }}
                onClick={() => setSlippage(0.1)}
                variant={"white"}>
                0.1 %
              </Button>
              <Button
                className={`w-full text-xs shadow-none py-3 h-12 ${
                  slippage === 0.5 && `bg-violin-500`
                }`}
                style={{
                  borderRadius: "0px",
                  backgroundColor: slippage === 0.5 ? "#938CEE" : "",
                }}
                onClick={() => setSlippage(0.5)}
                variant={"white"}>
                0.5 %
              </Button>
              <Button
                className={`w-full text-xs shadow-none py-3 h-12 ${
                  slippage === 1 && `bg-violin-500 dark:bg-violin-500`
                }`}
                style={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0.5rem",
                  borderTopRightRadius: "0.5rem",
                  backgroundColor: slippage === 1 ? "#938CEE" : "",
                }}
                onClick={() => setSlippage(1)}
                variant={"white"}>
                1 %
              </Button>
            </div>

            <Input
              placeholder={"Slippage"}
              id={"slippage"}
              className={"w-24 h-12"}
              value={slippage}
              onChange={(val) =>
                (floatNumRegex.test(val) || val === "") && setSlippage(val)
              }
            />
          </div>
          <Caption htmlFor='transaction-deadline'>
            {t("Transaction_deadline")}
          </Caption>
          <Input
            placeholder={"20"}
            id={"transaction-deadline"}
            className={`h-12`}
            value={deadline}
            onChange={(val) =>
              (floatNumRegex.test(val) || val === "") && setDeadLine(val)
            }
          />
        </div>
        <hr className={`w-full ${!details && `hidden`}`} />
        <div className={"flex flex-col w-full"}>
          <div className={"flex flex-row justify-between w-full mb-3"}>
            <Caption>{t("From")}</Caption>
            <Caption>
              {t("Balance")}:{" "}
              <span className={"dark:text-white"}>
                {userTickerBalance.toFixed(5)}
              </span>
            </Caption>
          </div>
          <div className={"flex items-center justify-center space-x-4 "}>
            <Input
              className={"w-full h-12 pl-14"}
              button={"Max"}
              placeholder={"0.0"}
              buttonLeft={true}
              value={fromInput}
              onChange={handleFromInput}
              onButtonClick={async () =>
                await handleFromInput(userTickerBalance.toString())
              }
            />
            <ListboxComponent
              options={constants.swappable}
              className={"w-64 h-12"}
              imageDir={"/tokens/"}
              value={fromTicker}
              onChange={(val) => setFromTicker(val)}
            />
          </div>
        </div>

        <img
          src='./arrowdown.svg'
          alt='Arrow Down'
          style={{ height: "100px", width: "100px" }}
        />

        <div className={"flex flex-col w-full"}>
          <div className={"flex flex-row justify-between w-full mb-3"}>
            <Caption>{t("To")}</Caption>
            <Caption>
              {t("Balance")}:{" "}
              <span className={"dark:text-white"}>
                {tenfiBalance.toFixed(5)}
              </span>
            </Caption>
          </div>
          <div className={"flex flex-row space-x-4"}>
            <Input
              value={toInput !== "0" ? toInput : ""}
              placeholder={"0.0"}
              className={"w-full h-12 pl-28"}
              token={"TENFI"}
              tokenLeft={true}
              tokenName={"TENFI"}
              onChange={handleToInput}
            />
          </div>
        </div>
        <div className={"flex flex-col items-center justify-between w-full"}>
          <span
            style={{ fontWeight: "600" }}
            className={`dark:text-night-700 text-violin-200 font-semibold text-sm`}>
            {t("Price")}
          </span>
          <span
            style={{ fontWeight: "700" }}
            className={`dark:text-night-900 text-night-10 font-bold text-xl`}>
            {tokenPerTenfi && tokenPerTenfi.toFixed(9)}
          </span>
          <span
            style={{ fontWeight: "600" }}
            className={`dark:text-night-900 text-violin-200 font-semibold opacity-50 text-sm`}>{`${
            fromTicker && fromTicker.toUpperCase()
          } per TENFI`}</span>
        </div>

        {!completed ? (
          <Button
            className={`w-full text-xs shadow-none py-3 h-12`}
            variant={"violin"}
            disabled={
              fromInput && fromInput > 0
                ? parseFloat(fromInput) > userTickerBalance
                : true
            }
            onClick={() => {
              approved ? buyTransaction() : approveTransaction()
            }}>
            {approved ? "Buy" : `${t("Approve")}`}
          </Button>
        ) : (
          <Button
            variant={"violin"}
            className={`w-full text-xs shadow-none py-3 h-12`}
            onClick={() => dispatch(popupClose())}>
            {t("Completed")}
          </Button>
        )}
        <div className={"flex flex-col w-full"}>
          <div className={"flex flex-row justify-between w-full"}>
            <span className={`dark:text-night-700 text-sm`}>
              {t("Min_received")}
            </span>
            <span className={`dark:text-white text-violin-200 text-sm`}>
              {minReceived === "NaN" || isNaN(minReceived) ? 0 : minReceived}
            </span>
          </div>
          <div className={"flex flex-row justify-between w-full"}>
            <span className={`dark:text-night-700 text-sm`}>
              {t("Price_impact")}
            </span>
            <span className={`dark:text-white text-violin-200 text-sm`}>
              {priceImpact < 1 ? "<1 %" : `${priceImpact} %`}
            </span>
          </div>
          <div className={"flex flex-row justify-between w-full"}>
            <span className={`dark:text-night-700 text-sm`}>
              {t("Liquidity_provider_fee")}
            </span>
            <span className={`dark:text-white text-violin-200 text-sm`}>
              {`${lpFee} ${fromTicker}`}
            </span>
          </div>
        </div>
      </div>
    </Popup>
  ) : (
    <div />
  )
}

function mapStateToProps({ popup, wallet }) {
  return {
    wallet,
    visible: popup.popups[EXCHANGE_POPUP],
  }
}

export default connect(mapStateToProps)(ExchangePopup)

// export default connect(mapStateToProps)(
//   React.memo(ExchangePopup, (prev, next) => {
//     if (next.visible === true) return false
//     if (prev.visible === false) return true
//   })
// )

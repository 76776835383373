import React, { useEffect, useState } from "react"
import Caption from "@components/caption"
import Button from "@components/button"
import Tooltip from "@components/tooltip"
import Card from "@components/card"
import { Pair } from "@components/token"
import { connect, useSelector } from "react-redux"
import { numberWithCommas } from "@utils/number"
import { GoLink } from "react-icons/all"
import Slider from "react-input-slider"
import Input from "@components/input"
import {
  connectWalletIfNeeded,
  deposit,
  isWalletConnectedWithDeposits,
  withdraw,
} from "@src/actions/wallet"
import { popupOpen } from "@src/actions/popup"
import {
  DISCLAIMER_POPUP,
  EXCHANGE_POPUP,
  LIQUIDITY_POPUP,
} from "../popup/index"
import Token from "../token/index"
import { useTranslation } from "react-i18next"
import constants from "@src/constants.json"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import { floatNumRegex } from "@src/utils/regexConstants"
import {
  BABYUSDT,
  BANANA,
  BISWAP,
  BUSDCHR,
  CAKE,
  CHESSUSDC,
  MBOXUSDT,
  TENFI,
  USDTAXS,
  USDTFLOW,
  USDTGALA,
  USDTTENFI,
  USDTUSDC_BI,
  YGGUSDT,
} from "@src/web3/abi"
import { useMemo } from "react"

function PoolDetail(props) {
  return (
    <div className={props.className}>
      <span className={`text-violin-200 text-sm my-1.5 font-semibold`}>
        {props.caption}
      </span>
      <span className={"md:text-lg lg:text-base xl:text-xl text-xl "}>
        {props.children}
      </span>
    </div>
  )
}

export function PoolDataLoading({ className, style }) {
  return (
    <span
      style={style}
      className={`animate-pulse flex space-x-4 h-4 w-14 mt-2 ${className}`}>
      <span className={`h-full bg-blue-400 rounded w-full`}></span>
    </span>
  )
}

const classes = `mb-3 dark:border-night-800 dark:bg-night-50 dark:text-white 
dark:hover:border-night-700 hover:border-gray-300 hover:bg-gray-100
transition duration-200 ease-in-out border mb-3 flex flex-col bg-gray-light
text-gray-700 px-4 py-2 rounded-md border-gray-300
`
const height = "lg:h-auto"

export function PoolPlaceholder() {
  return (
    <div className={`animate-pulse h-24 ${classes}`}>
      <div className={`block ${height}`}></div>
    </div>
  )
}

function Deposit(props) {
  const {
    id,
    wallet,
    dispatch,
    address,
    walletStatus,
    tokenName,
    token,
    poolWalletData,
  } = props
  const [amount, setAmount] = useState(0)
  const { t } = useTranslation()
  const [percentage, setPercentage] = useState(0)
  const [showSlider, setShowSlider] = useState(false)

  const handleGetLP = (token) => {
    if (!walletStatus.isConnected) {
      dispatch(connectWalletIfNeeded())
    } else if (token && token.name) {
      if (
        token.name === USDTTENFI ||
        token.name === BABYUSDT ||
        token.name === MBOXUSDT ||
        token.name === USDTAXS ||
        token.name === USDTFLOW ||
        token.name === USDTGALA ||
        token.name === YGGUSDT
      ) {
        return window.open("https://exchange.babyswap.finance/#/swap")
      } else if (
        constants.newPools.includes(token.name) &&
        (token.name !== USDTTENFI ||
          token.name !== BABYUSDT ||
          token.name !== MBOXUSDT ||
          token.name !== USDTAXS ||
          token.name !== USDTFLOW ||
          token.name !== USDTGALA ||
          token.name !== YGGUSDT)
      ) {
        return window.open("https://app.alpacafinance.org/lend", "_blank")
      } else {
        switch (token.name) {
          case TENFI:
            return dispatch(popupOpen(EXCHANGE_POPUP))
          case CAKE:
            return window.open(
              "https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
              "_blank"
            )
          case BANANA:
            return window.open(
              "https://app.apeswap.finance/swap?inputCurrency=ETH",
              "_blank"
            )
          case USDTUSDC_BI:
            return window.open("https://biswap.org/farms", "_blank")
          case BUSDCHR:
            return window.open(
              `https://pancakeswap.finance/swap?outputCurrency=0xf9cec8d50f6c8ad3fb6dccec577e05aa32b224fe`,
              "_blank"
            )
          case BISWAP:
            return window.open("https://exchange.biswap.org/#/swap", "_blank")
          case CHESSUSDC:
            return window.open(
              "https://pancakeswap.finance/add/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d/0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6",
              "_blank"
            )
          default:
            return dispatch(popupOpen(LIQUIDITY_POPUP, token))
        }
      }
    }
  }

  useEffect(() => {
    setShowSlider(isWalletConnectedWithDeposits(wallet))
  }, [wallet])

  const { isLoadingBalance } = wallet

  const handleInput = (value) => {
    if (
      (floatNumRegex.test(value) || value === "") &&
      value <= poolWalletData.available
    ) {
      setAmount(value)
      setPercentage(((100 * value) / poolWalletData.available).toFixed(2))
    }
  }

  return (
    <>
      <div className={"w-full relative px-4 pt-4 pb-2 "}>
        <Card
          className={"h-full"}
          caption={`${t("Wallet")}`}
          variant={"clean"}
          largeContent={
            isLoadingBalance ? (
              <PoolDataLoading />
            ) : (
              numberWithCommas(poolWalletData.available)
            )
          }
          corner={
            <Button
              className={
                "w-full text-xs shadow-none py-2.5 px-3 text-violin-500 dark:text-violin-500"
              }
              variant={"white"}
              disabled={!isWalletConnectedWithDeposits(walletStatus)}
              onClick={() => handleGetLP(token)}>
              Get {tokenName}
            </Button>
          }
          smallContent={
            isLoadingBalance ? (
              <PoolDataLoading />
            ) : (
              `$${numberWithCommas(poolWalletData.available_usd)}`
            )
          }
        />
      </div>
      <div
        className={
          "-ml-1 px-4 py-2 border-t-2 dark:border-night-300 h-full flex flex-col items-center justify-center"
        }>
        <Input
          placeholder={"0.00"}
          value={amount}
          variant={"clean"}
          className={`bg-gray-light hover:bg-gray-200 focus:bg-gray-200 text-2xl ${
            !(parseFloat(poolWalletData.available) > 1e-12) && `text-center`
          }`}
          style={{ boxShadow: "none", fontWeight: "bold" }}
          button={parseFloat(poolWalletData.available) > 1e-12 && `${t("MAX")}`}
          onChange={handleInput}
          onButtonClick={(inputVal, setInputVal) => {
            setInputVal && setInputVal(poolWalletData.available)
            if (poolWalletData.available > 1e-12) {
              setAmount(
                (parseInt(poolWalletData.available * 1e12) / 1e12).toFixed(12)
              )
              setPercentage(100)
            }
          }}
        />
        {showSlider && (
          <div className={`h-11 pl-4 flex w-full justify-between items-center`}>
            <Slider
              style={{
                width: "73%",
                height: "0.2rem",
              }}
              x={percentage}
              onChange={(val) => {
                if (poolWalletData.available) {
                  setPercentage(val.x.toFixed(2))
                  setAmount(
                    (
                      ((parseInt(poolWalletData.available * 1e12) / 1e12) *
                        val.x) /
                      100
                    ).toFixed(12)
                  )
                }
              }}
            />
            <span
              style={{ width: "4.3rem" }}
              className={"ml-4 text-center"}>{`${
              percentage ? percentage : 0
            }%`}</span>
          </div>
        )}
        <Button
          className={
            "w-full my-2 text-xs shadow-none py-4 text-violin-500 dark:text-violin-500"
          }
          variant={"white"}
          disabled={
            (poolWalletData.available &&
              parseFloat(poolWalletData.available.toFixed(12)) <
                parseFloat(parseFloat(amount).toFixed(12))) ||
            amount <= 0
          }
          onClick={() => dispatch(deposit(id, amount.toString(), address))}>
          {t("DEPOSIT_TO_VAULTS")}
        </Button>
      </div>
    </>
  )
}

function Withdrawal(props) {
  const { id, wallet, dispatch, address, poolWalletData } = props
  const [amount, setAmount] = useState(0)
  const { t } = useTranslation()
  const [percentage, setPercentage] = useState(0)
  const [showSlider, setShowSlider] = useState(false)
  const [disclaimerShow, setDisclaimerShow] = useState(false)
  const [withdrawButtonClick, setWithdrawButtonClick] = useState(false)
  const { popups } = useSelector((state) => state.popup)
  const poolsIdToShowDisclaimer = useMemo(() => [14, 15, 16, 61], [])

  useEffect(() => {
    setShowSlider(isWalletConnectedWithDeposits(wallet))
  }, [wallet])

  const { isLoadingBalance } = wallet

  useEffect(() => {
    const disclaimerObj = localStorage.getItem("tenfi_valuts_disclaimer")
    const obj = JSON.parse(disclaimerObj)

    if (
      !obj ||
      !obj[address] ||
      (poolsIdToShowDisclaimer.includes(id) &&
        obj &&
        obj[address] &&
        !obj[address][id])
    ) {
      setDisclaimerShow(true)
    } else {
      setDisclaimerShow(false)
    }
  }, [withdrawButtonClick, address, id, poolsIdToShowDisclaimer, popups])

  const handleInput = (value) => {
    if (
      (floatNumRegex.test(value) || value === "") &&
      value <= poolWalletData.deposit
    ) {
      setAmount(value)
      setPercentage(((100 * value) / poolWalletData.deposit).toFixed(2))
    }
  }

  return (
    <>
      <div className={"w-full relative px-4 pt-4 pb-2"}>
        <Card
          className={"h-full"}
          caption={`${t("Vault")}`}
          variant={"clean"}
          largeContent={
            isLoadingBalance ? (
              <PoolDataLoading />
            ) : (
              numberWithCommas(poolWalletData.deposit)
            )
          }
          smallContent={
            isLoadingBalance ? (
              <PoolDataLoading />
            ) : (
              `$${numberWithCommas(poolWalletData.deposit_usd)}`
            )
          }
        />
      </div>
      <div
        className={
          "-ml-1 px-4 py-2 border-t-2 dark:border-night-300 h-full flex flex-col items-center justify-center"
        }>
        <Input
          placeholder={"0.00"}
          value={amount}
          variant={"clean"}
          className={`bg-gray-light hover:bg-gray-200 focus:bg-gray-200 text-2xl text-night-10 ${
            !(parseFloat(poolWalletData.deposit) > 1e-18) && `text-center`
          }`}
          style={{ boxShadow: "none", fontWeight: "bold" }}
          button={parseFloat(poolWalletData.deposit) > 1e-18 && `${t("MAX")}`}
          onChange={handleInput}
          onButtonClick={(inputVal, setInputVal) => {
            setInputVal && setInputVal(poolWalletData.deposit)
            if (poolWalletData.deposit > 1e-12) {
              setAmount(poolWalletData.deposit.toFixed(12))
              setPercentage(100)
            }
          }}
        />
        {showSlider && (
          <div className={`h-11 pl-4 flex w-full justify-between items-center`}>
            <Slider
              style={{
                width: "73%",
                height: "0.2rem",
              }}
              x={percentage}
              onChange={(val) => {
                if (poolWalletData.deposit) {
                  setPercentage(val.x.toFixed(2))
                  setAmount(
                    ((poolWalletData.deposit * val.x) / 100).toFixed(12)
                  )
                }
              }}
            />
            <span
              style={{ width: "4.3rem" }}
              className={"ml-4 text-center"}>{`${
              percentage ? percentage : 0
            }%`}</span>
          </div>
        )}

        <Button
          className={
            "w-full my-2 text-xs shadow-none py-4 text-violin-500 dark:text-violin-500"
          }
          variant={"white"}
          disabled={
            (poolWalletData.deposit &&
              parseFloat(poolWalletData.deposit.toFixed(12)) <
                parseFloat(parseFloat(amount).toFixed(12))) ||
            amount <= 0
          }
          onClick={() => {
            setWithdrawButtonClick(true)
            if (disclaimerShow && poolsIdToShowDisclaimer.includes(id)) {
              dispatch(
                popupOpen(DISCLAIMER_POPUP, {
                  type: "WITHDRAW",
                  poolId: id,
                  withdrawFn: {
                    withdraw,
                    id,
                    amount,
                    address,
                  },
                })
              )
            } else {
              dispatch(withdraw(id, amount, address))
            }
          }}>
          {t("WITHDRAW_TO_WALLET")}
        </Button>
      </div>
    </>
  )
}

function Pool(props) {
  const [detailsVisible, setDetailsVisible] = useState(props.expanded)
  const locaiton = useLocation()

  const { t } = useTranslation()
  const {
    id,
    dispatch,
    poolId,
    name,
    label,
    strategy,
    tokenName,
    vault_address,
    farm_address,
    pair,
    isNew,
    wallet,
    lpLink,
    tenfi,
  } = props

  const { isFetching, params } = tenfi

  const { isLoadingBalance } = wallet

  const poolsData = { ...params.poolsDetails[poolId] }

  let poolWalletData = Object.assign(
    {},
    {
      deposit_usd: 0,
      pending: 0,
      pending_usd: 0,
      available_usd: 0,
    },
    wallet.balance.pools[name]
  )

  const { pool } = queryString.parse(locaiton.search)
  useEffect(() => {
    if (pool === name) {
      const element = document.getElementById(pool)
      if (element) {
        window.scrollTo({ top: element.offsetTop - 150, behavior: "smooth" })
        setDetailsVisible(!props.expanded)
      }
    }
  }, [props.expanded, pool, name])

  const getTooltip1Text = (name) => {
    if (name === "BNBTENFI_BI") {
      return t(
        "Biswap_TENFI_LP_vaults_are_auto-compounded_once_daily_to_achieve_targeted_APY"
      )
    } else if (name === USDTTENFI) {
      return "BabySwap TENFI LP vaults are auto-compounded once daily to achieve targeted APY"
    } else if (name.includes("TENFI")) {
      return t(
        "TENFI_native_pools_are_not_auto-compounded._However,_users_can_manually_compound_once_per_day_to_achieve_projected_APY"
      )
    } else if (strategy === "APESTATERGY") {
      return t(
        "Apeswap Pools are not auto-compounded. However, users can manually compound once per day to achieve projected APY"
      )
    } else {
      return t(
        "APY_is_calculated_based_on_once_daily_auto-compoundind_done_by_TEN_Finance"
      )
    }
  }

  return (
    <div
      key={props.key}
      id={name}
      className={` ${classes}
                ${
                  detailsVisible
                    ? " bg-gray-light dark:bg-night-350 border-gray-medium dark:border-night-800 shadow-xl"
                    : "dark:bg-night-50"
                }
                `}>
      {/* Top row */}
      <div className={`flex flex-wrap items-center ${height} relative`}>
        {constants.discontinuedPoolIds.includes(id) && (
          <span
            style={{ zIndex: 1, background: "#a30d30" }}
            className={
              "inline-flex top-0 items-center justify-center px-2 py-2 text-xs font-bold leading-none text-indigo-100 bg-red-600 rounded lg:-ml-8 absolute "
            }>
            Discontinued
          </span>
        )}
        {isNew && (
          <>
            <span
              style={{ zIndex: 1 }}
              className={
                "inline-flex top-0 items-center justify-center px-2 py-2 text-xs font-bold leading-none text-indigo-100 bg-night-700 rounded md:-ml-8 absolute "
              }>
              NEW
            </span>
          </>
        )}
        <div
          className={`flex my-1 px-1 w-full lg:w-2.5/12 overflow-hidden justify-center md:justify-start`}>
          {!pair[0].symbol || pair[0].symbol === "" ? (
            <Token
              symbol={name}
              label={label}
              className={"text-sm md:text-base"}
              size={50}
            />
          ) : (
            <Pair
              pair={pair}
              label={label}
              className={"text-sm md:text-base"}
              stratergy={strategy}
              size={48}
            />
          )}
        </div>

        <div className='my-1 px-1 w-full lg:flex-1 flex justify-around flex-wrap'>
          <div className='flex flex-col lg:flex-row w-4/12 lg:w-56 justify-around'>
            <PoolDetail
              className={
                "my-1 px-1 lg:w-auto w-1/2 text-sm md:text-base w-full mb-3 md:mb-0"
              }
              caption={`${t("Balance")}`}>
              <div className='flex text-base'>
                {isLoadingBalance ? (
                  <PoolDataLoading />
                ) : (
                  `$ ${numberWithCommas(
                    poolWalletData.deposit_usd + poolWalletData.pending_usd
                  )}`
                )}
              </div>
            </PoolDetail>
            <PoolDetail
              className={
                "my-1 px-1 lg:w-auto w-1/2 text-sm md:text-base w-full"
              }
              caption={`${t("Deposited")}`}>
              <div className='flex text-base'>
                {isLoadingBalance ? (
                  <PoolDataLoading />
                ) : (
                  `$ ${numberWithCommas(poolWalletData.deposit_usd)}`
                )}
              </div>
            </PoolDetail>
          </div>
          <div className='flex flex-col lg:flex-row lg:w-60 w-4/12 justify-around'>
            <PoolDetail
              className={
                "my-1 px-1 lg:w-auto w-1/2 text-sm md:text-base w-full mb-3 md:mb-0 text-green-500"
              }
              caption={`${t("Yearly")}`}>
              <div className='flex text-base'>
                {isFetching ? (
                  <PoolDataLoading />
                ) : (
                  `${numberWithCommas(poolsData.totalAPY, 2)}%`
                )}
              </div>
            </PoolDetail>
            <PoolDetail
              className={
                "my-1 px-1 lg:w-auto w-1/2 text-sm md:text-base w-full text-green-500"
              }
              caption={`${t("Daily")}`}>
              <div className='flex text-base'>
                {isFetching ? (
                  <PoolDataLoading />
                ) : (
                  `${numberWithCommas(
                    poolsData.apy_daily + poolsData.apr_daily,
                    2
                  )}%`
                )}
              </div>
            </PoolDetail>
          </div>
          <div className='flex flex-col lg:flex-row lg:w-64 justify-around'>
            <PoolDetail
              className={
                "my-1 px-1 lg:w-auto w-1/2 text-sm md:text-base w-full mb-3 md:mb-0"
              }
              caption={`${t("TVL")}`}>
              <div className='flex text-base whitespace-nowrap'>
                {isFetching ? (
                  <PoolDataLoading />
                ) : (
                  `$ ${numberWithCommas(poolsData.tvl)}`
                )}
              </div>
            </PoolDetail>
            <PoolDetail
              className={
                "my-1 px-1 lg:w-auto w-1/2 text-sm md:text-base w-full"
              }
              caption={`${t("Pending_TENFI")}`}>
              <div className='flex text-base'>
                {isLoadingBalance ? (
                  <PoolDataLoading />
                ) : (
                  `${poolWalletData.pending.toFixed(4)}`
                )}
              </div>
            </PoolDetail>
          </div>
        </div>

        <div className='my-1 px-1 w-full lg:w-auto overflow-hidden content-center lg:text-right py-2'>
          <Button
            className={
              "w-full my-2 text-sm shadow-none py-2.5 px-6 border-violin-500 text-violin-500 dark:text-violin-500"
            }
            variant={"white"}
            onClick={() => setDetailsVisible(!detailsVisible)}>
            {t("Manage")}
          </Button>
        </div>
      </div>
      {/* Details */}
      <div
        className={`overflow-hidden transition-height ease-in-out duration-500 ${
          detailsVisible ? "lg:h-90" : "h-0"
        }`}>
        {detailsVisible && (
          <>
            <div className={"py-4 flex flex-col lg:flex-row h-full -mx-2 pt-4"}>
              <div className='my-1 px-1 w-full lg:w-1/4 px-2 h-full'>
                <div
                  className={
                    " h-full rounded-md shadow-sm bg-gray-lightest dark:bg-night-300 " +
                    "dark:border-night-500 h-auto flex flex-col border border-gray-300"
                  }>
                  <Deposit
                    id={id}
                    tenfi={tenfi}
                    wallet={wallet}
                    poolWalletData={poolWalletData}
                    walletStatus={wallet}
                    dispatch={dispatch}
                    lpLink={lpLink}
                    tokenName={tokenName}
                    address={wallet.wallet}
                    token={props}
                  />
                </div>
              </div>

              <div className='my-1 px-1 w-full lg:w-1/4 px-2  h-full'>
                <div
                  className={
                    " h-full rounded-md shadow-sm bg-gray-lightest dark:bg-night-400 h-auto flex flex-col " +
                    "dark:border-night-500 h-auto flex flex-col border border-gray-300"
                  }>
                  <Withdrawal
                    id={id}
                    wallet={wallet}
                    poolWalletData={poolWalletData}
                    dispatch={dispatch}
                    address={wallet.wallet}
                  />
                </div>
              </div>

              <div className='w-full my-1 px-1 lg:w-1/2 px-2 h-full'>
                <div
                  className={
                    "h-full rounded-md bg-gray-lightest border h-auto flex flex-col " +
                    "border-gray-200 dark:bg-night-400 dark:border-night-500"
                  }>
                  <div className={"w-full relative px-4 pt-4 pb-2"}>
                    <Card
                      className={"h-full"}
                      caption={t("TENFI_pending")}
                      corner={
                        <Button
                          className={`h-11 text-xs dark:text-white font-light w-full lg:w-auto`}
                          textColor={`text-white`}
                          onClick={() =>
                            dispatch(withdraw(id, 0, wallet.wallet))
                          }>
                          {t("Claim")}
                        </Button>
                      }
                      variant={"clean"}
                      largeContent={
                        isLoadingBalance ? (
                          <PoolDataLoading />
                        ) : (
                          numberWithCommas(poolWalletData.pending)
                        )
                      }
                      smallContent={
                        isLoadingBalance ? (
                          <PoolDataLoading />
                        ) : (
                          `$${numberWithCommas(poolWalletData.pending_usd)}`
                        )
                      }
                    />
                  </div>

                  <div
                    className={
                      "px-4 py-2 border-t dark:border-night-500 h-full flex flex-wrap justify-evenly	"
                    }>
                    <div className='w-full md:w-4/12 md:max-w-15 px-2'>
                      <div className='my-1'>
                        <Caption className={"text-violin-200 h-6"}>
                          {t("Details")}
                        </Caption>
                      </div>

                      <div
                        className={`flex justify-between border-b dark:border-night-10 mt-2 text-sm dark:text-night-700 pb-1 text-violin-200`}>
                        Asset{" "}
                        <span className={`dark:text-white text-night-10`}>
                          {tokenName}
                        </span>
                      </div>
                      <div
                        className={`flex justify-between border-b mt-2 dark:border-night-10 text-sm dark:text-night-700 pb-1 text-violin-200`}>
                        {t("Price")}
                        <span className={`dark:text-white text-night-10`}>
                          {isFetching ? (
                            <PoolDataLoading />
                          ) : (
                            `$ ${numberWithCommas(poolsData.price)}`
                          )}
                        </span>
                      </div>
                      <div
                        className={`flex justify-between border-b mt-2 dark:border-night-10 text-sm dark:text-night-700 pb-1 text-violin-200`}>
                        {t("TVL")}
                        <span className={`dark:text-white text-night-10`}>
                          {isFetching ? (
                            <PoolDataLoading />
                          ) : (
                            `$ ${numberWithCommas(poolsData.tvl)}`
                          )}
                        </span>
                      </div>
                      <div
                        className={`flex justify-between border-b mt-2 dark:border-night-10 text-sm dark:text-night-700 pb-1 text-violin-200`}>
                        {t("Vault")}

                        <a
                          href={`https://bscscan.com/address/${vault_address}`}
                          target={"_blank"}
                          className={
                            "flex flex-row items-center space-x-2 dark:text-night-900"
                          }
                          rel='noopener noreferrer'>
                          <GoLink />
                          <span
                            className={`text-violin-500 dark:text-violin-500`}>
                            {t("Vault")}
                          </span>
                        </a>
                      </div>
                      <div
                        className={`flex justify-between text-sm mt-2 dark:text-night-700 text-violin-200`}>
                        {t("Farm")}
                        <a
                          href={`https://bscscan.com/address/${farm_address}`}
                          target={"_blank"}
                          className={
                            "flex flex-row items-center space-x-2 dark:text-night-900"
                          }
                          rel='noopener noreferrer'>
                          <GoLink />
                          <span
                            className={`text-violin-500 dark:text-violin-500`}>
                            {t("Farm")}
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className='w-full md:w-2.5/5 md:max-w-16 px-2 mt-4 md:mt-0'>
                      <div className={`flex justify-between my-1`}>
                        <Caption className={"text-violin-200 h-6"}>
                          {t("APY_CALC")}
                        </Caption>
                        <Tooltip name={getTooltip1Text(name)} iconSize={18} />
                      </div>
                      <div
                        className={`flex justify-between border-b dark:border-night-10 mt-2 text-sm dark:text-night-700 pb-1 text-violin-200`}>
                        {t("Farm_APY")}
                        <div className='flex items-center text-gray-400'>
                          {isFetching ? (
                            <PoolDataLoading />
                          ) : (
                            `${numberWithCommas(poolsData.apy, 2)}%`
                          )}
                          <span className={"text-xs text-green-500 ml-1"}>
                            {isFetching ? (
                              <PoolDataLoading />
                            ) : (
                              `(${numberWithCommas(
                                poolsData.apy_daily,
                                2
                              )}% daily)`
                            )}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`flex justify-between border-b mt-2 dark:border-night-10 text-sm dark:text-night-700 pb-1 text-violin-200`}>
                        <span className={"whitespace-nowrap"}>
                          {t("Rewards_APR")}
                        </span>
                        <div className='flex items-center text-gray-400 whitespace-nowrap'>
                          {isFetching ? (
                            <PoolDataLoading />
                          ) : (
                            `${numberWithCommas(poolsData.apr, 2)}%`
                          )}
                          <span className={"text-xs text-green-500 ml-1"}>
                            {isFetching ? (
                              <PoolDataLoading />
                            ) : (
                              `(${numberWithCommas(
                                poolsData.apr_daily,
                                2
                              )}% daily)`
                            )}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`flex justify-between mt-2 text-sm  dark:text-night-700 text-violin-200`}>
                        {t("Total")}
                        <div className='flex items-center dark:text-violin-300 text-night-10'>
                          {isFetching ? (
                            <PoolDataLoading />
                          ) : (
                            `${numberWithCommas(poolsData.totalAPY, 2)}%`
                          )}

                          <span className={"text-xs text-green-500 ml-1"}>
                            {isFetching ? (
                              <PoolDataLoading />
                            ) : (
                              `${`(${(
                                poolsData.apy_daily + poolsData.apr_daily
                              ).toFixed(2)}% daily)`}`
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/4 md:max-w-14 px-2 mt-4 md:mt-0 '>
                      <div className={`flex justify-between my-1`}>
                        <Caption className={"text-violin-200 h-6"}>
                          {t("Fees")}
                        </Caption>
                        <Tooltip
                          name={t(
                            "Deposit_fee_taken_at_initial_value,_all_other_fees_taken_from_profits_generated"
                          )}
                          className={" h-6"}
                          iconSize={18}
                        />
                      </div>
                      <div
                        className={`flex justify-between border-b dark:border-night-10 mt-2 text-sm dark:text-night-700 pb-1 text-violin-200`}>
                        {t("Gas")}
                        <span className='dark:text-white text-night-10'>
                          {isFetching ? (
                            <PoolDataLoading />
                          ) : (
                            `${numberWithCommas(poolsData.gas_fee)}%`
                          )}
                        </span>
                      </div>
                      <div
                        className={`flex justify-between border-b dark:border-night-10 mt-2 text-sm dark:text-night-700 pb-1 text-violin-200`}>
                        {t("Network")}
                        <span className='dark:text-white text-night-10'>
                          {isFetching ? (
                            <PoolDataLoading />
                          ) : (
                            `${numberWithCommas(poolsData.network_fee)}%`
                          )}
                        </span>
                      </div>
                      <div
                        className={`flex justify-between border-b dark:border-night-10 mt-2 text-sm dark:text-night-700 pb-1 text-violin-200`}>
                        {t("Burn")}
                        <span className='dark:text-white text-night-10'>
                          {isFetching ? (
                            <PoolDataLoading />
                          ) : (
                            `${numberWithCommas(poolsData.burn_fee)}%`
                          )}
                        </span>
                      </div>
                      <div
                        className={`flex justify-between border-b mt-2 dark:border-night-10 text-sm dark:text-night-700 pb-1 text-violin-200`}>
                        {t("Deposit")}
                        <span className='dark:text-white text-night-10'>
                          {isFetching ? (
                            <PoolDataLoading />
                          ) : (
                            `${numberWithCommas(poolsData.deposit_fee, 1)}%`
                          )}
                        </span>
                      </div>
                      <div
                        className={`flex justify-between text-sm mt-2 dark:text-night-700 text-violin-200`}>
                        {t("Withdraw")}
                        <span className='dark:text-white text-night-10'>
                          {isFetching ? (
                            <PoolDataLoading />
                          ) : (
                            `${numberWithCommas(poolsData.withdraw_fee)}%`
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

Pool.defaultProps = {
  isNew: false,
  balance: 0,
  deposited: 0,
  tvl: 0,
  price: 0,
  tenfi_pending: 0,
  asset: "",
  vault_url: "",
  farm_url: "",
  farm_apy: 0,
  farm_apy_daily: 0,
  apr: 0,
  apr_daily: 0,
  apy: 0,
  apy_daily: 0,
  gas_fee: 0,
  network_fee: 0,
  burn_fee: 0,
  deposit_fee: 0,
  withdraw_fee: 0,
  vault: 0,
  vault_usd: 0,
  lpLink: "",
  pair: [],
}

function mapStateToProps({ wallet, popup, tenfi }) {
  return {
    popup,
    wallet,
    tenfi,
  }
}

export default connect(mapStateToProps)(Pool)

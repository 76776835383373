import { ICON_LOADING } from "@src/components/icons/index"
import { floatNumRegex } from "@src/utils/regexConstants"
import { CONTRACT, SWAMPFINANCE } from "@src/web3/abi"
import { toEther } from "@src/web3/provider"
import {
  checkAllowance,
  getLPData,
  getContractApproval,
  createLP,
  getLPDataBelt,
  createBeltLP,
  create4BeltLP,
  get4BeltLP,
  getAmountsOut,
} from "@src/web3/transactions"
import { notificationClose, notificationOpen } from "./notification"
import { requestBalance } from "./wallet"

export const SLIPPAGE = "SLIPPAGE"
export const DEADLINE = "DEADLINE"
export const CONVERT = "CONVERT"
export const INP1PERINP2 = "INP1PERINP2"
export const INP2PERINP1 = "INP2PERINP1"
export const POOLSHARE = "POOLSHARE"
export const INPUT1BALANCE = "INPUT1BALANCE"
export const INPUT2BALANCE = "INPUT2BALANCE"
export const CLEARALL = "CLEARALL"
export const GET_LP = "GET_LP"
export const INPUT = "INPUT"
export const LIQUIDITYALLOWANCE = "LIQUIDITYALLOWANCE"
export const LIQUIDITYTRANSACTION = "LIQUIDITYTRANSACTION"
export const CLEAR_VALUE = "CLEAR_VALUE"

export const setSlippage = (value) => {
  return (dispatch) => {
    dispatch({
      type: SLIPPAGE,
      slippage: value,
    })
  }
}

export const setDeadline = (value) => {
  return (dispatch) => {
    dispatch({
      type: DEADLINE,
      deadline: value,
    })
  }
}

export const getLp = (amount, asset, LPAddress, slippage, belt, belt4) => {
  return async (dispatch) => {
    if (floatNumRegex.test(amount)) {
      dispatch({
        type: INPUT,
        amount,
      })
      if (parseFloat(amount) > 0 && asset && LPAddress && slippage) {
        if (belt4) {
          const belt4 = await get4BeltLP(amount, asset, slippage)
          let amountOut = 0
          if (asset === "BNB") {
            amountOut = await getAmountsOut(
              CONTRACT[asset],
              CONTRACT["BUSD"],
              amount
            )
          }

          dispatch({
            type: GET_LP,
            share: "0.00% ~ 0.11",
            liquidity: belt4.liquidity,
            minliquidity: belt4.minLiquidity,
            amountsOut0: toEther(amountOut),
          })
        } else if (belt) {
          const beltData = await getLPDataBelt(
            asset,
            LPAddress,
            amount,
            slippage
          )

          dispatch({
            type: GET_LP,
            share: "0.00% ~ 0.11",
            liquidity: beltData.recieved,
            minliquidity: beltData.minRecieved,
            amountsOut0: beltData.amount
              ? beltData.amount && toEther(beltData.amount)
              : beltData.swapAmount && toEther(beltData.swapAmount).toFixed(18),
          })
        } else {
          const { share, liquidity, minliquidity, amountsOut0, amountsOut1 } =
            await getLPData(amount, asset, LPAddress, slippage)

          dispatch({
            type: GET_LP,
            share,
            liquidity,
            minliquidity,
            amountsOut0:
              typeof amountsOut0 === "number"
                ? toEther(amountsOut0.toString())
                : amountsOut0,
            amountsOut1:
              typeof amountsOut1 === "number"
                ? toEther(amountsOut1.toString())
                : amountsOut1,
          })
        }
        if (asset === "BNB") {
          dispatch({
            type: LIQUIDITYALLOWANCE,
            allowanceStatus: true,
          })
        } else {
          dispatch(getAllowanceStatusLiquidity(asset, amount))
        }
      }
    }
    if (amount === "")
      dispatch({
        type: INPUT,
        amount,
      })
  }
}

export const getAllowanceStatusLiquidity = (tokenFrom, amount) => {
  return async (dispatch, getState) => {
    const { wallet } = getState()
    dispatch({
      type: LIQUIDITYALLOWANCE,
      allowanceStatus:
        (await checkAllowance(
          wallet.wallet,
          CONTRACT[tokenFrom],
          tokenFrom,
          amount,
          CONTRACT[SWAMPFINANCE]
        )) && amount,
    })
  }
}

export const approveLiquidityTransaction = (tokenFrom) => {
  return async (dispatch, getState) => {
    const { wallet } = getState()
    dispatch(
      notificationOpen({
        title: "Transaction in progress",
        message: "Please Wait",
        icon: ICON_LOADING,
      })
    )
    try {
      const transfer = await getContractApproval(
        wallet.wallet,
        CONTRACT[tokenFrom],
        tokenFrom,
        CONTRACT[SWAMPFINANCE]
      )
      if (transfer) {
        dispatch({
          type: LIQUIDITYALLOWANCE,
          allowanceStatus: true,
        })
        dispatch(notificationClose())
      } else {
        dispatch(notificationClose())
        dispatch(
          notificationOpen({
            title: "Error ",
            message: "User denied approval",
          })
        )
        dispatch({
          type: LIQUIDITYALLOWANCE,
          allowanceStatus: false,
        })
      }
    } catch (error) {
      dispatch(notificationClose())
      dispatch(
        notificationOpen({
          title: "Error ",
          message: error.message,
        })
      )
      dispatch({
        type: LIQUIDITYALLOWANCE,
        allowanceStatus: false,
      })
    }
  }
}

export const createLiquidityLP = (
  amount,
  lpAddress,
  ticker,
  minliquidity,
  belt,
  belt4
) => {
  return async (dispatch, getState) => {
    const { wallet, liquidity } = getState()
    if (amount && lpAddress && ticker && liquidity.minReceived) {
      dispatch(
        notificationOpen({
          title: "Transaction in progress",
          message: "Please Wait",
          icon: ICON_LOADING,
        })
      )
      if (belt4) {
        try {
          await create4BeltLP(
            parseFloat(liquidity.input).toFixed(18),
            lpAddress,
            ticker,
            parseFloat(liquidity.minReceived).toFixed(18),
            wallet.wallet
          )
          dispatch(notificationClose())
        } catch (error) {
          dispatch(notificationClose())
          dispatch(
            notificationOpen({
              title: "Error",
              message: error.message,
            })
          )
        }
      } else if (belt) {
        try {
          const result = await createBeltLP(
            parseFloat(liquidity.input).toFixed(18),
            lpAddress,
            ticker,
            parseFloat(liquidity.minReceived).toFixed(18),
            wallet.wallet
          )
          if (result) {
            dispatch(requestBalance(wallet.wallet))
            dispatch(notificationClose())
            dispatch({
              type: LIQUIDITYTRANSACTION,
              transaction: true,
            })
          }
        } catch (error) {
          console.log(error.message)
          dispatch(notificationClose())
          dispatch(
            notificationOpen({
              title: "Error",
              message: error.message,
            })
          )
        }
      } else {
        try {
          const result = await createLP(
            liquidity.input,
            lpAddress,
            ticker,
            liquidity.minReceived,
            wallet.wallet
          )

          if (result) {
            dispatch(requestBalance(wallet.wallet))
            dispatch(notificationClose())
            dispatch({
              type: LIQUIDITYTRANSACTION,
              transaction: true,
            })
          }
        } catch (error) {
          console.log(error)
          dispatch(notificationClose())
          dispatch(
            notificationOpen({
              title: "Error",
              message: error.message,
            })
          )
        }
      }
    }
  }
}

export const convert = () => {}

export const getInp1PerInp2 = () => {}
export const getInp2PerInp1 = () => {}

export const getInput1Balance = () => {}
export const getInput12alance = () => {}

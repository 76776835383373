import {
  COMPTROLLER,
  CONTRACT,
  PRICEORACE,
  UNICONTROLLER,
  XBNB,
  XTENFI,
} from "../abi"
import { BLOCKS_PER_DAY } from "../provider"
import { Utils } from "./index"

export class LendingUtils extends Utils {
  getxTokenBalance = async (userAddress, tokenAddress) => {
    try {
      const contract = this.getContractByAddress(XBNB, tokenAddress)
      const balance = await contract.methods.balanceOf(userAddress).call()
      return balance / 1e8
    } catch (error) {
      throw error
    }
  }

  getUnderlyingPrice = async (tokenAddress) => {
    try {
      const contract = this.getContract(PRICEORACE, PRICEORACE)
      const price = await contract.methods
        .getUnderlyingPrice(tokenAddress)
        .call()
      return price / 1e18
    } catch (e) {
      return 0
    }
  }

  getSupplyBalance = async (userAddress, xToken) => {
    try {
      const snapshot = await this.getAccountSnapshot(userAddress, xToken)

      return (snapshot[1] / 1e18) * (snapshot[3] / 1e18)
    } catch (error) {
      throw error
    }
  }

  getAccountSnapshot = async (userAddress, xToken) => {
    try {
      const contract = this.getContractByAddress(XBNB, xToken)
      const snapshot = await contract.methods
        .getAccountSnapshot(userAddress)
        .call()

      return snapshot
    } catch (error) {
      throw error
    }
  }

  getMarket = async (address) => {
    try {
      const contract = this.getContract(COMPTROLLER, UNICONTROLLER)
      const market = await contract.methods.markets(address).call()

      return market
    } catch (error) {
      throw error
    }
  }

  getCash = async (xTokenAddress) => {
    try {
      const contract = this.getContractByAddress(XBNB, xTokenAddress)
      const cash = await contract.methods.getCash().call()

      return cash
    } catch (error) {
      throw error
    }
  }

  getUnderlying = async (xToken) => {
    try {
      const contract = this.getContract(xToken, xToken)
      let underlying
      if (contract.methods.underlying) {
        underlying = await contract.methods.underlying().call()
      }
      return underlying
    } catch (error) {
      throw error
    }
  }

  getBorrowBalance = async (userAddress, xTokenAddress) => {
    try {
      const contract = this.getContractByAddress(XBNB, xTokenAddress)
      const balance = await contract.methods
        .borrowBalanceStored(userAddress)
        .call()

      return balance / 1e18
    } catch (error) {
      throw error
    }
  }

  getAssetPrice = async (tokenAddress) => {
    try {
      const contract = this.getContract(PRICEORACE, PRICEORACE)
      const price = await contract.methods.assetPrices(tokenAddress).call()

      return price / 1e18
    } catch (error) {
      throw error
    }
  }

  getXTenfiSpeed = async (tokenAddress) => {
    try {
      const contract = this.getContract(COMPTROLLER, UNICONTROLLER)

      const tenfiSpeed = await contract.methods.compSpeeds(tokenAddress).call()

      return tenfiSpeed
    } catch (error) {
      throw error
    }
  }

  getExchangeRate = async (tokenAddress) => {
    try {
      const contract = this.getContractByAddress(XBNB, tokenAddress)

      const exchangeRate = await contract.methods.exchangeRateStored().call()

      return exchangeRate
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  getXTenfiSupplyApy = async (tokenName, tokenAddress) => {
    try {
      const xTenfiPrice = await this.getAssetPrice(CONTRACT[XTENFI])
      const xTenfiSpeed = await this.getXTenfiSpeed(CONTRACT[tokenName])
      const xTenfiPerDay = (xTenfiSpeed / 1e18) * BLOCKS_PER_DAY
      const exchangeRate = await this.getExchangeRate(tokenAddress)
      let totalSupply = await this.getTotalSupply(XBNB, CONTRACT[tokenName])
      totalSupply = totalSupply * exchangeRate
      const assetPrice = await this.getUnderlyingPrice(tokenAddress)
      if (totalSupply === 0) {
        return 0
      } else {
        return (
          100 *
          (Math.pow(
            1 + (xTenfiPrice * xTenfiPerDay) / (totalSupply * assetPrice),
            365
          ) -
            1)
        )
      }
    } catch (error) {
      throw error
    }
  }

  getXTenfiBorrowApy = async (tokenName, tokenAddress) => {
    try {
      const xTenfiPrice = await this.getAssetPrice(CONTRACT[XTENFI])
      const xTenfiSpeed = await this.getXTenfiSpeed(CONTRACT[tokenName])
      const xTenfiPerDay = (xTenfiSpeed / 1e18) * BLOCKS_PER_DAY
      const totalBorrow = await this.getTotalBorrows(XBNB, CONTRACT[tokenName])

      const assetPrice = await this.getUnderlyingPrice(tokenAddress)
      if (totalBorrow === 0) {
        return 0
      } else {
        return (
          100 *
          (Math.pow(
            1 + (xTenfiPrice * xTenfiPerDay) / (totalBorrow * assetPrice),
            365
          ) -
            1)
        )
      }
    } catch (error) {
      throw error
    }
  }

  checkIsDepricated = async (xTokenAddress) => {
    try {
      const contract = this.getContract(COMPTROLLER, UNICONTROLLER)
      const status = await contract.methods.isDeprecated(xTokenAddress).call()
      return status
    } catch (error) {
      throw error
    }
  }

  getCollateralFactor = async (xTokenAddress) => {
    try {
      const market = await this.getMarket(xTokenAddress)
      const collateralFactor = this.toEther(market["collateralFactorMantissa"])
      return collateralFactor
    } catch (error) {
      throw error
    }
  }

  getSupplyApy = async (abi, address) => {
    try {
      const contract = this.getContractByAddress(abi, address)
      const supplyRatePerBlock = this.toEther(
        await contract.methods.supplyRatePerBlock().call()
      )

      const supplyApy =
        (Math.pow(supplyRatePerBlock * BLOCKS_PER_DAY + 1, 365) - 1) * 100

      return supplyApy
    } catch (error) {
      throw error
    }
  }

  getBorrowApy = async (abi, address) => {
    try {
      const contract = this.getContractByAddress(abi, address)
      const borrowRatePerBlock = this.toEther(
        await contract.methods.borrowRatePerBlock().call()
      )
      const borrowApy =
        (Math.pow(borrowRatePerBlock * BLOCKS_PER_DAY + 1, 365) - 1) * 100

      return borrowApy
    } catch (error) {
      throw error
    }
  }

  getTotalBorrows = async (abi, xTokenAddress) => {
    try {
      const contract = this.getContractByAddress(abi, xTokenAddress)
      const totalBorrows = await contract.methods.totalBorrows().call()
      return totalBorrows
    } catch (error) {
      throw error
    }
  }

  getXTenfiAccured = async (userAddress) => {
    try {
      const contract = this.getContract(COMPTROLLER, UNICONTROLLER)
      const xTenfiAccured = await contract.methods
        .compAccrued(userAddress)
        .call()
      return xTenfiAccured
    } catch (error) {
      throw error
    }
  }

  getAssetsIn = async (userAddress) => {
    try {
      const contract = this.getContract(COMPTROLLER, UNICONTROLLER)
      const assets = await contract.methods.getAssetsIn(userAddress).call()
      const res = []
      for (let i = 0; i < assets.length; i++) {
        const check = await this.checkIsDepricated(assets[i])
        if (!check) {
          res.push(assets[i])
        }
      }

      return res
    } catch (error) {
      throw error
    }
  }

  getAllMarkets = async () => {
    try {
      const contract = this.getContract(COMPTROLLER, UNICONTROLLER)
      const markets = await contract.methods.getAllMarkets().call()
      return markets
    } catch (error) {
      throw error
    }
  }

  getSupplyList = async (xToken) => {
    try {
      const contract = this.getContract(xToken, xToken)
      if (contract.methods.showSupplyList) {
        const supplyList = await contract.methods.showSupplyList().call()
        return supplyList
      } else {
        return []
      }
    } catch (error) {
      throw error
    }
  }

  getBorrowList = async (xToken) => {
    try {
      const contract = this.getContract(xToken, xToken)
      if (contract.methods.showBorrowList) {
        const borrowList = await contract.methods.showBorrowList().call()
        return borrowList
      } else {
        return []
      }
    } catch (error) {
      throw error
    }
  }

  getCloseFactorMantissa = async () => {
    try {
      const contract = this.getContract(COMPTROLLER, UNICONTROLLER)
      const data = await contract.methods.closeFactorMantissa().call()
      return data
    } catch (error) {
      throw error
    }
  }

  getLiquidateCalculateSeizeTokens = async (
    xTokenBorrowed,
    xTokenCollateral,
    actualRepayAmount
  ) => {
    try {
      const contract = this.getContract(COMPTROLLER, UNICONTROLLER)
      const data = await contract.methods
        .liquidateCalculateSeizeTokens(
          xTokenBorrowed,
          xTokenCollateral,
          actualRepayAmount
        )
        .call()
      return data
    } catch (error) {
      return {
        0: 0,
        1: 0,
      }
    }
  }

  getLiquidationIncentiveMantissa = async () => {
    try {
      const contract = this.getContract(COMPTROLLER, UNICONTROLLER)
      const data = await contract.methods.liquidationIncentiveMantissa().call()
      return data
    } catch (error) {
      throw error
    }
  }

  getSymbol = async (xTokenAddress) => {
    try {
      const contract = this.getContractByAddress(XBNB, xTokenAddress)
      const data = await contract.methods.symbol().call()
      return data
    } catch (error) {
      throw error
    }
  }

  balanceOfXtoken = async (owner, xTokenAddress) => {
    try {
      const contract = await this.getContractByAddress(XBNB, xTokenAddress)
      const data = await contract.methods.balanceOf(owner).call()
      return data
    } catch (e) {
      console.log(e)
      return 0
    }
  }

  getAccountLiquidity = async (account) => {
    try {
      const contract = this.getContract(COMPTROLLER, UNICONTROLLER)
      const accountLiquidity = await contract.methods
        .getAccountLiquidity(account)
        .call()

      return { account, ...accountLiquidity }
    } catch (error) {
      throw error
    }
  }
}

import { CONTRACT } from "../abi"
import { lendingTokens } from "../lendingdata"
import { LendingUtils } from "../Utils/lendingUtils"
import { LiquidationAccount } from "./LiquidationAccount"

export class Liquidation extends LendingUtils {
  #getLiquidationAccounts = async () => {
    try {
      let accounts = []
      const assetsIn = []

      lendingTokens.forEach((token) => {
        assetsIn.push(CONTRACT[token])
        accounts.push(this.getBorrowList(token))
      })
      /* REMOVE REDUNDANT ACCOUNTS AND RESOLVE THE PROMISE */
      accounts = [...new Set(await Promise.all(accounts)).flat(1)]

      return accounts
    } catch (error) {
      throw error
    }
  }
  getAccountData = async () => {
    try {
      let accounts = [
        "0x6e6B21d8A5Fe2Be12fc9EBe79B21fDeE44faEb02",
        "0x3825bc45387C295f39866a3151B8dca1ce629479",
      ]

      let liquidationAccounts = []

      let unhealthyAccounts = []
      accounts.forEach((account) => {
        unhealthyAccounts.push(
          (async () => {
            const liquidity = await this.getAccountLiquidity(account)
            if (liquidity["2"] > 0) {
              return account
            }
          })()
        )
      })
      unhealthyAccounts = (await Promise.all(unhealthyAccounts)).filter(
        (address) => address !== undefined
      )

      unhealthyAccounts.forEach((account) => {
        const liquidateAccount = new LiquidationAccount(account)
        liquidationAccounts.push(liquidateAccount.getAccountData())
      })

      return await Promise.all(liquidationAccounts)
    } catch (error) {
      throw error
    }
  }
}

import Web3 from "web3"
import {
  APESWAPFI,
  BANANA,
  BISWAP,
  BNB,
  BSWUSDT_BI,
  BUSD,
  CONTRACT,
  PANCAKE,
  POOLS,
  TENFI,
  TENFIV1,
  USD,
  USDC,
  USDT,
  BNBBUSD,
  BABY,
} from "../abi"
import { Utils } from "../Utils/index"
import constants from "@src/constants.json"
import { beltPools } from "../constants/index"
import axios from "axios"

export class Token {
  USD_TOKENS = [BUSD, USDT, USDC, USD]
  BNB_TOKENS = [BNB, BNB]
  STABLES = [...this.BNB_TOKENS, ...this.USD_TOKENS]
  beltPools = [...beltPools]
  allTokens = [...constants.filters["asset"]].filter(
    (token) =>
      ![...this.STABLES, ...this.beltPools, ...constants.alpacaTokens].find(
        (t) => t === token
      ) && token !== "All"
  )
  tokens = {}
  beltTokensList = []
  alpacaTokenList = []
  utility

  constructor(web3Provider, beltPools = [], alpacaToken = []) {
    if (web3Provider) {
      this.utility = new Utils(web3Provider)
    } else {
      this.utility = new Utils(
        new Web3.providers.HttpProvider(constants.web3.httpProvider)
      )
    }
    this.beltTokensList = beltPools
    this.alpacaTokenList = alpacaToken
  }

  setTokenPrice = (token, price) => {
    this.tokens = { ...this.tokens, [token]: price.toString() }
  }

  getTokenPrice = async () => {
    try {
      // USD PRICE
      this.USD_TOKENS.forEach((tokenName) => {
        this.setTokenPrice(tokenName, "1")
      })

      // BELT PRICE
      if (this.beltTokensList) {
        await this.getBeltLPPrice()
      }

      // TENFI PRICE
      await this.getTenfiPrice()

      // BNB PRICE
      await this.getBnbPrice()

      // OTHER TOKEN PRICE
      const otherTokensArry = []
      for (const token of this.allTokens) {
        otherTokensArry.push(this.getPrice(token))
      }
      await Promise.all(otherTokensArry)

      if (this.alpacaTokenList) {
        await this.getAlpacaTokenPrice()
      }

      return this.tokens
    } catch (error) {
      throw error
    }
  }

  getBeltLPPrice = async () => {
    try {
      for (const beltToken of this.beltTokensList["tokenList"]["BSC"]) {
        for (const token of this.beltPools) {
          if (
            CONTRACT[token].toLowerCase() === beltToken.address.toLowerCase()
          ) {
            this.setTokenPrice(token, beltToken.price)
          }
        }
      }
    } catch (error) {
      throw error
    }
  }

  getTenfiPrice = async () => {
    try {
      const price = await this.utility.getTenfiPrice()
      this.setTokenPrice(TENFI, price.toString())
      this.setTokenPrice(TENFIV1, price.toString())
    } catch (error) {
      throw error
    }
  }

  getBnbPrice = async () => {
    try {
      const [reserve0, reserve1] = await this.utility.getTokenReserves(
        PANCAKE,
        POOLS[BNBBUSD].address
      )
      const price = reserve1 / reserve0
      this.BNB_TOKENS.forEach((tokenName) => {
        this.setTokenPrice(tokenName, price.toString())
      })
    } catch (error) {
      throw error
    }
  }

  getAlpacaTokenPrice = async () => {
    try {
      const { data } = this.alpacaTokenList
      const { lendingPools } = data

      const lendingPool = [...lendingPools]

      const alpacaPrice = (
        await axios.get(
          "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=alpaca-finance"
        )
      ).data[0].current_price

      constants.alpacaRefTokens.forEach(async (pool, i) => {
        const poolFound = lendingPool.find((p) => p.symbol === pool)

        if (poolFound) {
          if (pool === "ALPACA") {
            this.setTokenPrice(
              pool,
              poolFound.baseTokenPerIbToken * alpacaPrice
            )
            this.setTokenPrice(
              "ibALPACA",
              poolFound.baseTokenPerIbToken * alpacaPrice
            )
          } else {
            this.setTokenPrice(
              constants.alpacaTokens[i],
              this.tokens[pool] * poolFound.baseTokenPerIbToken
            )
          }
        }
      })
    } catch (error) {
      throw error
    }
  }

  // getBabyPrice = async () => {
  //   try {
  //     const reserves = await this.getBABY_USDTTOKENPRICE()
  //     const price = reserves[1] / reserves[0]
  //     return price
  //   } catch (error) {
  //     throw error
  //   }
  // }

  // getBABY_USDTTOKENPRICE = async () => {
  //   try {
  //     const contract = this.utility.getContract(PANCAKE, BABYUSDT)
  //     const reserves = await contract.methods.getReserves().call()
  //     return [reserves[0], reserves[1]]
  //   } catch (error) {
  //     throw error
  //   }
  // }

  getPrice = async (tokenName) => {
    if (this.tokens[tokenName]) {
      return this.tokens[tokenName].toString()
    } else {
      try {
        let pool = null
        for (const poolData of Object.values(POOLS)) {
          const containsStable = this.STABLES.filter((x) =>
            poolData.pair.includes(x)
          )

          if (poolData.pair.includes(tokenName) && containsStable) {
            pool = poolData
          }
        }

        if (!pool) {
          return 0
        }
        /** GET RESERVES  */
        let reserves = []
        if (tokenName === BANANA) {
          reserves = await this.utility.getTokenReserves(
            PANCAKE,
            CONTRACT[APESWAPFI]
          )
        } else if (tokenName === BISWAP) {
          reserves = await this.utility.getTokenReserves(
            PANCAKE,
            CONTRACT[BSWUSDT_BI]
          )
        } else {
          reserves = await this.utility.getTokenReserves(PANCAKE, pool.address)
        }

        const [reserve0, reserve1] = reserves

        const pair1Token = pool.pair[0]
        const pair2Token = pool.pair[1]

        let pair1TokenPrice = 0
        let pair2TokenPrice = 0

        /** GET TOKEN PRICE  */

        switch (tokenName) {
          /** GET BANANA PRICE  */
          case BANANA:
            this.setTokenPrice(tokenName, reserve1 / reserve0)
            break
          /** GET BISWAP PRICE  */
          case BISWAP:
            this.setTokenPrice(tokenName, reserve0 / reserve1)
            break
          /** GET BABY PRICE  */
          case BABY:
            this.setTokenPrice(tokenName, reserve1 / reserve0)
            break
          /** GET OTHER TOKEN PRICE  */
          default:
            //PAIR 1 TOKEN PRICE
            if (this.STABLES.includes(pair1Token)) {
              if (this.tokens[pair1Token]) {
                pair1TokenPrice = this.tokens[pair1Token]
              } else {
                pair1TokenPrice = await this.getPrice(pair1Token)
              }
            } else {
              if (this.tokens[pair1Token]) {
                pair1TokenPrice =
                  (reserve1 / reserve0) * this.tokens[pair1Token]
              } else {
                pair1TokenPrice =
                  (reserve1 / reserve0) * (await this.getPrice(pair2Token))
                this.setTokenPrice(pair1Token, pair1TokenPrice)
              }
            }

            //PAIR 2 TOKEN PRICE
            if (this.STABLES.includes(pair2Token)) {
              if (this.tokens[pair2Token]) {
                pair2TokenPrice = this.tokens[pair2Token]
              } else {
                pair2TokenPrice = await this.getPrice(pair2Token)
              }
            } else {
              if (this.tokens[pair2Token]) {
                pair2TokenPrice =
                  (reserve0 / reserve1) * this.tokens[pair1Token]
              } else {
                pair2TokenPrice =
                  (reserve0 / reserve1) * (await this.getPrice(pair1Token))
                this.setTokenPrice(pair2Token, pair2TokenPrice)
              }
            }
        }

        const prices = {
          [pair1Token]: pair1TokenPrice,
          [pair2Token]: pair2TokenPrice,
        }

        return prices[tokenName].toString()
      } catch (err) {
        return 0
      }
    }
  }
}

import Popup, { TRANSACTION_POPUP } from "@components/popup/index"
import React from "react"
import { connect } from "react-redux"
import { POPUP_CLOSE } from "@src/actions/popup"

function TransactionPopup(props) {
  const { dispatch, visible } = props

  return (
    <Popup
      open={visible}
      openStateFn={() =>
        dispatch({ type: POPUP_CLOSE, popup: TRANSACTION_POPUP })
      }
      caption={"Transaction in progress"}>
      <div
        className={
          "flex flex-col w-full items-center space-y-8 dark:text-night-700"
        }>
        pending
      </div>
    </Popup>
  )
}

function mapStateToProps({ popup }) {
  return {
    visible: popup.popups[TRANSACTION_POPUP],
  }
}

export default connect(mapStateToProps)(TransactionPopup)

import { ICON_LOADING } from "@src/components/icons/index"
import { Liquidation } from "@src/web3/provider/liquidation"
import { liquidationLiquidateAccount } from "@src/web3/transactions"
import { errorAction } from "./index"
import { notificationOpen } from "./notification"
import { connectWalletIfNeeded } from "./wallet"

export const GET_LIQUIDATION_DATA = "GET_LIQUIDATION_DATA"
export const LIQUIDATION_DATA_LOADING = "LIQUIDATION_DATA_LOADING"
export const LIQUIDATION_DATA_FAILED = "LIQUIDATION_DATA_FAILED"

export const getLiquidationAccountsAction = () => {
  return async (dispatch, getState) => {
    const { wallet } = getState()
    dispatch({
      type: LIQUIDATION_DATA_LOADING,
    })
    try {
      let liquidationObj = new Liquidation()
      const liquidationData = await liquidationObj.getAccountData(wallet.wallet)
      dispatch({
        type: GET_LIQUIDATION_DATA,
        liquidationData,
      })
      liquidationObj = null
    } catch (error) {
      dispatch({
        type: LIQUIDATION_DATA_FAILED,
        error: error,
      })
    }
  }
}

export const liquidationAccountAction = (
  xTokenAddress,
  borrower,
  xTokenCollateral,
  repayAmountInEth,
  xToken
) => {
  return async (dispatch, getState) => {
    const { wallet } = getState()
    if (wallet.wallet) {
      dispatch(
        notificationOpen({
          title: "Transaction in progress",
          message: "Please Wait",
          icon: ICON_LOADING,
        })
      )
      try {
        await liquidationLiquidateAccount(
          xTokenAddress,
          wallet.wallet,
          borrower,
          xTokenCollateral,
          repayAmountInEth,
          xToken
        )
        dispatch(getLiquidationAccountsAction())
        dispatch(
          notificationOpen({
            title: "Success",
            message: "Successfully Liquidated",
          })
        )
      } catch (error) {
        dispatch(errorAction(error))
      }
    } else {
      dispatch(connectWalletIfNeeded())
    }
  }
}

import { lendingTokenMarkets } from "@src/utils/tokenMarketNames"
import { BNB, CONTRACT } from "../abi"
import { MAX_INT } from "../transactions"
import { LendingUtils } from "../Utils/lendingUtils"

export class LiquidationAccount extends LendingUtils {
  health = 0
  account
  assetToRepay = []
  assetToSeize = []
  assetsIn = []
  assetsInData = []

  constructor(account) {
    super()
    this.account = account
  }

  getAccountData = async () => {
    try {
      this.assetsIn = await this.getAssetsIn(this.account)
      this.assetsInData = await this.#getAssetsData()
      this.health = this.#getHealth()

      this.#getRepayAssetData()
      this.#getSeizeAssetData()

      let res
      if (this.assetToRepay.length && this.assetToSeize.length) {
        res = await this.getSeizeRepayData(
          this.account,
          this.assetToRepay[0]["assetAddress"],
          this.assetToSeize[0]["assetAddress"]
        )

        return {
          res: { ...res, health: this.health },
          assetsToRepayList: this.assetToRepay,
          assetsToSeizeList: this.assetToSeize,
        }
      } else {
        return null
      }
    } catch (error) {
      throw error
    }
  }

  #getAssetsData = async () => {
    try {
      this.assetsIn.forEach((assetAddress) => {
        this.assetsInData.push(
          (async () => ({
            symbol:
              lendingTokenMarkets[this.getTokenNameByAddress(assetAddress)],
            assetAddress,
            snapshot: await this.getAccountSnapshot(this.account, assetAddress),
            borrowBalance: await this.getBorrowBalance(
              this.account,
              assetAddress
            ),
            price: await this.getUnderlyingPrice(assetAddress),
            collateralFactor: await this.getCollateralFactor(assetAddress),
          }))()
        )
      })

      const assetData = (await Promise.all(this.assetsInData)).map((asset) => ({
        ...asset,
        supplyBalance:
          this.toEther(asset["snapshot"]["1"]) *
          this.toEther(asset["snapshot"]["3"]),
      }))

      return assetData
    } catch (error) {
      throw error
    }
  }

  #getRepayAssetData = () => {
    try {
      this.assetsInData.forEach((assetData) => {
        if (assetData["borrowBalance"] > 0) {
          this.assetToRepay.push(assetData)
        }
      })
    } catch (error) {
      throw error
    }
  }

  #getSeizeAssetData = () => {
    try {
      this.assetsInData.forEach((assetData) => {
        if (assetData["supplyBalance"] > 0) {
          this.assetToSeize.push(assetData)
        }
      })
    } catch (error) {
      throw error
    }
  }

  #getHealth = () => {
    let totalSuppliedCollateral = 0
    let totalBorrowBalance = 0
    let health = 0

    try {
      this.assetsInData.forEach((assetData) => {
        const { snapshot, price, collateralFactor } = assetData
        const collateral_bal =
          this.toEther(snapshot["1"]) *
          this.toEther(snapshot["3"]) *
          collateralFactor *
          price

        totalSuppliedCollateral += collateral_bal

        const borrow_bal = this.toEther(snapshot["2"]) * price
        totalBorrowBalance += borrow_bal
      })

      if (totalBorrowBalance !== 0) {
        health = totalSuppliedCollateral / totalBorrowBalance
      } else {
        health = MAX_INT
      }
    } catch (error) {
      throw error
    }

    return health
  }

  getSeizeRepayData = async (account, market, seizeMarket) => {
    try {
      if (account && market && seizeMarket) {
        let borrowBalance = await this.getBorrowBalance(account, market)

        if (borrowBalance > 0) {
          let closeFactor = this.toEther(await this.getCloseFactorMantissa())
          let repayAssetSymbol
          const repayUnderlying =
            CONTRACT[lendingTokenMarkets[this.getTokenNameByAddress(market)]]

          if (repayUnderlying === CONTRACT[BNB]) {
            repayAssetSymbol = BNB
          } else {
            repayAssetSymbol =
              lendingTokenMarkets[this.getTokenNameByAddress(market)]
          }

          const repayAssetPrice = await this.getUnderlyingPrice(market)
          let maxRepayAmount = closeFactor * parseFloat(borrowBalance)

          const seizeAssetPrice = await this.getUnderlyingPrice(seizeMarket)
          const seizeUnderlying =
            CONTRACT[
              lendingTokenMarkets[this.getTokenNameByAddress(seizeMarket)]
            ]

          let seizeAssetSymbol
          if (seizeUnderlying === CONTRACT[BNB]) {
            seizeAssetSymbol = BNB
          } else {
            seizeAssetSymbol =
              lendingTokenMarkets[this.getTokenNameByAddress(seizeMarket)]
          }

          let maxSeized =
            (
              await this.getLiquidateCalculateSeizeTokens(
                market,
                seizeMarket,
                this.toWei(maxRepayAmount)
              )
            )["1"] / 1e8

          const liquidIncentive = this.toEther(
            await this.getLiquidationIncentiveMantissa()
          )

          if (liquidIncentive < 1) {
            maxSeized = parseFloat(maxSeized) * (1 + liquidIncentive)
          } else {
            maxSeized = parseFloat(maxSeized) * liquidIncentive
          }

          maxSeized = (1 / seizeAssetPrice) * parseFloat(maxSeized)

          let maxSeizedInDollar = maxSeized * seizeAssetPrice
          let maxRepayInDollar = maxRepayAmount * repayAssetPrice

          if (maxSeizedInDollar < maxRepayInDollar) {
            if (liquidIncentive < 1) {
              maxRepayInDollar = maxSeizedInDollar / (1 + liquidIncentive)
            } else {
              maxRepayInDollar = maxRepayInDollar / liquidIncentive
            }
            maxRepayAmount = (1 / repayAssetPrice) * maxRepayInDollar
          } else {
            if (liquidIncentive < 1) {
              maxSeizedInDollar = maxRepayInDollar * (1 + liquidIncentive)
            } else {
              maxSeizedInDollar = maxRepayInDollar * liquidIncentive
            }
            maxSeized = (1 / seizeAssetPrice) * maxSeizedInDollar
          }

          return {
            address: account,
            seizeAsset: seizeMarket,
            maxSeizedAmount: maxSeized,
            maxSeizedInDollar: maxSeizedInDollar,
            maxRepayAmount: maxRepayAmount,
            maxRepayInDollar: maxRepayInDollar,
            seizeAssetSymbol: seizeAssetSymbol,
            market: market,
            borrowBalance: borrowBalance,
            repayAssetSymbol: repayAssetSymbol,
          }
        } else {
          return null
        }
      } else {
        throw new Error("Missing Arguement")
      }
    } catch (error) {
      throw error
    }
  }
}

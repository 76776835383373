import constants from "@src/constants.json"
import { useTranslation } from "react-i18next"

function Navigation(props) {
  const { t } = useTranslation()
  return (
    <ul
      className={`flex justify-center text-center uppercase font-bold mb-3 flex-col lg:flex-row`}>
      {constants.navigation.map((item, i) => (
        <li className='mr-6' key={item.label}>
          <a
            className={`dark:text-night-700 hover:text-blue-800 ${props.className}`}
            href={item.url}
            target={"_blank"}
            rel='noopener noreferrer'>
            {t(item.label.toUpperCase().split(" ").join("_"))}
          </a>
        </li>
      ))}
    </ul>
  )
}

export default Navigation

import { useEffect, useState } from "react"
import i18next from "i18next"
import { languageCodes } from "@utils/languageCodes"
import queryString from "query-string"
import { useHistory, useLocation } from "react-router-dom"

const FlagContainer = ({ langCode, name, onClick, className }) => {
  const [flagLogoPath, setFlagLogoPath] = useState()
  useEffect(() => {
    if (langCode) {
      import(`../../../public/flags/${langCode}.png`).then((val) => {
        setFlagLogoPath(val.default)
      })
    }
  }, [langCode])

  if (flagLogoPath) {
    return (
      <img
        src={flagLogoPath}
        className={`object-cover cursor-pointer w-full h-full  ${className}`}
        alt={name}
        onClick={onClick}
      />
    )
  } else {
    return <div className='rounded-full bg-blue-400 w-full h-full'></div>
  }
}

function LanguageSelector(props) {
  const [selectedLanguage, setSelectedLanguage] = useState(languageCodes[0])
  const [drop, setDrop] = useState(false)
  const location = useLocation()
  const history = useHistory()

  const { lng } = queryString.parse(location.search)

  useEffect(() => {
    if (lng) {
      i18next.changeLanguage(lng)
    }
  }, [lng])

  useEffect(() => {
    if (languageCodes.filter((val) => val.code === i18next.language)[0]) {
      setSelectedLanguage(
        languageCodes.filter((val) => val.code === i18next.language)[0]
      )
    }
  }, [])

  return (
    <div
      id='languageSelectorID'
      style={{ pointerEvents: "none" }}
      className={`w-10 h-10 p-1 cursor-pointer border-2 border-purple-500 rounded-full ${props.className}`}
      onMouseEnter={() => setDrop(true)}
      onMouseLeave={() => setDrop(false)}>
      <FlagContainer
        name={selectedLanguage.name}
        langCode={selectedLanguage.code}
      />
      <div
        className={`overflow-hidden transition-height ease-in-out duration-500 ${
          drop ? `h-27` : `h-0`
        }`}
        style={{ borderRadius: "1rem" }}>
        {languageCodes
          .filter((val) => val !== selectedLanguage)
          .map((lang, i) => (
            <div className={`my-2`} key={i}>
              <FlagContainer
                name={lang.name}
                langCode={lang.code}
                onClick={() => {
                  i18next.changeLanguage(lang.code)
                  setSelectedLanguage(lang)
                  history.push(`${location.pathname}?lng=${lang.code}`)
                }}
              />
            </div>
          ))}
      </div>
    </div>
  )
}

export default LanguageSelector

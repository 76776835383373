import {
  FETCH_TENFI_UI_DATA,
  GET_TENFI_PRICE,
  RECEIVE_TENFI_PARAMS,
  REQUEST_TENFI_PARAMS,
  REQUEST_TENFI_PARAMS_DATA,
} from "@src/actions/tenfi"

const initialState = {
  isFetching: true,
  isFetchingData: false,
  isFetchingUserParams: false,
  receivedAt: 0,
  pools: 0,
  tenfiPrice: 0,
  poolsUIData: [],
  params: {
    platformData: {
      tvl: 0,
      tenfi_usd: 0,
    },
    poolsDetails: {},
  },
}

function tenfi(state = initialState, action) {
  switch (action.type) {
    case REQUEST_TENFI_PARAMS:
      return {
        ...state,
        isFetching: true,
        pools: action.pools,
      }
    case REQUEST_TENFI_PARAMS_DATA:
      return {
        ...state,
        isFetchingData: true,
        pools: action.pools,
      }
    case RECEIVE_TENFI_PARAMS:
      return {
        ...state,
        isFetching: false,
        isFetchingData: false,
        params: action.params,
        receivedAt: action.receivedAt,
      }

    case FETCH_TENFI_UI_DATA:
      return { ...state, poolsUIData: action.poolsUIData }
    case GET_TENFI_PRICE:
      return { ...state, tenfiPrice: action.tenfiPrice }
    default:
      return state
  }
}

export default tenfi

import { BehaviorSubject } from "rxjs"
import { FunWalletEmbed, MessageListeners } from "@funfair-tech/wallet-sdk"
import Web3 from "web3"

export const isAuthenticated$ = new BehaviorSubject(false)
export const restoreAuthenticationTaskCompleted$ = new BehaviorSubject(false)

export async function lazyLoadFunWallet() {
  await FunWalletEmbed.load({
    appId: "0xb49f8c3f2b0d91d38ec84452420ae0277adcd19e035949e22b91ca462fe948cf",

    eventListenerCallback: () => {
      registerEventListeners()
    },
  })
}

export function registerEventListeners() {
  window.funwallet.sdk.on(
    MessageListeners.restoreAuthenticationCompleted,
    (result) => {
      if (result.origin === "https://wallet.funfair.io") {
        restoreAuthenticationTaskCompleted$.next(true)

        if (result.data.isAuthenticated) {
          isAuthenticated$.next(true)
        }
      }
    }
  )

  window.funwallet.sdk.on(
    MessageListeners.walletInactivityLoggedOut,
    (result) => {
      if (result.origin === "https://wallet.funfair.io") {
        isAuthenticated$.next(false)
      }
    }
  )

  window.funwallet.sdk.on(
    MessageListeners.walletDeviceDeletedLoggedOut,
    (result) => {
      if (result.origin === "https://wallet.funfair.io") {
        isAuthenticated$.next(false)
      }
    }
  )

  window.funwallet.sdk.on(MessageListeners.isKycVerified, (result) => {
    if (result.origin === "https://wallet.funfair.io") {
      if (!result.data.isVerified) {
        window.funwallet.sdk.showFunWalletModal()
      } else {
        console.error(
          "Your client should not show the kyc logic if already kyced"
        )
      }
    }
  })

  window.funwallet.sdk.on(MessageListeners.kycProcessCancelled, (result) => {
    if (result.origin === "https://wallet.funfair.io") {
      if (result.data.cancelled) {
        window.funwallet.sdk.hideFunWalletModal()
      }
    }
  })
}

export async function funFairlogin() {
  const result = await window.funwallet.sdk.auth.login()
  isAuthenticated$.next(true)
  return [result.authenticationCompleted.ethereumAddress]
}

export async function funFairlogout() {
  await window.funwallet.sdk.auth.logout()
  isAuthenticated$.next(false)
}

let web3 = undefined

export async function web3Instance() {
  if (web3) {
    return web3
  }

  web3 = new Web3(window.funwallet.sdk.ethereum)

  return web3
}

export async function signAMessage(messageText) {
  const ethereumAddress = await window.funwallet.sdk.eth.address()

  const result = await web3Instance().eth.personal.sign(
    messageText,
    ethereumAddress
  )

  return result
}

export async function sendTransaction(tx) {
  const ethereumAddress = await window.funwallet.sdk.eth.address()
  tx.from = ethereumAddress

  web3Instance()
    .eth.sendTransaction(tx)
    .once("transactionHash", (transactionHash) => {
      console.log("Transaction hash test app", transactionHash)
    })
    .on("error", async (error) => {
      console.log(error.message, error)
    })
}

export async function openKycProcess() {
  await window.funwallet.sdk.kyc.start()
}

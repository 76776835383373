import { ICON_LOADING } from "@src/components/icons/index"
import {
  getPendingFees,
  getTenLotsPendingReward,
  getTenLotsRemaining,
  getTenLotsReward,
  getTenLotsStaked,
  getTenLotsUserStaked,
} from "@src/web3/provider"
import {
  tenLotsCheckTransaction,
  tenLotsStake,
  tenLotsUserEntered,
  tenLotsUserFound,
  tenLotsUserNotFound,
} from "@src/web3/transactions"
import { notificationClose, notificationOpen } from "./notification"
import { connectWalletIfNeeded } from "./wallet"

export const STAKE_LOTS = "STAKE_LOTS"
export const UNSTAKE_LOTS = "UNSTAKE_LOTS"
export const GET_TENLOTS_REMAINING = "GET_TENLOTS_REMAINING"
export const GET_TENLOTS_REWARD = "GET_TENLOTS_REWARD"
export const GET_TENLOTS_STAKED = "GET_TENLOTS_STAKED"
export const GET_TENLOTS_USERS_STAKED = "GET_TENLOTS_USERS_STAKED"
export const GET_TENLOTS_USERS_ENTERED = "GET_TENLOTS_USERS_ENTERED"
export const GET_PENDING_FEES = "GET_PENDING_FEES"
export const GET_TENLOTS_REWARD_PENDING = "GET_TENLOTS_REWARD_PENDING"

export const getTenLotsUserEnteredAction = () => {
  return async (dispatch, getState) => {
    const { wallet } = getState()
    if (wallet.wallet) {
      try {
        const result = await tenLotsUserEntered(wallet.wallet)
        dispatch({
          type: GET_TENLOTS_USERS_ENTERED,
          userEntered: result,
        })
      } catch (error) {
        dispatch({
          type: GET_TENLOTS_USERS_ENTERED,
          userEntered: false,
        })
      }
    } else {
      dispatch({
        type: GET_TENLOTS_USERS_ENTERED,
        userEntered: false,
      })
    }
  }
}

export const tenLotsStakeAction = () => {
  return async (dispatch, getState) => {
    const { wallet } = getState()
    if (!wallet.wallet) {
      dispatch(connectWalletIfNeeded())
    } else {
      try {
        const result = await tenLotsUserEntered(wallet.wallet)
        dispatch({
          type: GET_TENLOTS_USERS_ENTERED,
          userEntered: result,
        })
        if (result) {
          dispatch(
            notificationOpen({
              title: "Error",
              message: "One Lot Per User",
            })
          )
        } else {
          dispatch(
            notificationOpen({
              title: "Transaction in progress",
              message: "Please Wait",
              icon: ICON_LOADING,
            })
          )
          const message = await tenLotsStake(wallet.wallet)
          dispatch(
            notificationOpen({
              title: "Alert",
              message: message,
            })
          )
        }

        dispatch(getRemainingAction())
        dispatch(getTenLotsUserStakedAction())
        dispatch(getTenLotsRewardAction())
        dispatch(getTenLotsUserEnteredAction())
        dispatch(getTenLotsStakedAction())
        dispatch(getPendingFeesAction())
        dispatch(getTenLotsPendingRewardAction())
      } catch (error) {
        dispatch(notificationClose())
        dispatch(
          notificationOpen({
            title: "Error",
            message: error.message,
          })
        )
      }
    }
  }
}

export const tenLotsUnstakeAction = () => {
  return async (dispatch, getState) => {
    const { wallet, tenlots } = getState()
    if (!wallet.wallet) {
      dispatch(connectWalletIfNeeded())
    } else {
      dispatch(
        notificationOpen({
          title: "Please Wait",
          message: "We are checking your transaction in Tenfi Vaults",
          icon: ICON_LOADING,
        })
      )
      try {
        const found = await tenLotsCheckTransaction(wallet.wallet)

        if (found) {
          const { TxHash, pID } = await tenLotsUserFound(
            wallet.wallet,
            tenlots.tenLotsReward
          )
          dispatch(
            notificationOpen({
              title: "Data Found",
              message: `You have withdrawn Transaction Hash ${TxHash} on ${new Date(
                Date(1634044117)
              ).toLocaleDateString()} PID ${pID} `,
            })
          )
        } else {
          dispatch(
            notificationOpen({
              title: "Please Wait",
              message: "Transaction In Progress",
              icon: ICON_LOADING,
            })
          )
          await tenLotsUserNotFound(wallet.wallet, tenlots.tenLotsReward)
          dispatch(getRemainingAction())
          dispatch(getTenLotsUserStakedAction())
          dispatch(getTenLotsRewardAction())
          dispatch(getTenLotsUserEnteredAction())
          dispatch(getTenLotsStakedAction())
          dispatch(getPendingFeesAction())
          dispatch(getTenLotsPendingRewardAction())
          dispatch(notificationClose())
        }

        /* const result = await tenLotsUnstake(
          wallet.wallet,
          tenlots.tenLotsReward
        )
        if (result) {
          const { TxHash, timeStamp, pID } = result
          dispatch(
            notificationOpen({
              title: "Alert",
              message: `${TxHash} with ${pID} on ${timeStamp} already exist`,
            })
          )
        } */
      } catch (error) {
        dispatch(notificationClose())
        dispatch(
          notificationOpen({
            title: "Error ",
            message: error.message,
          })
        )
      }
    }
  }
}

//silver 1
//bronze 0
//gold 2

export const getRemainingAction = () => {
  return async (dispatch) => {
    try {
      const gold = await getTenLotsRemaining(2)
      const silver = await getTenLotsRemaining(1)
      const bronze = await getTenLotsRemaining(0)
      dispatch({
        type: GET_TENLOTS_REMAINING,
        lotsListRemaining: {
          remainingSilver:
            parseInt(silver.maxAllowedUser) - parseInt(silver.userCount),
          remainingBronze:
            parseInt(bronze.maxAllowedUser) - parseInt(bronze.userCount),
          remainingGold:
            parseInt(gold.maxAllowedUser) - parseInt(gold.userCount),
        },
      })
    } catch (error) {
      dispatch({
        type: GET_TENLOTS_REMAINING,
        lotsListRemaining: {
          remainingSilver: 0,
          remainingBronze: 0,
          remainingGold: 0,
        },
      })
    }
  }
}

export const getTenLotsRewardAction = () => {
  return async (dispatch, getState) => {
    const { wallet } = getState()
    if (wallet.wallet) {
      dispatch({
        type: GET_TENLOTS_REWARD,
        tenLotsReward: await getTenLotsReward(wallet.wallet),
      })
    } else {
      dispatch({
        type: GET_TENLOTS_REWARD,
        tenLotsReward: 0,
      })
    }
  }
}

export const getTenLotsStakedAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_TENLOTS_STAKED,
        totalStaked: await getTenLotsStaked(),
      })
    } catch (error) {
      dispatch({
        type: GET_TENLOTS_STAKED,
        totalStaked: 0,
      })
    }
  }
}

export const getTenLotsUserStakedAction = () => {
  return async (dispatch, getState) => {
    const { wallet } = getState()
    try {
      const { balance, level } = await getTenLotsUserStaked(wallet.wallet)
      dispatch({
        type: GET_TENLOTS_USERS_STAKED,
        totalUserStaked: balance,
        level: level,
      })
    } catch (error) {
      dispatch({
        type: GET_TENLOTS_USERS_STAKED,
        totalUserStaked: 0,
      })
    }
  }
}

export const getPendingFeesAction = () => {
  return async (dispatch, getState) => {
    const { wallet } = getState()
    if (wallet.wallet) {
      try {
        const { pendingFee, timestamp } = await getPendingFees(wallet.wallet)
        dispatch({
          type: GET_PENDING_FEES,
          pendingFees: {
            pendingFee,
            timestamp,
          },
        })
      } catch (error) {
        dispatch({
          type: GET_PENDING_FEES,
          pendingFees: 0,
        })
      }
    } else {
      dispatch({
        type: GET_PENDING_FEES,
        pendingFees: 0,
      })
    }
  }
}

export const getTenLotsPendingRewardAction = () => {
  return async (dispatch, getState) => {
    const { wallet } = getState()
    if (wallet.wallet) {
      try {
        const rewardPending = await getTenLotsPendingReward(wallet.wallet)
        dispatch({
          type: GET_TENLOTS_REWARD_PENDING,
          rewardPending,
        })
      } catch (error) {
        dispatch({
          type: GET_TENLOTS_REWARD_PENDING,
          rewardPending: 0,
        })
      }
    } else {
      dispatch({
        type: GET_TENLOTS_REWARD_PENDING,
        rewardPending: 0,
      })
    }
  }
}

import Button from "@components/button"
import Popup, { WALLET_POPUP } from "@components/popup/index"
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { popupClose } from "@src/actions/popup"
import { ReactComponent as Metamask } from "@images/wallets/metamask.svg"
import { ReactComponent as WalletConnect } from "@images/wallets/wallet-connect-wallet.svg"
import BinanceChainWalletIcon from "@images/wallets/bsc-wallet.png"
import MathWalletIcon from "@images/wallets/math-wallet.png"
import Coin98WalletIcon from "@images/wallets/coin98-wallet.png"
import OntoWalletIcon from "@images/wallets/onto-wallet.png"
import SafePalWalletIcon from "@images/wallets/safepal-wallet.svg"
import BlankWalletIcon from "@images/wallets/blank-wallet.png"
import CoinbaseWalletIcon from "@images/wallets/coinbase-wallet.png"
// import FunFairWalletIcon from "@images/wallets/funfair-wallet.png"
import {
  BSC_PROVIDER,
  METAMASK_PROVIDER,
  WALLETCONNECT_PROVIDER,
  MATHWALLET_PROVIDER,
  COIN98_PROVIDER,
  ONTO_PROVIDER,
  SAFEPAL_PROVIDER,
  COINBASE_PROVIDER,
  BLANKWALLET_PROVIDER,
  // FUNFAIR_PROVIDER,
} from "@web3/provider"
import {
  requestWalletConnect,
  requestWalletDisconnect,
} from "@src/actions/wallet"
import { FaClipboard, FaExternalLinkAlt, IoIosLogOut } from "react-icons/all"
/* import {
  isAuthenticated$,
  restoreAuthenticationTaskCompleted$,
} from "@src/utils/funWalletUtils" */

function WalletPopup(props) {
  const { dispatch, visible, wallet } = props
  const [width, setWidth] = useState(window.innerWidth)
  // const [isFunWalletLoggedIn, setIsFunWalletLoggedIn] = useState(false)
  // const [isFunWalletLoading, setIsFunWalletLoading] = useState(true)

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize)

    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  /* useEffect(() => {
    isAuthenticated$.subscribe((value) => {
      setIsFunWalletLoggedIn(value)
      setIsFunWalletLoading(!restoreAuthenticationTaskCompleted$.value)
    })

    restoreAuthenticationTaskCompleted$.subscribe((value) => {
      if (value) {
        setIsFunWalletLoggedIn(isAuthenticated$.value)
        setIsFunWalletLoading(false)
      }
    })
  }, []) */

  const centerEllipse = (walletAddress) => {
    if (width <= 520 && walletAddress)
      return `${walletAddress.substring(0, 10)}...${walletAddress.substring(
        walletAddress.length - 9,
        walletAddress.length
      )}`
    else return walletAddress
  }

  return (
    <Popup
      open={visible}
      openStateFn={() => {
        if (wallet.wallet === null) {
          dispatch(requestWalletDisconnect(wallet.wallet))
        }
        dispatch(popupClose())
      }}
      className={"dark:bg-white w-full"}>
      {!wallet.isConnected && !wallet.isConnecting && (
        <div
          className={
            "flex flex-col w-full items-center space-y-4 dark:text-night-700"
          }>
          <Button
            variant={"white"}
            className={"w-full py-6 px-6 text-center"}
            icon={<Metamask style={{ width: 32, height: 32 }} />}
            loading={
              wallet.isConnecting && wallet.provider === METAMASK_PROVIDER
            }
            onClick={() => {
              dispatch(requestWalletConnect(METAMASK_PROVIDER))
              dispatch(popupClose())
            }}>
            Metamask
          </Button>
          <Button
            variant={"white"}
            className={"w-full py-6 px-6"}
            loading={wallet.isConnecting && wallet.provider === BSC_PROVIDER}
            textColor={`h-auto`}
            icon={
              <img
                src={BinanceChainWalletIcon}
                alt={"Binance Chain Wallet"}
                style={{ width: 32, height: 32 }}
              />
            }
            onClick={() => {
              dispatch(requestWalletConnect(BSC_PROVIDER))
              dispatch(popupClose())
            }}>
            <div className={`flex justify-center items-center h-full `}>
              <span className={`w-28 sm:w-full`}>
                Binance {width < 350 && <br />} Chain{" "}
                <br className={`sm:hidden`} />
                Wallet
              </span>
            </div>
          </Button>
          <Button
            variant={"white"}
            className={"w-full py-6 px-6"}
            icon={<WalletConnect style={{ width: 32, height: 32 }} />}
            textColor={`h-auto`}
            loading={
              wallet.isConnecting && wallet.provider === WALLETCONNECT_PROVIDER
            }
            onClick={() => {
              dispatch(requestWalletConnect(WALLETCONNECT_PROVIDER))
              dispatch(popupClose())
            }}>
            <div className={`flex justify-center items-center h-full `}>
              <span className={`w-28 sm:w-full`}>
                Wallet
                {width < 350 && <br />}
                Connect
              </span>
            </div>
          </Button>
          <Button
            variant={"white"}
            className={"w-full py-6 px-6"}
            icon={
              <img
                src={MathWalletIcon}
                alt={"Math Wallet"}
                style={{ width: 32, height: 32 }}
              />
            }
            loading={
              wallet.isConnecting && wallet.provider === MATHWALLET_PROVIDER
            }
            textColor={`h-auto`}
            onClick={() => {
              dispatch(requestWalletConnect(MATHWALLET_PROVIDER))
              dispatch(popupClose())
            }}>
            <div className={`flex justify-center items-center h-full `}>
              <span className={`w-28 sm:w-full`}>
                Math {width < 350 && <br />}
                Wallet
              </span>
            </div>
          </Button>
          <Button
            variant={"white"}
            className={"w-full py-6 px-6"}
            icon={
              <img
                src={Coin98WalletIcon}
                alt={"Coin98 Wallet"}
                style={{ width: 32, height: 32 }}
              />
            }
            loading={wallet.isConnecting && wallet.provider === COIN98_PROVIDER}
            textColor={`h-auto`}
            onClick={() => {
              dispatch(requestWalletConnect(COIN98_PROVIDER))
              dispatch(popupClose())
            }}>
            <div className={`flex justify-center items-center h-full `}>
              <span className={`w-28 sm:w-full`}>
                Coin98 {width < 350 && <br />}
                Wallet
              </span>
            </div>
          </Button>
          <Button
            variant={"white"}
            className={"w-full py-6 px-6"}
            icon={
              <img
                src={OntoWalletIcon}
                alt={"Onto Wallet"}
                style={{ width: 32, height: 32 }}
              />
            }
            loading={wallet.isConnecting && wallet.provider === ONTO_PROVIDER}
            textColor={`h-auto`}
            onClick={() => {
              dispatch(requestWalletConnect(ONTO_PROVIDER))
              dispatch(popupClose())
            }}>
            <div className={`flex justify-center items-center h-full `}>
              <span className={`w-28 sm:w-full`}>
                ONTO {width < 350 && <br />}
                Wallet
              </span>
            </div>
          </Button>
          <Button
            variant={"white"}
            className={"w-full py-6 px-6"}
            icon={
              <img
                src={SafePalWalletIcon}
                alt={"SafePal Wallet"}
                style={{ width: 32, height: 32 }}
              />
            }
            loading={
              wallet.isConnecting && wallet.provider === SAFEPAL_PROVIDER
            }
            textColor={`h-auto`}
            onClick={() => {
              dispatch(requestWalletConnect(SAFEPAL_PROVIDER))
              dispatch(popupClose())
            }}>
            <div className={`flex justify-center items-center h-full `}>
              <span className={`w-28 sm:w-full`}>
                SafePal {width < 350 && <br />}
                Wallet
              </span>
            </div>
          </Button>
          <Button
            variant={"white"}
            className={"w-full py-6 px-6"}
            icon={
              <img
                src={BlankWalletIcon}
                alt={"Blank Wallet"}
                style={{ width: 32, height: 32 }}
              />
            }
            loading={
              wallet.isConnecting && wallet.provider === BLANKWALLET_PROVIDER
            }
            textColor={`h-auto`}
            onClick={() => {
              dispatch(requestWalletConnect(BLANKWALLET_PROVIDER))
              dispatch(popupClose())
            }}>
            <div className={`flex justify-center items-center h-full `}>
              <span className={`w-28 sm:w-full`}>
                Blank {width < 350 && <br />}
                Wallet
              </span>
            </div>
          </Button>
          <Button
            variant={"white"}
            className={"w-full py-6 px-6"}
            icon={
              <img
                src={CoinbaseWalletIcon}
                alt={"Blank Wallet"}
                style={{ width: 32, height: 32 }}
              />
            }
            loading={
              wallet.isConnecting && wallet.provider === COINBASE_PROVIDER
            }
            textColor={`h-auto`}
            onClick={() => {
              dispatch(requestWalletConnect(COINBASE_PROVIDER))
              dispatch(popupClose())
            }}>
            <div className={`flex justify-center items-center h-full `}>
              <span className={`w-28 sm:w-full`}>
                Coinbase {width < 350 && <br />}
                Wallet
              </span>
            </div>
          </Button>
          {/* <Button
            variant={"white"}
            className={"w-full py-6 px-6"}
            icon={
              <img
                src={FunFairWalletIcon}
                alt={"FunFair Wallet"}
                style={{ width: 32, height: 32 }}
              />
            }
            disabled={isFunWalletLoggedIn && isFunWalletLoading}
            loading={
              wallet.isConnecting && wallet.provider === FUNFAIR_PROVIDER
            }
            textColor={`h-auto`}
            onClick={() => {
              dispatch(requestWalletConnect(FUNFAIR_PROVIDER))
              dispatch(popupClose())
            }}>
            <div className={`flex justify-center items-center h-full `}>
              <span className={`w-28 sm:w-full`}>
                Funfair {width < 350 && <br />}
                Wallet
              </span>
            </div>
          </Button> */}
        </div>
      )}
      {wallet.isConnected && (
        <div className={`px-2`}>
          <pre
            className={`flex justify-center overflow-hidden bg-gray-700 text-white  px-6 py-4 mb-6 rounded-lg space-x-4`}>
            <span>{centerEllipse(wallet.wallet)}</span>
          </pre>
          <div
            className={`flex items-center justify-center flex-col md:flex-row  mb-4 `}>
            <Button
              variant={"clean"}
              className={`m-1`}
              onClick={() => navigator.clipboard.writeText(wallet.wallet)}>
              <span className={" flex flex-row items-center space-x-2"}>
                <span className={`dark:text-white text-gray-700`}>
                  <FaClipboard />
                </span>
                <span>Copy to clipboard</span>
              </span>
            </Button>
            <Button
              variant={"clean"}
              className={`m-1 `}
              onClick={() =>
                window.open(
                  `https://bscscan.com/address/${wallet.wallet}`,
                  "_blank"
                )
              }>
              <span className={" flex flex-row items-center space-x-2"}>
                <span className={`dark:text-white text-gray-700`}>
                  <FaExternalLinkAlt />
                </span>
                <span>Open in BSCscan</span>
              </span>
            </Button>
          </div>
          <Button
            variant={"white"}
            className={"w-full py-2 px-6"}
            icon={<IoIosLogOut style={{ width: 32, height: 32 }} />}
            // iconClassName={`absolute`}
            onClick={() => {
              dispatch(requestWalletDisconnect(wallet.wallet))
              dispatch(popupClose())
            }}>
            Logout
          </Button>
        </div>
      )}
    </Popup>
  )
}

function mapStateToProps({ popup, wallet }) {
  return {
    wallet,
    visible: popup.popups[WALLET_POPUP],
  }
}

export default connect(mapStateToProps)(WalletPopup)

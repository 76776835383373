import {
  EXCHANGE_POPUP,
  LP_POPUP,
  SWAP_POPUP,
  TRANSACTION_POPUP,
  WALLET_POPUP,
  LIQUIDITY_POPUP,
} from "@components/popup"
import { POPUP_CLOSE, POPUP_OPEN } from "@src/actions/popup"
import {
  DISCLAIMER_POPUP,
  TENLEND_SUPPLY_POPUP,
  TENLEND_BORROW_POPUP,
} from "@src/components/popup/index"

const initialState = {
  popups: {
    [SWAP_POPUP]: false,
    [EXCHANGE_POPUP]: false,
    [LP_POPUP]: false,
    [WALLET_POPUP]: false,
    [TRANSACTION_POPUP]: false,
    [LIQUIDITY_POPUP]: false,
    [DISCLAIMER_POPUP]: false,
    [TENLEND_SUPPLY_POPUP]: false,
    [TENLEND_BORROW_POPUP]: false,
  },
  token: {},
}

export default function popup(state = initialState, action) {
  switch (action.type) {
    case POPUP_OPEN: {
      return {
        popups: {
          ...initialState.popups,
          [action.popup]: true,
        },
        token: action.token,
      }
    }
    case POPUP_CLOSE: {
      return {
        ...state,
        popups: {
          ...initialState.popups,
        },
      }
    }
    default:
      return { ...state }
  }
}

import { ICON_LOADING } from "@src/components/icons/index";
import { lendingTokenMarkets } from "@src/utils/tokenMarketNames";
import {
  BANANA,
  BNB,
  BTCB,
  BUSD,
  CAKE,
  CONTRACT,
  DAI,
  ETH,
  TUSD,
  USDC,
  USDT,
  UST,
} from "@src/web3/abi";
import { lendingTokens } from "@src/web3/lendingdata";
import { getLendingUIData, Lending } from "@src/web3/provider/Lending";
import {
  lendingBorrow,
  lendingClaim,
  lendingEnterMarket,
  lendingExitMarket,
  lendingRepay,
  lendingSupply,
  lendingSupplyEnable,
  lendingWithdraw,
} from "@src/web3/transactions";
import { notificationClose, notificationOpen } from "./notification";
import { connectWalletIfNeeded } from "./wallet";

export const GET_LENDING_UI_DATA = "GET_LENDING_UI_DATA";
export const LENDING_TOKEN_DATA_LOADING = "LENDING_TOKEN_DATA_LOADING";
export const GET_LENDING_TOKEN_DATA = "GET_LENDING_TOKEN_DATA";
export const LENDING_TOKEN_USER_DATA_LOADING =
  "LENDING_TOKEN_USER_DATA_LOADING";
export const GET_LENDING_TOKEN_PENDING_DATA = "LENDING_TOKEN_PENDING_DATA";
export const GET_LENDING_TOKEN_USER_DATA = "GET_LENDING_TOKEN_USER_DATA";

const mockData = {
  maxTotalSupply: [
    {
      borrowApy: 2.0692507901406865,
      borrowCap: 0,
      borrowDistributedApy: 32268.139323803625,
      borrowList: [],
      collateralFactor: 75,
      exchangeRate: 49.999871127129566,
      marketLiquidity: 5869.992234161472,
      netBorrowRate: 32270.208574593766,
      netSupplyRate: 1.1015861185222464,
      noOfBorrowers: 0,
      noOfSuppliers: 0,
      price: 51161.950000000004,
      reserveFactor: 0,
      supplyApy: 0.0038385018816589067,
      supplyDistributedApy: 1.0977476166405875,
      supplyList: [],
      token: "BTCB",
      totalBorrow: 563874.608036386,
      totalSupply: 300884123.79259396,
      underlying: "0xc00281229Af1920bb7f2FEdfEc41f796C217E575",
      xToken: "XBTCB",
      xTokenMinted: 294049.92213606,
      xTokenReserves: 0,
    },
    {
      borrowApy: 3.195950653494539,
      borrowCap: 0,
      borrowDistributedApy: 1.707319433138872e99,
      borrowList: [],
      collateralFactor: 75,
      exchangeRate: 50.95917954724552,
      marketLiquidity: 9701.591852593037,
      netBorrowRate: 1.707319433138872e99,
      netSupplyRate: 56757444.39346805,
      noOfBorrowers: 0,
      noOfSuppliers: 0,
      price: 3,
      reserveFactor: 0,
      supplyApy: 0.13745231395134727,
      supplyDistributedApy: 56757444.25601574,
      supplyList: [],
      token: "UST",
      totalBorrow: 1328.7308584233485,
      totalSupply: 30433.50641620246,
      underlying: "0x875088433999d61c663D391491c6D386D4ECCD8f",
      xToken: "XUST",
      xTokenMinted: 516955.50590517,
      xTokenReserves: 0,
    },
    {
      borrowApy: 10.76041545182973,
      borrowCap: 0,
      borrowDistributedApy: 6.12255820668246e77,
      borrowList: [],
      collateralFactor: 75,
      exchangeRate: 49.96418207186054,
      marketLiquidity: 8050.299240459596,
      netBorrowRate: 6.12255820668246e77,
      netSupplyRate: 6.909356207345036e29,
      noOfBorrowers: 0,
      noOfSuppliers: 0,
      price: 1,
      reserveFactor: 0,
      supplyApy: 3.2529205016347262,
      supplyDistributedApy: 6.909356207345036e29,
      supplyList: [],
      token: "BUSD",
      totalBorrow: 3670.4493928518104,
      totalSupply: 11719.39058125115,
      underlying: "0x1ea8a76577AC237d285c80E488FD151e174b7e8c",
      xToken: "XBUSD",
      xTokenMinted: 585549.76477288,
      xTokenReserves: 1358052060257888500,
    },
  ],
  maxTotalBorrow: [
    {
      borrowApy: 2.0692507901406865,
      borrowCap: 0,
      borrowDistributedApy: 32268.139323803625,
      borrowList: [],
      collateralFactor: 75,
      exchangeRate: 49.999871127129566,
      marketLiquidity: 5869.992234161472,
      netBorrowRate: 32270.208574593766,
      netSupplyRate: 1.1015861185222464,
      noOfBorrowers: 0,
      noOfSuppliers: 0,
      price: 51161.950000000004,
      reserveFactor: 0,
      supplyApy: 0.0038385018816589067,
      supplyDistributedApy: 1.0977476166405875,
      supplyList: [],
      token: "BTCB",
      totalBorrow: 563874.608036386,
      totalSupply: 300884123.79259396,
      underlying: "0xc00281229Af1920bb7f2FEdfEc41f796C217E575",
      xToken: "XBTCB",
      xTokenMinted: 294049.92213606,
      xTokenReserves: 0,
    },
    {
      borrowApy: 10.76041545182973,
      borrowCap: 0,
      borrowDistributedApy: 6.12255820668246e77,
      borrowList: [],
      collateralFactor: 75,
      exchangeRate: 49.96418207186054,
      marketLiquidity: 8050.299240459596,
      netBorrowRate: 6.12255820668246e77,
      netSupplyRate: 6.909356207345036e29,
      noOfBorrowers: 0,
      noOfSuppliers: 0,
      price: 1,
      reserveFactor: 0,
      supplyApy: 3.2529205016347262,
      supplyDistributedApy: 6.909356207345036e29,
      supplyList: [],
      token: "BUSD",
      totalBorrow: 3670.4493928518104,
      totalSupply: 11719.39058125115,
      underlying: "0x1ea8a76577AC237d285c80E488FD151e174b7e8c",
      xToken: "XBUSD",
      xTokenMinted: 585549.76477288,
      xTokenReserves: 1358052060257888500,
    },
    {
      borrowApy: 15.495991785220763,
      borrowCap: 0,
      borrowDistributedApy: 1.6460151359225753e294,
      borrowList: [],
      collateralFactor: 50,
      exchangeRate: 50.77606724156328,
      marketLiquidity: 151.2017883445269,
      netBorrowRate: 1.6460151359225753e294,
      netSupplyRate: 3.700693922821427e203,
      noOfBorrowers: 0,
      noOfSuppliers: 0,
      price: 12,
      reserveFactor: 0,
      supplyApy: 7.200578451152495,
      supplyDistributedApy: 3.700693922821427e203,
      supplyList: [],
      token: "CAKE",
      totalBorrow: 1692.30375756054,
      totalSupply: 3506.7252176948627,
      underlying: "0x0eF9Db7E0be3fF1058691610735b1579A768fBDe",
      xToken: "XCAKE",
      xTokenMinted: 14838.14295428,
      xTokenReserves: 0,
    },
  ],
  totalBorrow: 571185.2693846002,
  totalNoOfBorrowers: 2,
  totalNoOfSuppliers: 4,
  totalSupply: 300936062.4073868,
  xTokenData: lendingTokens
    .map((token) => ({
      borrowApy: 2.0692507901406865,
      borrowCap: 0,
      borrowDistributedApy: 32268.139323803625,
      borrowList: [],
      collateralFactor: 75,
      exchangeRate: 49.999871127129566,
      marketLiquidity: 5869.992234161472,
      netBorrowRate: 32270.208574593766,
      netSupplyRate: 1.1015861185222464,
      noOfBorrowers: 0,
      noOfSuppliers: 0,
      price: 51161.950000000004,
      reserveFactor: 0,
      supplyApy: 1.2038385018816589067,
      supplyDistributedApy: 1.0977476166405875,
      supplyList: [],
      token: lendingTokenMarkets[token],
      totalBorrow: 563874.608036386,
      totalSupply: 300884123.79259396,
      underlying: "0xc00281229Af1920bb7f2FEdfEc41f796C217E575",
      xToken: token,
      xTokenMinted: 294049.92213606,
      xTokenReserves: 0,
    }))
    .reduce((acc, cur) => ({ ...acc, [cur.token]: cur }), {}),
};

const mockUserData = {
  totalBorrowBalance: 0,
  totalSupplyBalance: 0,
  netApy: 0,
  borrow: {
    borrowLimit: 0,
    usedBorrowLimit: 0,
  },
  tokenUserData: lendingTokens
    .map((token) => ({
      borrowBalance: 0,
      borrowBalanceUsd: 0,
      borrowLimit: 0,
      enterMarket: false,
      isDepricated: false,
      netApy: 0,
      price: 12,
      supplyAllowance: false,
      supplyBalance: 0,
      supplyBalanceUsd: 0,
      token: lendingTokenMarkets[token],
      tokenBalance: 0,
      xToken: token,
      xTokenBalance: 0,
    }))
    .reduce((acc, cur) => ({ ...acc, [cur.token]: cur }), {}),
};

export const getLendingDataAction = () => {
  return async (dispatch) => {
    dispatch({
      type: LENDING_TOKEN_DATA_LOADING,
      loading: true,
    });
    // let lending = new Lending()
    try {
      // const lendingData = await lending.getLendingData()
      dispatch({
        type: GET_LENDING_TOKEN_DATA,
        lendingTokenData: mockData,
      });
    } catch (error) {
      dispatch(getLendingDataAction());
    }

    // lending = null
  };
};

export const getLendingUserDataAction = (walletAddress) => {
  return async (dispatch, getState) => {
    if (walletAddress) {
      dispatch({
        type: LENDING_TOKEN_USER_DATA_LOADING,
        loading: true,
      });
      // let lending = new Lending()
      try {
        // const lendingUserData = await lending.getLendingUserData(walletAddress)
        dispatch({
          type: GET_LENDING_TOKEN_USER_DATA,
          lendingTokenUserData: mockUserData,
        });
      } catch (error) {
        dispatch(getLendingUserDataAction(walletAddress));
      }
      // lending = null
    }
  };
};

export const getLendingPendingDataAction = (walletAddress) => {
  return async (dispatch, getState) => {
    if (walletAddress) {
      // let lending = new Lending()
      try {
        // const { pendingXTenfi, pendingxTenfiUsd, xTenfiPrice, xTenfiBalance } =
        //   await lending.getPendingData(walletAddress)
        dispatch({
          type: GET_LENDING_TOKEN_PENDING_DATA,
          pendingXTenfi: 0,
          pendingxTenfiUsd: 0,
          xTenfiPrice: 0,
          xTenfiBalance: 0,
        });
      } catch (error) {
        console.log(error);
      }
      // lending = null
    }
  };
};

export const lendingEnterMarketAction = (xToken, underlying) => {
  return async (dispatch, getState) => {
    const { wallet } = getState();
    if (wallet.wallet) {
      dispatch(
        notificationOpen({
          title: "Transaction in progress",
          message: "Please Wait",
          icon: ICON_LOADING,
        })
      );
      try {
        await lendingEnterMarket(xToken, underlying, wallet.wallet);
        dispatch(
          notificationOpen({
            title: "Success",
            message: "Collateral Enabled Successfully",
          })
        );
      } catch (error) {
        dispatch(notificationClose());
        dispatch(
          notificationOpen({
            title: "Error",
            message: `${error.message}`,
          })
        );
      }
      dispatch(getLendingUserDataAction(wallet.wallet));
    } else {
      dispatch(connectWalletIfNeeded());
    }
  };
};

export const lendingExitMarketAction = (xToken) => {
  return async (dispatch, getState) => {
    const { wallet } = getState();
    if (wallet.wallet) {
      dispatch(
        notificationOpen({
          title: "Transaction in progress",
          message: "Please Wait",
          icon: ICON_LOADING,
        })
      );
      try {
        await lendingExitMarket(xToken, wallet.wallet);
        dispatch(
          notificationOpen({
            title: "Success",
            message: "Collateral Disabled Successfully",
          })
        );
      } catch (error) {
        dispatch(notificationClose());
        dispatch(
          notificationOpen({
            title: "Error",
            message: `${error.message}`,
          })
        );
      }
      dispatch(getLendingUserDataAction(wallet.wallet));
    } else {
      dispatch(connectWalletIfNeeded());
    }
  };
};

export const lendingUIDataAction = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: GET_LENDING_UI_DATA,
        uiData: getLendingUIData(),
      });
    } catch (error) {}
  };
};

export const enableSupplyAction = (token, xToken) => {
  return async (dispatch, getState) => {
    const { wallet } = getState();
    if (wallet.wallet) {
      dispatch(
        notificationOpen({
          title: "Transaction in progress",
          message: "Please Wait",
          icon: ICON_LOADING,
        })
      );
      try {
        await lendingSupplyEnable(token, xToken, wallet.wallet);
        dispatch(
          notificationOpen({
            title: "Success",
            message: "Supply Enabled",
          })
        );
      } catch (error) {
        dispatch(notificationClose());
        dispatch(
          notificationOpen({
            title: "Error",
            message: `${error.message}`,
          })
        );
      }
      dispatch(getLendingUserDataAction(wallet.wallet));
    } else {
      dispatch(connectWalletIfNeeded());
    }
  };
};

export const lendingSupplyAction = (xToken, amount) => {
  return async (dispatch, getState) => {
    const { wallet } = getState();
    if (wallet.wallet) {
      dispatch(
        notificationOpen({
          title: "Transaction in progress",
          message: "Please Wait",
          icon: ICON_LOADING,
        })
      );
      try {
        await lendingSupply(xToken, amount, wallet.wallet);
        dispatch(
          notificationOpen({
            title: "Success",
            message: "Supplied Successfully",
          })
        );
      } catch (error) {
        dispatch(notificationClose());
        dispatch(
          notificationOpen({
            title: "Error",
            message: `${error.message}`,
          })
        );
      }
      dispatch(getLendingUserDataAction(wallet.wallet));
    } else {
      dispatch(connectWalletIfNeeded());
    }
  };
};

export const lendingWithdrawAction = (xToken, amount) => {
  return async (dispatch, getState) => {
    const { wallet } = getState();
    if (wallet.wallet) {
      dispatch(
        notificationOpen({
          title: "Transaction in progress",
          message: "Please Wait",
          icon: ICON_LOADING,
        })
      );
      try {
        await lendingWithdraw(xToken, amount, wallet.wallet);
        dispatch(
          notificationOpen({
            title: "Success",
            message: "Withdrawn Successfully",
          })
        );
      } catch (error) {
        dispatch(notificationClose());
        dispatch(
          notificationOpen({
            title: "Error",
            message: `${error.message}`,
          })
        );
      }
      dispatch(getLendingUserDataAction(wallet.wallet));
    } else {
      dispatch(connectWalletIfNeeded());
    }
  };
};

export const lendingBorrowAction = (xToken, amount) => {
  return async (dispatch, getState) => {
    const { wallet } = getState();
    if (wallet.wallet) {
      dispatch(
        notificationOpen({
          title: "Transaction in progress",
          message: "Please Wait",
          icon: ICON_LOADING,
        })
      );
      try {
        await lendingBorrow(xToken, amount, wallet.wallet);
        dispatch(
          notificationOpen({
            title: "Success",
            message: "Successfully Borrowed",
          })
        );
      } catch (error) {
        dispatch(notificationClose());
        dispatch(
          notificationOpen({
            title: "Error",
            message: `${error.message}`,
          })
        );
      }
      dispatch(getLendingUserDataAction(wallet.wallet));
    } else {
      dispatch(connectWalletIfNeeded());
    }
  };
};

export const lendingRepayAction = (xToken, amount) => {
  return async (dispatch, getState) => {
    const { wallet } = getState();
    if (wallet.wallet) {
      dispatch(
        notificationOpen({
          title: "Transaction in progress",
          message: "Please Wait",
          icon: ICON_LOADING,
        })
      );
      try {
        await lendingRepay(xToken, amount, wallet.wallet);
        dispatch(
          notificationOpen({
            title: "Success",
            message: "Successfully Repayed",
          })
        );
      } catch (error) {
        dispatch(notificationClose());
        dispatch(
          notificationOpen({
            title: "Error",
            message: `${error.message}`,
          })
        );
      }
      dispatch(getLendingUserDataAction(wallet.wallet));
    } else {
      dispatch(connectWalletIfNeeded());
    }
  };
};

export const lendingClaimAction = () => {
  return async (dispatch, getState) => {
    const { wallet, lending } = getState();

    if (wallet.wallet) {
      const { tokenUserData } = lending["lendingTokenUserData"];

      const userAssetData = Object.keys(tokenUserData)
        .map((obj) =>
          tokenUserData[obj]["supplyBalanceUsd"] > 0
            ? CONTRACT[tokenUserData[obj]["xToken"]]
            : undefined
        )
        .filter((val) => val !== undefined);

      dispatch(
        notificationOpen({
          title: "Transaction in progress",
          message: "Please Wait",
          icon: ICON_LOADING,
        })
      );
      try {
        await lendingClaim(wallet.wallet, userAssetData);
        dispatch(
          notificationOpen({
            title: "Success",
            message: "Successfully Caimed",
          })
        );
      } catch (error) {
        dispatch(notificationClose());
        dispatch(
          notificationOpen({
            title: "Error",
            message: `${error.message}`,
          })
        );
      }
      dispatch(getLendingUserDataAction(wallet.wallet));
    } else {
      dispatch(connectWalletIfNeeded());
    }
  };
};

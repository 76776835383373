import Caption from "@components/caption"
import Input from "@components/input"
import { FiArrowDown } from "react-icons/all"
import Button from "@components/button"
import Popup, { SWAP_POPUP } from "@components/popup/index"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { popupClose } from "@src/actions/popup"
import { getAllowance } from "@web3/transactions"
import { ICO1, TENFI, TENFIV1 } from "@web3/abi"
import {
  approve,
  isWalletConnectedWithDeposits,
  swapv1v2,
} from "@src/actions/wallet"

function SwapPopup(props) {
  const { dispatch, visible, wallet } = props

  const [amount, setAmount] = useState(0)
  const [isAllowed, setIsAllowed] = useState(0)

  useEffect(() => {
    if (isWalletConnectedWithDeposits(wallet))
      getAllowance(ICO1, TENFIV1, wallet.wallet).then((res) =>
        setIsAllowed(parseInt(res))
      )
  }, [isAllowed, wallet])

  return isWalletConnectedWithDeposits(wallet) ? (
    <Popup
      open={visible}
      openStateFn={() => dispatch(popupClose())}
      caption={"Swap TENFI"}>
      <div
        className={
          "flex flex-col w-full items-center space-y-8 dark:text-night-700"
        }>
        <div className={"flex flex-col w-full"}>
          <div className={"flex flex-row justify-between w-full"}>
            <Caption>From</Caption>
            <Caption>
              Balance:{" "}
              <span className={"dark:text-white"}>
                {wallet.balance.tokenBalance[TENFIV1].balance}
              </span>
            </Caption>
          </div>
          <Input
            className={"w-full"}
            token={"tenfi"}
            tokenName={"TENFI V1"}
            value={amount}
            max={wallet.balance.tokenBalance[TENFIV1].balance}
            onChange={(val) => {
              setAmount(val)
            }}
          />
        </div>
        <FiArrowDown size={32} />
        <div className={"flex flex-col w-full"}>
          <div className={"flex flex-row justify-between w-full"}>
            <Caption>To</Caption>
            <Caption>
              Balance:{" "}
              <span className={"dark:text-white"}>
                {wallet.balance.tokenBalance[TENFI].balance}
              </span>
            </Caption>
          </div>
          <Input
            readonly
            className={"w-full"}
            token={"tenfi"}
            tokenName={"TENFI V2"}
            value={amount * 1.1}
          />
        </div>
        {isAllowed > 0 && (
          <Button
            variant={"violin"}
            className={"w-full"}
            onClick={() =>
              dispatch(swapv1v2(ICO1, TENFIV1, amount, wallet.wallet))
            }>
            Swap
          </Button>
        )}

        {isAllowed === 0 && (
          <Button
            variant={"violin"}
            className={"w-full"}
            onClick={() => dispatch(approve(ICO1, TENFIV1, wallet.wallet))}>
            Approve
          </Button>
        )}
      </div>
    </Popup>
  ) : (
    <div />
  )
}

function mapStateToProps({ popup, wallet }) {
  return {
    wallet,
    visible: popup.popups[SWAP_POPUP],
  }
}

export default connect(mapStateToProps)(SwapPopup)

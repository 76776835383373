import Popup, { TENLEND_SUPPLY_POPUP } from "@components/popup/index"
import React from "react"
import { connect, useSelector } from "react-redux"
import { popupClose } from "@src/actions/popup"
import { useState } from "react"
import Token from "../token/index"
import Tab from "../tab/index"
import Button from "../button/index"
import Input from "../input/index"
import { useContext } from "react"
import { ThemeContext } from "../themeContext"
import {
  enableSupplyAction,
  lendingSupplyAction,
  lendingWithdrawAction,
} from "@src/actions/lending"
import { floatNumRegex } from "@src/utils/regexConstants"

function TenLendSupplyPopup(props) {
  const { dispatch, visible, tokenData } = props
  const { theme } = useContext(ThemeContext)
  const [supplyTab, setSupplyTab] = useState(true)
  const [supplyValue, setSupplyValue] = useState("")
  const [withdrawValue, setWithdrawValue] = useState("")

  const { token, xToken } = tokenData

  const { lendingTokenData, lendingTokenUserData } = useSelector(
    (state) => state.lending
  )
  const { tokenUserData, borrow, totalBorrowBalance } = lendingTokenUserData

  const { xTokenData } = lendingTokenData

  return (
    <Popup open={visible} openStateFn={() => dispatch(popupClose())}>
      <div className={"flex flex-col"}>
        <span className={"text-2xl font-semibold"}>
          {tokenData.marketName && tokenData.marketName}
        </span>
        {supplyTab ? (
          <div className={"flex flex-col items-center"}>
            {tokenUserData &&
            tokenUserData[token] &&
            tokenUserData[token]["supplyAllowance"] ? (
              <div className='flex items-center justify-center relative'>
                <div style={{ width: "80%" }}>
                  <Input
                    placeholder={"0.00"}
                    value={supplyValue}
                    variant={"clean"}
                    className={`border-none bg-white dark:bg-white h-56 text-5xl text-purple-100 text-2xl text-center`}
                    style={{
                      background: theme === "light" ? "#fff" : "#16232E",
                      boxShadow: "none",
                      fontWeight: "bold",
                      color: "#A061F8",
                    }}
                    onChange={(val) => {
                      if (val === "") setSupplyValue("")
                      if (floatNumRegex.test(val) && val !== "")
                        setSupplyValue(val)
                    }}
                  />
                </div>

                <Button
                  onClick={() => {
                    // if (
                    //   tokenUserData &&
                    //   tokenUserData[token] &&
                    //   tokenUserData[token]["tokenBalance"]
                    // ) {
                    //   setSupplyValue(
                    //     (
                    //       parseFloat(
                    //         tokenUserData[token]["tokenBalance"].toFixed(8)
                    //       ) - (1e-8).toFixed(8)
                    //     ).toFixed(8)
                    //   )
                    // }
                  }}
                  variant={"clean"}
                  className={"absolute right-2"}>
                  MAX
                </Button>
              </div>
            ) : (
              <>
                <Token
                  size={"6rem"}
                  symbol={tokenData.token && tokenData.token}
                  label={""}
                />
                <p
                  className={
                    "mt-5 mb-20 font-normal text-sm text-center w-80 text-gray-1280"
                  }>
                  To Supply or Repay Chainlink to the Compound Protocol, you
                  need to enable it first.
                </p>
              </>
            )}
          </div>
        ) : (
          <div className='flex items-center justify-center relative'>
            <div style={{ width: "80%" }}>
              <Input
                placeholder={"0.00"}
                variant={"clean"}
                value={withdrawValue}
                className={`border-none bg-white dark:bg-white h-56 text-5xl text-purple-100 text-2xl text-center`}
                style={{
                  background: theme === "light" ? "#fff" : "#16232E",
                  boxShadow: "none",
                  fontWeight: "bold",
                  color: "#A061F8",
                }}
                onChange={(val) => {
                  if (val === "") setWithdrawValue("")
                  if (floatNumRegex.test(val) && val !== "")
                    setWithdrawValue(val)
                }}
              />
            </div>

            <Button
              onClick={() => {
                // if (
                //   tokenUserData &&
                //   tokenUserData[token] &&
                //   tokenUserData[token]["supplyBalance"]
                // ) {
                //   const collateralFactorMantissa =
                //     xTokenData[token]["collateralFactor"] / 100
                //   const collateralGiven =
                //     tokenUserData[token]["supplyBalanceUsd"] *
                //     collateralFactorMantissa
                //   if (
                //     borrow["borrowLimit"] - collateralGiven >=
                //     totalBorrowBalance
                //   ) {
                //     setWithdrawValue(
                //       (
                //         parseFloat(
                //           tokenUserData[token]["supplyBalance"]
                //         ).toFixed(8) - (1e-8).toFixed(8)
                //       ).toFixed(8)
                //     )
                //   } else {
                //     const maxBorrow =
                //       (borrow["borrowLimit"] - totalBorrowBalance) /
                //       tokenUserData[token]["price"] /
                //       collateralFactorMantissa
                //     setWithdrawValue(
                //       (
                //         parseFloat(maxBorrow).toFixed(8) - (1e-8).toFixed(8)
                //       ).toFixed(8)
                //     )
                //   }
                // }
              }}
              variant={"clean"}
              className={"absolute right-2"}>
              MAX
            </Button>
          </div>
        )}

        <div className={"flex"}>
          <Tab
            className={"w-full text-center"}
            active={supplyTab}
            onClick={() => setSupplyTab(true)}>
            SUPPLY
          </Tab>
          <Tab
            className={"w-full text-center"}
            active={!supplyTab}
            onClick={() => setSupplyTab(false)}>
            WITHDRAW
          </Tab>
        </div>
        <div className={"flex flex-col mt-11 px-2"}>
          <span className={"font-normal text-sm text-gray-1280"}>
            Supply Rates
          </span>
          <div
            className={
              "flex items-center justify-between text-sm font-semibold my-7"
            }>
            <span className={"flex items-center"}>
              <Token
                size={"2rem"}
                symbol={tokenData.token && tokenData.token}
                label={""}
                className={"mr-5"}
              />
              Supply APY
            </span>
            {lendingTokenData &&
              xTokenData &&
              xTokenData[token] &&
              parseFloat(xTokenData[token]["supplyApy"]).toFixed(2)}{" "}
            %
          </div>
          <div
            className={
              "flex items-center justify-between text-sm font-semibold border-t dark:border-night-500 pt-7"
            }>
            <span className={"flex items-center"}>
              <Token
                symbol={"tenyield"}
                size={"2rem"}
                label={""}
                className={"mr-5"}
              />
              Distribution APY
            </span>
            {xTokenData &&
              xTokenData[token] &&
              parseFloat(xTokenData[token]["supplyDistributedApy"]).toFixed(
                2
              )}{" "}
            %
          </div>
        </div>

        {supplyTab &&
        tokenUserData &&
        tokenUserData[token] &&
        !tokenUserData[token]["supplyAllowance"] ? (
          <div className={"mt-8 w-full flex flex-col"}>
            <Button
              className={`h-11 text-xs dark:text-white font-light w-full lg:w-auto`}
              textColor={`text-white`}
              onClick={() => {
                /* dispatch(enableSupplyAction(token, xToken)) */
              }}>
              Enable
            </Button>
          </div>
        ) : (
          <div className={"mt-8 w-full flex flex-col"}>
            <span className={"font-normal text-sm text-gray-1280"}>
              Borrow Limits
            </span>
            <div
              className={
                "flex items-center justify-between text-sm font-semibold my-7"
              }>
              <span className={"flex items-center"}>Borrow Limit</span>$ {""}
              {borrow &&
                borrow["borrowLimit"] &&
                parseFloat(borrow["borrowLimit"]).toFixed(2)}{" "}
            </div>
            <div
              className={
                "flex items-center justify-between text-sm font-semibold border-t dark:border-night-500 pt-7 mb-8"
              }>
              <span className={"flex items-center"}>Borrow Limit Used</span>
              {borrow &&
                borrow["borrowLimit"] &&
                parseFloat(borrow["usedBorrowLimit"]).toFixed(4)}{" "}
              %
            </div>
          </div>
        )}

        {supplyTab &&
          tokenUserData &&
          tokenUserData[token] &&
          tokenUserData[token]["supplyAllowance"] && (
            <Button
              disabled={
                supplyValue === "" ||
                parseFloat(supplyValue) <= 0 ||
                parseFloat(
                  (
                    parseFloat(
                      tokenUserData[token]["tokenBalance"].toFixed(8)
                    ) - parseFloat((1e-8).toFixed(8))
                  ).toFixed(8)
                ) < parseFloat(supplyValue)
                  ? true
                  : false
              }
              className={`h-11 text-xs   font-light w-full lg:w-auto`}
              textColor={`text-night-10 dark:text-white`}
              onClick={() => {
                // dispatch(lendingSupplyAction(xToken, supplyValue))
              }}>
              Supply
            </Button>
          )}

        {!supplyTab && (
          <Button
            disabled={
              withdrawValue === "" ||
              parseFloat(withdrawValue) <= 0 ||
              parseFloat(
                (
                  parseFloat(tokenUserData[token]["supplyBalance"].toFixed(8)) -
                  parseFloat((1e-8).toFixed(8))
                ).toFixed(8)
              ) < parseFloat(withdrawValue)
                ? true
                : false
            }
            className={`h-11 text-xs   font-light w-full lg:w-auto`}
            textColor={`text-night-10 dark:text-white`}
            onClick={() => {
              // dispatch(lendingWithdrawAction(xToken, withdrawValue))
            }}>
            Withdraw
          </Button>
        )}

        {supplyTab ? (
          <div className={"w-full flex flex-col"}>
            <div
              className={
                "mt-8 flex justify-between font-normal text-sm text-center text-gray-1280"
              }>
              <span>Wallet Balance</span>
              <span>
                <span className={"text-night-10 dark:text-white mr-1"}>
                  {tokenUserData &&
                    tokenUserData[token] &&
                    parseFloat(tokenUserData[token]["tokenBalance"]).toFixed(2)}
                </span>
                {tokenData.token && tokenData.token}
              </span>
            </div>
          </div>
        ) : (
          <div className={"w-full flex flex-col"}>
            <div
              className={
                "mt-8 flex justify-between font-normal text-sm text-center text-gray-1280"
              }>
              <span>Currently Supplying</span>
              <span>
                <span className={"text-night-10 dark:text-white mr-1"}>
                  {tokenUserData &&
                    tokenUserData[token] &&
                    parseFloat(tokenUserData[token]["supplyBalance"]).toFixed(
                      2
                    )}
                </span>
                {tokenData.token && tokenData.token}
              </span>
            </div>
          </div>
        )}
      </div>
    </Popup>
  )
}

function mapStateToProps({ popup, wallet }) {
  return {
    wallet,
    visible: popup.popups[TENLEND_SUPPLY_POPUP],
    tokenData: popup.token,
  }
}

export default connect(mapStateToProps)(TenLendSupplyPopup)

import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { useEffect, Suspense, Fragment } from "react";
import SwapPopup from "@components/popup/swap";
import LpPopup from "@components/popup/lp";
import ExchangePopup from "@components/popup/exchange";
import WalletPopup from "@components/popup/wallet";
import LiquidityPopup from "@components/popup/liquidity";
import TransactionPopup from "@components/popup/transaction";
import Notification from "@components/notification";
import PageContainer from "./components/pageContainer";
import { numberWithCommas } from "./utils/number";
import { useDispatch, useSelector } from "react-redux";
import {
  requestWalletConnect,
  requestWalletDisconnect,
  switchWallet,
  UPDATE_INTERVAL,
} from "./actions/wallet";
import {
  DISCLAIMER_POPUP,
  EXCHANGE_POPUP,
  LIQUIDITY_POPUP,
  LP_POPUP,
  SWAP_POPUP,
  TENLEND_SUPPLY_POPUP,
  TENLEND_BORROW_POPUP,
  TRANSACTION_POPUP,
  WALLET_POPUP,
  TENLEND_CLAIM_REWARD,
} from "./components/popup/index";
import { getTenfiPriceAction } from "./actions/tenfi";
import Yieldexdisclaimer from "./components/popup/yieldexdisclaimer";
import TenLendSupplyPopup from "./components/popup/TenLendSupplyPopup";
import TenLendBorrowPopup from "./components/popup/TenLendBorrowPopup";
import Market from "@src/pages/tenlend/market";
import LendingClaimRewardPopup from "./components/popup/LendingClaimRewardPopup";
const TenLend = React.lazy(() => import("./pages/tenlend"));

function App() {
  const tenfi = useSelector((state) => state.tenfi);
  const { popups } = useSelector((state) => state.popup);
  const dispatch = useDispatch();

  useEffect(() => {
    const provider = localStorage.getItem("provider-name");
    const disconnect = localStorage.getItem("disconnected");
    if (provider !== "null" && disconnect === "0") {
      dispatch(requestWalletConnect(provider));
    }

    window.ethereum &&
      window.ethereum.on("accountsChanged", function (accounts) {
        if (accounts[0]) {
          dispatch(switchWallet(accounts[0]));
        } else {
          dispatch(requestWalletDisconnect());
        }
      });
  }, [dispatch]);

  useEffect(() => {
    const tenfiPrice = numberWithCommas(tenfi.tenfiPrice, 2);
    if (parseFloat(tenfi.tenfiPrice) > 0)
      document.title = `TEN Finance | TENFI - $${tenfiPrice}`;
  }, [tenfi.tenfiPrice, dispatch]);

  useEffect(() => {
    if (!window.fetchTenfiInterval) {
      dispatch(getTenfiPriceAction());
    }

    window.fetchTenfiPriceInterval = setInterval(() => {
      dispatch(getTenfiPriceAction());
    }, UPDATE_INTERVAL);

    return () => {
      clearInterval(window.fetchTenfiPriceInterval);
    };
  }, [dispatch]);

  const popupsObj = [
    { id: SWAP_POPUP, component: <SwapPopup /> },
    { id: LP_POPUP, component: <LpPopup /> },
    { id: EXCHANGE_POPUP, component: <ExchangePopup /> },
    { id: LIQUIDITY_POPUP, component: <LiquidityPopup /> },
    { id: WALLET_POPUP, component: <WalletPopup /> },
    { id: TRANSACTION_POPUP, component: <TransactionPopup /> },
    { id: DISCLAIMER_POPUP, component: <Yieldexdisclaimer /> },
    { id: TENLEND_SUPPLY_POPUP, component: <TenLendSupplyPopup /> },
    { id: TENLEND_BORROW_POPUP, component: <TenLendBorrowPopup /> },
    { id: TENLEND_CLAIM_REWARD, component: <LendingClaimRewardPopup /> },
  ];

  return (
    <>
      {!popupsObj.filter((obj) => popups[obj.id] === true).length && (
        <Notification />
      )}

      {popupsObj.map((obj, i) => (
        <Fragment key={i}> {popups[obj.id] && obj.component}</Fragment>
      ))}

      <div className="relative">
        <div
          style={{ zIndex: "3", top: "50vh" }}
          className="fixed opacity-90 bg-night-1000 text-white w-full flex items-center flex-col justify-center h-44">
          <span className="text-4xl md:text-5xl font-bold">COMING SOON</span>
          <a
            href="https://lend.gitbook.io/home/"
            target="_blank"
            className="text-base text-violin-1200">
            CLICK HERE FOR MORE INFO
          </a>
        </div>
      </div>

      <Router>
        <Switch>
          <PageContainer>
            <Suspense fallback={<></>}>
              <Route exact path={["/", "/markets"]}>
                <TenLend />
              </Route>
              {/* <Route exact path='/market/:token'>
                <Market />
              </Route> */}
            </Suspense>
          </PageContainer>
        </Switch>
      </Router>
    </>
  );
}

export default App;

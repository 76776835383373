import {
  XBNB,
  XBTCB,
  XBUSD,
  XDAI,
  XETH,
  XLEND,
  XTENFI,
  XTUSD,
  XUSDC,
  XUSDT,
} from "./abi";

export const lendingTokens = [
  XBUSD,
  XBTCB,
  XBNB,
  XETH,
  XUSDT,
  XUSDC,
  XDAI,
  XTUSD,
  XLEND,
  XTENFI,
];

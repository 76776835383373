import {
  GET_PENDING_FEES,
  GET_TENLOTS_REMAINING,
  GET_TENLOTS_REWARD,
  GET_TENLOTS_REWARD_PENDING,
  GET_TENLOTS_STAKED,
  GET_TENLOTS_USERS_ENTERED,
  GET_TENLOTS_USERS_STAKED,
  STAKE_LOTS,
  UNSTAKE_LOTS,
} from "@src/actions/tenlots"

const initialState = {
  lotsListRemaining: {
    remainingSilver: 0,
    remainingBronze: 0,
    remainingGold: 0,
  },
  tenLotsReward: 0,
  totalStaked: 0,
  totalUserStaked: 0,
  level: 0,
  userEntered: false,
  pendingFees: 0,
  rewardPending: 0,
  timeStamp: 0,
}

function tenLots(state = initialState, action) {
  switch (action.type) {
    case STAKE_LOTS:
      return { ...state }
    case UNSTAKE_LOTS:
      return { ...state }
    case GET_TENLOTS_REMAINING:
      return { ...state, lotsListRemaining: action.lotsListRemaining }
    case GET_TENLOTS_REWARD:
      return { ...state, tenLotsReward: action.tenLotsReward }
    case GET_TENLOTS_STAKED:
      return { ...state, totalStaked: action.totalStaked }
    case GET_TENLOTS_USERS_STAKED:
      return {
        ...state,
        totalUserStaked: action.totalUserStaked,
        level: action.level,
      }
    case GET_TENLOTS_USERS_ENTERED:
      return {
        ...state,
        userEntered: action.userEntered,
      }
    case GET_PENDING_FEES:
      return {
        ...state,
        pendingFees: action.pendingFees.pendingFee,
        timeStamp: action.pendingFees.timestamp,
      }
    case GET_TENLOTS_REWARD_PENDING:
      return {
        ...state,
        rewardPending: action.rewardPending,
      }
    default:
      return state
  }
}

export default tenLots

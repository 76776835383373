export const REQUEST_NEWS = 'REQUEST_NEWS'
export const RECEIVE_NEWS = 'RECEIVE_NEWS'


function requestNews(){
    return {
        type: REQUEST_NEWS
    }
}

function receiveNews(json) {
    return {
        type: RECEIVE_NEWS,
        news: json,
        receivedAt: Date.now()
    }
}

export function fetchNews() {

    return dispatch => {
        dispatch(requestNews())
        // uncomment this section to allow API
        /*return fetch(`${constants.api}/news`)
            .then(req => req.json())
            .then(json => dispatch(receiveNews(json)))*/
        dispatch(receiveNews([
            {
                "brief": "Swap your TENFI v1 for the NEW TENFI v2! If you’re holding the TENFI v1, swap with TENFI v2 for an equal amount plus 10% bonus!",
                "content": "Swap your TENFI v1 for the NEW TENFI v2! If you’re holding the TENFI v1, swap with TENFI v2 for an equal amount plus 10% bonus!",
                "url": "/read-more"
            }
        ]))
    }
}

function shouldFetchNews(state) {
    const news = state.news.items

    return !news.length
}

export function fetchNewsIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchNews(getState())) {
            return dispatch(fetchNews())
        }
    }
}
import React from "react"

const Tab = (props) => {
  const { onClick, active, activeColor, children, className } = props
  return (
    <label
      onClick={onClick}
      htmlFor=''
      className={`flex flex-col font-medium ${
        !active ? `dark:text-white text-violin-200` : `text-purple-100`
      }  cursor-pointer px-4 whitespace-nowrap ${className}`}>
      {children}

      <span
        className={`origin-left	transform duration-200 ease-in-out ${
          active ? `scale-x-100` : `scale-x-0 `
        } h-px mt-2 ${activeColor ? activeColor : "bg-violin-500"}`}></span>
    </label>
  )
}

export default Tab

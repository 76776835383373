import {
  FETCH_YIELDEX_UI_DATA,
  RECEIVE_YIELDEX_PARAMS,
  REQUEST_YIELDEX_PARAMS,
} from "@src/actions/yieldex"

const initialState = {
  isYieldexFetching: false,
  isFetchingUserParams: false,
  receivedAt: 0,
  pools: 0,
  yieldexUIData: [],
  params: {
    yieldexPoolsDetails: {},
  },
}

function yieldex(state = initialState, action) {
  switch (action.type) {
    case REQUEST_YIELDEX_PARAMS:
      return Object.assign({}, state, {
        pools: action.pools,
      })
    case RECEIVE_YIELDEX_PARAMS:
      return Object.assign({}, state, {
        isFetching: false,
        params: action.params,
        isYieldexFetching: false,
        receivedAt: action.receivedAt,
      })
    case FETCH_YIELDEX_UI_DATA:
      return {
        ...state,
        yieldexUIData: action.yieldexUIData,
        isYieldexFetching: true,
      }
    default:
      return state
  }
}

export default yieldex

import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import HttpApi from "i18next-http-backend"

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: [
      "en-US",
      "zh-CN",
      "zh-HK",
      "id-ID",
      "ko-KR",
      "th-TH",
      "fr-FR",
      "es-ES",
      "ja-JP",
      "pt-BR",
      "vi-VN",
      "ru-RU",
    ],
    fallbackLng: "en-US",
    detection: {
      order: [
        "path",
        "localStorage",
        "cookie",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "subdomain",
      ],
      caches: ["localStorage", "cookie"],
    },
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
    react: {
      useSuspense: false,
    },
  })

import React, { useEffect, useState } from "react"
import Button from "@components/button"
import Token from "@components/token"

const InputVariants = {
  night:
    "text-gray-700 border-gray-200 " +
    "focus:bg-white focus:border-gray-500  " +
    "dark:bg-night-100 dark:border-night-500 dark:text-night-900 " +
    "dark:hover:bg-night-500 dark:focus:bg-night-500 dark:focus:border-night-700 dark:hover:border-night-700" +
    "h-14 appearance-none block w-full transition duration-300 border-2 rounded-md text-lg rounded py-4 px-4 leading-tight",
  "dark-night":
    "text-gray-700 border-gray-300 dark:text-white " +
    "dark:bg-night-300 dark:border-night-300 " +
    "dark:hover:bg-night-100 dark:focus:bg-night-100 dark:focus:border-night-700 dark:hover:border-night-700" +
    "h-14 appearance-none block w-full transition duration-300 border-2 rounded-md text-lg rounded py-4 px-4 leading-tight",
  clean:
    "bg-gray-100 dark:bg-night-300 text-gray-700 dark:text-gray-50 border" +
    "border-transparent border-l-0 border-r-0 border-t-0 focus:border-b-2 " +
    "h-14 appearance-none block w-full transition duration-300 text-lg py-4 px-4 leading-tight",
}

function Input(props) {
  const variant = props.variant
    ? InputVariants[props.variant]
    : InputVariants["night"]

  const {
    tokenName,
    token,
    placeholder,
    id,
    value,
    onChange,
    button,
    onButtonClick,
    className,
    readonly,
    type,
    max,
    style,
    buttonLeft,
    tokenLeft,
  } = props

  const [val, setVal] = useState(value)

  useEffect(() => {
    setVal(value)
  }, [value])

  return (
    <div className={"relative w-full"}>
      <input
        readOnly={readonly}
        onChange={(e) => {
          onChange && onChange(e.target.value)
          setVal(e.target.value)
        }}
        value={value}
        min={0}
        max={max}
        className={`${variant}  ${className}`}
        style={style}
        id={id}
        type={type || "text"}
        placeholder={placeholder}
      />
      {button && (
        <div
          className={`absolute ${
            buttonLeft ? `left-3` : `right-2`
          } top-0 h-full w-12`}>
          <Button
            onClick={() => {
              onButtonClick(val, setVal)
            }}
            variant={"clean"}
            className={"h-full"}>
            {button}
          </Button>
        </div>
      )}
      {token && (
        <div
          className={`flex flex-row items-center absolute ${
            tokenLeft ? `left-4` : `right-4`
          }  top-0 h-full`}>
          <Token
            tokens={[token]}
            size={32}
            label={tokenName}
            symbol={token}
            labelStyle={`dark:text-white`}
          />
        </div>
      )}
    </div>
  )
}

export default Input
